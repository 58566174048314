import { Column, Row } from '@hivediversity/common-lib/base';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { defaultIndustry } from 'constant';
import placeholders from 'constant/placeholders';
import staticDataCreator from 'store/static-data/actions';
import { getStaticData, getSubIndustries } from 'store/static-data/selectors';
import { DropdownContainer } from 'styled';

import { SubIndustriesContainer } from './styled';
import AddAndRemoveButtons from '../add-and-remove-buttons';
import Dropdown from '../dropdown';
import ErrorMessage from '../error-message';

const MultiIndustries = ({ onChange, industries }) => {
  const dispatch = useDispatch();
  const { industries: currentIndustries } = useSelector(getStaticData);
  const subIndustries = useSelector(getSubIndustries);

  const getSubIndustriesByIndustry = industryId =>
    subIndustries?.flat()?.filter(subIndustry => Number(subIndustry?.industryId) === industryId) || [];

  const handleChangeIndustry = (name, value) => {
    const currentSubIndustries = getSubIndustriesByIndustry(value);
    if (currentSubIndustries.length === 0) {
      dispatch(staticDataCreator.staticDataFetchSubIndustriesRequest([value]));
    }
    const industryNameSplitted = name.split('.');
    const industryName = industryNameSplitted[0];
    const index = industryNameSplitted[1];
    onChange(name, value);
    onChange(`${industryName}.${index}.subIndustries`, ['']);
  };

  return (
    <>
      <FieldArray
        name="industries"
        render={arrayIndustriesHelpers => (
          <>
            {industries.map((industry, index) => (
              <Row key={index} $bsStyle={{ flexWrap: 'wrap' }}>
                <DropdownContainer
                  $bsStyle={{
                    maxWidth: '400px',
                    minWidth: '200px',
                    width: '100%',
                    marginRight: '20px',
                  }}
                >
                  <Dropdown
                    name={`industries.${index}.id`}
                    onChange={handleChangeIndustry}
                    isClearable={false}
                    showIndicatorSeparator
                    value={industry.id}
                    placeholder={placeholders.SELECT_VERTICAL}
                    options={currentIndustries}
                  />
                </DropdownContainer>
                <Row style={{ maxWidth: '170px', flexWrap: 'wrap' }}>
                  <AddAndRemoveButtons
                    onAdd={() => arrayIndustriesHelpers.push(defaultIndustry)}
                    onRemove={() => arrayIndustriesHelpers.remove(index)}
                    showRemoveButton={industries.length > 1}
                    removeButtonStyle={{ marginRight: '20px' }}
                    showAddMore={industries.length - 1 === index}
                  />
                </Row>
                <Column $bsStyle={{ flex: 1 }}>
                  <FieldArray
                    name={`industries.${index}.subIndustries`}
                    render={arraySubIndustriesHelpers => (
                      <SubIndustriesContainer>
                        {industry.subIndustries.map((subIndustry, subIndustryIndex) => (
                          <Row key={subIndustryIndex} $bsStyle={{ flex: 1, flexWrap: 'wrap' }}>
                            <DropdownContainer
                              $bsStyle={{
                                maxWidth: '400px',
                                minWidth: '200px',
                                width: '100%',
                                marginRight: '20px',
                              }}
                            >
                              <Dropdown
                                name={`industries[${index}].subIndustries[${subIndustryIndex}]`}
                                onChange={onChange}
                                isClearable={false}
                                showIndicatorSeparator
                                value={subIndustry}
                                placeholder={placeholders.SELECT_INDUSTRY}
                                options={getSubIndustriesByIndustry(industry.id)}
                                noFormik
                              />
                            </DropdownContainer>
                            <AddAndRemoveButtons
                              onAdd={() => arraySubIndustriesHelpers.push('')}
                              onRemove={() => arraySubIndustriesHelpers.remove(index)}
                              showRemoveButton={industry.subIndustries.length > 1}
                              showAddMore={industry.subIndustries.length - 1 === subIndustryIndex}
                              removeButtonStyle={{ marginRight: '20px' }}
                            />
                          </Row>
                        ))}
                        <ErrorMessage control={`industries.${index}.subIndustries`} />
                      </SubIndustriesContainer>
                    )}
                  />
                </Column>
              </Row>
            ))}
          </>
        )}
      />
    </>
  );
};

MultiIndustries.propTypes = {
  onChange: PropTypes.func.isRequired,
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      subIndustries: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    })
  ),
};

MultiIndustries.defaultProps = {
  industries: [],
};

export default MultiIndustries;
