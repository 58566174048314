import { push } from 'connected-react-router';
import { takeLatest, call, put } from 'redux-saga/effects';

import { routes } from 'constant';
import { fetchRequest, changePassword } from 'services/employer';
import { clearToken } from 'services/storage';
import authCreator from 'store/authorization/actions';
import { companyTypes } from 'store/company/actions';
import employerCreator, { employerTypes } from 'store/employer/actions';

export function* fetch() {
  try {
    const { employer } = yield call(fetchRequest);
    yield put(employerCreator.employerFetchSuccess(employer));
  } catch (error) {
    yield put(employerCreator.employerFetchError(error));
  }
}

export function* changePasswordRequest({ oldPassword, newPassword }) {
  try {
    const { success, message } = yield call(changePassword, oldPassword, newPassword);
    if (success) {
      yield call(clearToken);
      yield put(employerCreator.changePasswordSuccess());
      yield put(authCreator.authGetTokenSuccess(null));
      yield put(push(routes.LOGIN));
    } else {
      yield put(employerCreator.changePasswordError(message));
    }
  } catch (error) {
    yield put(employerCreator.changePasswordError(error));
  }
}

export function* employerSaga() {
  yield takeLatest([employerTypes.EMPLOYER_FETCH_REQUEST, companyTypes.COMPANY_FETCH_SUCCESS], fetch);
  yield takeLatest(employerTypes.CHANGE_PASSWORD_REQUEST, changePasswordRequest);
}
