import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 39px;
  margin: 0 10px 10px 0;
  justify-content: center;
  position: relative;
  flex-direction: column;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Wrapper = styled.div`
  border-radius: 20px 20px 0 0;
  width: 100%;
  max-width: 180px;
  display: flex;
  flex-direction: column;
`;

export const SideBarRight = styled.div`
  overflow-y: auto;
  box-shadow: 10px 0 17px -3px rgb(0 0 0 / 18%);
  background: #0c0c0c;
  z-index: 999999999;
  padding: 30px 70px 70px 30px;
  max-width: min(800px, 100vw);
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  flex-direction: column;
  transition: all 0.3s ease-in-out ${({ transform }) => (transform === 0 ? '' : '.2s')};
  transform: ${({ transform }) => (transform === 0 ? 0 : `translatex(${transform + 68}px)`)};
  min-height: 100%;
  margin-bottom: 50px;
  width: ${({ width }) => width || 0};
  @media screen and (max-width: 767px) {
    padding-bottom: 85px;
  }
`;

export const Menu = styled.div``;

export const CloseButton = styled.button.attrs({ type: 'button' })`
  right: 30px;
  top: 20px;
  width: 30px;
  height: 30px;
  position: fixed;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 9999999;
  background: transparent;
  border: none;
  @media screen and (max-width: 1144px) {
    ${({ hide }) => (hide ? 'opacity: 0;  pointer-events: none;' : '')}
  }

  span {
    display: block;
    position: absolute;
    height: 4px;
    background: #fdbf33;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  .top {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0;
    left: 3px;
    width: 25px;
  }

  .mid {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    width: 0;
    opacity: 0;
  }

  .bot {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 18px;
    left: 2px;
    width: 25px;
  }

  &:active,
  :focus {
    outline: 0;
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;
