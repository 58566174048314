import { Column, Row } from '@hivediversity/common-lib/base';
import { Collapsible } from '@hivediversity/common-lib/components';
import { companySection, yesNo } from '@hivediversity/common-lib/constant';
import { companyProps } from '@hivediversity/common-lib/utils';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  Modal,
  RadioButtons,
  Switch,
  Tooltip,
  UsInternationalDropdowns,
  WrapTextBox,
  ClickHereLabel,
} from 'components';
import { tooltipsTexts } from 'constant';
import { InputLabel } from 'styled';

import { ChartImg, WrapperInput } from '../styled';

const Administration = ({ onCollapse, isValid, open, values, setFieldValue, showValidateIcon }) => {
  const [showChartImageModal, setShowChartImageModal] = useState(false);
  return (
    <Collapsible
      id={companySection.ADMINISTRATION}
      onCollapse={onCollapse}
      title="Administrator of This Account"
      isValid={isValid}
      open={open}
      showValidateIcon={showValidateIcon}
    >
      <Modal
        $bsStyle={{ maxWidth: 'initial' }}
        show={showChartImageModal}
        onClose={() => setShowChartImageModal(false)}
      >
        <ChartImg />
      </Modal>
      <WrapperInput marginBottom="40px" $bsStyle={{ maxWidth: '900px' }}>
        <Row>
          <InputLabel>Account Administrator *</InputLabel>
          <Tooltip text={tooltipsTexts.ACCOUNT_ADMINISTRATOR} />
        </Row>
        <Switch
          name="international"
          value={values.pointOfContact.international}
          leftLabel="US"
          label="International"
          labelStyle={{ fontSize: '22px' }}
          leftLabelStyle={{ fontSize: '22px' }}
          onChange={() => setFieldValue('pointOfContact.international', !values.pointOfContact.international)}
          style={{ marginBottom: '25px', marginTop: '20px' }}
        />
        <Row $bsStyle={{ flexWrap: 'wrap', marginTop: 0 }}>
          <Column>
            <WrapTextBox
              name="pointOfContact.firstName"
              placeholder="First Name"
              $bsStyle={{ minWidth: '300px', maxWidth: '300px', margin: '0 10px 10px 0' }}
              maxLength={255}
              errorMessageStyle={{ marginBottom: '10px' }}
            />
          </Column>
          <Column>
            <WrapTextBox
              name="pointOfContact.lastName"
              placeholder="Last Name"
              $bsStyle={{ minWidth: '300px', maxWidth: '300px', margin: '0 10px 10px 0' }}
              maxLength={255}
              errorMessageStyle={{ marginBottom: '10px' }}
            />
          </Column>
        </Row>
        <Row $bsStyle={{ flexWrap: 'wrap' }}>
          <Column>
            <WrapTextBox
              name="pointOfContact.address"
              placeholder="Street Address"
              $bsStyle={{ minWidth: '300px', maxWidth: '300px', margin: '0 10px 10px 0' }}
              maxLength={255}
              errorMessageStyle={{ marginBottom: '10px' }}
            />
          </Column>
          <Column>
            <WrapTextBox
              name="pointOfContact.postalCode"
              placeholder="Postal Code"
              $bsStyle={{ minWidth: '300px', maxWidth: '300px', margin: '0 10px 10px 0' }}
              maxLength={255}
            />
          </Column>
        </Row>
        <UsInternationalDropdowns
          names={{
            country: 'pointOfContact.countryId',
            anotherCountry: 'pointOfContact.countryName',
            anotherCity: 'pointOfContact.cityName',
            state: 'pointOfContact.stateCode',
            city: 'pointOfContact.cityId',
          }}
          values={{
            international: values.pointOfContact.international,
            countryId: values.pointOfContact.countryId,
            countryName: values.pointOfContact.countryName,
            cityName: values.pointOfContact.cityName,
            stateId: values.pointOfContact.stateCode,
            cityId: values.pointOfContact.cityId,
          }}
          setFieldValue={setFieldValue}
          showAddAnotherCountry={values.pointOfContact.countryId === 214}
          showAddButton={false}
        />
        <Row $bsStyle={{ flexWrap: 'wrap' }}>
          <Column>
            <WrapTextBox
              name="pointOfContact.companyEmailDomain"
              placeholder="Company Email Domain"
              $bsStyle={{ minWidth: '300px', maxWidth: '300px', margin: '0 10px 10px 0' }}
              maxLength={255}
              errorMessageStyle={{ marginBottom: '10px' }}
            />
          </Column>
          <Column>
            <WrapTextBox
              name="pointOfContact.email"
              placeholder="Email"
              $bsStyle={{ minWidth: '300px', maxWidth: '300px', margin: '0 10px 10px 0' }}
              maxLength={255}
            />
          </Column>
        </Row>
        <Row $bsStyle={{ flexWrap: 'wrap', marginTop: 0 }}>
          <Column>
            <WrapTextBox
              name="pointOfContact.phone"
              placeholder="Phone"
              $bsStyle={{ minWidth: '300px', maxWidth: '300px', margin: '0 10px 10px 0' }}
              maxLength={255}
              errorMessageStyle={{ marginBottom: '10px' }}
            />
          </Column>
        </Row>
      </WrapperInput>
      <WrapperInput>
        <InputLabel>View HIVE Community Aggregate Data & Insights Reporting *</InputLabel>
        <ClickHereLabel
          onClick={() => setShowChartImageModal(true)}
          label="to see an example of HIVE's Aggregate Data & Insights Reporting provided for all of
          HIVE's community."
        />
        <RadioButtons
          name="aggregateDataHive"
          options={yesNo}
          value={values.aggregateDataHive}
          setFieldValue={setFieldValue}
          isHorizontal
        />
      </WrapperInput>
    </Collapsible>
  );
};

Administration.propTypes = {
  onCollapse: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  showValidateIcon: PropTypes.bool,
  values: companyProps.isRequired,
};

Administration.defaultProps = {
  showValidateIcon: false,
};

export default Administration;
