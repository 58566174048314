import { Column, Row } from '@hivediversity/common-lib/base';
import { opportunityProps } from '@hivediversity/common-lib/utils';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicantCard } from 'components';
import { applicantsDefaultFilters, contactTagEntities, resumeOrigin } from 'constant';
import opportunityDataCreator from 'store/opportunity/actions';
import {
  getApplicants,
  getOpportunityMetadata,
  getOpportunityApplicantsFilters,
  getIsLoading as getIsLoadingApplicants,
  getError,
} from 'store/opportunity/selectors';
import staticDataCreator from 'store/static-data/actions';
import { getIsLoading } from 'store/static-data/selectors';

import { WrapperCards, NoSearchResults } from './styled';
import TopFilters from './top-filters';

const Applicants = ({ opportunity }) => {
  const dispatch = useDispatch();
  const [staticDataRequested, setStaticDataRequested] = useState(false);
  const isLoadingStaticData = useSelector(getIsLoading);
  const applicants = useSelector(getApplicants);
  const filters = useSelector(getOpportunityApplicantsFilters);
  const isLoadingApplicants = useSelector(getIsLoadingApplicants);
  const metadata = useSelector(getOpportunityMetadata);
  const error = useSelector(getError);
  const [scrollYPosition, setScrollYPosition] = useState(0);

  useEffect(() => {
    setStaticDataRequested(true);
    dispatch(
      staticDataCreator.staticDataFetchRequest({
        tags: true,
        contactTags: true,
        majorCategories: true,
        languages: true,
        jobFunctions: true,
        months: true,
      })
    );
  }, []);

  useEffect(() => {
    if (staticDataRequested && !isLoadingStaticData) {
      dispatch(staticDataCreator.staticDataFetchIndustriesRequest());
      dispatch(staticDataCreator.getZonesRequest());
      dispatch(staticDataCreator.getMajorsMinorsRequest());
    }
  }, [staticDataRequested, isLoadingStaticData]);

  const handleScroll = event => {
    const root = document.getElementById('root');
    const topFilters = document.getElementById('filters');
    const bottom =
      event.target.scrollTop > 0 &&
      Math.ceil(event.target.scrollTop + event.target.clientHeight) >= event.target.scrollHeight;
    if (metadata?.last_page > metadata?.current_page && bottom && event.target.contains(topFilters)) {
      dispatch(
        opportunityDataCreator.getOpportunityApplicantsRequest(opportunity.id, {
          ...filters,
          page: metadata.current_page + 1,
        })
      );
      setScrollYPosition(root.scrollTop - 20);
    }
  };

  useEffect(() => {
    const root = document.getElementById('root');
    root.scrollTo(0, scrollYPosition);
  }, [scrollYPosition]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [metadata]);

  return (
    <>
      <TopFilters opportunityId={opportunity.id} locations={opportunity.locations} />
      <WrapperCards>
        {applicants.map(applicant => (
          <Column key={applicant.id}>
            <ApplicantCard
              applicant={applicant}
              opportunity={opportunity}
              entity={contactTagEntities.OPPORTUNITY}
              origin={resumeOrigin.OPPORTUNITY}
            />
          </Column>
        ))}
      </WrapperCards>
      {isLoadingApplicants && (
        <Row center>
          <Loader type="TailSpin" color="white" height={40} width={40} />
        </Row>
      )}
      {error ? (
        <div style={{ color: 'white', textAlign: 'center' }}>
          <h3 style={{ letterSpacing: '0.9px' }}>{error}</h3>
        </div>
      ) : (
        <>
          {!isLoadingApplicants && isEmpty(applicants) && (
            <>
              {isEqual(filters, applicantsDefaultFilters) ? (
                <div style={{ color: 'white', textAlign: 'center' }}>
                  <h3 style={{ letterSpacing: '0.9px' }}>This position doesn&apos;t have applicants yet.</h3>
                </div>
              ) : (
                <NoSearchResults>No search results found</NoSearchResults>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

Applicants.propTypes = {
  opportunity: opportunityProps.isRequired,
};

export default Applicants;
