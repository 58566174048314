import PropTypes from 'prop-types';
import React from 'react';

import Switch from '../switch';
import UsInternationalDropdown from '../us-international-dropdowns';

const UsOrInternational = ({
  names,
  values,
  setFieldValue,
  isClearable,
  onMore,
  onClear,
  showAddAnotherCountry,
  showZipCode,
  disabled,
  showRemoveButton,
  showAddButton,
  showSwitch,
}) => (
  <>
    {showSwitch && (
      <Switch
        name={names.international}
        value={values.international}
        leftLabel="US"
        label="International"
        labelStyle={{ fontSize: '22px' }}
        leftLabelStyle={{ fontSize: '22px' }}
        onChange={() => setFieldValue(names.international, !values.international)}
        style={{ marginTop: '20px' }}
        disabled={disabled}
      />
    )}
    <UsInternationalDropdown
      values={values}
      names={names}
      setFieldValue={setFieldValue}
      onClear={onClear}
      onMore={onMore}
      isClearable={isClearable}
      showAddAnotherCountry={showAddAnotherCountry}
      showZipCode={showZipCode}
      disabled={disabled}
      showRemoveButton={showRemoveButton}
      showAddButton={showAddButton}
    />
  </>
);

UsOrInternational.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  onMore: PropTypes.func,
  onClear: PropTypes.func,
  names: PropTypes.shape({
    international: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    anotherCountry: PropTypes.string.isRequired,
    anotherCity: PropTypes.string.isRequired,
    zipCode: PropTypes.string,
  }).isRequired,
  values: PropTypes.shape({
    countryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stateId: PropTypes.string,
    cityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    international: PropTypes.bool,
    countryName: PropTypes.string,
    cityName: PropTypes.string,
    zipCode: PropTypes.string,
  }).isRequired,
  isClearable: PropTypes.bool,
  showAddAnotherCountry: PropTypes.bool,
  showZipCode: PropTypes.bool,
  disabled: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
  showAddButton: PropTypes.bool,
  showSwitch: PropTypes.bool,
};

UsOrInternational.defaultProps = {
  isClearable: true,
  showAddButton: true,
  showSwitch: true,
  showAddAnotherCountry: false,
  showRemoveButton: false,
  showZipCode: false,
  disabled: false,
  onClear: undefined,
  onMore: undefined,
};

export default UsOrInternational;
