import { Error404 } from '@hivediversity/common-lib/components/errors';
import React, { useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import { ErrorFallback, Header } from 'components';
import { routes } from 'constant';
import ApplicantResume from 'pages/applicant-resume';
import ChangePassword from 'pages/change-password';
import Company from 'pages/company';
import InitialCompanyEdit from 'pages/initial-company-edit';
import Opportunities from 'pages/opportunities';
import Opportunity from 'pages/opportunity';
import Profile from 'pages/profile';
import Support from 'pages/support';
import Welcome from 'pages/welcome';
import { getProfileComplete } from 'store/company/selectors';

const WithSessionRoutes = () => {
  const { push } = useHistory();
  const profileComplete = useSelector(getProfileComplete);

  const Page404 = useCallback(
    () => (
      <Error404
        goBackButton={{
          label: 'Back to Home Page',
          onClick: () => push(routes.ROOT),
        }}
      />
    ),
    []
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {profileComplete ? (
        <>
          <Header />
          <Switch>
            <Redirect exact from={routes.ROOT} to={routes.DASHBOARD} />
            <Route exact path={[routes.NEW_OPPORTUNITY, routes.EDIT_OPPORTUNITY]} component={Opportunity} />
            <Route exact path={routes.COMPANY} component={Company} />
            <Route exact path={routes.SUPPORT} component={Support} />
            <Route exact path={routes.DASHBOARD} component={Opportunities} />
            <Route exact path={routes.CHANGE_PASSWORD} component={ChangePassword} />
            <Route exact path={routes.PROFILE} component={Profile} />
            <Route exact path={routes.RESUME} component={ApplicantResume} />
            <Route path="/profile/:opportunityId/:applicantId" component={Profile} />
            {/* TODO: uncomment after clarification from customer */}
            {/* {company.aggregateDataHive && <Route exact path={routes.DATA_INSIGHTS} component={DataInsights} />} */}
            <Route exact path={routes.ERROR_404} component={Page404} />
            <Redirect from="*" to={routes.ERROR_404} />
          </Switch>
        </>
      ) : (
        <>
          <Header showOpportunitiesItem={false} />
          <Switch>
            <Redirect exact from={routes.ROOT} to={routes.WELCOME} />
            <Route exact path={routes.NEW_COMPANY} component={InitialCompanyEdit} />
            <Route exact path={routes.SUPPORT} component={Support} />
            <Route exact path={routes.CHANGE_PASSWORD} component={ChangePassword} />
            <Route exact path={routes.WELCOME} component={Welcome} />
            <Route exact path={routes.ERROR_404} component={Page404} />
            <Redirect from="*" to={routes.ERROR_404} />
          </Switch>
        </>
      )}
    </ErrorBoundary>
  );
};

export default WithSessionRoutes;
