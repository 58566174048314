import { CustomButton } from '@hivediversity/common-lib/buttons';
import { colors } from '@hivediversity/common-lib/constant';
import PropTypes from 'prop-types';
import React from 'react';

const OpportunityButtons = ({ opportunityId, onViewAll, onEdit, totalApplicants }) => (
  <>
    <CustomButton
      color={colors.ORANGE}
      id={opportunityId}
      onClick={onViewAll}
      $bsStyle={{
        maxWidth: '100px',
        minWidth: '155px',
        lineHeight: 'initial',
        marginBottom: '10px',
      }}
    >
      {`View All (${totalApplicants})`}
    </CustomButton>
    <CustomButton
      color={colors.BLUE}
      id={opportunityId}
      onClick={onEdit}
      $bsStyle={{ maxWidth: '100px', minWidth: '155px' }}
    >
      Edit
    </CustomButton>
  </>
);

OpportunityButtons.propTypes = {
  onViewAll: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  opportunityId: PropTypes.number.isRequired,
  totalApplicants: PropTypes.number.isRequired,
};

export default OpportunityButtons;
