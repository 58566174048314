import { companyProps } from '@hivediversity/common-lib/utils';
import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number,
  active: PropTypes.bool,
  city: PropTypes.string,
  company: companyProps,
  companyId: PropTypes.number,
  country: PropTypes.string,
  createdAt: PropTypes.string,
  deletedAt: PropTypes.string,
  editCompanyProfile: PropTypes.bool,
  email: PropTypes.string,
  emailVerificationToken: PropTypes.string,
  emailVerifiedAt: PropTypes.string,
  isCompanyAdmin: PropTypes.bool,
  state: PropTypes.string,
  updatedAt: PropTypes.string,
  username: PropTypes.string,
});
