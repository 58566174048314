import { Hr } from '@hivediversity/common-lib/base';
import { CustomButton } from '@hivediversity/common-lib/buttons';
import { LoadingPage } from '@hivediversity/common-lib/components';
import { colors, status } from '@hivediversity/common-lib/constant';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import logo from 'assets/svg/hive-new.svg';
import { Dropdown } from 'components';
import { routes, opportunitiesEntities } from 'constant';
import companyCreator from 'store/company/actions';
import { getCompany } from 'store/company/selectors';
import { getEmployer } from 'store/employer/selectors';
import opportunityCreator from 'store/opportunity/actions';
import {
  getOpportunitiesByStatus,
  getArchivedOpportunities,
  getOpportunitiesCreatedByOthers,
  getIsLoadingByEntity,
} from 'store/opportunity/selectors';
import staticDataCreator from 'store/static-data/actions';
import { Container, HeaderContainer, Title } from 'styled';

import ArchiveOpportunities from './archive';
import BlockOpportunity from './block-opportunity';
import HireSomeoneQuestionModal from './hire-someone-question-modal';
import { FilterContainer, WelcomeTitle, WrapperButtons, Logo, LogoContainer } from './styled';

const Opportunities = () => {
  const dispatch = useDispatch();
  const [sortValue, setSortValue] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const company = useSelector(getCompany);
  const isLoadingOpportunitiesCreatedByMe = useSelector(state =>
    getIsLoadingByEntity(state, opportunitiesEntities.OPPORTUNITIES_CREATED_BY_ME)
  );
  const isLoadingOpportunitiesCreatedByOthers = useSelector(state =>
    getIsLoadingByEntity(state, opportunitiesEntities.OPPORTUNITIES_CREATED_BY_OTHERS)
  );
  const isLoadingOpportunitiesArchived = useSelector(state =>
    getIsLoadingByEntity(state, opportunitiesEntities.ARCHIVED_OPPORTUNITIES)
  );
  const employer = useSelector(getEmployer);
  const archiveOpportunities = useSelector(getArchivedOpportunities);
  const opportunitiesCreatedByMe = useSelector(state =>
    getOpportunitiesByStatus(state, [status.ACTIVE, status.INACTIVE], sortOrder, sortValue)
  );
  const opportunitiesCreatedByOthers = useSelector(getOpportunitiesCreatedByOthers);

  const handleRedirect = useCallback(e => dispatch(push(e.target.id)), []);
  const goToOpportunityCreate = () => dispatch(push(routes.NEW_OPPORTUNITY));

  const handleToggleSort = (control, sort) => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSortValue(sort);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(staticDataCreator.staticDataFetchRequest({ tags: true, contactTags: true, months: true }));
    dispatch(
      opportunityCreator.opportunityFetchRequest(opportunitiesEntities.ARCHIVED_OPPORTUNITIES, {
        status: status.ARCHIVED,
      })
    );
    dispatch(
      opportunityCreator.opportunityFetchRequest(opportunitiesEntities.OPPORTUNITIES_CREATED_BY_ME, { createdBy: 1 })
    );
    dispatch(
      opportunityCreator.opportunityFetchRequest(opportunitiesEntities.OPPORTUNITIES_CREATED_BY_OTHERS, {
        createdBy: 0,
      })
    );
    dispatch(
      opportunityCreator.opportunityFetchRequest(opportunitiesEntities.EXPIRED_OPPORTUNITIES, {
        startDatePassed: 1,
      })
    );
    dispatch(companyCreator.companyFetchProgressRequest());
    dispatch(opportunityCreator.cleanOpportunity());
  }, []);

  return (
    <>
      {isLoadingOpportunitiesCreatedByMe || isLoadingOpportunitiesCreatedByOthers || isLoadingOpportunitiesArchived ? (
        <LoadingPage background="black" />
      ) : (
        <>
          <HireSomeoneQuestionModal />
          <Container background="linear-gradient(to bottom right, #FD4CF4, #000)">
            {isEmpty(archiveOpportunities) &&
            isEmpty(opportunitiesCreatedByMe) &&
            isEmpty(opportunitiesCreatedByOthers) ? (
              <>
                <LogoContainer>
                  <Logo src={logo} alt="Hive Diversity" />
                </LogoContainer>
                <WelcomeTitle>Welcome to the Hive!</WelcomeTitle>
                <WrapperButtons>
                  <CustomButton
                    onClick={goToOpportunityCreate}
                    color={colors.ORANGE}
                    $bsStyle={{ maxWidth: 'none', width: 'auto', margin: '0 15px' }}
                  >
                    Create a Position
                  </CustomButton>
                  <CustomButton
                    id={employer?.editCompanyProfile ? '/company?tab=edit' : '/company?tab=review'}
                    onClick={handleRedirect}
                    color={colors.LIGHT_BLUE}
                    $bsStyle={{ maxWidth: 'none', width: 'auto', margin: '0 15px' }}
                  >
                    {employer?.editCompanyProfile ? 'Edit Company Profile' : 'View Company Profile'}
                  </CustomButton>
                </WrapperButtons>
              </>
            ) : (
              <>
                <HeaderContainer>
                  <Title>{company.name}</Title>
                  <CustomButton
                    color={colors.VIOLET}
                    onClick={goToOpportunityCreate}
                    $bsStyle={{ maxWidth: '150px', alignSelf: 'flex-start', lineHeight: 'initial' }}
                  >
                    Create new position
                  </CustomButton>
                  <Hr margin="20px 0 10px 0" />
                </HeaderContainer>

                <FilterContainer>
                  <Dropdown
                    noFormik
                    name="sort"
                    onChange={handleToggleSort}
                    placeholder="Sort"
                    options={[
                      { id: 'title', name: 'Name' },
                      { id: 'createdAt', name: 'Date' },
                      { id: 'type', name: 'Type' },
                    ]}
                    value={sortValue}
                    $bsStyle={{
                      padding: 0,
                      margin: '0 0 0 19px',
                      maxWidth: '300px',
                      minWidth: '180px',
                    }}
                    dropdownStyles={{
                      placeholder: {
                        color: '#fff',
                        fontSize: '14px',
                        padding: '0 6px',
                        fontWeight: 300,
                      },
                      container: {
                        borderRadius: '8px',
                        minHeight: '40px',
                      },
                    }}
                  />
                </FilterContainer>
                {opportunitiesCreatedByMe.map(opportunity => (
                  <BlockOpportunity
                    key={opportunity.id}
                    opportunity={opportunity}
                    entity={opportunitiesEntities.OPPORTUNITIES_CREATED_BY_ME}
                  />
                ))}
                <Title>Created by Others</Title>
                {opportunitiesCreatedByOthers.map(opportunity => (
                  <BlockOpportunity
                    key={opportunity.id}
                    opportunity={opportunity}
                    entity={opportunitiesEntities.OPPORTUNITIES_CREATED_BY_OTHERS}
                  />
                ))}
                <ArchiveOpportunities title="Archived Positions" opportunities={archiveOpportunities} />
              </>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default Opportunities;
