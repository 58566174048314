import { apiRoutes } from 'constant';

import { post } from './http';

export const requestMailValid = email => post(apiRoutes.EMAIL_EXISTS, { email });

export const requestSendMail = email => post(apiRoutes.FORGOT_PASSWORD, { email });

export const requestResetPassword = (email, token, password) =>
  post(apiRoutes.RESET_PASSWORD, { email, token, password });
