import { CustomButton as Button } from '@hivediversity/common-lib/buttons';
import { colors } from '@hivediversity/common-lib/constant';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCompany } from 'store/company/selectors';
import employerCreator from 'store/employer/actions';
import { getEmployer, getError, getIsLoading } from 'store/employer/selectors';

import {
  Wrapper,
  Text,
  Title,
  UsersImg,
  WrapperContent,
  WrapperHexagons,
  Container,
  WrapperWithoutEdit,
  UsersImagesContainer,
  HiveImage,
} from './styled';

const Welcome = () => {
  const dispatch = useDispatch();
  const goToCompanyEdit = () => dispatch(push('/company/new'));
  const employer = useSelector(getEmployer);
  const company = useSelector(getCompany);
  const isLoading = useSelector(getIsLoading);
  const error = useSelector(getError);

  useEffect(() => {
    dispatch(employerCreator.employerFetchRequest());
  }, [dispatch]);

  return (
    !isLoading && (
      <Container
        noPadding
        background="linear-gradient(167.94deg, rgba(29, 37, 38, 0.82) -0.81%, rgba(6, 8, 8, 0.82) 100.61%), #000000;"
      >
        <HiveImage />
        {employer && employer.editCompanyProfile && (
          <WrapperHexagons>
            <Wrapper>
              <WrapperContent>
                <Title>Let&apos;s Get Buzzing!</Title>
                <Text>
                  We are a revolutionary, first-of-its-kind virtual recruiting platform that’s built for equity and
                  powered by inclusion. Welcome to our community of next generation talent and companies that value
                  diversity.
                </Text>
                <Button
                  $bsStyle={{ minHeight: '60px', borderRadius: '30px', fontSize: '22px' }}
                  color={colors.ORANGE}
                  onClick={goToCompanyEdit}
                >
                  Create profile
                </Button>
              </WrapperContent>
              <UsersImagesContainer>
                <UsersImg />
              </UsersImagesContainer>
            </Wrapper>
          </WrapperHexagons>
        )}
        {employer && !employer.editCompanyProfile && (
          <WrapperWithoutEdit>
            <WrapperContent>
              <Text>Your company is not activated yet, please contact HIVE admin</Text>
            </WrapperContent>
          </WrapperWithoutEdit>
        )}
        {(!company && !employer) ||
          (error && (
            <WrapperWithoutEdit>
              <WrapperContent>{error || <Text>Error, please contact an admin</Text>}</WrapperContent>
            </WrapperWithoutEdit>
          ))}
      </Container>
    )
  );
};

export default Welcome;
