export const applicantsFilter = [
  { id: 1, name: 'All Applicants' },
  { id: 2, name: 'Selected Applicants' },
];

export const registrantsFilter = [
  { id: 1, name: 'All Registrants' },
  { id: 2, name: 'Select Registrants Later from Dashboard' },
];

export const applicationsReceived = [
  { id: 2, name: 'Daily' },
  { id: 3, name: 'Weekly' },
  { id: 4, name: 'At Deadline Only', exclusive: true },
];

export const selectAll = { id: 0, name: 'Select All', selectAll: true };

export const unselectAll = { id: 0, name: 'Unselect All', unselectAll: true };

export const locationTypesForEvent = [
  { id: 1, name: 'In-Person' },
  { id: 2, name: 'Virtual' },
];

export const applicantsSort = [
  { id: 'name', name: 'Name' },
  { id: 'postulationDate', name: 'Date' },
  { id: 'status', name: 'Status' },
];

export const RADIO_BUTTON_OPTIONS = [
  {
    id: 1,
    name: 'Yes',
  },
  {
    id: 0,
    name: 'No',
  },
  {
    id: -1,
    name: 'I prefer not to answer',
  },
];
