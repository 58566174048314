import { createReducer } from 'reduxsauce';

import { applicantTypes } from 'store/applicant/actions';
import { initialState } from 'store/applicant/initial-state';

const findStudentRequest = state => ({
  ...state,
  isLoading: true,
  error: initialState.error,
  student: initialState.student,
});

const findStudentSuccess = (state, { student }) => ({
  ...state,
  student,
  isLoading: initialState.isLoading,
  error: initialState.error,
});

const findStudentFailure = (state, { error }) => ({
  ...state,
  error,
  isLoading: initialState.isLoading,
  applicant: initialState.applicant,
});

const sendApplicantLinkRequest = (state, { applicantId }) => ({
  ...state,
  isSendingLink: true,
  mailSent: false,
  applicantToSendEmail: applicantId,
  error: initialState.error,
});

const sendApplicantLinkSuccess = state => ({
  ...state,
  isSendingLink: false,
  mailSent: true,
  error: initialState.error,
});

const sendApplicantLinkFailure = (state, { error }) => ({
  ...state,
  error,
  mailSent: false,
  applicantToSendEmail: undefined,
  isSendingLink: false,
});

const getApplicantEmailRequest = state => ({
  ...state,
  gettingEmail: true,
  error: initialState.error,
});

const getApplicantEmailSuccess = (state, { email }) => ({
  ...state,
  gettingEmail: false,
  email,
  error: initialState.error,
});

const getApplicantEmailFailure = (state, { error }) => ({
  ...state,
  error,
  gettingEmail: false,
});

const downloadApplicantsCSVRequest = state => ({
  ...state,
  error: null,
  downloading: true,
});

const downloadApplicantsCSVSuccess = state => ({
  ...state,
  error: null,
  downloading: false,
});

const downloadApplicantsCSVFailure = (state, { error }) => ({
  ...state,
  error,
  downloading: false,
});

const cleanApplicantEmail = state => ({
  ...state,
  email: undefined,
});

const reducer = createReducer(initialState, {
  [applicantTypes.APPLICANT_FIND_STUDENT_REQUEST]: findStudentRequest,
  [applicantTypes.APPLICANT_FIND_STUDENT_SUCCESS]: findStudentSuccess,
  [applicantTypes.APPLICANT_FIND_STUDENT_ERROR]: findStudentFailure,

  [applicantTypes.APPLICANT_SEND_LINK_REQUEST]: sendApplicantLinkRequest,
  [applicantTypes.APPLICANT_SEND_LINK_SUCCESS]: sendApplicantLinkSuccess,
  [applicantTypes.APPLICANT_SEND_LINK_ERROR]: sendApplicantLinkFailure,

  [applicantTypes.GET_APPLICANT_EMAIL_REQUEST]: getApplicantEmailRequest,
  [applicantTypes.GET_APPLICANT_EMAIL_SUCCESS]: getApplicantEmailSuccess,
  [applicantTypes.GET_APPLICANT_EMAIL_ERROR]: getApplicantEmailFailure,

  [applicantTypes.DOWNLOAD_APPLICANTS_CSV_REQUEST]: downloadApplicantsCSVRequest,
  [applicantTypes.DOWNLOAD_APPLICANTS_CSV_SUCCESS]: downloadApplicantsCSVSuccess,
  [applicantTypes.DOWNLOAD_APPLICANTS_CSV_ERROR]: downloadApplicantsCSVFailure,

  [applicantTypes.CLEAN_APPLICANT_EMAIL]: cleanApplicantEmail,
});

export default reducer;
