import styled from 'styled-components';

export const ButtonNotes = styled.button`
  top: 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  background: linear-gradient(
      160deg,
      rgba(139, 53, 201, 0.9) 0%,
      rgba(175, 115, 218, 0.7) 40%,
      rgba(255, 255, 255, 0.2) 100%
    ),
    #53b7c2;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Gentleman', Arial, sans-serif;
  left: 35px;
  position: absolute;
  z-index: 10;
  font-size: medium;
`;

export const TagNumberOfNotes = styled.div`
  font-size: initial;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(191, 91, 149, 1) 0%, rgba(244, 174, 69, 1) 100%), rgb(191, 91, 149);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Gentleman', Arial, sans-serif;
  left: 30px;
  position: absolute;
  top: 29px;
  z-index: 11;
  text-align: center;
`;

export const NotesContainer = styled.div`
  width: 30rem;
  height: 30em;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 1rem;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: gray;
  }
  @media screen and (max-width: 650px) {
    width: 14rem;
    height: 14em;
  }
`;

export const DivHacho2 = styled.div`
  height: 5rem;
  background: blue;
`;

export const TextareaNote = styled.textarea`
  opacity: 1;
  overflow-y: hidden;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-family: 'Gentleman', Arial, sans-serif;
  font-weight: 400;
  -webkit-letter-spacing: 0.01px;
  -moz-letter-spacing: 0.01px;
  -ms-letter-spacing: 0.01px;
  letter-spacing: 0.01px;
  border: 1px white solid;
  border-radius: 3px;
  padding: 12px 13px;
  font-size: 13px;
  resize: none;
  line-height: 1.5;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: transparent;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: scroll;
  height: 100%;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
`;

export const NoteConteiner = styled.div`
  color: #fff;
  font-weight: 700;
  background: linear-gradient(
      160deg,
      rgba(139, 53, 201, 0.9) 0%,
      rgba(175, 115, 218, 0.7) 40%,
      rgba(255, 255, 255, 0.2) 100%
    ),
    #53b7c2;
  border-radius: 20px;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  text-transform: uppercase;
  border: transparent;
  margin: 2em 0.5em;
  padding: 1em 0.5em;
`;

export const NoteConteinerText = styled.div`
  text-align: justify;
  padding: 0.5rem 1rem;
  font-size: x-small;
`;

export const NoteConteinerTitle = styled.div`
  text-align: initial;
  margin: 0rem 1rem;
`;

export const NoteConteinerDeleteButton = styled.button`
  border: none;
  background: transparent;
  color: #fff;
  font-weight: bold;
  float: right;
  cursor: pointer;
  margin: -35px 7px 0 0;
  text-transform: lowercase;
`;

export const NoteConteinerDate = styled.div`
  text-align: initial;
  font-size: x-small;
  margin: 0rem 1rem;
`;

export const AlertAction = styled.div`
  font-weight: bold;
  margin: 1rem;
`;

export const EmptyNoteContainer = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30rem;
  height: 30em;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 1rem;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: gray;
  }
  @media screen and (max-width: 650px) {
    width: 14rem;
    height: 14em;
  }
`;

export const EmptyNoteBody = styled.div`
  text-align: center;
  font-size: x-large;
  font-weight: 600;
  @media screen and (max-width: 650px) {
    font-size: large;
  }
`;

export const EmptyNoteBodyIcon = styled.div`
  margin: 1rem;
  font-size: 7rem;
  @media screen and (max-width: 650px) {
    font-size: 4rem;
  }
`;

export const ModalTagNumberOfNotes = styled.div`
  width: 6.5rem;
  font-size: x-small;
  padding: 0.2rem;
  margin: 0.2rem;
  border-radius: 1rem;
  border: none;
  outline: none;
  background: linear-gradient(90deg, rgba(191, 91, 149, 1) 0%, rgba(244, 174, 69, 1) 100%), rgb(191, 91, 149);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Gentleman', Arial, sans-serif;
  left: 30px;
  position: absolute;
  top: 29px;
  z-index: 11;
  text-align: center;
`;
