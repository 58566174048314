import styled from 'styled-components';

import checkIcon from 'assets/svg/violet-check.svg';

export const CheckField = styled.input`
  &:not(:checked),
  &:checked {
    position: absolute;
    left: -9999px;
  }
  &:checked ~ span {
    background-color: #fff;
    background-image: url(${checkIcon});
    border: none;
    opacity: 1;
    background-size: 46%;
    background-repeat: no-repeat;
    margin: auto;
  }
  &:checked ~ p {
    opacity: 1;
  }
`;

export const Label = styled.label`
  display: flex;
  cursor: pointer;
  padding: 4px 0;
  align-items: center;
  color: white;
  @media screen and (max-height: 800px) {
    padding: 1.5vh 1vh;
  }

  p {
    @media screen and (max-height: 800px) {
      font-size: 2vh;
    }
  }

  span {
    content: '';
    height: 25px;
    width: 25px;
    display: block;
    flex-shrink: 0;
    border-radius: 100%;
    border: 1px solid #fff;
    background-size: 50%;
    background: transparent no-repeat center center;
    @media screen and (max-height: 800px) {
      height: 4vh;
      width: 4vh;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ centered }) => (centered ? `display: flex; align-items: center;` : '')};
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const WrapperCheck = styled.div`
  display: flex;
  flex-direction: row;
`;
