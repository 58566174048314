import styled from 'styled-components';

export const WrapperButton = styled.div`
  max-width: 180px;
  flex: 1;
  margin: 0 10px 5px 0;
  @media screen and (max-width: 767px) {
    margin: 0 5px 10px;
    width: 50%;
  }
`;

export const WrapperCheckbox = styled.label`
  display: block;
  cursor: pointer;
  & button {
    border: ${({ checkedTag }) => (checkedTag ? '2px solid #fff' : 'none')};
  }
  & input[type='checkbox'] {
    display: none;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 -10px 40px auto;
  justify-content: flex-end;
  @media screen and (max-width: 767px) {
    justify-content: center;
    margin: 0 -5px 20px;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 -20px 66px;
`;

export const WrapperCards = styled.div`
  flex-direction: row;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  grid-gap: 40px;
`;

export const NoSearchResults = styled.p`
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  color: white;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Title = styled.label`
  font-size: 20px;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-weight: 700;
  margin-right: 8px;
  margin-bottom: 3px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }
`;
