const API = process.env.REACT_APP_URL_BASE;

const applicants = `${API}employer/applicants`;
const applicant = `${applicants}/:id`;

const applicantsRoutes = {
  APPLICANTS: applicants,
  APPLICANT: applicant,
  UPDATE_CONTACT_TAG: `${applicant}/updateContactTag`,
  CONFIRM_SEND_APPLICANT_EMAIL: `${applicant}/updateContactTagConfirm`,
  APPLICANT_EMAIL: `${applicants}/mail/preview/:applicantId/:tagId?format=plane`,
  SEND_APPLICANT_LINK: `${applicant}/sendLink`,
};

const company = `${API}employer/company`;

const companyRoutes = {
  COMPANY: company,
  ACTIVATE: `${company}/activate`,
  VIDEOS: `${company}/videos`,
  CULTURE_IMAGE: `${company}/cultureImage`,
  SOCIAL_IMPACT_IMAGE: `${company}/socialImpactImage`,
  COMPANY_PHOTO: `${company}/photo`,
  COMPANY_LOGO: `${company}/logo`,
  WELCOME_IMAGE: `${company}/welcomeImage`,
  PROGRESS: `${company}/progress`,
};

const employer = `${API}employer`;

const employerRoutes = {
  EMPLOYER: employer,
  UPDATE_PASSWORD: `${employer}/updatePassword`,
  USERS_LIST: `${employer}/list`,
};

const employerAuth = `${API}auth/employer`;

const loginRoutes = {
  LOGIN: `${employerAuth}/login`,
  IMPERSONATE: `${employerAuth}/impersonate`,
};

const opportunities = `${company}/opportunities`;
const opportunitiesApplicants = `${opportunities}/:opportunityId/applicants/:applicantId`;
const opportunity = `${company}/opportunity`;
const opportunityWithId = `${opportunity}/:id`;
const applicantsByOpportunity = `${opportunityWithId}/applicants`;

const applicantsNotes = `${company}/applicants`;

const opportunitiesRoutes = {
  OPPORTUNITIES: opportunities,
  OPPORTUNITY: opportunity,
  OPPORTUNITY_WITH_ID: opportunityWithId,
  OPPORTUNITY_PHOTO: `${opportunityWithId}/photo`,
  TEAM_MEDIA: `${opportunityWithId}/teamMedia`,
  COWORKERS_VIDEO: `${opportunityWithId}/coworkersVideo`,
  OPPORTUNITY_LOGO: `${opportunityWithId}/logo`,
  OPPORTUNITY_APPLICANTS: applicantsByOpportunity,
  OPPORTUNITY_APPLICANTS_LIST: `${applicantsByOpportunity}/list`,
  POTENTIAL_APPLICANTS: `${opportunity}/potentialApplicants`,
  EXPIRED_OPPORTUNITIES: `${opportunities}/hire`,
  REMIND_LATER_OR_DECLINE: `${opportunities}/remind-me-later-decline`,
  STUDENT: `${opportunitiesApplicants}/student`,
  OPPORTUNITY_APPLICANT: opportunitiesApplicants,
  DOWNLOAD_APPLICANTS_CSV: `${applicantsByOpportunity}/csv`,
  CREATE_APPLICANT_NOTE: `${applicantsNotes}/:id/notes`,
  DELETE_APPLICANT_NOTE: `${applicantsNotes}/notes/:id`,
};

const recoveryRoutes = {
  EMAIL_EXISTS: `${employerAuth}/emailExists`,
  FORGOT_PASSWORD: `${employerAuth}/forgotPassword`,
  RESET_PASSWORD: `${employerAuth}/resetPassword`,
};

const statRoutes = {
  STATS: `${employer}/students/stats`,
};

const industries = `${API}industries`;
const states = `${API}states`;

const staticDataRoutes = {
  STATIC_DATA: `${API}staticData`,
  STATES: states,
  CITIES: `${states}/:stateId/cities`,
  COUNTRIES: `${API}countries`,
  INDUSTRIES: industries,
  SUB_INDUSTRIES: `${industries}/:industryId`,
  TYPES: `${API}types`,
  ZONES: `${API}zones?international=1`,
  DIVERSITIES: `${API}diversities/:id`,
  JOB_FUNCTIONS: `${API}jobFunctions`,
  MAJORS_CATEGORIES: `${API}majorCategories`,
  MAJORS_MINORS: `${API}majorsMinors`,
  MAJORS_MINORS_BY_CATEGORY: `${API}majorsMinors?categoryId=:id`,
};

const apiRoutes = {
  ...applicantsRoutes,
  ...companyRoutes,
  ...employerRoutes,
  ...loginRoutes,
  ...opportunitiesRoutes,
  ...recoveryRoutes,
  ...statRoutes,
  ...staticDataRoutes,
};

export default apiRoutes;
