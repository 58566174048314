import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmModal } from 'components';
import { EMPTY_NOTE } from 'constant/messages';
import opportunityCreator from 'store/opportunity/actions';
import { getOperationRequestNote } from 'store/opportunity/selectors';

import Note from './note';
import {
  AlertAction,
  ButtonNotes,
  EmptyNoteBody,
  EmptyNoteBodyIcon,
  EmptyNoteContainer,
  ModalTagNumberOfNotes,
  NotesContainer,
  TagNumberOfNotes,
  TextareaNote,
} from './styled';

const Notes = ({ aplicantId, aplicantNotes }) => {
  const dispatch = useDispatch();
  const operationRequestNote = useSelector(getOperationRequestNote);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [neatNotes, setNeatNotes] = useState(aplicantNotes);
  const [textNote, setTextNote] = useState('');
  const [alertNote, setAlertNote] = useState({ color: '', text: '' });

  const showAlert = (text, color = '') => {
    setAlertNote({ color, text });
    setTimeout(() => {
      setAlertNote({ color: '', text: '' });
    }, 3000);
  };

  useEffect(() => {
    showAlert(operationRequestNote.msg, operationRequestNote.color);
  }, [operationRequestNote]);

  useEffect(() => {
    setNeatNotes(
      aplicantNotes.sort((a, b) => {
        return b.id - a.id;
      })
    );
  }, [aplicantNotes]);

  const openModal = () => {
    setShowNotesModal(!showNotesModal);
    setAlertNote({ color: '', text: '' });
    setTextNote('');
  };

  const handleChange = e => {
    setTextNote(e.target.value);
  };

  const removeNote = async id => {
    await dispatch(opportunityCreator.applicantDeleteNote(id, aplicantId));
  };

  const addNote = async () => {
    if (textNote && textNote.trim().length > 0) {
      await dispatch(opportunityCreator.applicantCreateNote(textNote, aplicantId));
      setTextNote('');
    } else {
      showAlert(EMPTY_NOTE, 'red');
    }
  };

  const leyendNote = () => {
    const text = neatNotes.length === 1 ? 'note' : 'notes';
    return `${neatNotes.length} ${text} added!`;
  };

  return (
    <>
      {neatNotes.length > 0 && <TagNumberOfNotes>{neatNotes.length}</TagNumberOfNotes>}

      <ButtonNotes onClick={() => openModal()} title="View notes">
        🗒
      </ButtonNotes>

      <ConfirmModal
        show={showNotesModal}
        title="Notes"
        cancelLabel="Close"
        acceptLabel="Add note"
        onAccept={() => addNote()}
        onDismiss={() => openModal()}
      >
        {neatNotes.length > 0 && <ModalTagNumberOfNotes>{leyendNote()}</ModalTagNumberOfNotes>}
        {neatNotes.length > 0 && (
          <NotesContainer>
            {neatNotes.map(aplicantNote => (
              <Note key={aplicantNote.id} removeNote={removeNote} aplicantNote={aplicantNote} />
            ))}
          </NotesContainer>
        )}

        {neatNotes.length === 0 && (
          <EmptyNoteContainer>
            <EmptyNoteBody>
              <EmptyNoteBodyIcon>🗒</EmptyNoteBodyIcon>
              be the first one to leave a note!
            </EmptyNoteBody>
          </EmptyNoteContainer>
        )}

        {alertNote.text && <AlertAction style={{ color: alertNote.color }}>{alertNote.text}</AlertAction>}
        <TextareaNote
          value={textNote}
          onChange={handleChange}
          placeholder="write something (max 500)."
          cols="50"
          maxLength="500"
          rows="4"
        />
      </ConfirmModal>
    </>
  );
};

Notes.propTypes = {
  aplicantId: PropTypes.number.isRequired,
  aplicantNotes: PropTypes.instanceOf(Array).isRequired,
};

export default Notes;
