import styled from 'styled-components';

export const ClickHere = styled.span`
  font-size: 15px;
  line-height: initial;
  background: transparent;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  color: #f5c252;
  margin-right: 5px;
  width: 30px;
`;

export const Label = styled.span`
  font-size: 15px;
  color: #fff;
  line-height: 18px;
  font-weight: 500;
  cursor: initial;
  text-decoration: initial;
  text-transform: initial;
`;
