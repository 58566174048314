import { createReducer } from 'reduxsauce';

import { dataInsightTypes } from 'store/data-insight/actions';
import initialState from 'store/data-insight/initial-state';

const setFilters = (state, { section, params }) => {
  const otherSections = {};
  Object.keys(initialState.filters)
    .filter(filter => filter !== section)
    .forEach(sectionToAssign =>
      Object.assign(otherSections, { [sectionToAssign]: initialState.filters[sectionToAssign] })
    );
  return {
    ...state,
    filters: { ...otherSections, [section]: params },
  };
};

const cleanFilters = state => ({ ...state, filters: initialState.filters });

const reducers = createReducer(initialState, {
  [dataInsightTypes.SET_FILTERS]: setFilters,
  [dataInsightTypes.CLEAN_FILTERS]: cleanFilters,
});

export default reducers;
