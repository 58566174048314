import React from 'react';
import { components } from 'react-select';

const groupStyles = {
  borderBottom: '2px solid #9D84EB',
};

const Group = props => (
  <div style={groupStyles}>
    <components.Group {...props} />
  </div>
);

export default Group;
