import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`;

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999999999999999;
  overflow-x: hidden;
  overflow-y: hidden;
  outline: 0;
  transform: translate(-50%, -50%);
  border-radius: ${({ fullPage }) => (fullPage ? 'initial' : '30px')};
  background-color: ${({ fullPage }) => (fullPage ? 'black' : 'transparent')};
  ${({ fullPage }) => (fullPage ? 'width: 100%; height: 100%' : '')};
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const Title = styled.label`
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
`;

export const Content = styled.div`
  z-index: 99999;
  text-align: center;
  background: ${({ background }) => background || 'transparent'};
  padding: ${({ fullPage }) => (fullPage ? 0 : '55px')};
  ${({ fullPage }) =>
    fullPage ? 'height: 100%; width: 100vw; overflow-y: auto' : 'margin: auto; max-width: 600px; border-radius: 3px;'};
  ${({ $bsStyle }) => $bsStyle || ''}
`;
