import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  mailValidRequest: ['email'],
  mailValidSuccess: [],
  mailValidError: ['error'],

  sendMailRequest: ['email'],
  sendMailSuccess: [],
  sendMailError: ['sendError'],

  resendMailSuccess: [],
  resendMailRequest: ['email'],

  resetPasswordRequest: ['email', 'token', 'password'],
  resetPasswordSuccess: [],
  resetPasswordError: ['resetError'],
});

export const recoverTypes = Types;

export default Creators;
