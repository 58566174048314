import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  opportunityFetchRequest: ['entity', 'filters'],
  opportunityFetchSuccess: ['entity', 'opportunities'],
  opportunityFetchError: ['error'],

  opportunityFindRequest: ['opportunityId', 'term', 'skip'],
  opportunityFindSuccess: ['opportunity', 'applicants'],
  opportunityFindError: ['error'],

  opportunitySaveRequest: ['values'],
  opportunitySaveSuccess: ['opportunity'],
  opportunitySaveError: ['error'],

  opportunityDeleteRequest: ['opportunityId'],
  opportunityDeleteSuccess: [],
  opportunityDeleteError: ['error'],

  opportunityUploadImageRequest: ['typeImage', 'files'],
  opportunityUploadImageSuccess: ['company'],
  opportunityUploadImageError: ['error'],

  getPotentialApplicantsRequest: ['opportunity'],
  getPotentialApplicantsSuccess: ['potentialApplicants'],
  getPotentialApplicantsError: ['error'],

  getTotalApplicantsRequest: ['opportunityId'],
  getTotalApplicantsSuccess: ['totalApplicants'],
  getTotalApplicantsError: ['error'],

  updateOpportunityStatusRequest: ['id', 'status', 'validations', 'entity', 'completed'],
  updateOpportunityStatusSuccess: ['opportunity'],
  updateOpportunityStatusError: ['error'],

  saveApplicantTagRequest: ['opportunityId', 'applicantId', 'tagId', 'entity', 'isContactTag', 'email'],
  saveApplicantTagSuccess: ['applicant'],
  saveApplicantTagError: ['error'],

  confirmSendApplicantEmailRequest: ['applicantId'],
  confirmSendApplicantEmailSuccess: [],
  confirmSendApplicantEmailError: ['error'],

  applicantCreateNote: ['noteText', 'applicantId'],
  applicantAddNote: ['note', 'applicantId'],
  applicantDeleteNote: ['noteId', 'applicantId'],
  applicantRemoveNote: ['noteId', 'applicantId'],
  setOperationRequestNote: [],

  getOpportunityApplicantsRequest: ['opportunityId', 'filters'],
  getOpportunityApplicantsSuccess: ['applicants', 'metadata'],
  getOpportunityApplicantsError: ['error'],

  saveOpportunitiesExpiredRequest: ['opportunities'],
  saveOpportunitiesExpiredSuccess: [],
  saveOpportunitiesExpiredError: ['error'],

  remindLaterOrDeclineOpportunitiesExpiredRequest: ['data'],
  remindLaterOrDeclineOpportunitiesExpiredSuccess: [],
  remindLaterOrDeclineOpportunitiesExpiredError: ['error'],

  applicantFindRequest: ['opportunityId', 'applicantId'],
  applicantFindSuccess: ['applicant'],
  applicantFindError: ['error'],

  cleanOpportunity: [],

  cleanLastApplicantContactTagSaved: [],

  setOpportunityApplicantsFilters: ['filters'],

  setOpportunityApplicants: ['applicants'],

  setTags: ['tags', 'name'],

  setOpportunityHireSomeoneFromHive: ['opportunityId', 'field', 'value'],
});

export const opportunityTypes = Types;

export default Creators;
