const parseEmptyStringToUndefined = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    if (Array.isArray(obj)) {
      return acc;
    }
    if (obj[key] === '') {
      return { ...acc, [key]: undefined };
    }
    return { ...acc, [key]: obj[key] };
  }, {});
};

export default parseEmptyStringToUndefined;
