export default {
  control: (provided, state) => ({
    display: 'flex',
    margin: state.selectProps.isSearchable ? '10px 3px 10px 3px' : '0px',
    background: '#522BA5',
    flexDirection: 'row-reverse',
    borderRadius: '11px',
    border: state.isFocused ? '2px solid #fff' : 0,
    fontFamily: 'Brandon Grotesque',
    fontWeight: 400,
  }),
  menuList: provider => ({
    ...provider,
    minHeight: 'auto',
    background: '#8768E6',
    '::-webkit-scrollbar': {
      width: '11px',
    },
    '::-webkit-scrollbar-track': {
      marginTop: '10px',
      marginBottom: '10px',
      background: '#AF96FB',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#522BA5',
      borderRadius: '10px',
    },
  }),
  placeholder: provider => ({
    ...provider,
    color: '#fff',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    fontFamily: 'Brandon Grotesque',
  }),
  option: (provided, state) => {
    let style = {};
    if (state.data.selectAll || state.data.unselectAll) {
      style = {
        borderTop: '2px solid #9D84EB',
        borderBottom: state.options[0].options ? 'none' : '2px solid #9D84EB',
        padding: '8px 0 8px 0',
        fontWeight: 'bold',
      };
    }
    return {
      color: '#fff',
      fontFamily: 'Brandon Grotesque',
      whiteSpace: 'wrap',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      fontSize: '16px',
      '&:hover': {
        background: '#AF96FB',
      },
      paddingLeft: '5px',
      background: 'transparent',
      padding: '3px 0 3px 0',
      ...style,
    };
  },
  menu: () => ({
    marginTop: 0,
    padding: '0 5px 0 15px',
    background: '#8768E6',
    borderRadius: '11px',
  }),
  noOptionsMessage: base => ({
    ...base,
    color: '#fff',
    fontFamily: 'Brandon Grotesque',
    fontWeight: 400,
  }),
  valueContainer: provided => ({ ...provided, height: '40px', fontSize: '16px' }),
};
