import styled from 'styled-components';

export const TitleLabel = styled.label`
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-size: 19px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  margin-bottom: 35px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-right: 50px;
  @media screen and (max-width: 1024px) {
    max-width: 150px;
  }
  @media screen and (max-width: 767px) {
    margin-right: 0;
    max-width: 100px;
  }
`;

export const Title = styled.label`
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-size: ${({ fontSize }) => fontSize || '50px'};
  font-weight: 500;
  line-height: 120%;
  color: white;
  margin-left: auto;

  @media screen and (max-width: 767px) {
    font-size: 35px;
    margin-left: initial;
    ${({ subItem }) =>
      subItem
        ? 'font-size: 16px;' +
          '-webkit-line-clamp: 2;' +
          '-webkit-box-orient: vertical;' +
          'overflow: hidden;' +
          'text-overflow: ellipsis;' +
          'line-height: 1.625;' +
          'display: -webkit-box;' +
          'text-align: left;' +
          'font-weight: 600;'
        : ''}
  }
`;

export const Text = styled.label`
  font-family: 'Gentleman', Arial, sans-serif;
  font-size: 18px;
  color: white;
  line-height: 20px;
  text-transform: uppercase;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
`;

export const BarWrapper = styled.div`
  flex: 1;
`;

export const BarLabel = styled.div`
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: #8b35c9;
  position: absolute;
  margin-top: 40px;
  margin-left: 40px;
`;

export const CtaWrapper = styled.div`
  min-width: 104px;
  margin-left: 50px;
  @media screen and (max-width: 767px) {
    margin-left: 10px;
  }
`;

export const ViewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 15px 0 auto;
`;

export const ProfileViews = styled.p`
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;

export const OpportunityViews = styled.p`
  color: #ffc033;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;
