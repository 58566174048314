import { Row } from '@hivediversity/common-lib/base';
import { opportunityProps } from '@hivediversity/common-lib/utils';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';

import { ApplicantCard, ErrorMessage, OpportunitySwitch } from 'components';
import { opportunitiesEntities, resumeOrigin, routes, settingSlider } from 'constant';
import { getOpportunityId, getUpdateOpportunityStatusError } from 'store/opportunity/selectors';
import { transformRoute } from 'utils';

import OpportunityButtons from './opportunity-buttons';
import {
  Title,
  Created,
  Wrapper,
  EmptyApplicants,
  ButtonMobile,
  ButtonDesktop,
  WrapperTitle,
  WrapperBlock,
} from './styled';

const BlockOpportunity = ({ opportunity, entity }) => {
  const dispatch = useDispatch();
  const error = useSelector(getUpdateOpportunityStatusError);
  const currentOpportunityId = useSelector(getOpportunityId);

  const goToOpportunityApplicants = e =>
    dispatch(push(transformRoute(routes.EDIT_OPPORTUNITY, { opportunityId: e.target.id })));

  const goToEditOpportunity = e =>
    dispatch(push(transformRoute(`${routes.EDIT_OPPORTUNITY}?tab=Edit`, { opportunityId: e.target.id })));

  return (
    <Fragment key={opportunity.id}>
      <Row $bsStyle={{ flexWrap: 'wrap' }}>
        <WrapperBlock $bsStyle={{ flexDirection: 'row' }}>
          <WrapperTitle>
            <Title $bsStyle={{ fontSize: '30px' }}>
              {opportunity.totalNewApplicants > 0 ? (
                <>
                  {opportunity.title}
                  <span>{`(${opportunity.totalNewApplicants} New)`}</span>
                </>
              ) : (
                opportunity.title
              )}
            </Title>
            <Created>{`Created: ${opportunity.createdAt}`}</Created>
            {opportunity.createdBy.username && <Created>{`Created by: ${opportunity.createdBy.username}`}</Created>}
          </WrapperTitle>
          <OpportunitySwitch
            name={`status-${opportunity.id}`}
            id={opportunity.id}
            status={opportunity.status}
            isCompleted={opportunity.completed}
            entity={entity}
          />
        </WrapperBlock>
        {currentOpportunityId === opportunity.id && error && <ErrorMessage messageError={error} />}
        <ButtonDesktop>
          <OpportunityButtons
            opportunityId={opportunity.id}
            totalApplicants={opportunity.totalApplicants}
            onViewAll={goToOpportunityApplicants}
            onEdit={goToEditOpportunity}
          />
        </ButtonDesktop>
      </Row>
      {isEmpty(opportunity.newApplicants) ? (
        <Wrapper>
          <EmptyApplicants>You don&apos;t have new applicants</EmptyApplicants>
          <ButtonMobile>
            <OpportunityButtons
              opportunityId={opportunity.id}
              totalApplicants={opportunity.totalApplicants}
              onViewAll={goToOpportunityApplicants}
              onEdit={goToEditOpportunity}
            />
          </ButtonMobile>
        </Wrapper>
      ) : (
        <Wrapper>
          <Slider {...settingSlider}>
            {opportunity.newApplicants.map(applicant => (
              <Fragment key={applicant.id}>
                <ApplicantCard
                  applicant={applicant}
                  opportunity={opportunity}
                  entity={opportunity.entity}
                  origin={resumeOrigin.OPPORTUNITIES}
                />
              </Fragment>
            ))}
          </Slider>
          <ButtonMobile>
            <OpportunityButtons
              opportunityId={opportunity.id}
              totalApplicants={opportunity.totalApplicants}
              onViewAll={goToOpportunityApplicants}
              onEdit={goToEditOpportunity}
            />
          </ButtonMobile>
        </Wrapper>
      )}
    </Fragment>
  );
};

BlockOpportunity.propTypes = {
  opportunity: opportunityProps.isRequired,
  entity: PropTypes.oneOf(Object.values(opportunitiesEntities)),
};

BlockOpportunity.defaultProps = {
  entity: undefined,
};

export default BlockOpportunity;
