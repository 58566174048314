import { Row } from '@hivediversity/common-lib/base';
import React from 'react';

import { WrapTextBox } from 'components';

import { Title } from '../styled';

const Duration = () => (
  <Row
    $bsStyle={{
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: '20px',
      marginTop: '20px',
    }}
  >
    <Title $bsStyle={{ marginRight: '10px', marginTop: '10px' }}>Duration</Title>
    <WrapTextBox
      name="partTimeDuration"
      placeholder="Ex: This part-time position will run..."
      maxLength={255}
      wrapperContainerStyle={{ marginBottom: '10px', flex: 1, height: '100%' }}
    />
  </Row>
);

export default Duration;
