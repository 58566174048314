import { Column, Row } from '@hivediversity/common-lib/base';
import { CustomButton } from '@hivediversity/common-lib/buttons';
import { colors } from '@hivediversity/common-lib/constant';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';

import { Modal } from 'components';

const ImageEditor = ({ image, onConfirm, onDismiss, show, borderRadius, isTheImageSaved, height, width, ...props }) => {
  const imageRef = useRef();
  const buttonRef = useRef();
  const [scale, setScale] = useState(1);
  const [showOpenImage, setShowOpenImage] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const { getRootProps, getInputProps, open } = useDropzone({
    noKeyboard: true,
    noClick: true,
    onDrop: file => {
      setCurrentImage(file[0]);
      setShowOpenImage(false);
    },
    maxFiles: 1,
    noDragEventsBubbling: true,
  });

  useEffect(() => {
    if (show) {
      setCurrentImage(image && isTheImageSaved ? `${image}?${new Date().getTime()}` : image);
      setShowOpenImage(!image);
    }
  }, [image, show]);

  useEffect(() => {
    if (!image && show && showOpenImage) {
      buttonRef.current.click();
    }
  }, [buttonRef, show, showOpenImage]);

  const handleConfirm = () => {
    const canvas = imageRef.current.getImage();
    canvas.toBlob(blob => onConfirm(blob));
  };

  return (
    <Modal $bsStyle={{ maxWidth: 'initial' }} containerStyles={{ maxWidth: '1024px', width: '100%' }} show={show}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <AvatarEditor
          ref={imageRef}
          image={currentImage}
          borderRadius={borderRadius}
          scale={scale}
          {...props}
          height={height}
          width={width}
          crossOrigin="anonymous"
        />
        <Column center>
          <Column style={{ marginTop: '10px', marginBottom: '10px' }}>
            <label htmlFor="zoom" style={{ color: '#fff' }}>
              Zoom
            </label>
            <input
              type="range"
              min="1"
              max="2"
              step="0.01"
              value={scale}
              onChange={({ target: { value } }) => setScale(value)}
            />
          </Column>
          <Row center $bsStyle={{ flexWrap: 'wrap' }}>
            <CustomButton color={colors.LIGHT_BLUE} onClick={handleConfirm} $bsStyle={{ maxWidth: '100px' }}>
              Confirm
            </CustomButton>
            <CustomButton color={colors.ORANGE} onClick={onDismiss} $bsStyle={{ marginLeft: '5px', maxWidth: '100px' }}>
              Cancel
            </CustomButton>
            <CustomButton
              ref={buttonRef}
              color={colors.GREEN}
              onClick={open}
              $bsStyle={{ marginLeft: '5px', maxWidth: '100px', lineHeight: 'normal' }}
            >
              Add photo
            </CustomButton>
          </Row>
        </Column>
      </div>
    </Modal>
  );
};

ImageEditor.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  image: PropTypes.string,
  borderRadius: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  show: PropTypes.bool.isRequired,
  isTheImageSaved: PropTypes.bool,
  style: PropTypes.shape({}),
};

ImageEditor.defaultProps = {
  style: {},
  borderRadius: undefined,
  isTheImageSaved: false,
  image: undefined,
  height: undefined,
  width: undefined,
};

export default ImageEditor;
