import dropRight from 'lodash/dropRight';
import { createReducer } from 'reduxsauce';

import { staticDataTypes } from 'store/static-data/actions';
import { initialState } from 'store/static-data/initial-state';

const fetchRequest = state => ({
  ...state,
  staticData: [],
  isLoading: true,
  error: null,
});

const fetchSuccess = (state, { data }) => ({
  ...state,
  ...data,
  isLoading: false,
});

const fetchFailure = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
  staticData: [],
});

const fetchStatesRequest = state => ({
  ...state,
  isLoadingStates: true,
  error: null,
});

const fetchStatesSuccess = (state, { states }) => ({
  ...state,
  states,
  isLoadingStates: false,
});

const fetchStatesFailure = (state, { error }) => ({
  ...state,
  error,
  isLoadingStates: false,
});

const fetchCitiesRequest = state => ({
  ...state,
  isLoadingCitiesByState: true,
  error: null,
});

const fetchCitiesSuccess = (state, { cities }) => {
  const currentCities = state.cities || [];
  return {
    ...state,
    cities: currentCities.concat(cities),
    isLoadingCitiesByState: false,
    error: null,
  };
};

const fetchCitiesFailure = (state, { error }) => ({
  ...state,
  isLoadingCitiesByState: false,
  error,
});

const fetchCountriesRequest = state => ({
  ...state,
  isLoadingCountries: true,
  error: null,
});

const fetchCountriesSuccess = (state, { countries }) => ({
  ...state,
  countries,
  isLoadingCountries: false,
});

const fetchCountriesFailure = (state, { error }) => ({
  ...state,
  error,
  isLoadingCountries: false,
});

const fetchIndustriesRequest = state => ({
  ...state,
  isLoadingIndustries: true,
  error: null,
});

const fetchIndustriesSuccess = (state, { industries }) => ({
  ...state,
  industries,
  isLoadingIndustries: false,
});

const fetchIndustriesFailure = (state, { error }) => ({
  ...state,
  error,
  isLoadingIndustries: false,
});

const fetchSubIndustriesRequest = state => ({
  ...state,
  isLoadingSubIndustries: true,
  error: null,
});

const fetchSubIndustriesSuccess = (state, { subIndustries }) => {
  const currentSubIndustries = state.subIndustries || [];
  return {
    ...state,
    subIndustries: currentSubIndustries.concat(subIndustries),
    isLoadingSubIndustries: false,
  };
};

const fetchSubIndustriesFailure = (state, { error }) => ({
  ...state,
  error,
  isLoadingSubIndustries: false,
});

const getClassStandingRequest = state => ({
  ...state,
  isFetchingClassStanding: true,
});

const getClassStandingSuccess = (state, { classStanding }) => ({
  ...state,
  isFetchingClassStanding: false,
  classStanding,
  error: null,
});

const getClassStandingFailure = (state, { error }) => ({
  ...state,
  isFetchingClassStanding: false,
  error,
});

const getZonesRequest = state => ({
  ...state,
  isFetchingZones: true,
});

const getZonesSuccess = (state, { zones = [] }) => ({
  ...state,
  isFetchingZones: false,
  zones,
  zonesWithoutInternational: dropRight(zones),
  error: null,
});

const getZonesFailure = (state, { error }) => ({
  ...state,
  isFetchingZones: false,
  error,
});

const resetStaticDataStore = state => ({
  ...state,
  industries: [],
  subIndustries: [],
});

const getDiversitiesRequest = state => ({
  ...state,
  isLoadingDiversities: true,
  error: null,
});

const getDiversitiesSuccess = (state, { diversities }) => ({
  ...state,
  diversities,
  isLoadingDiversities: false,
  error: null,
});

const getDiversitiesError = (state, { error }) => ({
  ...state,
  error,
  isLoadingDiversities: false,
});

const getMajorsMinorsByCategoryRequest = (state, { entity }) => ({
  ...state,
  error: null,
  [`${entity}Loading`]: true,
});

const getMajorsMinorsByCategorySuccess = (state, { entity, entities }) => ({
  ...state,
  error: null,
  [entity]: entities,
  [`${entity}Loading`]: false,
});

const getMajorsMinorsByCategoryFailure = (state, { entity, error }) => ({
  ...state,
  error,
  [`${entity}Loading`]: false,
});

const getMajorsMinorsRequest = state => ({
  ...state,
  error: null,
  isLoadingMajorsMinors: true,
});

const getMajorsMinorsSuccess = (state, { majorsMinors }) => ({
  ...state,
  error: null,
  majorsMinors,
  isLoadingMajorsMinors: false,
});

const getMajorsMinorsFailure = (state, { error }) => ({
  ...state,
  error,
  isLoadingMajorsMinors: false,
});

const reducer = createReducer(initialState, {
  [staticDataTypes.STATIC_DATA_FETCH_REQUEST]: fetchRequest,
  [staticDataTypes.STATIC_DATA_FETCH_SUCCESS]: fetchSuccess,
  [staticDataTypes.STATIC_DATA_FETCH_ERROR]: fetchFailure,

  [staticDataTypes.STATIC_DATA_FETCH_STATES_REQUEST]: fetchStatesRequest,
  [staticDataTypes.STATIC_DATA_FETCH_STATES_SUCCESS]: fetchStatesSuccess,
  [staticDataTypes.STATIC_DATA_FETCH_STATES_ERROR]: fetchStatesFailure,

  [staticDataTypes.STATIC_DATA_FETCH_CITIES_REQUEST]: fetchCitiesRequest,
  [staticDataTypes.STATIC_DATA_FETCH_CITIES_SUCCESS]: fetchCitiesSuccess,
  [staticDataTypes.STATIC_DATA_FETCH_CITIES_ERROR]: fetchCitiesFailure,

  [staticDataTypes.STATIC_DATA_FETCH_COUNTRIES_REQUEST]: fetchCountriesRequest,
  [staticDataTypes.STATIC_DATA_FETCH_COUNTRIES_SUCCESS]: fetchCountriesSuccess,
  [staticDataTypes.STATIC_DATA_FETCH_COUNTRIES_ERROR]: fetchCountriesFailure,

  [staticDataTypes.STATIC_DATA_FETCH_INDUSTRIES_REQUEST]: fetchIndustriesRequest,
  [staticDataTypes.STATIC_DATA_FETCH_INDUSTRIES_SUCCESS]: fetchIndustriesSuccess,
  [staticDataTypes.STATIC_DATA_FETCH_INDUSTRIES_ERROR]: fetchIndustriesFailure,

  [staticDataTypes.STATIC_DATA_FETCH_SUB_INDUSTRIES_REQUEST]: fetchSubIndustriesRequest,
  [staticDataTypes.STATIC_DATA_FETCH_SUB_INDUSTRIES_SUCCESS]: fetchSubIndustriesSuccess,
  [staticDataTypes.STATIC_DATA_FETCH_SUB_INDUSTRIES_ERROR]: fetchSubIndustriesFailure,

  [staticDataTypes.GET_CLASS_STANDING_REQUEST]: getClassStandingRequest,
  [staticDataTypes.GET_CLASS_STANDING_SUCCESS]: getClassStandingSuccess,
  [staticDataTypes.GET_CLASS_STANDING_ERROR]: getClassStandingFailure,

  [staticDataTypes.GET_ZONES_REQUEST]: getZonesRequest,
  [staticDataTypes.GET_ZONES_SUCCESS]: getZonesSuccess,
  [staticDataTypes.GET_ZONES_ERROR]: getZonesFailure,

  [staticDataTypes.RESET_STATIC_DATA_STORE]: resetStaticDataStore,

  [staticDataTypes.GET_DIVERSITIES_REQUEST]: getDiversitiesRequest,
  [staticDataTypes.GET_DIVERSITIES_SUCCESS]: getDiversitiesSuccess,
  [staticDataTypes.GET_DIVERSITIES_ERROR]: getDiversitiesError,

  [staticDataTypes.GET_MAJORS_MINORS_BY_CATEGORY_REQUEST]: getMajorsMinorsByCategoryRequest,
  [staticDataTypes.GET_MAJORS_MINORS_BY_CATEGORY_SUCCESS]: getMajorsMinorsByCategorySuccess,
  [staticDataTypes.GET_MAJORS_MINORS_BY_CATEGORY_ERROR]: getMajorsMinorsByCategoryFailure,

  [staticDataTypes.GET_MAJORS_MINORS_REQUEST]: getMajorsMinorsRequest,
  [staticDataTypes.GET_MAJORS_MINORS_SUCCESS]: getMajorsMinorsSuccess,
  [staticDataTypes.GET_MAJORS_MINORS_ERROR]: getMajorsMinorsFailure,
});

export default reducer;
