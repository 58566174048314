import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';

export const getArchivedOpportunities = state => state.opportunity.archivedOpportunities;

export const getOpportunitiesCreatedByMe = state => state.opportunity.opportunitiesCreatedByMe;

export const getOpportunitiesCreatedByOthers = state => state.opportunity.opportunitiesCreatedByOthers;

export const getOpportunity = state => state.opportunity.opportunity;

export const getOpportunityId = state => state.opportunity.opportunityId;

export const getIsLoading = state => state.opportunity.isLoading;

export const getOperationRequestNote = state => state.opportunity.operationRequestNote;

export const getIsLoadingByEntity = (state, entity) => state.opportunity[`isLoading${capitalize(entity)}`];

export const getError = state => state.opportunity.error;

export const getUpdateOpportunityStatusError = state => state.opportunity.updateOpportunityStatusError;

export const getIsSaving = state => state.opportunity.isSaving;

export const getOpportunitiesByStatus = (state, statuses, order = 'asc', value = 'createdAt') => {
  const opportunities = getOpportunitiesCreatedByMe(state);
  const opportunitiesFiltered = opportunities.filter(opportunity => statuses.includes(opportunity.status));
  if (value === 'createdAt') {
    const opportunitiesOrderedByDate = opportunitiesFiltered.sort((a, b) => b.createdAt - a.createdAt);
    return order === 'asc' ? opportunitiesOrderedByDate : opportunitiesOrderedByDate.reverse();
  }
  return order === 'asc' ? sortBy(opportunitiesFiltered, value) : sortBy(opportunitiesFiltered, value).reverse();
};

export const getPotentialApplicants = state => state.opportunity.potentialApplicants;

export const getSavingTag = state => state.opportunity.savingTag;

export const getApplicants = state => state.opportunity.applicants;

export const getOpportunityApplicantsFilters = state => state.opportunity.filters;

export const getMySelectedTags = state => state.opportunity.tags;

export const getSelectedContactTags = state => state.opportunity.contactTag;

export const getOpportunityMetadata = state => state.opportunity.metadata;

export const getOpportunitiesExpired = state => state.opportunity.expiredOpportunities;

export const getApplicant = state => state.opportunity.applicant;
