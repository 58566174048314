const getInitials = (name = '') => {
  let initials = name.split(' ');

  if (initials.length > 1) {
    initials = initials.shift().charAt(0) + initials.pop().charAt(0);
  } else {
    initials = name.substring(0, 1);
  }

  return initials;
};

export default getInitials;
