import { takeLatest, call, put } from 'redux-saga/effects';

import { fetchStatsRequest } from 'services/stat';
import statCreator, { statTypes } from 'store/stat/actions';

export function* getStats({ params }) {
  try {
    const data = yield call(fetchStatsRequest, params);
    yield put(statCreator.getStatsSuccess(data.percentage));
  } catch (error) {
    yield put(statCreator.getStatsError(error));
  }
}

export function* statSaga() {
  yield takeLatest(statTypes.GET_STATS_REQUEST, getStats);
}
