import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

const MultiValue = ({ ...props }) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

MultiValue.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
};

export default MultiValue;
