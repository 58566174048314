import capitalize from 'lodash/capitalize';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { createReducer } from 'reduxsauce';

import { opportunitiesEntities } from 'constant';
import { ADDED_NOTE, REMOVED_NOTE } from 'constant/messages';
import { opportunityTypes } from 'store/opportunity/actions';
import { initialState } from 'store/opportunity/initial-state';

const getApplicants = (tags, applicants) => {
  const checkboxesChecked = tags.filter(tag => tag.checked);
  return isEmpty(checkboxesChecked)
    ? applicants
    : applicants.filter(applicant =>
        includes(
          checkboxesChecked.map(checkbox => checkbox.id),
          applicant.tag
        )
      );
};

const fetchRequest = (state, { entity }) => ({
  ...state,
  [`isLoading${capitalize(entity)}`]: true,
  error: initialState.error,
  opportunities: initialState.opportunities,
  applicants: initialState.applicants,
  opportunity: initialState.opportunity,
  metadata: initialState.metadata,
});

const fetchSuccess = (state, { entity, opportunities }) => ({
  ...state,
  [entity]: opportunities,
  [`isLoading${capitalize(entity)}`]: initialState.isLoading,
  error: initialState.error,
});

const fetchFailure = (state, { error }) => ({
  ...state,
  error,
  [`isLoading${capitalize(state.entity)}`]: initialState.isLoading,
  opportunities: initialState.opportunities,
  entity: null,
});

const findRequest = state => ({
  ...state,
  isLoading: true,
  error: initialState.error,
  opportunity: initialState.opportunity,
});

const findSuccess = (state, { opportunity }) => ({
  ...state,
  opportunity,
  isLoading: initialState.isLoading,
  opportunityId: opportunity.id,
  error: null,
});

const findFailure = (state, { error }) => ({
  ...state,
  error,
  isLoading: initialState.isLoading,
  opportunity: initialState.opportunity,
});

const saveRequest = (state, { section }) => ({
  ...state,
  isSaving: !section,
  isSavingSection: !!section,
  error: initialState.error,
});

const saveSuccess = (state, { opportunity }) => ({
  ...state,
  opportunity,
  isSaving: initialState.isSaving,
  isSavingSection: false,
  error: initialState.error,
});

const saveFailure = (state, { error }) => ({
  ...state,
  error,
  isSaving: initialState.isSaving,
  isSavingSection: false,
});

const deleteRequest = state => ({
  ...state,
  isDeleting: true,
  updateOpportunityStatusError: initialState.error,
});

const deleteSuccess = (state, { opportunity }) => ({
  ...state,
  opportunity,
  isDeleting: initialState.isDeleting,
  updateOpportunityStatusError: initialState.error,
});

const deleteFailure = (state, { error }) => ({
  ...state,
  updateOpportunityStatusError: error,
  isDeleting: initialState.isDeleting,
});

const getPotentialApplicantsRequest = state => ({
  ...state,
  loading: true,
  error: initialState.error,
  potentialApplicants: undefined,
});

const getPotentialApplicantsSuccess = (state, { potentialApplicants }) => ({
  ...state,
  potentialApplicants,
  loading: false,
  error: null,
});

const getPotentialApplicantsError = (state, { error }) => ({
  ...state,
  error,
  loading: false,
});

const getTotalApplicantsRequest = state => ({
  ...state,
  loading: true,
  error: initialState.error,
  totalApplicants: undefined,
});

const getTotalApplicantsSuccess = (state, { totalApplicants }) => ({
  ...state,
  totalApplicants,
  loading: false,
  error: null,
});

const getTotalApplicantsError = (state, { error }) => ({
  ...state,
  error,
  loading: false,
});

const updateOpportunityStatusRequest = (state, { id, entity }) => ({
  ...state,
  opportunityId: id,
  isSaving: true,
  updateOpportunityStatusError: null,
  entity,
});

const updateOpportunityStatusSuccess = (state, { opportunity }) => {
  let opportunities = [];
  if (state.entity) {
    opportunities = state[state.entity].slice();
    const opportunityUpdated = opportunities.find(currentOpportunity => currentOpportunity.id === opportunity.id);
    if (opportunityUpdated) {
      opportunityUpdated.status = opportunity.status;
    }
  }
  return {
    ...state,
    opportunity,
    opportunities,
    isSaving: false,
    updateOpportunityStatusError: null,
    entity: null,
  };
};

const updateOpportunityStatusFailure = (state, { error }) => ({
  ...state,
  updateOpportunityStatusError: error,
  isSaving: false,
  entity: null,
});

const saveTagRequest = (state, { applicantId, entity, opportunityId, tagId, isContactTag }) => ({
  ...state,
  savingTag: applicantId,
  error: initialState.error,
  tag: {
    entity,
    opportunityId,
    tagId,
    isContactTag,
  },
});

const saveTagSuccess = (state, { applicant }) => {
  const { entity } = state.tag;
  if (!entity) {
    return {
      ...state,
      savingTag: null,
      applicant,
      error: initialState.error,
    };
  }
  if (
    [
      opportunitiesEntities.OPPORTUNITIES_CREATED_BY_ME,
      opportunitiesEntities.ARCHIVED_OPPORTUNITIES,
      opportunitiesEntities.OPPORTUNITIES_CREATED_BY_OTHERS,
    ].includes(entity)
  ) {
    const opportunities = state[entity].slice();
    const opportunity = opportunities.find(({ id }) => id === state.tag.opportunityId);
    const selectedApplicantIndex = opportunity.newApplicants.findIndex(({ id }) => id === applicant.id);
    opportunity.newApplicants[selectedApplicantIndex] = applicant;
    return {
      ...state,
      [entity]: opportunities,
      savingTag: null,
      error: initialState.error,
    };
  }
  const applicants = state.applicants.slice();
  const selectedApplicantIndex = applicants.findIndex(({ id }) => id === applicant.id);
  applicants[selectedApplicantIndex] = applicant;
  const tags = state.selectedTags.slice();
  return {
    ...state,
    savingTag: null,
    applicants: getApplicants(tags, applicants),
    error: initialState.error,
  };
};

const saveTagFailure = (state, { error }) => ({
  ...state,
  error,
  savingTag: null,
  tag: null,
});

const removeNote = (state, { noteId, applicantId }) => {
  const applicantNote = state.applicants.find(applicant => applicant.id === applicantId);
  const notesUpdatedNote = applicantNote.notes.filter(note => note.id !== noteId);
  const applicantUpdatedNote = { ...applicantNote, notes: notesUpdatedNote };

  const applicantsNotes = state.applicants.map(element => {
    if (element.id === applicantId) return applicantUpdatedNote;
    return element;
  });

  return {
    ...state,
    applicants: applicantsNotes,
    operationRequestNote: {
      msg: REMOVED_NOTE,
      color: 'green',
    },
  };
};

const addNote = (state, { note, applicantId }) => {
  const applicantNote = state.applicants.find(applicant => applicant.id === applicantId);
  const notesUpdatedNote = [...applicantNote.notes, note];
  const applicantUpdatedNote = { ...applicantNote, notes: notesUpdatedNote };

  const applicantsNotes = state.applicants.map(element => {
    if (element.id === applicantId) return applicantUpdatedNote;
    return element;
  });

  return {
    ...state,
    applicants: applicantsNotes,
    operationRequestNote: {
      msg: ADDED_NOTE,
      color: 'green',
    },
  };
};

const setOperationRequestNote = (state, { operationRequestNote }) => ({
  ...state,
  operationRequestNote,
});

const confirmSendApplicantEmailRequest = state => ({
  ...state,
  inConfirmation: true,
  error: initialState.error,
});

const confirmSendApplicantEmailSuccess = state => ({
  ...state,
  inConfirmation: false,
  error: initialState.error,
});

const confirmSendApplicantFailure = (state, { error }) => ({
  ...state,
  inConfirmation: false,
  error,
});

const getOpportunityApplicantsRequest = state => ({
  ...state,
  isLoading: true,
  error: initialState.error,
});

const getOpportunityApplicantsSuccess = (state, { applicants, metadata }) => {
  const newApplicants =
    !isEmpty(state.applicants) && metadata.current_page > 1 ? state.applicants.concat(applicants) : applicants;
  return {
    ...state,
    applicants: uniqBy(newApplicants, applicant => applicant.id),
    error: initialState.error,
    isLoading: false,
    metadata,
  };
};

const getOpportunityApplicantsFailure = (state, { error }) => ({
  ...state,
  isLoading: false,
  error,
});

const saveOpportunitiesExpiredRequest = state => ({
  ...state,
  savingExpiredOpportunities: true,
  error: null,
});

const saveOpportunitiesExpiredSuccess = state => ({
  ...state,
  savingExpiredOpportunities: false,
  error: null,
  expiredOpportunities: [],
});

const saveOpportunitiesExpireFailure = (state, { error }) => ({
  ...state,
  savingExpiredOpportunities: false,
  error,
});

const remindLaterOrDeclineOpportunitiesExpiredRequest = state => ({
  ...state,
  savingExpiredOpportunities: true,
  error: null,
});

const remindLaterOrDeclineOpportunitiesExpiredSuccess = state => ({
  ...state,
  savingExpiredOpportunities: false,
  error: null,
  expiredOpportunities: [],
});

const remindLaterOrDeclineOpportunitiesExpireFailure = (state, { error }) => ({
  ...state,
  savingExpiredOpportunities: false,
  error,
});

const findApplicantRequest = state => ({
  ...state,
  isLoading: true,
  error: initialState.error,
  applicant: initialState.applicant,
});

const findApplicantSuccess = (state, { applicant }) => ({
  ...state,
  applicant,
  isLoading: initialState.isLoading,
  error: initialState.error,
});

const findApplicantFailure = (state, { error }) => ({
  ...state,
  error,
  isLoading: initialState.isLoading,
  applicant: initialState.applicant,
});

const cleanOpportunity = state => ({
  ...state,
  opportunity: null,
  updateOpportunityStatusError: null,
  opportunityId: null,
  filters: initialState.filters,
  selectedTags: [],
});

const setOpportunityApplicantsFilters = (state, { filters }) => ({
  ...state,
  filters,
});

const setOpportunityApplicants = (state, { applicants }) => ({
  ...state,
  applicants,
});

const setTags = (state, { tags, name }) => ({
  ...state,
  [name]: tags,
});

const setOpportunityHireSomeoneFromHive = (state, { opportunityId, field, value }) => {
  const expiredOpportunities = state.expiredOpportunities.slice();
  const currentOpportunity = expiredOpportunities.find(({ id }) => id === opportunityId);
  currentOpportunity[field] = value;
  return {
    ...state,
    expiredOpportunities,
  };
};

const reducer = createReducer(initialState, {
  [opportunityTypes.OPPORTUNITY_FETCH_REQUEST]: fetchRequest,
  [opportunityTypes.OPPORTUNITY_FETCH_SUCCESS]: fetchSuccess,
  [opportunityTypes.OPPORTUNITY_FETCH_ERROR]: fetchFailure,

  [opportunityTypes.OPPORTUNITY_FIND_REQUEST]: findRequest,
  [opportunityTypes.OPPORTUNITY_FIND_SUCCESS]: findSuccess,
  [opportunityTypes.OPPORTUNITY_FIND_ERROR]: findFailure,

  [opportunityTypes.OPPORTUNITY_SAVE_REQUEST]: saveRequest,
  [opportunityTypes.OPPORTUNITY_SAVE_SUCCESS]: saveSuccess,
  [opportunityTypes.OPPORTUNITY_SAVE_ERROR]: saveFailure,

  [opportunityTypes.OPPORTUNITY_DELETE_REQUEST]: deleteRequest,
  [opportunityTypes.OPPORTUNITY_DELETE_SUCCESS]: deleteSuccess,
  [opportunityTypes.OPPORTUNITY_DELETE_ERROR]: deleteFailure,

  [opportunityTypes.GET_POTENTIAL_APPLICANTS_REQUEST]: getPotentialApplicantsRequest,
  [opportunityTypes.GET_POTENTIAL_APPLICANTS_SUCCESS]: getPotentialApplicantsSuccess,
  [opportunityTypes.GET_POTENTIAL_APPLICANTS_ERROR]: getPotentialApplicantsError,

  [opportunityTypes.GET_TOTAL_APPLICANTS_REQUEST]: getTotalApplicantsRequest,
  [opportunityTypes.GET_TOTAL_APPLICANTS_SUCCESS]: getTotalApplicantsSuccess,
  [opportunityTypes.GET_TOTAL_APPLICANTS_ERROR]: getTotalApplicantsError,

  [opportunityTypes.UPDATE_OPPORTUNITY_STATUS_REQUEST]: updateOpportunityStatusRequest,
  [opportunityTypes.UPDATE_OPPORTUNITY_STATUS_SUCCESS]: updateOpportunityStatusSuccess,
  [opportunityTypes.UPDATE_OPPORTUNITY_STATUS_ERROR]: updateOpportunityStatusFailure,

  [opportunityTypes.SAVE_APPLICANT_TAG_REQUEST]: saveTagRequest,
  [opportunityTypes.SAVE_APPLICANT_TAG_SUCCESS]: saveTagSuccess,
  [opportunityTypes.SAVE_APPLICANT_TAG_ERROR]: saveTagFailure,

  [opportunityTypes.APPLICANT_ADD_NOTE]: addNote,
  [opportunityTypes.APPLICANT_REMOVE_NOTE]: removeNote,
  [opportunityTypes.SET_OPERATION_REQUEST_NOTE]: setOperationRequestNote,

  [opportunityTypes.CONFIRM_SEND_APPLICANT_EMAIL_REQUEST]: confirmSendApplicantEmailRequest,
  [opportunityTypes.CONFIRM_SEND_APPLICANT_EMAIL_SUCCESS]: confirmSendApplicantEmailSuccess,
  [opportunityTypes.CONFIRM_SEND_APPLICANT_EMAIL_ERROR]: confirmSendApplicantFailure,

  [opportunityTypes.GET_OPPORTUNITY_APPLICANTS_REQUEST]: getOpportunityApplicantsRequest,
  [opportunityTypes.GET_OPPORTUNITY_APPLICANTS_SUCCESS]: getOpportunityApplicantsSuccess,
  [opportunityTypes.GET_OPPORTUNITY_APPLICANTS_ERROR]: getOpportunityApplicantsFailure,

  [opportunityTypes.SAVE_OPPORTUNITIES_EXPIRED_REQUEST]: saveOpportunitiesExpiredRequest,
  [opportunityTypes.SAVE_OPPORTUNITIES_EXPIRED_SUCCESS]: saveOpportunitiesExpiredSuccess,
  [opportunityTypes.SAVE_OPPORTUNITIES_EXPIRED_ERROR]: saveOpportunitiesExpireFailure,

  [opportunityTypes.REMIND_LATER_OR_DECLINE_OPPORTUNITIES_EXPIRED_REQUEST]:
    remindLaterOrDeclineOpportunitiesExpiredRequest,
  [opportunityTypes.REMIND_LATER_OR_DECLINE_OPPORTUNITIES_EXPIRED_SUCCESS]:
    remindLaterOrDeclineOpportunitiesExpiredSuccess,
  [opportunityTypes.REMIND_LATER_OR_DECLINE_OPPORTUNITIES_EXPIRED_ERROR]:
    remindLaterOrDeclineOpportunitiesExpireFailure,

  [opportunityTypes.APPLICANT_FIND_REQUEST]: findApplicantRequest,
  [opportunityTypes.APPLICANT_FIND_SUCCESS]: findApplicantSuccess,
  [opportunityTypes.APPLICANT_FIND_ERROR]: findApplicantFailure,

  [opportunityTypes.CLEAN_OPPORTUNITY]: cleanOpportunity,

  [opportunityTypes.SET_OPPORTUNITY_APPLICANTS_FILTERS]: setOpportunityApplicantsFilters,

  [opportunityTypes.SET_OPPORTUNITY_APPLICANTS]: setOpportunityApplicants,

  [opportunityTypes.SET_TAGS]: setTags,

  [opportunityTypes.SET_OPPORTUNITY_HIRE_SOMEONE_FROM_HIVE]: setOpportunityHireSomeoneFromHive,
});

export default reducer;
