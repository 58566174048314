const TOKEN_KEY = 'id_token';

export const setToken = token => localStorage.setItem(TOKEN_KEY, token);

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token || token === 'undefined') {
    return null;
  }
  return token;
};

export const clearToken = () => localStorage.removeItem(TOKEN_KEY);
