import styled from 'styled-components';

export const Error = styled.h2`
  color: #fff;
  font-size: 28px;
  font-weight: bold;
`;

export const FixedContainer = styled.div`
  background-color: #000000;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 0;
`;
