import { colors } from '@hivediversity/common-lib/constant';
import { getColor } from '@hivediversity/common-lib/utils';
import styled from 'styled-components';

import arrowDown from 'assets/svg/arrow-down.svg';
import hexagon from 'assets/svg/avatar.svg';
import starImage from 'assets/svg/star.svg';

export const CardContainer = styled.div`
  border-radius: 33px;
  background: ${({ background }) => background || '#1D2526'};
  max-width: 375px;
  height: 450px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  overflow: hidden;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 6px;
  position: relative;
`;

export const Title = styled.h4`
  margin-top: 0;
  margin-bottom: 3px;
  padding: 5px 0;
  border-radius: 0 0 136px 136px;
  font-family: 'Gentleman', Arial, sans-serif;
  font-weight: 400;
  color: #fff;
  width: 100%;
  justify-content: center;
  font-style: normal;
  font-size: 25px;
  line-height: 25px;
  display: flex;
  background: linear-gradient(159.95deg, #ea9c00 0%, #ffd633 90.93%);
  z-index: 1;
`;

export const SubTitle = styled.h5`
  border-radius: 0 0 58px 58px;
  font-family: 'Gentleman', Arial, sans-serif;
  font-weight: 800;
  color: #fff;
  width: 100%;
  justify-content: center;
  font-style: normal;
  font-size: 11px;
  line-height: 100%;
  display: flex;
  position: relative;
  background: #ea9c00;
  margin-top: -55px;
  z-index: 0;
  height: 70px;
  align-items: flex-end;
  margin-bottom: 0;
  padding-bottom: 5px;
`;

export const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  color: #000;
  border-radius: 33px 33px 0 0;
  padding: 0 30px 20px 30px;
  position: relative;
  z-index: 5;
  overflow: auto;
  height: 315px;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
    height: 7px;
    -webkit-overflow-scrolling: auto;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -5px;
  button {
    margin: 0 5px;
  }
`;

export const Star = styled.img.attrs({ src: starImage, alt: 'star' })`
  position: absolute;
  left: 0;
`;

export const TagsContainer = styled.div`
  position: absolute;
  right: 35px;
  top: 35px;
  z-index: 10;
`;

export const ContactTag = styled.button`
  z-index: 1;
  height: 50px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  background: black;
  position: relative;
  color: #fff;
  font-weight: 700;
  font-size: 17px;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  text-transform: uppercase;
  &:disabled {
    cursor: default;
  }
`;

export const ContactTagDescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ArrowContainer = styled.div`
  margin: auto 30px auto auto;
`;

export const ArrowDown = styled.img.attrs({ src: arrowDown, alt: 'arrow-down' })`
  height: 10px;
  width: 100%;
  transform: ${({ showContactTags }) => (!showContactTags ? 'translateY(0)' : 'rotate(0.5turn)')};
`;

export const ContactTagSelector = styled.button`
  display: block;
  border: none;
  cursor: pointer;
  outline: none;
  background: black;
  height: 40px;
  width: 100%;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  font-size: 17px;
  text-transform: uppercase;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  opacity: ${({ $visibility }) => ($visibility ? '1' : '0')};
  transform: ${({ $visibility }) => ($visibility ? 'translateY(0)' : 'translate(50px, -100px)')};
`;

export const Round = styled.button`
  right: 35px;
  top: 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  background: ${({ color }) => getColor(color) || colors.H_WHITE};
  &:disabled {
    cursor: default;
  }
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Gentleman', Arial, sans-serif;
`;

export const TagsSelectorContainer = styled.div`
  min-height: ${({ showContactTags }) => (showContactTags ? '320px' : 'initial')};
  width: 100%;
`;

export const TagsSelector = styled.div`
  margin-top: 10px;
  width: 100%;
  & button {
    margin-bottom: 10px;
  }
  visibility: ${({ $visibility }) => ($visibility ? 'visible' : 'hidden')};
`;

export const TagRound = styled.button`
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  outline: none;
  background: ${({ color }) => getColor(color) || colors.H_WHITE};
  opacity: ${({ $visibility }) => ($visibility ? '1' : '0')};
  transform: ${({ $visibility }) => ($visibility ? 'translateY(0)' : 'translateY(10px)')};
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: ${({ delay }) => (delay > 0 ? `0.${delay}s` : '0s')};
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Gentleman', Arial, sans-serif;
`;

export const Hexagon = styled.div`
  background-image: url(${hexagon});
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  color: white;
  font-weight: 900;
  font-size: 60px;
  line-height: 115%;
  width: 122px;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

export const Image = styled.img.attrs({ alt: 'applicant' })`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto auto auto;
  width: 115px;
  height: 108px;
  border-radius: 50%;
  object-fit: cover;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  & li {
    font-weight: 400;
    margin-bottom: 2px;
    font-size: 14px;
    font-family: 'Brandon Grotesque', Arial, sans-serif;
    strong {
      font-weight: 800;
      font-family: Gentleman, Arial, sans-serif;
      font-size: 13px;
    }
    &:first-child {
      strong {
        font-size: 17px;
      }
    }
  }
`;

export const CardInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const Telephone = styled.p`
  font-weight: 300;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-size: 13px;
  margin-bottom: 0;
  padding-bottom: 0;
`;

export const Email = styled.div`
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  max-height: 250px;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-weight: 300;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: gray;
  }
  text-align: start;
  font-size: 18px;
`;

export const EmailAnimation = styled.div`
  margin-top: 10px;
  animation-name: rotate;
  animation-duration: 0.7s;
  animation-iteration-count: 2;
  display: flex;
  justify-content: flex-end;
  @keyframes rotate {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(20px);
    }
`;

export const EmailArrowDown = styled.img.attrs({ src: arrowDown, alt: 'arrow-down' })`
  height: 20px;
`;

export const Anchor = styled.a`
  text-decoration: none;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  min-width: 200px;
  max-width: 800px;
  width: 100%;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Textarea = styled.textarea`
  overflow-y: auto;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  width: 100%;
  height: 100%;
  max-height: 300px;
`;
