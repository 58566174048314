import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const Tab = ({ id, title, children }) => {
  return <Fragment key={`${id}${title}`}>{children}</Fragment>;
};

Tab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

Tab.defaultProps = {
  children: [],
  title: '',
};

export default Tab;
