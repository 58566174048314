import { Row, Column } from '@hivediversity/common-lib/base';
import { CustomButton } from '@hivediversity/common-lib/buttons';
import { FiltersList } from '@hivediversity/common-lib/components';
import { colors } from '@hivediversity/common-lib/constant';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown, ErrorMessage, SidebarButton, Switch, WrapTextBox } from 'components';
import opportunityCreator from 'store/opportunity/actions';
import { getStaticData } from 'store/static-data/selectors';
import { Text } from 'styled';

import { Divider, Label } from './styled';

const Filters = ({ opportunityId, filters, onRemove, onChangeFilter }) => {
  const dispatch = useDispatch();
  const {
    classStanding,
    majorCategories,
    languages,
    industries,
    jobFunctions,
    zones,
    zonesWithoutInternational,
    majorsMinors,
  } = useSelector(getStaticData);
  const [closeSideBar, setCloseSideBar] = useState(false);

  const handleSearchByFilters = () => {
    setCloseSideBar(true);
    dispatch(opportunityCreator.getOpportunityApplicantsRequest(opportunityId, filters));
  };

  return (
    <>
      <SidebarButton placeholder="Filter" close={closeSideBar} setClose={setCloseSideBar}>
        <Divider>
          <Label>Current Class Standing</Label>
          <Dropdown
            name="classStanding"
            onChange={onChangeFilter}
            value={filters.classStanding}
            placeholder="Select Class Standing"
            options={classStanding}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
              maxWidth: '300px',
              marginTop: '10px',
            }}
            showIndicatorSeparator
            noFormik
            isMulti
            getOptionLabel={option => option.description}
          />
        </Divider>
        <Divider>
          <Label>Major</Label>
          <Dropdown
            name="majors"
            onChange={onChangeFilter}
            value={filters.majors}
            placeholder="Select Major"
            options={majorsMinors}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
              maxWidth: '300px',
              marginTop: '10px',
            }}
            showIndicatorSeparator
            noFormik
            isMulti
          />
        </Divider>
        <Divider>
          <Label>Major Category</Label>
          <Dropdown
            name="majorCategory"
            onChange={onChangeFilter}
            value={filters.majorCategory}
            placeholder="Select Major Category"
            options={majorCategories}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
              maxWidth: '300px',
              marginTop: '10px',
            }}
            showIndicatorSeparator
            noFormik
            isMulti
          />
        </Divider>
        <Divider>
          <Label>Minor</Label>
          <Dropdown
            name="minors"
            onChange={onChangeFilter}
            value={filters.minors}
            placeholder="Select Minor"
            options={majorsMinors}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
              maxWidth: '300px',
              marginTop: '10px',
            }}
            showIndicatorSeparator
            noFormik
            isMulti
          />
        </Divider>
        <Divider>
          <Label>Minor Category</Label>
          <Dropdown
            name="minorCategory"
            onChange={onChangeFilter}
            value={filters.minorCategory}
            placeholder="Select Minor Category"
            options={majorCategories}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
              maxWidth: '300px',
              marginTop: '10px',
            }}
            showIndicatorSeparator
            noFormik
            isMulti
          />
        </Divider>
        <Divider>
          <Label>GPA Range</Label>
          <Row $bsStyle={{ flexWrap: 'wrap', marginTop: '10px' }}>
            <Column $bsStyle={{ maxWidth: '100px', marginRight: '10px' }}>
              <Text>From</Text>
              <WrapTextBox
                type="number"
                noFormik
                name="gpaFrom"
                placeholder="From"
                onChange={({ target: { name, value } }) => onChangeFilter(name, Number(value))}
                showErrorMessage={false}
              />
            </Column>
            <Column $bsStyle={{ maxWidth: '100px' }}>
              <Text>To</Text>
              <WrapTextBox
                type="number"
                noFormik
                name="gpaTo"
                placeholder="To"
                onChange={({ target: { name, value } }) => onChangeFilter(name, Number(value))}
                showErrorMessage={false}
              />
            </Column>
            {filters.gpaFrom > filters.gpaTo && (
              <ErrorMessage
                messageError="GPA From can't be more than GPA To"
                style={{ width: '100%', marginTop: '10px' }}
              />
            )}
          </Row>
        </Divider>
        <Divider>
          <Label>Have Honors?</Label>
          <Switch
            name="honors"
            leftLabel="None or not shown"
            label="Yes"
            value={filters.honors}
            onChange={() => onChangeFilter('honors', !filters.honors)}
            labelStyle={{ fontSize: '18px' }}
            leftLabelStyle={{ fontSize: '18px' }}
            style={{ marginBottom: 0, marginTop: '10px' }}
          />
        </Divider>
        <Divider>
          <Label>Have Scholarships?</Label>
          <Switch
            name="scholarships"
            leftLabel="None or not shown"
            label="Yes"
            value={filters.scholarships}
            onChange={() => onChangeFilter('scholarships', !filters.scholarships)}
            labelStyle={{ fontSize: '18px' }}
            leftLabelStyle={{ fontSize: '18px' }}
            style={{ marginBottom: 0, marginTop: '10px' }}
          />
        </Divider>
        <Divider>
          <Label>School/College Region</Label>
          <Dropdown
            name="schoolRegion"
            onChange={onChangeFilter}
            value={filters.schoolRegion}
            placeholder="Select Region"
            options={zonesWithoutInternational}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
              maxWidth: '300px',
              marginTop: '10px',
            }}
            showIndicatorSeparator
            noFormik
          />
        </Divider>
        <Divider>
          <Label>Permanent Address Region</Label>
          <Dropdown
            name="addressRegion"
            onChange={onChangeFilter}
            value={filters.addressRegion}
            placeholder="Select Permanent Address Region"
            options={zones}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
              maxWidth: '300px',
              marginTop: '10px',
            }}
            showIndicatorSeparator
            noFormik
          />
        </Divider>
        <Divider>
          <Label>Language Skills</Label>
          <Dropdown
            name="language"
            onChange={onChangeFilter}
            value={filters.language}
            placeholder="Select Language Skills"
            options={languages}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
              maxWidth: '300px',
              marginTop: '10px',
            }}
            showIndicatorSeparator
            noFormik
            isMulti
          />
        </Divider>
        <Divider>
          <Label>Industries of Interest</Label>
          <Dropdown
            name="industries"
            onChange={onChangeFilter}
            value={filters.industries}
            placeholder="Select Industry Vertical"
            options={industries}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
              maxWidth: '300px',
              marginTop: '10px',
            }}
            showIndicatorSeparator
            noFormik
            isMulti
          />
        </Divider>
        <Divider>
          <Label>Job Functions of Interest</Label>
          <Dropdown
            name="jobFunctions"
            onChange={onChangeFilter}
            value={filters.jobFunctions}
            placeholder="Select Job Function"
            options={jobFunctions}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
              maxWidth: '300px',
              marginTop: '10px',
            }}
            showIndicatorSeparator
            noFormik
            isMulti
          />
        </Divider>
        <Divider>
          <Label>Professional Experience - Industry Verticals</Label>
          <Dropdown
            name="industryVerticals"
            onChange={onChangeFilter}
            value={filters.industryVerticals}
            placeholder="Select Industry Vertical"
            options={industries}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
              maxWidth: '300px',
              marginTop: '10px',
            }}
            showIndicatorSeparator
            noFormik
            isMulti
          />
        </Divider>
        <Divider>
          <Label>Professional Experience - Job Functions</Label>
          <Dropdown
            name="experienceJobFunctions"
            onChange={onChangeFilter}
            value={filters.experienceJobFunctions}
            placeholder="Select Job Function"
            options={jobFunctions}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
              maxWidth: '300px',
              marginTop: '10px',
            }}
            showIndicatorSeparator
            noFormik
            isMulti
          />
        </Divider>
        <CustomButton color={colors.LIGHT_BLUE} onClick={() => handleSearchByFilters(filters)}>
          Apply filters
        </CustomButton>
      </SidebarButton>
      <FiltersList
        filters={{
          classStanding: filters.classStanding,
          majorCategory: filters.majorCategory,
          minorCategory: filters.minorCategory,
          gpaTo: filters.gpaTo,
          gpaFrom: filters.gpaFrom,
          honors: filters.honors,
          scholarships: filters.scholarships,
          schoolRegion: filters.schoolRegion,
          addressRegion: filters.addressRegion,
          experienceJobFunctions: filters.experienceJobFunctions,
          industryVerticals: filters.industryVerticals,
          jobFunctions: filters.jobFunctions,
          language: filters.language,
          industries: filters.industries,
          majors: filters.majors,
          minors: filters.minors,
        }}
        onRemove={onRemove}
      />
    </>
  );
};

Filters.propTypes = {
  opportunityId: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    honors: PropTypes.bool,
    scholarships: PropTypes.bool,
    classStanding: PropTypes.arrayOf(PropTypes.number),
    majorCategory: PropTypes.arrayOf(PropTypes.number),
    minorCategory: PropTypes.arrayOf(PropTypes.number),
    majors: PropTypes.arrayOf(PropTypes.number),
    minors: PropTypes.arrayOf(PropTypes.number),
    schoolRegion: PropTypes.number,
    addressRegion: PropTypes.number,
    experienceJobFunctions: PropTypes.arrayOf(PropTypes.number),
    industryVerticals: PropTypes.arrayOf(PropTypes.number),
    jobFunctions: PropTypes.arrayOf(PropTypes.number),
    language: PropTypes.arrayOf(PropTypes.number),
    industries: PropTypes.arrayOf(PropTypes.number),
    gpaFrom: PropTypes.number,
    gpaTo: PropTypes.number,
  }).isRequired,
};

export default Filters;
