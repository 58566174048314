import find from 'lodash/find';
import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect, { createFilter } from 'react-select';

import { getDropdownMultiValues } from 'utils';

import customStyles from './custom-style';
import DropdownIndicator from './dropdown-indicator';
import IndicatorSeparator from './indicator-separator';
import { Container } from './styled';
import ErrorMessage from '../error-message';

const Dropdown = ({
  name,
  options,
  value,
  onChange,
  placeholder,
  isLoading,
  isSearchable,
  isClearable,
  $bsStyle,
  isObject,
  centered,
  noFormik,
  setFieldTouched,
  showIndicatorSeparator,
  dropdownStyles,
  maxMenuHeight,
  getOptionLabel,
  getOptionValue,
  isMulti,
  disabled,
  ...props
}) => {
  const onChangeSelect = selected => {
    if (setFieldTouched) {
      setFieldTouched(name, true);
    }
    if (isObject) {
      return onChange(selected);
    }
    if (!selected) {
      return onChange(name, null);
    }
    if (isMulti) {
      return onChange(
        name,
        selected.map(select => select.id)
      );
    }
    return onChange(name, selected?.id);
  };

  const formatOptionLabel = (option, search) => {
    const highlighted = search.inputValue
      ? getOptionLabel(option).replace(new RegExp(search.inputValue, 'gi'), label => `<strong>${label}</strong>`)
      : getOptionLabel(option);
    // eslint-disable-next-line react/no-danger
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };

  return (
    <Container $bsStyle={$bsStyle} centered={centered} key={`container${name}`}>
      <ReactSelect
        key={`dropdown${name}`}
        id={`dropdown${name}`}
        name={name}
        menuPlacement="auto"
        onChange={onChangeSelect}
        components={{
          IndicatorSeparator: showIndicatorSeparator ? IndicatorSeparator : null,
          DropdownIndicator,
        }}
        options={options}
        value={
          isMulti
            ? getDropdownMultiValues(options, value, getOptionValue)
            : find(options, option => getOptionValue(option) === value) || ''
        }
        styles={customStyles(centered, dropdownStyles)}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        maxMenuHeight={maxMenuHeight || 300}
        placeholder={placeholder || ''}
        defaultMenuIsOpen={false}
        formatOptionLabel={formatOptionLabel}
        filterOption={createFilter({ ignoreAccents: false })}
        closeMenuOnSelect
        captureMenuScroll={false}
        isMulti={isMulti}
        menuPortalTarget={document.body}
        menuShouldBlockScroll
        isDisabled={disabled}
        {...props}
      />
      {!noFormik && <ErrorMessage control={name} style={{ marginTop: '10px', marginBottom: '10px' }} />}
    </Container>
  );
};

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  name: PropTypes.string.isRequired,
  maxMenuHeight: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  dropdownStyles: PropTypes.shape({
    placeholder: PropTypes.shape({}),
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    PropTypes.bool,
  ]),
  isLoading: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isObject: PropTypes.bool,
  placeholder: PropTypes.string,
  $bsStyle: PropTypes.shape({}),
  centered: PropTypes.bool,
  noFormik: PropTypes.bool,
  isMulti: PropTypes.bool,
  showIndicatorSeparator: PropTypes.bool,
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  $bsStyle: null,
  placeholder: '',
  isLoading: false,
  isSearchable: true,
  isObject: false,
  isMulti: false,
  isClearable: true,
  value: '',
  centered: true,
  noFormik: false,
  showIndicatorSeparator: false,
  setFieldTouched: undefined,
  dropdownStyles: undefined,
  options: [],
  maxMenuHeight: 300,
  getOptionLabel: option => option.name,
  getOptionValue: option => option.id,
  disabled: false,
};

export default Dropdown;
