import PropTypes from 'prop-types';
import React from 'react';

import { Wrapper, Container, Progress, Label } from './styled';

const ProgressBar = ({
  completed,
  width,
  height,
  bgCompleted,
  baseBgColor,
  borderRadius,
  labelSize,
  labelColor,
  $bsStyle,
}) => (
  <Wrapper $bsStyle={$bsStyle}>
    <Container width={width} height={height} baseBgColor={baseBgColor} borderRadius={borderRadius}>
      <div style={{ position: 'relative' }}>
        {labelSize && (
          <Label labelSize={labelSize} labelColor={labelColor}>
            {`${completed}%`}
          </Label>
        )}
      </div>
      <Progress bgCompleted={bgCompleted} completed={completed} borderRadius={borderRadius} />
    </Container>
  </Wrapper>
);

ProgressBar.propTypes = {
  completed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  bgCompleted: PropTypes.string,
  baseBgColor: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  borderRadius: PropTypes.string,
  labelColor: PropTypes.string,
  labelSize: PropTypes.string,
  $bsStyle: PropTypes.shape({}),
};

ProgressBar.defaultProps = {
  bgCompleted: 'linear-gradient(180deg, #FFCF39 0%, #DC9610 100%)',
  height: '65px',
  width: '100%',
  borderRadius: '30px',
  baseBgColor: 'rgba(182,204,203,0.2)',
  labelColor: '#8b35c9',
  labelSize: null,
  $bsStyle: undefined,
};

export default ProgressBar;
