import { Resume } from '@hivediversity/common-lib/components';
import { useQuery } from '@hivediversity/common-lib/hooks';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Modal } from 'components';
import { resumeOrigin, routes } from 'constant';
import opportunityDataCreator from 'store/opportunity/actions';
import { getApplicant } from 'store/opportunity/selectors';
import { transformRoute } from 'utils';

const ApplicantResume = () => {
  const dispatch = useDispatch();
  const { opportunityId, applicantId } = useParams();
  const applicant = useSelector(getApplicant);
  const origin = useQuery('origin', window.location);

  useEffect(() => {
    dispatch(opportunityDataCreator.applicantFindRequest(opportunityId, applicantId));
  }, []);

  return (
    <Modal
      show
      fullPage
      onClose={() =>
        dispatch(
          push(
            origin === resumeOrigin.OPPORTUNITY
              ? transformRoute(routes.OPPORTUNITY_APPLICANTS_TAB, { opportunityId })
              : routes.DASHBOARD
          )
        )
      }
    >
      <>{applicant && <Resume applicantResumeUrl={`${applicant.resumeUrl}&download=true`} />}</>
    </Modal>
  );
};

export default ApplicantResume;
