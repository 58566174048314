import {
  compensationType as compensationTypeEnums,
  opportunityLocationType,
  opportunityTypes,
  expectedDateHire as expectedDateHireEnums,
  opportunityDuration,
  opportunityForEnums,
} from '@hivediversity/common-lib/constant';
import * as yup from 'yup';

import { schemaMessages, urlRegex, emailRegex } from 'constant';
import { isDateAfterToday, unformatMoney } from 'utils';

function uniqueRemoteLocation() {
  // eslint-disable-next-line
  return this.test('unique', null, function (locations) {
    const locationsWithIndex = locations.map((location, index) => ({ ...location, index }));

    const remoteLocations = locationsWithIndex.filter(location => location.type === opportunityLocationType.REMOTE);
    if (remoteLocations.length > 1) {
      const lastRemoteLocation = remoteLocations[remoteLocations.length - 1];
      throw new yup.ValidationError(
        this.createError({
          path: `${this.path}.${lastRemoteLocation.index}.type`,
          message: schemaMessages.REMOTE_LOCATION,
        })
      );
    }
  });
}

yup.addMethod(yup.array, 'uniqRemoteLocation', uniqueRemoteLocation);

function unique(message, mapper = a => a) {
  return this.test('unique', message, (list = []) => list.length === new Set(list.map(mapper)).size);
}

yup.addMethod(yup.array, 'unique', unique);

function uniqueProperty(propertyName, message) {
  // eslint-disable-next-line no-restricted-syntax,func-names
  return this.test('uniqueProperty', message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    if (this.parent.filter(parent => parent !== value).some(parent => parent[propertyName] === value[propertyName])) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      });
    }

    return true;
  });
}

yup.addMethod(yup.object, 'uniqueProperty', uniqueProperty);

const validationSchema = yup.object().shape({
  id: yup.number().nullable(),
  title: yup.string().required(schemaMessages.REQUIRED),
  summary: yup.string().required(schemaMessages.REQUIRED),
  type: yup.string().required(schemaMessages.REQUIRED),
  numberOfPotentialHires: yup
    .number()
    .when('type', {
      is: type => type !== opportunityTypes.EVENT,
      then: yup.number().positive(schemaMessages.POSITIVE_NUMBER).nullable(),
    })
    .positive(schemaMessages.POSITIVE_NUMBER),
  applicationsReceived: yup.array().of(yup.number()).min(1, schemaMessages.REQUIRED).required(schemaMessages.REQUIRED),
  requireUsWorkAuthorization: yup
    .bool()
    .nullable()
    .when('skipWorkEligibilityQuestions', {
      is: skipWorkEligibilityQuestions => !skipWorkEligibilityQuestions,
      then: yup.bool().nullable().required(schemaMessages.REQUIRED),
    }),
  sponsorWorkVisa: yup
    .bool()
    .nullable()
    .when('skipWorkEligibilityQuestions', {
      is: skipWorkEligibilityQuestions => !skipWorkEligibilityQuestions,
      then: yup.bool().nullable().required(schemaMessages.REQUIRED),
    }),
  hiringTemporarilyAuthorizedCandidates: yup
    .bool()
    .nullable()
    .when('skipWorkEligibilityQuestions', {
      is: skipWorkEligibilityQuestions => !skipWorkEligibilityQuestions,
      then: yup.bool().nullable().required(schemaMessages.REQUIRED),
    }),
  skipWorkEligibilityQuestions: yup.bool().nullable().required(schemaMessages.REQUIRED),
  jobFunctions: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number(),
        name: yup.string(),
      })
    )
    .when('type', {
      is: type => type !== opportunityTypes.EVENT,
      then: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.number(),
            name: yup.string(),
          })
        )
        .compact(value => value.id === 0)
        .min(1, schemaMessages.REQUIRED),
    })
    .compact(value => value.id === 0),
  locations: yup
    .array()
    .of(
      yup.object().shape({
        type: yup.number().nullable().required(schemaMessages.REQUIRED),
        international: yup.bool().when('type', {
          is: value => value !== opportunityLocationType.REMOTE,
          then: yup.bool().required(schemaMessages.REQUIRED),
        }),
        stateCode: yup
          .string()
          .when(['international', 'type'], {
            is: (international, type) => !international && type !== opportunityLocationType.REMOTE,
            then: yup.string().nullable().required(schemaMessages.REQUIRED),
          })
          .nullable(),
        zipCode: yup
          .string()
          .when(['international', 'type'], {
            is: (international, type) => !international && type !== opportunityLocationType.REMOTE,
            then: yup.string().nullable().required(schemaMessages.REQUIRED),
          })
          .nullable(),
        cityId: yup
          .number()
          .when(['stateCode', 'type'], {
            is: (stateCode, type) => stateCode && type !== opportunityLocationType.REMOTE,
            then: yup.number().nullable().required(schemaMessages.REQUIRED),
          })
          .nullable(),
        countryId: yup
          .number()
          .when(['international', 'type'], {
            is: (international, type) => international && type !== opportunityLocationType.REMOTE,
            then: yup.number().nullable().required(schemaMessages.REQUIRED),
          })
          .nullable(),
        cityName: yup
          .string()
          .when(['countryId', 'type'], {
            is: (countryId, type) => countryId && countryId !== 214 && type !== opportunityLocationType.REMOTE,
            then: yup.string().nullable().required(schemaMessages.REQUIRED),
          })
          .nullable(),
        countryName: yup
          .string()
          .when(['countryId', 'type'], {
            is: (countryId, type) => countryId && countryId === 214 && type !== opportunityLocationType.REMOTE,
            then: yup.string().nullable().required(schemaMessages.REQUIRED),
          })
          .nullable(),
      })
    )
    .uniqRemoteLocation(),
  startDay: yup.number().nullable().required(schemaMessages.REQUIRED),
  startMonth: yup.number().nullable().required(schemaMessages.REQUIRED),
  startYear: yup
    .number()
    // eslint-disable-next-line
    .test('invalid-start-date', schemaMessages.INVALID_DATE, function (value) {
      const { startDay, startMonth, id } = this.parent;
      if (!id && startDay && startMonth && value) {
        return isDateAfterToday(value, startMonth - 1, startDay);
      }
      return true;
    })
    .nullable()
    .required(schemaMessages.REQUIRED),
  startHour: yup.number().nullable().required(schemaMessages.REQUIRED),
  startMinute: yup.number().nullable().required(schemaMessages.REQUIRED),
  anteMeridiem: yup.bool().nullable().required(schemaMessages.REQUIRED),
  endDay: yup.number().nullable().required(schemaMessages.REQUIRED),
  endMonth: yup.number().nullable().required(schemaMessages.REQUIRED),
  endYear: yup
    .number()
    // eslint-disable-next-line
    .test('invalid-end-date', schemaMessages.INVALID_DATE, function (value) {
      const { endDay, endMonth } = this.parent;
      return endDay && endMonth && value ? isDateAfterToday(value, endMonth - 1, endDay) : true;
    })
    .nullable()
    .required(schemaMessages.REQUIRED),
  endHour: yup.number().nullable().required(schemaMessages.REQUIRED),
  endMinute: yup.number().nullable().required(schemaMessages.REQUIRED),
  endHourAnteMeridiem: yup.bool().nullable().required(schemaMessages.REQUIRED),
  eventDay: yup
    .number()
    .when('type', {
      is: type => type === opportunityTypes.EVENT,
      then: yup.number().nullable().required(schemaMessages.REQUIRED),
    })
    .nullable(),
  eventMonth: yup
    .number()
    .when('type', {
      is: type => type === opportunityTypes.EVENT,
      then: yup.number().nullable().required(schemaMessages.REQUIRED),
    })
    .nullable(),
  eventYear: yup
    .number()
    .when('type', {
      is: type => type === opportunityTypes.EVENT,
      then: yup
        .number()
        .nullable()
        // eslint-disable-next-line
        .test('invalid-end-date', schemaMessages.INVALID_DATE, function (value) {
          const { endDay, endMonth } = this.parent;
          return endDay && endMonth && value ? isDateAfterToday(value, endMonth - 1, endDay) : true;
        })
        .required(schemaMessages.REQUIRED),
    })
    .nullable(),
  eventHour: yup
    .number()
    .when('type', {
      is: type => type === opportunityTypes.EVENT,
      then: yup.number().nullable().required(schemaMessages.REQUIRED),
    })
    .nullable(),
  eventMinute: yup
    .number()
    .when('type', {
      is: type => type === opportunityTypes.EVENT,
      then: yup.number().nullable().required(schemaMessages.REQUIRED),
    })
    .nullable(),
  eventAnteMeridiem: yup
    .bool()
    .when('type', {
      is: type => type === opportunityTypes.EVENT,
      then: yup.bool().nullable().required(schemaMessages.REQUIRED),
    })
    .nullable(),
  industries: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          id: yup.number().nullable().required(schemaMessages.REQUIRED),
          subIndustries: yup
            .array()
            .of(yup.number())
            .compact()
            .min(1, schemaMessages.REQUIRED)
            .unique(schemaMessages.UNIQUE_SUB_INDUSTRIES),
        })
        .uniqueProperty('id', schemaMessages.INDUSTRY_REPEATED)
    )
    .required(schemaMessages.REQUIRED),
  hasExternalApplicationLink: yup
    .bool()
    .when('type', {
      is: type => [opportunityTypes.JOB, opportunityTypes.INTERNSHIPS].includes(type),
      then: yup.bool().nullable().required(schemaMessages.REQUIRED),
    })
    .nullable(),
  applicationUrl: yup
    .string()
    .when(['hasExternalApplicationLink', 'type'], {
      is: (hasExternalApplicationLink, type) => hasExternalApplicationLink || type === opportunityTypes.EVENT,
      then: yup.string().required(schemaMessages.REQUIRED),
    })
    .matches(urlRegex, schemaMessages.VALID_URL)
    .nullable(),
  expectedDateMonth: yup
    .number()
    .when('expectedDateHire', {
      is: value => value === expectedDateHireEnums.DATE,
      then: yup.number().nullable().required(schemaMessages.REQUIRED),
    })
    .nullable(),
  expectedDateYear: yup
    .number()
    // eslint-disable-next-line
    .test('invalid-expected-date', schemaMessages.INVALID_DATE, function (value) {
      const { expectedDateDay, expectedDateMonth, expectedDateHire } = this.parent;
      return expectedDateHire === expectedDateHireEnums.DATE && expectedDateDay
        ? expectedDateDay && expectedDateMonth && isDateAfterToday(value, expectedDateMonth - 1, expectedDateDay)
        : true;
    })
    .when('expectedDateHire', {
      is: value => value === expectedDateHireEnums.DATE,
      then: yup.number().nullable().required(schemaMessages.REQUIRED),
    })
    .nullable(),
  duration: yup
    .number()
    .when('type', {
      is: value => [opportunityTypes.JOB, opportunityTypes.INTERNSHIPS].includes(value),
      then: yup.number().nullable().required(schemaMessages.REQUIRED),
    })
    .nullable(),
  applicantsFilter: yup
    .number()
    .when(['hasExternalApplicationLink', 'type'], {
      is: (hasExternalApplicationLink, type) =>
        ([opportunityTypes.JOB, opportunityTypes.INTERNSHIPS].includes(type) && hasExternalApplicationLink) ||
        type === opportunityTypes.EVENT,
      then: yup.number().nullable().required(schemaMessages.REQUIRED),
    })
    .nullable(),
  annualSalaryFrom: yup
    .string()
    .when(['type', 'duration'], {
      is: (type, duration) => type === opportunityTypes.JOB && duration === opportunityDuration.FULL_TIME,
      then: yup
        .string()
        .nullable()
        .test(
          'annual-salary-from-validation',
          "Annual salary from can't be more than To",
          (value, context) =>
            !(
              value &&
              context.parent.annualSalaryTo &&
              unformatMoney(value) > unformatMoney(context.parent.annualSalaryTo)
            )
        )
        .required(schemaMessages.REQUIRED),
    })
    .nullable(),
  salary: yup
    .string()
    .when(['type', 'duration', 'compensationType'], {
      is: (type, duration, compensationType) =>
        type === opportunityTypes.INTERNSHIPS && duration && compensationType === compensationTypeEnums.PAID,
      then: yup.string().nullable().required(schemaMessages.REQUIRED),
    })
    .nullable(),
  paymentPeriod: yup
    .number()
    .when(['type', 'duration', 'compensationType'], {
      is: (type, duration, compensationType) =>
        type === opportunityTypes.INTERNSHIPS && duration && compensationType === compensationTypeEnums.PAID,
      then: yup.number().nullable().required(schemaMessages.REQUIRED),
    })
    .nullable(),
  annualSalaryTo: yup
    .string()
    .when(['type', 'duration'], {
      is: (type, duration) => type === opportunityTypes.JOB && duration === opportunityDuration.FULL_TIME,
      then: yup
        .string()
        .test(
          'annual-salary-to-validation',
          "Annual salary to can't be less than From",
          (value, context) =>
            !(
              value &&
              context.parent.annualSalaryFrom &&
              unformatMoney(value) < unformatMoney(context.parent.annualSalaryFrom)
            )
        )
        .nullable()
        .required(schemaMessages.REQUIRED),
    })
    .nullable(),
  visibleForCandidates: yup
    .bool()
    .when(['type', 'duration', 'compensation', 'annualSalaryFrom', 'annualSalaryTo'], {
      is: (type, duration, compensation, annualSalaryFrom, annualSalaryTo) =>
        type === opportunityTypes.JOB &&
        ((duration === opportunityDuration.FULL_TIME && annualSalaryFrom && annualSalaryTo) ||
          (duration === opportunityDuration.PART_TIME && compensation)),
      then: yup.bool().nullable().required(schemaMessages.REQUIRED),
    })
    .nullable(),
  compensationVisibleForStudents: yup
    .bool()
    .when(['type', 'compensationType', 'salary'], {
      is: (type, compensationType, salary) =>
        type === opportunityTypes.INTERNSHIPS && compensationType === compensationTypeEnums.PAID && salary,
      then: yup.bool().nullable().required(schemaMessages.REQUIRED),
    })
    .nullable(),
  preferredContactEmail: yup
    .string()
    .nullable()
    .matches(emailRegex, schemaMessages.VALID_EMAIL)
    .required(schemaMessages.REQUIRED),
  classStanding: yup.array().of(yup.number()).min(1, schemaMessages.REQUIRED).required(schemaMessages.REQUIRED),
  laborActAgreement: yup
    .bool()
    .nullable()
    .when(['type', 'compensationType'], {
      is: (type, compensationType) =>
        type === opportunityTypes.INTERNSHIPS && compensationType === compensationTypeEnums.UNPAID,
      then: yup.bool().oneOf([true], schemaMessages.REQUIRED).nullable(),
    }),
  opportunityFor: yup.number().nullable().required(schemaMessages.REQUIRED),
  opportunityForName: yup
    .string()
    .nullable()
    .when('opportunityFor', {
      is: opportunityFor =>
        [opportunityForEnums.INTERNAL_DIVISION, opportunityForEnums.SUBSIDIARY].includes(opportunityFor),
      then: yup.string().nullable().required(schemaMessages.REQUIRED),
    }),
  opportunityForDescription: yup
    .string()
    .nullable()
    .when('opportunityFor', {
      is: opportunityFor =>
        [opportunityForEnums.INTERNAL_DIVISION, opportunityForEnums.SUBSIDIARY].includes(opportunityFor),
      then: yup.string().nullable().required(schemaMessages.REQUIRED),
    }),
  adminUsers: yup
    .array()
    .of(yup.number())
    .when('grantAdminAccess', {
      is: grantAdminAccess => grantAdminAccess,
      then: yup
        .array()
        .of(yup.number())
        .compact(value => !value || value === '')
        .min(1, schemaMessages.REQUIRED)
        .unique(schemaMessages.USER_REPEATED),
    }),
});

export default validationSchema;
