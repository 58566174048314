import { isDate } from 'lodash';

const formatDate = input => {
  if (!input || !isDate(new Date(input))) {
    return null;
  }
  return new Date(input);
};

export default formatDate;
