import PropTypes from 'prop-types';
import React from 'react';

import { Container } from './styled';
import Blanket from '../blanket';
import Menu from '../menu';

const Dropdown = ({ children, isOpen, target }) => (
  <Container>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket /> : null}
  </Container>
);

Dropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  target: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default Dropdown;
