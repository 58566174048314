import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

const Control = ({ children, ...props }) => (
  <components.Control {...props}>{props.selectProps.isSearchable ? children : null}</components.Control>
);

Control.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  selectProps: PropTypes.shape({
    isSearchable: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Control;
