export const initialState = {
  company: null,
  error: null,
  isLoading: false,
  isUpdating: false,
  isUpdatingSection: false,
  isUploading: false,
  isUploadingImages: false,
  isLoadingUsers: false,
  isActivating: false,
  profileComplete: false,
  users: [],
};
