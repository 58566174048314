export const CANT_DELETE = "This position can't be deleted because it has active applicants";
export const WANT_DELETE = 'Are you sure you want to delete this position?';
export const SURE_RESTORE_OR_ARCHIVE = isRestore =>
  `Are you sure you want to ${isRestore ? 'restore' : 'archive'} this position?`;
export const FILE_MAX_SIZE = 'Image Max size 10MB / Video Max size 80MB';
export const SURE_COPY_OPPORTUNITY = 'Are you sure you want to copy this position?';
export const AN_ERROR_OCCURRED = 'Oops! Something went wrong.';

export const ADDED_NOTE = 'The new note has been added';
export const REMOVED_NOTE = 'The note has been removed';
export const EMPTY_NOTE = 'First you must write something';
