import { Column } from '@hivediversity/common-lib/base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Check, ConfirmModal } from 'components';

import { Anchor } from '../styled';

const FairLaborStandardsModal = ({ show, onAccept, onDismiss, laborActAgreement }) => {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState();

  useEffect(() => {
    if (show) {
      setHasAcceptedTerms(laborActAgreement);
    }
  }, [laborActAgreement, show]);

  return (
    <ConfirmModal
      show={show}
      onAccept={onAccept}
      onDismiss={onDismiss}
      title="Internship Programs Under The Fair Labor Standards Act"
      acceptLabel="Confirm"
      isAcceptButtonDisabled={!hasAcceptedTerms}
      cancelLabel="Cancel"
    >
      <Column center>
        <Anchor
          href="https://www.dol.gov/agencies/whd/fact-sheets/71-flsa-internships"
          rel="noreferrer"
          target="_blank"
          $bsStyle={{ fontSize: '22px', margin: '15px' }}
        >
          Fair Labor Standards Act Agreement
        </Anchor>
        <Check
          name="laborActAgreement"
          label="I HAVE CLICKED THE ABOVE LINK, READ THE ENTIRE AGREEMENT AND CONFIRM THAT THIS
          UNPAID OPPORTUNITY IS IN ACCORDANCE WITH THE FAIR LABOR STANDARDS ACT."
          onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
          value={hasAcceptedTerms}
          labelStyle={{ textAlign: 'start' }}
        />
      </Column>
    </ConfirmModal>
  );
};

FairLaborStandardsModal.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  laborActAgreement: PropTypes.bool,
};

FairLaborStandardsModal.defaultProps = {
  laborActAgreement: false,
};

export default FairLaborStandardsModal;
