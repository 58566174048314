import { Sidebar, SidebarItem } from '@hivediversity/common-lib/components';
import { push } from 'connected-react-router';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { routes } from 'constant';
import { clearToken } from 'services/storage';
import { getProfileComplete } from 'store/company/selectors';

const Header = ({ showOpportunitiesItem }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const profileComplete = useSelector(getProfileComplete);
  const [sidebarClose, setSidebarClose] = useState(false);
  const [currentPath, setCurrentPath] = useState();
  const handleRedirect = useCallback(
    e => {
      setSidebarClose(true);
      setCurrentPath(e.target.id);
      dispatch(push(e.target.id));
    },
    [location]
  );
  const getPathName = useCallback(path => location.pathname.startsWith(path), [location]);

  useEffect(() => {
    if (
      [routes.DASHBOARD, routes.SUPPORT, routes.COMPANY, routes.WELCOME, routes.DATA_INSIGHTS].some(route =>
        location.pathname.startsWith(route)
      )
    ) {
      setSidebarClose(false);
    }
  }, [currentPath, location]);

  const handleLogout = () => {
    clearToken();
    window.location.href = routes.LOGIN;
  };

  return (
    <Sidebar onLogout={handleLogout} isClosed={sidebarClose}>
      <SidebarItem
        id={profileComplete ? routes.COMPANY_PREVIEW : routes.WELCOME}
        onClick={handleRedirect}
        active={getPathName(profileComplete ? routes.COMPANY : routes.WELCOME)}
      >
        Company profile
      </SidebarItem>
      {showOpportunitiesItem && (
        <SidebarItem id={routes.DASHBOARD} onClick={handleRedirect} active={getPathName(routes.DASHBOARD)}>
          Positions
        </SidebarItem>
      )}
      {/*TODO: uncomment after clarification from customer */}
      {/* {company.aggregateDataHive && (
        <SidebarItem id={routes.DATA_INSIGHTS} onClick={handleRedirect} active={getPathName(routes.DATA_INSIGHTS)}>
          Data & Insights
        </SidebarItem>
      )} */}
      <SidebarItem id={routes.SUPPORT} onClick={handleRedirect} active={getPathName(routes.SUPPORT)}>
        Admin & Support
      </SidebarItem>
      <SidebarItem id="privacy" onClick={noop} href="mailto:info@hellohive.com">
        <a href="https://hellohive.com/userprivacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </SidebarItem>
      <SidebarItem id="conditions" onClick={noop} href="mailto:info@hellohive.com">
        <a href="https://hellohive.com/userterms" target="_blank" rel="noopener noreferrer">
          Terms &amp; Conditions
        </a>
      </SidebarItem>
    </Sidebar>
  );
};

Header.propTypes = {
  showOpportunitiesItem: PropTypes.bool,
};

Header.defaultProps = {
  showOpportunitiesItem: true,
};

export default Header;
