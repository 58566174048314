import { CustomButton } from '@hivediversity/common-lib/buttons';
import { colors } from '@hivediversity/common-lib/constant';
import { stateToHTML } from 'draft-js-export-html';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import Loader from 'react-loader-spinner';

import { Editor, Modal } from 'components';

import { ButtonsContainer, Email, EmailAnimation, EmailArrowDown } from './styled';

const ConfirmCandidateModal = ({ show, onDismiss, onAccept, email }) => {
  const [isScrollable, setIsScrollable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const draftRef = useRef(null);

  useEffect(() => {
    if (email && show) {
      const emailDiv = document.getElementById('email');
      setIsScrollable(emailDiv && emailDiv.scrollHeight > emailDiv.clientHeight);
    }
    if (!show) {
      setIsEditing(false);
    }
  }, [email, show]);

  const handleAccept = () => {
    if (draftRef?.current?.props) {
      onAccept(stateToHTML(draftRef?.current?.props?.editorState?.getCurrentContent()));
    } else {
      onAccept(email);
    }
  };

  return (
    <Modal
      show={show}
      title="The candidate will receive the below email notification:"
      $bsStyle={{
        maxWidth: '800px',
        width: '100%',
        minWidth: '200px',
        padding: '25px',
      }}
      containerStyles={{ width: '100%', maxWidth: '700px', minWidth: '200px' }}
      background="linear-gradient(135deg, rgba(29,37,38,1) 0%, rgba(29,37,38,1) 15%, rgba(0,0,0,1) 100%), #1D2526"
    >
      <>
        {email ? (
          <>
            {isEditing ? (
              <Editor value={email} showToolbar={false} ref={draftRef} />
            ) : (
              <Email
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: email }}
                id="email"
              />
            )}
            {isScrollable && (
              <EmailAnimation>
                <EmailArrowDown />
              </EmailAnimation>
            )}
          </>
        ) : (
          <Loader type="TailSpin" color="white" height={24} width={24} />
        )}
        <ButtonsContainer>
          <CustomButton
            $bsStyle={{ maxWidth: '200px', marginBottom: '5px' }}
            color={colors.LIGHT_BLUE}
            onClick={handleAccept}
          >
            Send
          </CustomButton>
          <CustomButton
            $bsStyle={{ maxWidth: '200px', marginBottom: '5px' }}
            color={colors.GREEN}
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ? 'Discard' : 'Edit'}
          </CustomButton>
          <CustomButton $bsStyle={{ maxWidth: '200px', marginBottom: '5px' }} color={colors.ORANGE} onClick={onDismiss}>
            Cancel
          </CustomButton>
        </ButtonsContainer>
      </>
    </Modal>
  );
};

ConfirmCandidateModal.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  email: PropTypes.string,
};

ConfirmCandidateModal.defaultProps = {
  email: undefined,
};

export default ConfirmCandidateModal;
