import { createReducer } from 'reduxsauce';

import { employerTypes } from 'store/employer/actions';
import { initialState } from 'store/employer/initial-state';

const fetchRequest = state => ({
  ...state,
  isLoading: true,
  error: null,
});

const fetchSuccess = (state, { employer }) => ({
  ...state,
  employer,
  isLoading: false,
  error: null,
});

const fetchFailure = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

const changePasswordRequest = state => ({
  ...state,
  changingPassword: true,
  error: null,
});

const changePasswordSuccess = state => ({
  ...state,
  changingPassword: false,
  error: null,
});

const changePasswordError = (state, { error }) => ({
  ...state,
  error,
  changingPassword: false,
});

const reducer = createReducer(initialState, {
  [employerTypes.EMPLOYER_FETCH_REQUEST]: fetchRequest,
  [employerTypes.EMPLOYER_FETCH_SUCCESS]: fetchSuccess,
  [employerTypes.EMPLOYER_FETCH_ERROR]: fetchFailure,

  [employerTypes.CHANGE_PASSWORD_REQUEST]: changePasswordRequest,
  [employerTypes.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [employerTypes.CHANGE_PASSWORD_ERROR]: changePasswordError,
});

export default reducer;
