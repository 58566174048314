export const getStaticData = state => state.staticData;

export const getIsLoading = state => state.staticData.isLoading;

export const getError = state => state.staticData.error;

export const getIsLoadingStates = state => state.staticData.isLoadingStates;

export const getCities = state => state.staticData.cities || [];

export const getSubIndustries = state => state.staticData.subIndustries || [];

export const getDiversities = state => state.staticData.diversities;

export const getIsLoadingDiversities = state => state.staticData.isLoadingDiversities;

export const getMajorsOrMinorsCategories = (state, entity) => state.staticData[entity];
