import compact from 'lodash/compact';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import Select from 'react-select';

import { selectAll, unselectAll } from 'constant/options';
import { getDropdownMultiValues } from 'utils';

import Control from './control';
import Dropdown from './dropdown';
import Group from './group';
import Input from './input';
import MultiValue from './multi-value';
import NoOptionsMessage from './no-options-message';
import Option from './option';
import selectStyles from './selectStyles';
import { Search } from './styled';
import Target from './target';
import ValueContainer from './value-container';

const DropdownWithCheckbox = ({
  onChange,
  targetPlaceholder,
  placeholder,
  getOptionLabel,
  getOptionValue,
  name,
  value,
  options,
  isMulti,
  hasSelectAllOptions,
  groupSelectedOptions,
  closeWhenAnOptionIsSelected,
  defaultValue,
  isSearchable,
  setIsOpen,
  isOpen,
  disabled,
}) => {
  const ref = useRef(null);
  const handleClickOutside = event => {
    if (ref.current && event.target.contains(ref.current)) {
      setIsOpen(name, false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const handleOpen = () => setIsOpen(name, !isOpen);

  const handleChange = (selectedValue, actionMeta) => {
    const { option } = actionMeta;
    if (!selectedValue) {
      return null;
    }
    let currentValue = isMulti ? selectedValue.map(select => getOptionValue(select)) : getOptionValue(selectedValue);
    if (isMulti) {
      if (option.selectAll) {
        currentValue = options.map(currentOption => getOptionValue(currentOption));
      }
      if (option.unselectAll) {
        currentValue = [];
      }
    }
    if (closeWhenAnOptionIsSelected) {
      setIsOpen(name, false);
    }
    return onChange(name, currentValue);
  };

  const getOptions = () => {
    let currentOptions = options;
    if (isMulti) {
      if (hasSelectAllOptions) {
        currentOptions = [compact(value).length > 0 ? unselectAll : selectAll, ...options];
      }
      if (groupSelectedOptions && isArray(value) && value?.length > 0) {
        const filterSelectedOptions = getDropdownMultiValues(options, value, getOptionValue);
        const filterUnselectedOptions = filter(options, option => !value.includes(getOptionValue(option)));
        currentOptions = [
          {
            label: '',
            options: [filterSelectedOptions.length === options.length ? unselectAll : selectAll],
          },
          { label: '', options: filterSelectedOptions },
          { label: '', options: filterUnselectedOptions },
        ];
      }
    }
    return currentOptions;
  };

  const getTargetPlaceholder = () => {
    if ((!isMulti && !value && !isBoolean(value)) || (isMulti && compact(value).length === 0)) {
      return targetPlaceholder;
    }
    if (isMulti) {
      const selectedOptions = getDropdownMultiValues(options, value, getOptionValue);
      if (selectedOptions.length > 2) {
        const totalSelectedOptions = selectedOptions.length;
        const firstSelectedOption = selectedOptions[0];
        const secondSelectedOption = selectedOptions[1];
        const twoSelectedOptions = [firstSelectedOption, secondSelectedOption]
          .map(selectedOption => getOptionLabel(selectedOption))
          .join(', ');
        return `${twoSelectedOptions} +${totalSelectedOptions - 2} more...`;
      }
      return selectedOptions.map(selectedOption => getOptionLabel(selectedOption)).join(', ');
    }
    return getOptionLabel(options.find(option => getOptionValue(option) === value));
  };

  const handleClean = () => {
    onChange(name, defaultValue);
    setIsOpen(name, false);
  };

  const handleAction = ({ target: { id } }) => (id === 'clean' ? handleClean() : handleOpen());

  return (
    <div ref={ref}>
      <Dropdown
        target={
          <Target
            placeholder={getTargetPlaceholder()}
            isOpen={isOpen}
            isClearable={(isMulti && compact(value).length > 0) || (!isMulti && (value || isBoolean(value)))}
            disabled={disabled}
            onAction={handleAction}
          />
        }
        isOpen={isOpen}
      >
        <Select
          name={name}
          components={{
            DropdownIndicator: isSearchable ? Search : null,
            Control,
            IndicatorSeparator: null,
            Input,
            Option,
            MultiValue: isMulti ? MultiValue : null,
            Group: groupSelectedOptions ? Group : null,
            ValueContainer,
            NoOptionsMessage,
          }}
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          onChange={handleChange}
          options={getOptions()}
          placeholder={placeholder}
          value={
            isMulti
              ? getDropdownMultiValues(options, value, getOptionValue)
              : find(options, option => getOptionValue(option) === value) || ''
          }
          captureMenuScroll={false}
          styles={selectStyles}
          menuIsOpen
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          isMulti={isMulti}
          isSearchable={isSearchable}
        />
      </Dropdown>
    </div>
  );
};

DropdownWithCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  name: PropTypes.string.isRequired,
  targetPlaceholder: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string])),
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      selectAll: PropTypes.bool,
      unselectAll: PropTypes.bool,
    })
  ),
  isMulti: PropTypes.bool,
  hasSelectAllOptions: PropTypes.bool,
  groupSelectedOptions: PropTypes.bool,
  closeWhenAnOptionIsSelected: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
};

DropdownWithCheckbox.defaultProps = {
  getOptionLabel: option => option.name,
  getOptionValue: option => option.id,
  value: undefined,
  options: [],
  isMulti: false,
  hasSelectAllOptions: false,
  groupSelectedOptions: false,
  closeWhenAnOptionIsSelected: false,
  isOpen: false,
  disabled: false,
  isSearchable: true,
  defaultValue: undefined,
};

export default DropdownWithCheckbox;
