import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

import Check from '../../check';

const Option = ({ ...props }) => (
  <components.Option {...props}>
    <Check
      name={props.selectProps.name}
      value={props.isSelected || props.data.unselectAll}
      label={props.selectProps.getOptionLabel(props.data)}
      labelStyle={{
        margin: 'auto',
        paddingLeft: '10px',
        fontWeight: 400,
        fontSize: '19px',
        fontFamily: 'Brandon Grotesque',
        letterSpacing: '-0.5px',
      }}
      noFormik
      readOnly
    />
  </components.Option>
);

Option.propTypes = {
  selectProps: PropTypes.shape({
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    selectAll: PropTypes.bool,
    unselectAll: PropTypes.bool,
  }).isRequired,
};

export default Option;
