import { useQuery } from '@hivediversity/common-lib/hooks';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import authorizationCreator from 'store/authorization/actions';
import { getToken } from 'store/authorization/selectors';
import companyCreator from 'store/company/actions';
import { getCompany } from 'store/company/selectors';

const useLogin = () => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const company = useSelector(getCompany);
  const [isRequested, setIsRequested] = useState(false);
  const code = useQuery('code', window.location);
  const state = useQuery('state', window.location);
  const opportunityId = useQuery('opportunityId', window.location);
  const { location, push } = useHistory();
  const [redirectTo, setRedirectTo] = useState('/');

  useEffect(() => {
    if (
      location.pathname !== '/impersonate' &&
      location.pathname !== '/login' &&
      !location.pathname.includes('/recovery')
    ) {
      setRedirectTo(location.pathname);
    }
  }, []);

  useEffect(() => {
    if (code && !state) {
      dispatch(authorizationCreator.authImpersonateRequest(code, opportunityId));
      setIsRequested(true);
    }
  }, [code, state]);

  useEffect(() => {
    if (!isRequested && !code && !state) {
      dispatch(authorizationCreator.authGetTokenRequest());
      setIsRequested(true);
    }
  }, [dispatch, setIsRequested, isRequested, code, state]);

  useEffect(() => {
    if (token && !company) {
      dispatch(companyCreator.companyFetchRequest());
      push(redirectTo);
    }
  }, [token, company]);

  return [isRequested, token, company];
};

export default useLogin;
