import ReactLoader from 'react-loader-spinner';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 0 15px 0;
  justify-content: ${({ centered }) => (centered ? 'center' : 'left')};
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ $bsStyle }) => $bsStyle || ''}

  .small-triangle-alt {
    right: 3px;
    top: 50%;
    margin-top: -10px;
    transform: rotate(90deg);
    position: absolute;
  }

  &.select-wrapper--alt {
    .small-triangle-alt {
      margin-top: -17px;
    }
  }
`;

export const Loader = styled(ReactLoader)`
  position: absolute;
  right: 0;
  display: flex;
`;
