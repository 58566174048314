import { createReducer } from 'reduxsauce';

import { companyTypes } from 'store/company/actions';
import { initialState } from 'store/company/initial-state';

const fetchRequest = state => {
  return {
    ...state,
    isLoading: true,
    error: null,
  };
};

const fetchSuccess = (state, { company }) => {
  return {
    ...state,
    company,
    isLoading: false,
    error: null,
    profileComplete: company ? company.profileComplete : undefined,
  };
};

const fetchFailure = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
  profileComplete: false,
});

const updateRequest = (state, { section }) => ({
  ...state,
  isUpdating: !section,
  isUpdatingSection: !!section,
  error: null,
});

const updateSuccess = (state, { company }) => ({
  ...state,
  company,
  isUpdating: false,
  isUpdatingSection: false,
  error: null,
});

const updateFailure = (state, { error }) => ({
  ...state,
  error,
  isUpdating: false,
  isUpdatingSection: false,
});

const activateRequest = state => ({
  ...state,
  isActivating: true,
  error: null,
});

const activateSuccess = (state, { company }) => ({
  ...state,
  company,
  isActivating: false,
  error: null,
});

const activateFailure = (state, { error }) => ({
  ...state,
  error,
  isActivating: false,
});

const progressRequest = state => ({
  ...state,
  isLoading: true,
  error: null,
});

const progressSuccess = (state, { progress }) => ({
  ...state,
  progress,
  isLoading: initialState.isLoading,
  error: null,
});

const ProgressFailure = (state, { error }) => ({
  ...state,
  error,
  isLoading: initialState.isLoading,
  company: null,
});

const fetchUsersByCompanyRequest = state => ({
  ...state,
  isLoadingUsers: true,
  error: null,
});

const fetchUsersByCompanySuccess = (state, { users }) => ({
  ...state,
  isLoadingUsers: false,
  error: null,
  users,
});

const fetchUsersByCompanyFailure = (state, { error }) => ({
  ...state,
  isLoadingUsers: false,
  error,
});

const updateProfileComplete = (state, { value }) => ({
  ...state,
  profileComplete: value,
});

const reducer = createReducer(initialState, {
  [companyTypes.COMPANY_FETCH_REQUEST]: fetchRequest,
  [companyTypes.COMPANY_FETCH_SUCCESS]: fetchSuccess,
  [companyTypes.COMPANY_FETCH_ERROR]: fetchFailure,

  [companyTypes.COMPANY_UPDATE_REQUEST]: updateRequest,
  [companyTypes.COMPANY_UPDATE_SUCCESS]: updateSuccess,
  [companyTypes.COMPANY_UPDATE_ERROR]: updateFailure,

  [companyTypes.COMPANY_TOGGLE_ACTIVATE_REQUEST]: activateRequest,
  [companyTypes.COMPANY_TOGGLE_ACTIVATE_SUCCESS]: activateSuccess,
  [companyTypes.COMPANY_TOGGLE_ACTIVATE_ERROR]: activateFailure,

  [companyTypes.COMPANY_FETCH_PROGRESS_REQUEST]: progressRequest,
  [companyTypes.COMPANY_FETCH_PROGRESS_SUCCESS]: progressSuccess,
  [companyTypes.COMPANY_FETCH_PROGRESS_ERROR]: ProgressFailure,

  [companyTypes.COMPANY_FETCH_USERS_REQUEST]: fetchUsersByCompanyRequest,
  [companyTypes.COMPANY_FETCH_USERS_SUCCESS]: fetchUsersByCompanySuccess,
  [companyTypes.COMPANY_FETCH_USERS_ERROR]: fetchUsersByCompanyFailure,

  [companyTypes.UPDATE_PROFILE_COMPLETE]: updateProfileComplete,
});

export default reducer;
