import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setFilters: ['section', 'params'],
  cleanFilters: [],
});

export const dataInsightTypes = Types;

export default Creators;
