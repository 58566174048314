import styled from 'styled-components';

export const Triangle = styled.span`
  width: 0;
  display: inline-block;
  height: 0;
  border-style: solid;
  border-width: 4.97px 0 4.97px 5px;
  border-color: transparent transparent transparent #ffbe33;
  right: 15px;
  top: 65%;
  margin-top: -10px;
  transform: rotate(90deg);
  position: absolute;
`;
