import { size } from 'lodash';

const getContainerHeight = state => {
  if (state.hasValue && state.isMulti) {
    return 'auto';
  }
  return '40px';
};

const customStyles = (centered, dropdownStyles) => ({
  option: (provided, state) => {
    return {
      ...provided,
      color: state.isSelected ? 'white' : 'grey',
      overflow: 'hidden',
      whiteSpace: 'wrap',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      fontSize: '14px',
    };
  },
  indicatorSeparator: () => ({}),
  clearIndicator: provider => ({ ...provider, padding: '3px', color: '#ffbe33' }),
  container: (provided, state) => ({
    border: '1px solid #fff',
    alignItems: 'flex-end',
    display: 'flex',
    borderRadius: '2px',
    position: 'relative',
    height: getContainerHeight(state),
    minHeight: '40px',
    '&:hover': {
      border: `1px solid ${state.isDisabled ? '#fff' : '#fdc93b'}`,
      color: 'grey',
      opacity: state.isDisabled ? 0.5 : 1,
    },
    opacity: state.isDisabled ? 0.5 : 1,
    ...(dropdownStyles?.container && {
      ...dropdownStyles.container,
    }),
    flexDirection: 'column',
  }),
  control: () => ({
    width: '100%',
    display: 'flex',
    whiteSpace: 'nowrap',
    height: '100%',
    alignItems: 'center',
  }),
  menuList: provider => ({ ...provider, minHeight: 'auto' }),
  input: () => ({
    color: 'white',
    textAlign: 'left',
    display: 'inline-block',
    alignItems: 'center',
    padding: '0 6px',
  }),
  placeholder: provider => ({
    ...provider,
    color: 'rgba(255,255,255,0.55)',
    fontSize: '15px',
    ...(dropdownStyles?.placeholder && {
      ...dropdownStyles.placeholder,
    }),
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 6px',
    justifyContent: 'left',
    textAlign: 'left',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const textSize = state && state.data && state.data.name && size(state.data.name);
    let styleText = '14px';
    if (textSize && textSize > 35) {
      styleText = '12px';
    }
    if (textSize && textSize > 35 && window.screen.width < 800) {
      styleText = '12px';
    }
    if (textSize && textSize > 35 && window.screen.width < 400) {
      styleText = '9px';
    }

    return {
      ...provided,
      opacity,
      transition,
      color: 'white',
      justifyContent: centered ? 'center' : 'left',
      fontSize: styleText,
      width: '100%',
      display: 'inline-block',
      padding: '0 6px',
    };
  },
});

export default customStyles;
