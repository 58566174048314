import * as Yup from 'yup';

import {
  PASSWORD_NUMBER,
  PASSWORD_LOWERCASE_LETTER,
  PASSWORD_UPPERCASE_LETTER,
  PASSWORD_SPECIAL_CHARACTER,
} from 'constant/regex';

export const passwordValidaiton = Yup.string()
  .required('Password is required')
  .min(8, 'Password length too short, password must be between 8 and 30 characters')
  .max(30, 'Password length too long, password must be between 8 and 30 characters')
  .matches(PASSWORD_NUMBER, {
    message: 'Missing 1 number',
    excludeEmptyString: true,
  })
  .matches(PASSWORD_LOWERCASE_LETTER, {
    message: 'Missing 1 lowercase letter',
    excludeEmptyString: true,
  })
  .matches(PASSWORD_UPPERCASE_LETTER, {
    message: 'Missing 1 uppercase letter',
    excludeEmptyString: true,
  })
  .matches(PASSWORD_SPECIAL_CHARACTER, {
    message: 'Missing 1 special character',
    excludeEmptyString: true,
  });

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current password is required'),
  newPassword: passwordValidaiton,
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export default validationSchema;
