import { Hr } from '@hivediversity/common-lib/base';
import { CustomButton } from '@hivediversity/common-lib/buttons';
import { MessageError } from '@hivediversity/common-lib/components';
import { colors } from '@hivediversity/common-lib/constant';
import { useQuery } from '@hivediversity/common-lib/hooks';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Switch, Tab, Tabs } from 'components';
import { routes } from 'constant';
import { getIndustries } from 'services/company';
import companyCreator from 'store/company/actions';
import { getCompany, getError } from 'store/company/selectors';
import { getEmployer } from 'store/employer/selectors';
import { Container, HeaderContainer, Title } from 'styled';
import { parseNullToEmptyString } from 'utils';

import Editor from './editor';
import Preview from './preview';
import { TabsContainer, WrapperEditor } from './styled';

const Company = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const company = useSelector(getCompany);
  const error = useSelector(getError);
  const employer = useSelector(getEmployer);
  const queryTab = useQuery('tab', location);
  const [selectedTab, setSelectedTab] = useState(queryTab);
  const goToOpportunities = () => dispatch(push(routes.DASHBOARD));

  useEffect(() => {
    if (employer?.editCompanyProfile) {
      setSelectedTab(queryTab);
    }
  }, [queryTab]);

  const handleToggleActiveStatus = () => dispatch(companyCreator.companyToggleActivateRequest(!company.active));

  const backgroundColor = () =>
    selectedTab === 'edit' && employer?.editCompanyProfile
      ? 'linear-gradient(to bottom right, #FD4CF4, #000)'
      : 'linear-gradient(145deg, rgba(67,175,187,1) 0%, rgba(1,4,4,1) 100%)';

  return (
    <Container background={backgroundColor()}>
      {!isEmpty(company) && (
        <>
          <HeaderContainer>
            <Title>{company.name}</Title>
            <CustomButton
              color={colors.VIOLET}
              onClick={goToOpportunities}
              $bsStyle={{ maxWidth: '170px', lineHeight: 'initial', fontSize: '12px' }}
            >
              Create/Edit Positions
            </CustomButton>
            <Hr margin="20px 0 10px 0" />
          </HeaderContainer>
          {employer?.editCompanyProfile && (
            <Switch
              name="activeStatus"
              label={company.active ? 'ACTIVE' : 'INACTIVE'}
              value={company.active}
              onChange={handleToggleActiveStatus}
              labelStyle={{ fontSize: '20px' }}
            />
          )}
          <TabsContainer>
            {employer?.editCompanyProfile ? (
              <Tabs id="company" active={selectedTab} onClick={setSelectedTab}>
                <Tab id="edit" title="EDIT">
                  <WrapperEditor>
                    <Editor
                      company={parseNullToEmptyString({
                        ...company,
                        industries: getIndustries(company?.industries),
                      })}
                    />
                  </WrapperEditor>
                </Tab>
                <Tab id="preview" title="PREVIEW">
                  <Preview company={company} />
                </Tab>
              </Tabs>
            ) : (
              <Preview company={company} />
            )}
          </TabsContainer>
        </>
      )}
      <MessageError messageError={error} />
    </Container>
  );
};

export default Company;
