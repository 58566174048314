import PropTypes from 'prop-types';

export default PropTypes.shape({
  aboutUs: PropTypes.bool,
  administration: PropTypes.bool,
  culture: PropTypes.bool,
  people: PropTypes.bool,
  whatsTheBuzz: PropTypes.bool,
  flightGuide: PropTypes.bool,
  opportunities: PropTypes.bool,
});
