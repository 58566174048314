import { createReducer } from 'reduxsauce';

import { recoverTypes } from 'store/recover-pass/actions';
import { initialState } from 'store/recover-pass/initial-state';

const mailValidRequest = state => ({
  ...state,
  isLoading: true,
  error: null,
});

const mailValidSuccess = state => ({
  ...state,

  isLoading: false,
  error: null,
});

const mailValidError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

const sendMailRequest = state => ({
  ...state,
  isLoading: true,
  isSuccess: false,
  sendError: null,
});

const sendMailSuccess = state => ({
  ...state,
  isLoading: false,
  isSuccess: true,
  sendError: null,
});

const sendMailError = (state, { sendError }) => ({
  ...state,
  sendError,
  isSuccess: false,
  isLoading: false,
});

const resendMailRequest = state => ({
  ...state,
  isSuccess: true,
  sendError: null,
});

const resendMailSuccess = state => ({
  ...state,
  isSuccess: true,
  sendError: null,
});

const resetPasswordRequest = state => ({
  ...state,
  isUpdating: true,
  resetError: null,
});

const resetPasswordSuccess = state => ({
  ...state,
  isUpdating: false,
  resetError: null,
});

const resetPasswordError = (state, { resetError }) => ({
  ...state,
  resetError,
  isUpdating: false,
});

const reducer = createReducer(initialState, {
  [recoverTypes.MAIL_VALID_REQUEST]: mailValidRequest,
  [recoverTypes.MAIL_VALID_SUCCESS]: mailValidSuccess,
  [recoverTypes.MAIL_VALID_ERROR]: mailValidError,

  [recoverTypes.SEND_MAIL_REQUEST]: sendMailRequest,
  [recoverTypes.SEND_MAIL_SUCCESS]: sendMailSuccess,
  [recoverTypes.SEND_MAIL_ERROR]: sendMailError,

  [recoverTypes.RESEND_MAIL_REQUEST]: resendMailRequest,
  [recoverTypes.RESEND_MAIL_SUCCESS]: resendMailSuccess,

  [recoverTypes.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [recoverTypes.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [recoverTypes.RESET_PASSWORD_ERROR]: resetPasswordError,
});

export default reducer;
