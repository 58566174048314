import { Row } from '@hivediversity/common-lib/base';
import { CustomButton as Button } from '@hivediversity/common-lib/buttons';
import { Collapsible, FilePreview } from '@hivediversity/common-lib/components';
import { colors, companySection } from '@hivediversity/common-lib/constant';
import { companyProps } from '@hivediversity/common-lib/utils';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { UploadFile, MultiSelect, Tooltip, WrapTextBox } from 'components';
import { placeholders, tooltipsTexts } from 'constant';
import { FILE_MAX_SIZE } from 'constant/messages';
import { getStaticData } from 'store/static-data/selectors';
import { InputLabel, MaxFileSize } from 'styled';

import { WrapperInput, SubtitleLabel } from '../styled';

const Culture = ({ onCollapse, isValid, open, setFieldValue, setValues, values, initialValues, showValidateIcon }) => {
  const [culturePreviewImage, setCulturePreviewImage] = useState();
  const { careerKeywords } = useSelector(getStaticData);
  const inputFileCulture = useRef(null);

  const handleChangeCulture = file => {
    setValues({
      ...values,
      hasCultureImage: true,
      cultureImage: file,
      cultureImageName: file.name,
      deleteCultureImage: false,
    });
    setCulturePreviewImage(URL.createObjectURL(file));
  };

  const handleRemoveCultureImage = () => {
    setValues({
      ...values,
      hasCultureImage: false,
      cultureImage: null,
      cultureImageName: null,
      cultureImageUrl: null,
      deleteCultureImage: !!initialValues.cultureImageUrl,
    });
    setCulturePreviewImage(undefined);
  };

  return (
    <Collapsible
      id={companySection.CULTURE}
      onCollapse={onCollapse}
      title="Culture"
      isValid={isValid}
      open={open}
      showValidateIcon={showValidateIcon}
    >
      <WrapperInput>
        <Row>
          <InputLabel>Our Culture</InputLabel>
          <Tooltip text={tooltipsTexts.OUR_CULTURE} />
        </Row>
        <WrapperInput $bsStyle={{ marginBottom: 0 }}>
          <Row $bsStyle={{ padding: '0  0 10px 0' }}>
            <Button
              color={colors.VIOLET}
              onClick={() => inputFileCulture.current.click()}
              $bsStyle={{ maxWidth: '184px', lineHeight: 'initial' }}
            >
              UPLOAD picture/video
            </Button>
            <MaxFileSize>{values.cultureImageName ? `${values.cultureImageName}` : FILE_MAX_SIZE}</MaxFileSize>
          </Row>
          <UploadFile
            id="cultureImageUrl"
            types={['image', 'video']}
            accept="image/png, image/jpeg, video/mp4, video/mov, video/mpeg-4, video/quicktime"
            fileRef={inputFileCulture}
            onChange={handleChangeCulture}
            onRemove={() => {
              // eslint-disable-line @typescript-eslint/no-empty-function
            }}
          />
          {(culturePreviewImage || values.cultureImageUrl) && (
            <FilePreview
              fileName={values.cultureImageName}
              url={culturePreviewImage || values.cultureImageUrl}
              imageHeight={200}
              imageWidth={200}
              onRemove={handleRemoveCultureImage}
            />
          )}
        </WrapperInput>

        <SubtitleLabel $bsStyle={{ fontStyle: 'italic' }}>Caption</SubtitleLabel>
        <WrapTextBox
          name="cultureCaption"
          placeholder="Example: Our Human Capital Team at our annual company retreat in April 2020."
          wrapperContainerStyle={{ maxWidth: '600px', width: '100%' }}
          maxLength={255}
        />

        <SubtitleLabel>Description</SubtitleLabel>
        <WrapTextBox
          name="culture"
          placeholder="Example: Every year, a different department is responsible for planning
          the social event at our annual conference. In 2020, the Human Capital team recreated
          the set of The Price is Right and we all had such a fun time! Come on down!!"
          rows="10"
          component="textarea"
          maxLength={5000}
          $bsStyle={{ overflowY: 'scroll', height: '100%' }}
        />
      </WrapperInput>

      <WrapperInput>
        <Row>
          <InputLabel>Mission Statement</InputLabel>
          <Tooltip text={tooltipsTexts.MISSION_STATEMENT} />
        </Row>
        <WrapTextBox
          name="mission"
          placeholder="To equitably provide a careers platform where: students and recent
          graduates learn about and understand careers and DE&I, employers are able to
          meaningfully engage with prepared students, and ONE COMMUNITY of all come
          together to build the next generation of workforce talent."
          rows="10"
          component="textarea"
          $bsStyle={{ marginBottom: '16px', overflowY: 'scroll', height: '100%' }}
          maxLength={5000}
        />
        <Row $bsStyle={{ alignItems: 'center', flexWrap: 'wrap' }}>
          <SubtitleLabel $bsStyle={{ margin: '0 96px 0 0' }}>More info link</SubtitleLabel>
          <WrapTextBox
            name="missionLink"
            placeholder={placeholders.URL_START_WITH_HTTP}
            wrapperContainerStyle={{ maxWidth: '289px', width: '100%' }}
            maxLength={255}
          />
        </Row>
      </WrapperInput>

      <WrapperInput>
        <InputLabel>What does diversity mean to us? *</InputLabel>
        <WrapTextBox
          name="diversityMean"
          placeholder="Diversity. Inclusion. They’re more than just words for us.
          They’re the hard-and-fast principles guiding how we build our teams,
          cultivate leaders and create a company that’s the right fit for every
          person inside of it. We have a global, multicultural following—we want
          to reflect that inside our walls."
          rows="6"
          component="textarea"
          $bsStyle={{ overflowY: 'scroll', height: '100%' }}
          maxLength={5000}
        />
        <Row $bsStyle={{ alignItems: 'center', marginTop: '16px', flexWrap: 'wrap' }}>
          <SubtitleLabel $bsStyle={{ margin: '0 96px 0 0' }}>More info link</SubtitleLabel>
          <WrapTextBox
            name="diversityMeanLink"
            placeholder={placeholders.URL_START_WITH_HTTP}
            wrapperContainerStyle={{ maxWidth: '289px', width: '100%' }}
            maxLength={255}
          />
        </Row>
      </WrapperInput>

      <WrapperInput marginBottom="35px">
        <Row>
          <InputLabel>Cultural / Career Keywords (Select 5-15) *</InputLabel>
          <Tooltip text={tooltipsTexts.CULTURAL_KEYWORDS} />
        </Row>
        <MultiSelect
          name="hiveCulturalKeywords"
          placeholder="Select Keyword"
          values={values.hiveCulturalKeywords}
          color={colors.VIOLET}
          options={careerKeywords}
          setFieldValue={setFieldValue}
          showIndicatorSeparator
        />
      </WrapperInput>

      <WrapperInput>
        <Row>
          <InputLabel>Social Impact Areas of Focus | Corporate Responsibility</InputLabel>
          <Tooltip text={tooltipsTexts.SOCIAL_IMPACT_AREAS_OF_FOCUS} />
        </Row>
        <WrapTextBox
          name="socialImpact"
          placeholder="One of our many focal points of social impact and corporate
          citizenship is the environment and sustainability. Over the last decade,
          we’ve continually set bigger and bolder environment goals for ourselves—always
          innovating our approach to sustainability. We’re proud to announce that we’re
          moving to the next level with three additional, industry-leading goals.
          See more about this and several other priorities via the included URL."
          rows="10"
          component="textarea"
          $bsStyle={{ marginBottom: '16px', overflowY: 'scroll', height: '100%' }}
          maxLength={5000}
        />
        <Row $bsStyle={{ alignItems: 'center', flexWrap: 'wrap' }}>
          <SubtitleLabel $bsStyle={{ margin: '0 96px 0 0' }}>More info link</SubtitleLabel>
          <WrapTextBox
            name="socialImpactLink"
            placeholder={placeholders.URL_START_WITH_HTTP}
            maxLength={255}
            wrapperContainerStyle={{ maxWidth: '289px', width: '100%' }}
          />
        </Row>
      </WrapperInput>
    </Collapsible>
  );
};

Culture.propTypes = {
  onCollapse: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  showValidateIcon: PropTypes.bool,
  values: companyProps.isRequired,
  initialValues: companyProps.isRequired,
};

Culture.defaultProps = {
  showValidateIcon: false,
};

export default Culture;
