import styled from 'styled-components';

import next from 'assets/png/next.png';
import prev from 'assets/png/prev.png';

export const WrapperBlock = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.label`
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-size: 35px;
  margin: 20px 0 10px 0;
  color: white;
  font-weight: 400;
  text-transform: capitalize;
  @media screen and (max-width: 767px) {
    font-size: 25px;
    padding-right: 30px;
  }
  @media screen and (max-width: 420px) {
    font-size: 21px;
  }
  & span {
    margin-left: 8px;
    color: #ffc033;
    font-size: 30px;
    text-transform: lowercase;
  }
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const Created = styled.label`
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: white;
  margin-bottom: 20px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 50px;
  padding-bottom: 8px;
  @media screen and (max-width: 767px) {
    margin: 0 -20px 30px -20px;
  }
  .slick-slider {
    margin: 0 -20px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      max-width: calc(100% - 32px);
      margin: 0 auto;
    }
    .slick-track {
      .slick-slide {
        @media screen and (max-width: 767px) {
          padding: 0;
        }
      }
    }
    &[dir='ltr'] {
      margin: 0 auto;
      max-width: calc(100% - 72px);
      .slick-track {
        .slick-slide {
          @media screen and (max-width: 767px) {
            padding: 0 20px;
          }
        }
      }
    }
  }
  .slick-prev {
    left: -54px;
    width: 72px;
    height: 72px;
    @media screen and (max-width: 767px) {
      width: 32px;
      height: 32px;
      left: -25px;
    }
  }
  .slick-prev:before {
    display: block;
    background: url(${prev}) no-repeat center;
    background-size: 100%;
    content: '';
    height: 100%;
    width: 100%;
  }
  .slick-next {
    right: -54px;
    width: 72px;
    height: 72px;
    @media screen and (max-width: 767px) {
      width: 32px;
      height: 32px;
      right: -25px;
    }
  }
  .slick-next:before {
    display: block;
    background: url(${next}) no-repeat center;
    background-size: 100%;
    content: '';
    height: 100%;
    width: 100%;
  }
  .slick-slider .slick-slide {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .slick-container {
    width: 100%;
  }
  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    > div {
      > div {
        margin: 0 auto;
      }
    }
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-slider {
    padding-bottom: 30px;
  }
`;

export const EmptyApplicants = styled.p`
  font-size: 17px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
`;

export const ButtonMobile = styled.div`
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
  }
  button {
    @media screen and (max-width: 767px) {
      margin: 10px auto;
    }
  }
`;

export const ButtonDesktop = styled.div`
  width: 155px;
  margin-left: auto;
  padding-top: 30px;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
