const getTitleWithNumber = (title, total, leftExpand, rightExpanded) => {
  if (total > 99) {
    return `${title ? `${title} ` : ''}${leftExpand ? `${leftExpand}` : ''}99${
      rightExpanded ? `${rightExpanded}` : ''
    }`;
  }
  if (total > 0) {
    return `${title ? `${title} ` : ''}${leftExpand ? `${leftExpand}` : ''}${total}${
      rightExpanded ? `${rightExpanded}` : ''
    }`;
  }
  return `${title ? `${title} ` : ''}`;
};

export default getTitleWithNumber;
