import styled from 'styled-components';

export const SwitchContainer = styled.div`
  transition: 0.4s;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  width: 55px;
  height: 27px;
  border-radius: 40px;
  border: 6px solid #ffc033;
  align-items: center;
  flex-direction: row;
  display: flex;
  padding: 3px;
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};
  @media screen and (max-width: 767px) {
    width: 50px;
    border: 4px solid #ffc033;
  }
`;

export const SwitchBullet = styled.div`
  transition: 0.4s;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  width: 10px;
  height: 10px;
  background-color: #ffc033;
  border-radius: 9px;
  margin-left: ${({ checked }) => (checked ? 'calc(100% - 16px)' : '0px')};
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};
  @media screen and (max-width: 767px) {
    margin-left: ${({ checked }) => (checked ? 'calc(100% - 12px)' : '0px')};
  }
`;

export const Label = styled.label`
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-size: 30px;
  font-weight: 400;
  margin: 0 0 0 15px;
  ${({ labelStyle }) => labelStyle || ''}
  @media screen and (max-width: 767px) {
    font-size: 25px;
  }
  @media screen and (max-width: 420px) {
    font-size: 20px;
  }
`;

export const LeftLabel = styled.label`
  margin: 0 15px 0 0;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-size: 30px;
  font-weight: 400;
  ${({ leftLabelStyle }) => leftLabelStyle || ''}
  @media screen and (max-width: 767px) {
    font-size: 25px;
  }
  @media screen and (max-width: 420px) {
    font-size: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  ${({ centered }) => (centered ? `display: flex; flex: 1; justify-content: center;` : '')};
  text-align: left;
  align-items: center;
  ${({ style }) => style || ''}
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
`;
