export default {
  REQUIRED: 'Required.',
  ENTER_VALID_NUMBER: 'Enter valid number.',
  FIELD_MUST_BE_CHECKED: 'Field must be checked.',
  INVALID_DATE: 'The date cannot be before today.',
  VALID_URL: 'Start with http or https',
  VALID_EMAIL: 'Enter a valid email',
  POSITIVE_NUMBER: 'Enter a positive number',
  REMOTE_LOCATION: 'You already selected Remote as location',
  UNIQUE_SUB_INDUSTRIES: 'There is the same industry more than once.',
  INDUSTRY_REPEATED: 'This industry vertical is repeated',
  USER_REPEATED: 'You have selected the same user more than once',
};
