export const getCompany = state => state.company.company;

export const getIsLoading = state => state.company.isLoading;

export const getProfileComplete = state => state.company.profileComplete;

export const getError = state => state.company.error;

export const getIsUpdating = state => state.company.isUpdating;

export const getIsUploading = state => state.company.isUploading;

export const getProgress = state => state.company.progress;

export const getUsers = state => state.company.users;
