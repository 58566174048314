import PropTypes from 'prop-types';
import React from 'react';

import {
  NoteConteiner,
  NoteConteinerText,
  NoteConteinerTitle,
  NoteConteinerDeleteButton,
  NoteConteinerDate,
} from './styled';

const Note = ({ aplicantNote, removeNote }) => {
  const deleteNote = () => {
    removeNote(aplicantNote.id);
  };

  return (
    <NoteConteiner>
      <NoteConteinerTitle>{aplicantNote.userCompany.name || ''}:</NoteConteinerTitle>
      <NoteConteinerDate>{aplicantNote.createdAt || ''}</NoteConteinerDate>
      <NoteConteinerDeleteButton onClick={() => deleteNote()} title="remove note">
        x
      </NoteConteinerDeleteButton>
      <NoteConteinerText>{aplicantNote.note || ''}</NoteConteinerText>
    </NoteConteiner>
  );
};

Note.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  aplicantNote: PropTypes.objectOf(PropTypes.object).isRequired,
  removeNote: PropTypes.func.isRequired,
};

export default Note;
