import { push } from 'connected-react-router';
import { takeLatest, call, put } from 'redux-saga/effects';

import { routes } from 'constant';
import { requestMailValid, requestSendMail, requestResetPassword } from 'services/recover-pass';
import recoverPassCreator, { recoverTypes } from 'store/recover-pass/actions';

export function* mailValid({ email }) {
  try {
    const { success, message } = yield call(requestMailValid, email);
    if (success) {
      yield put(recoverPassCreator.mailValidSuccess(email));
    } else {
      yield put(recoverPassCreator.mailValidError(message));
    }
  } catch (error) {
    yield put(recoverPassCreator.mailValidError(email));
  }
}

export function* sendMail({ email }) {
  try {
    const { success } = yield call(requestSendMail, email);
    if (success) {
      yield put(recoverPassCreator.sendMailSuccess());
    }
  } catch (error) {
    yield put(recoverPassCreator.sendMailError('error'));
  }
}

export function* resendMail({ email }) {
  try {
    const { success } = yield call(requestSendMail, email);
    if (success) {
      yield put(recoverPassCreator.resendMailSuccess());
    }
  } catch (error) {
    yield put(recoverPassCreator.sendMailError('error'));
  }
}

export function* resetPassword({ email, token, password }) {
  try {
    const { success } = yield call(requestResetPassword, email, token, password);
    if (success) {
      yield put(recoverPassCreator.resetPasswordSuccess());
      yield put(push(routes.LOGIN));
    }
  } catch (error) {
    yield put(recoverPassCreator.resetPasswordError('Reset password error'));
  }
}

export function* recoverPassSaga() {
  yield takeLatest(recoverTypes.MAIL_VALID_REQUEST, mailValid);
  yield takeLatest(recoverTypes.SEND_MAIL_REQUEST, sendMail);
  yield takeLatest(recoverTypes.RESEND_MAIL_REQUEST, resendMail);
  yield takeLatest(recoverTypes.RESET_PASSWORD_REQUEST, resetPassword);
}
