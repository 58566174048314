const opportunity = '/opportunity/';
const editOpportunity = `${opportunity}:opportunityId`;

const company = '/company';

const routes = {
  ROOT: '/',
  WELCOME: '/welcome',
  NEW_OPPORTUNITY: `${opportunity}new`,
  EDIT_OPPORTUNITY: editOpportunity,
  REVIEW_OPPORTUNITY_TAB: `${editOpportunity}?tab=Review`,
  EDIT_OPPORTUNITY_TAB: `${editOpportunity}?tab=Edit`,
  OPPORTUNITY_APPLICANTS_TAB: `${editOpportunity}?tab=applicants`,
  COMPANY: company,
  NEW_COMPANY: `${company}/new`,
  COMPANY_PREVIEW: `${company}?tab=preview`,
  SUPPORT: '/support',
  DASHBOARD: '/dashboard',
  CHANGE_PASSWORD: '/change-password',
  LOGIN: '/login',
  RECOVERY_PASSWORD: '/recovery',
  PROFILE: '/profile/:opportunityId/:applicantId',
  DATA_INSIGHTS: '/data-insights',
  RESUME: '/resume/:opportunityId/:applicantId',
  ERROR_404: '/404',
};

export default routes;
