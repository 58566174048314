import PropTypes from 'prop-types';
import React from 'react';

import { ClickHere, Label } from './styled';

const ClickHereLabel = ({ onClick, label }) => (
  <p>
    <ClickHere onClick={onClick}>Click here</ClickHere>
    <Label>{label}</Label>
  </p>
);

ClickHereLabel.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default ClickHereLabel;
