import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 16px;
  width: 100%;
`;

export const Logo = styled.img`
  margin-top: 50px;
  width: 150px;
  height: 50px;
`;

export const WelcomeTitle = styled.h2`
  text-transform: uppercase;
  font-size: 50px;
  text-align: center;
  color: white;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-weight: 700;
  @media screen and (max-width: 1024px) {
    font-size: 36px;
  }
  @media screen and (max-width: 767px) {
    font-size: 28px;
  }
  @media screen and (max-width: 420px) {
    font-size: 24px;
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  & button {
    @media screen and (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
