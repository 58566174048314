const getPercentage = percentage => {
  if (percentage === 0) {
    return percentage;
  }
  const percentageFloat = parseFloat(String(percentage)).toFixed(2);
  if (!Number.isNaN(Number(percentageFloat))) {
    const percentageSplitted = percentageFloat.split('.')[1];
    if (['00', '01'].includes(percentageSplitted)) {
      return parseFloat(String(percentage)).toFixed(0);
    }
    return percentageFloat;
  }
  return percentage;
};

export default getPercentage;
