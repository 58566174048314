import styled from 'styled-components';

import hive from 'assets/png/hive.png';
import UsersImage from 'assets/png/welcome-users.png';
import HexagonsImage from 'assets/svg/hexagons-welcome.svg';

export const Wrapper = styled.div`
  align-items: center;
  z-index: 2;
  width: 100%;
  margin: 0 auto;
  padding: 150px 25px;
  position: relative;
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    padding: 120px 25px 32px 25px;
  }
  @media screen and (min-width: 780px) {
    padding: 220px 25px 150px 25px;
  }
  @media screen and (min-width: 1024px) {
    display: flex;
    width: 100%;
    
  @media screen and (min-width: 1024px) {
    display: flex;
    width: 100%;
  }
  @media screen and (min-width: 1240px) {
    display: flex;
    width: 1240px;
    padding: 0 25px;
  }
`;

export const WrapperWithoutEdit = styled.div`
  align-items: center;
  z-index: 3;
  width: 100%;
  margin: 0 auto;
  padding: 150px 25px;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: 480px) {
    padding: 120px 25px 60px 25px;
  }
  @media screen and (min-width: 780px) {
    padding: 220px 25px 150px 25px;
  }
  @media screen and (min-width: 1024px) {
    display: flex;
    width: 100%;
  }
  @media screen and (min-width: 1240px) {
    display: flex;
    width: 1240px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  background-color: #000; /* For browsers that do not support gradients */
  background: ${({ background, noBackground }) => {
    if (noBackground) {
      return 'none';
    }
    return background || '#000';
  }};
  padding-left: ${({ noPadding }) => (noPadding ? 0 : '30px')};
  @media screen and (max-width: 480px) {
    overflow: visible;
  }
`;

export const WrapperHexagons = styled.div`
  height: 100%;
  background-repeat: no-repeat;
  background-size: 60% auto;
  background-position: bottom right;
  @media screen and (min-width: 1024px) {
    height: 100vh;
    margin-top: -65px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 60% 100%;
    background-image: url(${HexagonsImage});
    background-position: center right;
  }
`;

export const WrapperContent = styled.div`
  position: relative;
  z-index: 50;
  @media screen and (min-width: 780px) {
    width: 500px;
  }
  @media screen and (min-width: 1024px) {
    padding: 0;
    height: auto;
  }
`;

export const Text = styled.p`
  color: white;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.01em;
  margin: 0 0 40px 0;
  font-weight: 300;
`;

export const Title = styled.h2`
  color: white;
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 29px;
  margin-top: 0;
  text-transform: uppercase;
  font-family: 'Gentleman', Arial, sans-serif;
`;

export const UsersImagesContainer = styled.div`
  text-align: center;
  margin-top: 56px;
`;

export const HiveImage = styled.img.attrs({ src: hive })`
  max-width: 320px;
  width: 140px;
  margin-top: 20px;
  margin-left: 90px;
`;

export const UsersImg = styled.img.attrs({ src: UsersImage })`
  max-width: 320px;

  @media screen and (min-width: 480px) {
    max-width: 430px;

  @media screen and (min-width: 850px) {
    bottom: 95px;
    right: -80px;
    position: absolute;
    max-width: 450px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 650px;
    position: static;
  }
`;
