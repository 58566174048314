import { opportunityProps } from '@hivediversity/common-lib/utils';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { routes } from 'constant';
import { getCompany } from 'store/company/selectors';
import { transformRoute } from 'utils';

import {
  Card,
  WrapperImage,
  Image,
  CompanyName,
  Description,
  EmptyOpportunities,
  OpportunityTitle,
  Title,
  Wrapper,
} from './styled';

const ArchiveOpportunities = ({ opportunities, title }) => {
  const dispatch = useDispatch();
  const company = useSelector(getCompany);
  return (
    <>
      <Title>{title}</Title>
      {isEmpty(opportunities) ? (
        <EmptyOpportunities>There are no opportunities.</EmptyOpportunities>
      ) : (
        <Wrapper>
          {opportunities.map(opportunity => (
            <Card
              key={opportunity.id}
              onClick={() => dispatch(push(transformRoute(routes.EDIT_OPPORTUNITY, { opportunityId: opportunity.id })))}
            >
              {!isEmpty(opportunity.logoUrl) ? (
                <WrapperImage>
                  <Image src={opportunity.logoUrl} alt={opportunity.id} />
                </WrapperImage>
              ) : (
                ''
              )}
              <OpportunityTitle>{opportunity.title}</OpportunityTitle>
              <CompanyName>{company.name}</CompanyName>
              <Description>{opportunity.description}</Description>
            </Card>
          ))}
        </Wrapper>
      )}
    </>
  );
};

ArchiveOpportunities.propTypes = {
  title: PropTypes.string.isRequired,
  opportunities: PropTypes.arrayOf(opportunityProps),
};

ArchiveOpportunities.defaultProps = {
  opportunities: [],
};

export default ArchiveOpportunities;
