import isBoolean from 'lodash/isBoolean';
import PropTypes from 'prop-types';
import React from 'react';

import { Container, SwitchContainer, SwitchBullet, Label, LeftLabel } from './styled';

const Switch = ({ name, value, onChange, style, centered, label, labelStyle, leftLabel, leftLabelStyle, disabled }) => {
  const checked = isBoolean(value) ? value : parseInt(value, 10) === 1;

  return (
    <Container key={name} centered={centered} style={style}>
      {leftLabel && (
        <LeftLabel leftLabelStyle={leftLabelStyle} disabled={disabled}>
          {leftLabel}
        </LeftLabel>
      )}
      <SwitchContainer
        role="button"
        disabled={disabled}
        onClick={
          disabled
            ? () => {
                // eslint-disable-line @typescript-eslint/no-empty-function
              }
            : onChange
        }
      >
        <SwitchBullet disabled={disabled} checked={checked} />
      </SwitchContainer>
      {label && (
        <Label labelStyle={labelStyle} disabled={disabled}>
          {label}
        </Label>
      )}
    </Container>
  );
};

Switch.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  style: PropTypes.shape({}),
  labelStyle: PropTypes.shape({}),
  leftLabelStyle: PropTypes.shape({}),
  centered: PropTypes.bool,
  label: PropTypes.string,
  leftLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

Switch.defaultProps = {
  onChange: () => {
    // eslint-disable-line @typescript-eslint/no-empty-function
  },
  style: null,
  labelStyle: null,
  leftLabelStyle: null,
  centered: false,
  label: '',
  leftLabel: '',
  disabled: false,
  value: false,
};

export default Switch;
