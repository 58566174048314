import { apiRoutes } from 'constant';
import { transformRoute } from 'utils';

import { getOrCached, post } from './http';

export const fetchRequest = params => post(apiRoutes.STATIC_DATA, params);

export const fetchStatesRequest = term => {
  const searchParams = new URLSearchParams();
  if (term) {
    searchParams.set('term', term);
  }
  return getOrCached(`${apiRoutes.STATES}?${searchParams.toString()}`);
};

export const fetchCitiesRequest = stateId => getOrCached(transformRoute(apiRoutes.CITIES, { stateId }));

export const fetchCountriesRequest = () => getOrCached(apiRoutes.COUNTRIES);

export const fetchIndustriesRequest = () => getOrCached(apiRoutes.INDUSTRIES);

export const fetchSubIndustriesRequest = industryId =>
  getOrCached(transformRoute(apiRoutes.SUB_INDUSTRIES, { industryId }));

export const fetchClassStandingRequest = () => getOrCached(apiRoutes.TYPES);

export const getZonesRequest = () => getOrCached(apiRoutes.ZONES);

export const fetchDiversitiesRequest = id => getOrCached(transformRoute(apiRoutes.DIVERSITIES, { id }));

export const fetchJobFunctionsRequest = () => getOrCached(apiRoutes.JOB_FUNCTIONS);

export const fetchMajorsMinorsRequest = () => getOrCached(apiRoutes.MAJORS_MINORS);

export const fetchMajorCategoriesRequest = () => getOrCached(apiRoutes.MAJORS_CATEGORIES);

export const fetchMajorsMinorsByCategoryRequest = id =>
  getOrCached(transformRoute(apiRoutes.MAJORS_MINORS_BY_CATEGORY, { id }));
