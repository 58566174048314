import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import applicant from 'store/applicant/reducers';
import authorization from 'store/authorization/reducers';
import company from 'store/company/reducers';
import dataInsight from 'store/data-insight/reducers';
import employer from 'store/employer/reducers';
import opportunity from 'store/opportunity/reducers';
import recoverPass from 'store/recover-pass/reducers';
import stat from 'store/stat/reducers';
import staticData from 'store/static-data/reducers';

export default history =>
  combineReducers({
    router: connectRouter(history),
    authorization,
    opportunity,
    company,
    employer,
    applicant,
    recoverPass,
    staticData,
    stat,
    dataInsight,
  });
