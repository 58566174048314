import { Row } from '@hivediversity/common-lib/base';
import { CustomButton } from '@hivediversity/common-lib/buttons';
import { Collapsible, FilePreview } from '@hivediversity/common-lib/components';
import { colors, gpa, opportunityTypes, yesNo, opportunitySection } from '@hivediversity/common-lib/constant';
import { opportunityProps } from '@hivediversity/common-lib/utils';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Checkboxes, Dropdown, MultiSelect, RadioButtons, Tooltip, UploadFile, WrapTextBox } from 'components';
import { tooltipsTexts } from 'constant';
import { FILE_MAX_SIZE } from 'constant/messages';
import { getStaticData } from 'store/static-data/selectors';
import { MaxFileSize } from 'styled';

import {
  Anchor,
  Title,
  QuestionContainer,
  SubtitleLabel,
  ClassStandingLabelWrapper,
  StyledTitle,
  StyledCheck,
} from './styled';

const ClassStandingTypes = {
  UNDERGRADUATE: 'Undergrad/Bachelors',
  GRADUATE: 'Graduate/Masters',
};

const transformClassStandingOptions = (arr, gradTypeFilter) => {
  let result = [];
  const classStanding = arr.find(entry => entry.name === gradTypeFilter);

  if (classStanding) {
    result = (classStanding?.types || []).map(entry => ({
      id: entry.id,
      name: entry.name,
      description: `${entry.name} ${entry.caption ?? ''}`,
      render: () => (
        <ClassStandingLabelWrapper>
          {entry.name} <p className="description">{entry.caption ?? ''}</p>
        </ClassStandingLabelWrapper>
      ),
    }));
  }

  return result;
};
const Qualifications = ({ setFieldValue, values, initialValues, open, onCollapse, isValid, showValidateIcon }) => {
  const { majorCategories, languages, isLoading, classStanding } = useSelector(getStaticData);
  const [coworkersPreviewImage, setCoworkersPreviewImage] = useState();
  const imageFile = useRef();

  const [undergradClassStanding, setUndergradClassStanding] = useState([]);
  const [mastersClassStanding, setMastersClassStanding] = useState([]);

  const onWorkEligibilityChange = useCallback(
    (...rest) => {
      setFieldValue('skipWorkEligibilityQuestions', false);

      setFieldValue(...rest);
    },
    [setFieldValue]
  );

  const handleSkipWorkEligibilityQuestionsChange = useCallback(() => {
    setFieldValue('requireUsWorkAuthorization', null);
    setFieldValue('sponsorWorkVisa', null);
    setFieldValue('hiringTemporarilyAuthorizedCandidates', null);

    setFieldValue('skipWorkEligibilityQuestions', !values.skipWorkEligibilityQuestions);
  }, [values.skipWorkEligibilityQuestions]);

  useEffect(() => {
    if (classStanding && classStanding.length > 0) {
      setUndergradClassStanding(transformClassStandingOptions(classStanding, ClassStandingTypes.UNDERGRADUATE));
      setMastersClassStanding(transformClassStandingOptions(classStanding, ClassStandingTypes.GRADUATE));
    }
  }, [classStanding]);

  const handleChangeImage = file => {
    setFieldValue('teamMedia', file);
    setFieldValue('hasTeamMediaImage', true);
    setFieldValue('teamMediaImageName', file.name);
    setFieldValue('deleteTeamMediaImage', false);
    setCoworkersPreviewImage(URL.createObjectURL(file));
  };

  const removeTeamMediaImage = () => {
    setFieldValue('teamMedia', null);
    setFieldValue('hasTeamMediaImage', false);
    setFieldValue('teamMediaImageName', null);
    setFieldValue('deleteTeamMediaImage', !!initialValues.teamMedia);
    setCoworkersPreviewImage(undefined);
  };

  return (
    <Collapsible
      id={opportunitySection.QUALIFICATIONS}
      title="Qualifications"
      onCollapse={onCollapse}
      isValid={isValid}
      open={open}
      showValidateIcon={showValidateIcon}
    >
      <QuestionContainer>
        <Title>GPA Minimum (Optional)</Title>
        <Dropdown
          name="minGpa"
          isSearchable={false}
          onChange={setFieldValue}
          value={values.minGpa}
          placeholder="Select GPA"
          options={gpa}
          $bsStyle={{
            minWidth: '100px',
            maxWidth: '400px',
          }}
          showIndicatorSeparator
        />
      </QuestionContainer>
      <QuestionContainer marginBottom="35px">
        <Title>Major Category (Optional)</Title>
        <MultiSelect
          name="majorCategories"
          placeholder="Select Major Category"
          options={majorCategories}
          values={values.majorCategories}
          setFieldValue={setFieldValue}
          isLoading={isLoading}
          showIndicatorSeparator
          isRequired={false}
        />
      </QuestionContainer>
      <QuestionContainer marginBottom="35px">
        <Title>Language Skills (Optional)</Title>
        <MultiSelect
          name="languages"
          placeholder="Select Language Skills"
          options={languages}
          values={values.languages}
          isLoading={isLoading}
          setFieldValue={setFieldValue}
          showIndicatorSeparator
          isRequired={false}
        />
      </QuestionContainer>
      <QuestionContainer>
        <Row $bsStyle={{ flexWrap: 'wrap' }}>
          <Title>
            Open To<span className="colon">:</span>
          </Title>
          <Tooltip text={tooltipsTexts.OPPORTUNITY_OPEN_TO} $bsStyle={{ zIndex: '10' }} position="top" />
        </Row>
        <Row $bsStyle={{ flexWrap: 'wrap' }}>
          <Title>Undergrad/Bachelors</Title>
          <Tooltip text={tooltipsTexts.OPPORTUNITY_CLASS_UNDERGRADUATE} />
        </Row>
        <Checkboxes
          $bsStyle={{ justifyContent: 'space-between', flex: 1, maxWidth: '900px' }}
          name="classStanding"
          options={undergradClassStanding}
          values={values}
          optionLabel="description"
        />
        {mastersClassStanding && mastersClassStanding.length > 0 && (
          <>
            <Row $bsStyle={{ flexWrap: 'wrap' }}>
              <Title>Graduate/Masters</Title>
              <Tooltip text={tooltipsTexts.OPPORTUNITY_CLASS_MASTER} />
            </Row>
            <Checkboxes
              $bsStyle={{ justifyContent: 'space-between', flex: 1, maxWidth: '900px' }}
              name="classStanding"
              options={mastersClassStanding}
              values={values}
              optionLabel="description"
            />
          </>
        )}
      </QuestionContainer>
      <QuestionContainer>
        <Title>Recommended Computer/Technical Skills</Title>
        <WrapTextBox
          name="computer"
          placeholder={`${
            values.type === opportunityTypes.EVENT ? 'Registrants should have' : 'This position requires'
          } advanced competencies in computer programming skills (C++, Java and/or HTML) as well as Microsoft Excel.`}
          component="textarea"
          rows={10}
          maxLength={5000}
          $bsStyle={{ overflowY: 'scroll', height: '100%' }}
        />
      </QuestionContainer>
      <QuestionContainer>
        <Row>
          <Title>Additional Preferred Qualifications</Title>
          <Tooltip text={tooltipsTexts.ADDITIONAL_PREFERRED_QUALIFICATIONS} />
        </Row>
        <WrapTextBox
          name="otherQualifications"
          placeholder="This should not include anything from the above
            inputs. Recommended/preferred qualifications include experience
          with financial modeling, demonstrated relationship management skills
          and high competencies in multitasking/organization."
          component="textarea"
          rows={10}
          maxLength={5000}
          $bsStyle={{ overflowY: 'scroll', height: '100%' }}
        />
      </QuestionContainer>
      <QuestionContainer>
        <StyledTitle>Work Eligibility</StyledTitle>
      </QuestionContainer>
      <QuestionContainer>
        <Title>
          Does {`${values.type === opportunityTypes.EVENT ? 'attendance at this event' : 'this position'}`} require{' '}
          <Anchor
            rel="noreferrer"
            target="_blank"
            href="https://www.justice.gov/crt/best-practices-recruiting-and-hiring-workers"
          >
            U.S. work authorization
          </Anchor>
          ? *
        </Title>
        <RadioButtons
          name="requireUsWorkAuthorization"
          value={values.requireUsWorkAuthorization}
          options={yesNo}
          setFieldValue={onWorkEligibilityChange}
        />
      </QuestionContainer>
      <QuestionContainer>
        <Title>
          Eligibility for international students (Candidates who are not US Citizens, US Nationals, Permanent Residents,
          Refugees, or Asylees)
        </Title>
        <Title>
          Would you sponsor a{' '}
          <Anchor
            rel="noreferrer"
            target="_blank"
            href="https://www.uscis.gov/working-in-the-united-states/temporary-nonimmigrant-workers"
          >
            work visa
          </Anchor>{' '}
          {`${values.type === opportunityTypes.EVENT ? 'for a future candidate' : 'for the right candidate'}`}? *
        </Title>
        <RadioButtons
          name="sponsorWorkVisa"
          value={values.sponsorWorkVisa}
          options={yesNo}
          setFieldValue={onWorkEligibilityChange}
        />
      </QuestionContainer>
      <QuestionContainer>
        <Title>
          Are you willing to hire candidates that are temporarily authorized to work for a defined period in their field
          of study? (e.g. for a job / internship under{' '}
          <Anchor rel="noreferrer" target="_blank" href="https://www.ice.gov/sevis/practical-training">
            OPT/CPT
          </Anchor>
          ) *
        </Title>
        <RadioButtons
          name="hiringTemporarilyAuthorizedCandidates"
          value={values.hiringTemporarilyAuthorizedCandidates}
          options={yesNo}
          setFieldValue={onWorkEligibilityChange}
        />
      </QuestionContainer>
      <QuestionContainer>
        <StyledCheck
          name="skipWorkEligibilityQuestions"
          label="I prefer not to answer the questions related to candidates' work eligibility."
          value={values.skipWorkEligibilityQuestions}
          checked={values.skipWorkEligibilityQuestions}
          onChange={handleSkipWorkEligibilityQuestionsChange}
        />
      </QuestionContainer>
      <QuestionContainer>
        <Title>Welcome from Coworkers & Team</Title>
        <Row $bsStyle={{ flexWrap: 'wrap', alignItems: 'center' }}>
          <CustomButton color={colors.LIGHT_BLUE} onClick={() => imageFile.current.click()}>
            Upload picture/video
          </CustomButton>
          <MaxFileSize>{values.teamMediaImageName || FILE_MAX_SIZE}</MaxFileSize>
        </Row>
        <UploadFile
          types={['image', 'video']}
          accept="image/png, image/jpeg, video/mp4, video/mov, video/mpeg-4, video/quicktime"
          onChange={handleChangeImage}
          id="teamMedia"
          fileRef={imageFile}
        />
        {(coworkersPreviewImage || values.teamMedia) && (
          <Row $bsStyle={{ marginTop: '20px' }}>
            <FilePreview
              fileName={values.teamMediaImageName}
              url={coworkersPreviewImage || values.teamMedia}
              imageHeight={200}
              imageWidth={200}
              onRemove={removeTeamMediaImage}
            />
          </Row>
        )}
        <SubtitleLabel $bsStyle={{ fontStyle: 'italic' }}>Caption</SubtitleLabel>
        <WrapTextBox
          name="teamMediaCaption"
          placeholder="Text"
          wrapperContainerStyle={{
            maxWidth: '496px',
            marginBottom: '20px',
            width: '100%',
          }}
        />
        <SubtitleLabel>Description</SubtitleLabel>
        <WrapTextBox
          name="teamMediaDescription"
          placeholder="If you included a picture or video above, also include a written
          welcome from your team for potential candidates."
          rows={10}
          maxLength={5000}
          component="textarea"
          $bsStyle={{ overflowY: 'scroll', height: '100%' }}
        />
      </QuestionContainer>
    </Collapsible>
  );
};

Qualifications.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  showValidateIcon: PropTypes.bool,
  values: opportunityProps.isRequired,
  initialValues: opportunityProps.isRequired,
};

Qualifications.defaultProps = {
  showValidateIcon: false,
};

export default Qualifications;
