import PropTypes from 'prop-types';
import React from 'react';

import helpInfoImage from 'assets/png/help_info.png';

import { Image, TooltipContainer } from './styled';

const Tooltip = ({ text, $bsStyle, imageStyle, image, position }) => (
  <TooltipContainer data-text={text} $bsStyle={$bsStyle} position={position}>
    <Image imageStyle={imageStyle} src={image || helpInfoImage} />
  </TooltipContainer>
);

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  $bsStyle: PropTypes.shape({}),
  imageStyle: PropTypes.shape({}),
  image: PropTypes.string,
  position: PropTypes.oneOf(['left']),
};

Tooltip.defaultProps = {
  $bsStyle: {},
  imageStyle: {},
  image: undefined,
  position: undefined,
};

export default Tooltip;
