import React from 'react';

const Blanket = props => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      zIndex: 1,
    }}
    {...props}
  />
);

export default Blanket;
