import { Row } from '@hivediversity/common-lib/base';
import { FieldArray } from 'formik';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { AddAndRemoveButtons, WrapTextBox } from 'components';
import { DropdownContainer } from 'styled';

import Dropdown from '../dropdown';

const MultiText = ({ value, name, setFieldValue, dropdowns, inputs, onAdd }) => {
  useEffect(() => {
    if (size(value) === 0 && setFieldValue && name) {
      setFieldValue(name, ['']);
    }
  }, []);

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <Row $bsStyle={{ marginTop: '10px', flexWrap: 'wrap' }}>
          {value.map((entity, index) => (
            <Row key={index} $bsStyle={{ width: '100%', flexWrap: 'wrap' }}>
              {inputs.map(input => (
                <WrapTextBox
                  key={input.id}
                  name={`${name}.${index}.${input.name}`}
                  placeholder={input.placeholder}
                  maxLength={255}
                  showErrorMessage
                  wrapperContainerStyle={{
                    margin: '0 20px 10px 0',
                    minWidth: '250px',
                    maxWidth: '300px',
                    ...input.style,
                  }}
                />
              ))}
              {dropdowns.map((dropdown, dropdownIndex) => (
                <DropdownContainer key={`${index}-${dropdownIndex}`} $bsStyle={dropdown.style}>
                  <Dropdown
                    key={dropdown.id}
                    name={`${name}.${index}.${dropdown.name}`}
                    onChange={dropdown.onChange}
                    value={entity[dropdown.name]}
                    placeholder={dropdown.placeholder}
                    isSearchable={dropdown.isSearchable}
                    showIndicatorSeparator
                    options={dropdown.options(entity)}
                  />
                </DropdownContainer>
              ))}
              <AddAndRemoveButtons
                size={value.length}
                index={index}
                onAdd={() => onAdd(arrayHelpers)}
                onRemove={() => arrayHelpers.remove(index)}
                addButtonStyle={{ minHeight: '90px' }}
                removeButtonStyle={{ minHeight: '90px' }}
                showRemoveButton={value.length > 1}
                showAddMore={value.length - 1 === index}
              />
            </Row>
          ))}
        </Row>
      )}
    />
  );
};

MultiText.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAdd: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      style: PropTypes.shape({}),
    })
  ).isRequired,
  dropdowns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      onChange: PropTypes.func.isRequired,
      options: PropTypes.func.isRequired,
      placeholder: PropTypes.string,
      name: PropTypes.string.isRequired,
      style: PropTypes.shape({}),
      isSearchable: PropTypes.bool.isRequired,
    })
  ),
};

MultiText.defaultProps = {
  dropdowns: [],
};

export default MultiText;
