import { push } from 'connected-react-router';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import {
  fetchRequest,
  updateRequest,
  activateRequest,
  uploadFormImagesRequest,
  removeFormImagesRequest,
  progressRequest,
  getUsersByCompanyRequest,
} from 'services/company';
import companyCreator, { companyTypes } from 'store/company/actions';

export function* fetch() {
  try {
    const company = yield call(fetchRequest);
    yield put(companyCreator.companyFetchSuccess(company));
  } catch (error) {
    yield put(companyCreator.companyFetchError(error));
  }
}

export function* uploadFormImage({ typeImage, files }) {
  try {
    yield call(uploadFormImagesRequest, typeImage, files);
  } catch (error) {
    yield put(companyCreator.companyUpdateError(error));
  }
}

export function* uploadFiles({ files }) {
  yield all(files.map(file => call(uploadFormImage, file)));
}

export function* removeFormImage(file) {
  try {
    yield call(removeFormImagesRequest, file);
  } catch (error) {
    yield put(companyCreator.companyUpdateError(error));
  }
}

export function* removeFiles({ files }) {
  yield all(files.map(file => call(removeFormImage, file)));
}

export function* update({ values }) {
  try {
    const response = yield call(updateRequest, values);
    if (response.message) {
      yield put(companyCreator.companyUpdateError(response.message));
    } else {
      const videosToUpload = values?.videos?.some(video => !video.toDelete && (video.index >= 0 || video.id >= 0));
      const videosToRemove = values.videos?.some(video => video?.toDelete && video?.id > 0);
      const files = [
        {
          typeImage: 'welcomeImage',
          files: values.welcomeImageUrl,
          toUpload: values.newWelcomeImageUploaded,
          toRemove: values.deleteWelcomeImage,
        },
        {
          typeImage: 'cultureImage',
          files: values.cultureImage,
          toUpload: values.hasCultureImage,
          toRemove: values.deleteCultureImage,
        },
        {
          typeImage: 'logo',
          files: values.originalLogoUrl,
          toUpload: !!values.originalLogoUrl,
          toRemove: false,
        },
        {
          typeImage: 'photo',
          files: values.originalPhotoUrl,
          toUpload: !!values.originalPhotoUrl,
          toRemove: false,
        },
        {
          typeImage: 'video',
          files: values.videos,
          toUpload: videosToUpload,
          toRemove: videosToRemove,
        },
      ];
      let { company } = response;
      const filesToUpload = files.filter(file => file.toUpload);
      const filesToRemove = files.filter(file => file.toRemove);
      if (filesToUpload.length > 0) {
        yield* uploadFiles({ files: filesToUpload });
      }
      if (filesToRemove.length > 0) {
        yield* removeFiles({ files: filesToRemove });
      }
      if (filesToRemove.length > 0 || filesToUpload.length > 0) {
        company = yield call(fetchRequest);
      }
      yield put(companyCreator.companyUpdateSuccess(company));
      if (values.validations) {
        yield put(companyCreator.updateProfileComplete(true));
        yield put(push('/company?tab=preview'));
      }
    }
  } catch (error) {
    yield put(companyCreator.companyUpdateError(error));
  }
}

export function* activate({ active }) {
  try {
    const company = yield call(activateRequest, active);
    yield put(companyCreator.companyToggleActivateSuccess(company));
  } catch (error) {
    yield put(companyCreator.companyToggleActivateError(error));
  }
}

export function* progressFetch() {
  try {
    const { data } = yield call(progressRequest);
    yield put(companyCreator.companyFetchProgressSuccess(data));
  } catch (error) {
    yield put(companyCreator.companyFetchProgressError(error));
  }
}

export function* getUsersByCompany() {
  try {
    const { data } = yield call(getUsersByCompanyRequest);
    yield put(companyCreator.companyFetchUsersSuccess(data));
  } catch (error) {
    yield put(companyCreator.companyFetchUsersError(error));
  }
}

export function* companySaga() {
  yield takeLatest(companyTypes.COMPANY_FETCH_REQUEST, fetch);
  yield takeLatest(companyTypes.COMPANY_UPDATE_REQUEST, update);
  yield takeLatest(companyTypes.COMPANY_TOGGLE_ACTIVATE_REQUEST, activate);
  yield takeLatest(companyTypes.COMPANY_FETCH_PROGRESS_REQUEST, progressFetch);
  yield takeLatest(companyTypes.COMPANY_FETCH_USERS_REQUEST, getUsersByCompany);
}
