import { Hr, Row } from '@hivediversity/common-lib/base';
import { CustomButton, LoadingButton } from '@hivediversity/common-lib/buttons';
import { CompanyBanner } from '@hivediversity/common-lib/components';
import { colors, companySection, model } from '@hivediversity/common-lib/constant';
import { companyProps } from '@hivediversity/common-lib/utils';
import { Formik } from 'formik';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ImageEditor } from 'components';
import { routes } from 'constant';
import companyCreator from 'store/company/actions';
import { getIsUpdating } from 'store/company/selectors';
import staticDataCreator from 'store/static-data/actions';
import { getIsValid } from 'utils';

import AboutUs from './sections/about-us';
import Administration from './sections/administration';
import Culture from './sections/culture';
import FlightGuide from './sections/flight-guide';
import People from './sections/people';
import WhatsTheBuzz from './sections/whats-the-buzz';
import { Container, FormFormik, BannerContainer } from './styled';
import validationSchema from './validation-schema';

const defaultOpenConfig = {
  aboutUs: false,
  administration: false,
  culture: false,
  people: false,
  whatsTheBuzz: false,
  flightGuide: false,
};

const CompanyEditor = ({ company, isInitial }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsUpdating);
  const location = useLocation();
  const isNewCompany = location.pathname === routes.NEW_COMPANY;
  const [open, setOpen] = useState(defaultOpenConfig);
  const [showBannerEditor, setShowBannerEditor] = useState(false);
  const [showLogoEditor, setShowLogoEditor] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      staticDataCreator.staticDataFetchRequest({
        industries: true,
        jobFunctions: true,
        companyTypes: true,
        careerKeywords: true,
        employees: true,
        socialNetworks: true,
      })
    );
    dispatch(staticDataCreator.staticDataFetchCountriesRequest());
  }, []);

  useEffect(() => {
    const statesIds = [];
    if (company.additionalLocations.length > 0) {
      company.additionalLocations.forEach(additionalLocation => {
        if (additionalLocation.stateCode) {
          statesIds.push(additionalLocation.stateCode);
        }
      });
    }
    if (company.headquarterLocation?.stateCode) {
      statesIds.push(company.headquarterLocation?.stateCode);
    }
    if (company.additionalLocations.length > 0) {
      company.additionalLocations.forEach(additionalLocation => {
        if (additionalLocation.stateCode) {
          statesIds.push(additionalLocation.stateCode);
        }
      });
    }
    if (company.pointOfContact?.stateCode) {
      statesIds.push(company.pointOfContact?.stateCode);
    }
    const industriesIds = [];
    if (company.industries.length > 0) {
      company.industries.forEach(industry => {
        if (industry?.id) {
          industriesIds.push(industry?.id);
        }
      });
    }
    if (company.subsidiaries.length > 0) {
      company.subsidiaries.forEach(subsidiary => {
        if (subsidiary.industryId) {
          industriesIds.push(subsidiary.industryId);
        }
      });
    }
    if (company.internalDivisions.length > 0) {
      company.internalDivisions.forEach(internalDivision => {
        if (internalDivision.verticalId) {
          industriesIds.push(internalDivision.verticalId);
        }
      });
    }
    if (industriesIds.length > 0) {
      dispatch(staticDataCreator.staticDataFetchSubIndustriesRequest(compact(uniq(industriesIds))));
    }
    if (statesIds.length > 0) {
      dispatch(staticDataCreator.staticDataFetchCitiesRequest(compact(uniq(statesIds))));
    }
  }, []);

  const handleCollapse = ({ target: { id } }) => setOpen({ ...open, [id]: !open[id] });

  const handleSaveImage = (name, image, setFieldValue) => {
    setFieldValue(name, image);
    setShowLogoEditor(false);
    setShowBannerEditor(false);
    setFieldValue(name === 'originalLogoUrl' ? 'logoUrl' : 'photoUrl', URL.createObjectURL(image));
  };

  const handleSave = values =>
    dispatch(
      companyCreator.companyUpdateRequest({
        ...values,
        logoUrl: company.logoUrl,
        photoUrl: company.photoUrl,
      })
    );
  return (
    <Container>
      <Formik
        onSubmit={values => handleSave({ ...values, validations: true })}
        validationSchema={validationSchema}
        initialValues={company}
      >
        {({ values, setFieldValue, errors, submitCount, handleSubmit, dirty, setValues }) => (
          <FormFormik>
            <BannerContainer isInitial={isInitial}>
              <CompanyBanner
                company={{
                  ...values,
                  industries: company.originalIndustries,
                }}
                editable
                followable={false}
                bannerAndLogoRequired={submitCount > 0}
                onEditLogo={() => setShowLogoEditor(true)}
                onEditBanner={() => setShowBannerEditor(true)}
                setShowEdit
              />
            </BannerContainer>
            <ImageEditor
              onConfirm={image => handleSaveImage('originalPhotoUrl', image, setFieldValue)}
              image={values.photoUrl}
              show={showBannerEditor}
              onDismiss={() => setShowBannerEditor(false)}
              borderRadius={0}
              className="image-editor"
              isTheImageSaved={!values.originalPhotoUrl}
              height={200}
              width={900}
            />
            <ImageEditor
              onConfirm={image => handleSaveImage('originalLogoUrl', image, setFieldValue)}
              image={values.logoUrl}
              show={showLogoEditor}
              onDismiss={() => setShowLogoEditor(false)}
              borderRadius={100}
              style={{ height: '142px', width: '142px' }}
              isTheImageSaved={!values.originalLogoUrl}
            />
            <Hr $bsStyle={{ margin: '40px 0 0 0' }} />
            <AboutUs
              onCollapse={handleCollapse}
              isValid={getIsValid(model.COMPANY, companySection.ABOUT_US, errors)}
              open={open.aboutUs}
              setFieldValue={setFieldValue}
              values={values}
              showValidateIcon={!isNewCompany || (isNewCompany && submitCount > 0)}
            />
            <Culture
              onCollapse={handleCollapse}
              isValid={getIsValid(model.COMPANY, companySection.CULTURE, errors)}
              open={open.culture}
              setFieldValue={setFieldValue}
              setValues={setValues}
              values={values}
              initialValues={company}
              showValidateIcon={!isNewCompany || (isNewCompany && submitCount > 0)}
            />
            <People
              onCollapse={handleCollapse}
              isValid={getIsValid(model.COMPANY, companySection.PEOPLE, errors)}
              open={open.people}
              setFieldValue={setFieldValue}
              setValues={setValues}
              values={values}
              initialValues={company}
              showValidateIcon={!isNewCompany || (isNewCompany && submitCount > 0)}
            />
            <WhatsTheBuzz
              onCollapse={handleCollapse}
              isValid={getIsValid(model.COMPANY, companySection.WHATS_THE_BUZZ, errors)}
              open={open.whatsTheBuzz}
              setFieldValue={setFieldValue}
              values={values}
              showValidateIcon={!isNewCompany || (isNewCompany && submitCount > 0)}
            />
            <FlightGuide
              onCollapse={handleCollapse}
              isValid={getIsValid(model.COMPANY, companySection.FLIGHT_GUIDE, errors)}
              open={open.flightGuide}
              showValidateIcon={!isNewCompany || (isNewCompany && submitCount > 0)}
            />
            <Administration
              onCollapse={handleCollapse}
              isValid={getIsValid(model.COMPANY, companySection.ADMINISTRATION, errors)}
              setFieldValue={setFieldValue}
              values={values}
              open={open.administration}
              showValidateIcon={!isNewCompany || (isNewCompany && submitCount > 0)}
            />
            <Row center $bsStyle={{ margin: '20px 0', flexWrap: 'wrap' }}>
              <LoadingButton
                component={CustomButton}
                color={colors.VIOLET}
                onClick={() => {
                  setOpen(defaultOpenConfig);
                  handleSubmit();
                }}
                label="Publish"
                isLoading={isLoading}
                style={{ alignSelf: 'center', margin: 32, maxWidth: '184px' }}
              />
              {!company.profileComplete && (
                <LoadingButton
                  component={CustomButton}
                  color={colors.BLUE}
                  onClick={() => {
                    setOpen(defaultOpenConfig);
                    handleSave({ ...values, validations: false });
                  }}
                  disabled={!dirty}
                  label="save Draft"
                  isLoading={isLoading}
                  style={{
                    alignSelf: 'center',
                    margin: 32,
                    maxWidth: '184px',
                    lineHeight: 'initial',
                  }}
                  greyDisabled
                />
              )}
            </Row>
          </FormFormik>
        )}
      </Formik>
    </Container>
  );
};

CompanyEditor.propTypes = {
  company: companyProps,
  isInitial: PropTypes.bool,
};

CompanyEditor.defaultProps = {
  company: null,
  isInitial: false,
};

export default CompanyEditor;
