import { takeLatest, call, put, all } from 'redux-saga/effects';

import {
  fetchRequest,
  fetchStatesRequest,
  fetchCitiesRequest,
  fetchCountriesRequest,
  fetchIndustriesRequest,
  fetchSubIndustriesRequest,
  fetchClassStandingRequest,
  getZonesRequest,
  fetchDiversitiesRequest,
  fetchJobFunctionsRequest,
  fetchMajorsMinorsByCategoryRequest,
  fetchMajorCategoriesRequest,
  fetchMajorsMinorsRequest,
} from 'services/static-data';
import staticDataCreator, { staticDataTypes } from 'store/static-data/actions';

export function* fetch({ params }) {
  try {
    const { data } = yield call(fetchRequest, params);
    yield put(staticDataCreator.staticDataFetchSuccess(data));
  } catch (error) {
    yield put(staticDataCreator.staticDataFetchError(error));
  }
}

export function* fetchStates({ term }) {
  try {
    const response = yield call(fetchStatesRequest, term);
    yield put(staticDataCreator.staticDataFetchStatesSuccess(response));
  } catch (error) {
    yield put(staticDataCreator.staticDataFetchStatesError(error));
  }
}

export function* fetchCitiesByStatesRequest({ statesIds }) {
  try {
    if (statesIds.length > 1) {
      const cities = yield all(statesIds.map(stateId => call(fetchCitiesRequest, stateId)));
      yield put(staticDataCreator.staticDataFetchCitiesSuccess(cities));
    } else {
      const cities = yield call(fetchCitiesRequest, statesIds);
      yield put(staticDataCreator.staticDataFetchCitiesSuccess(cities));
    }
  } catch (error) {
    yield put(staticDataCreator.staticDataFetchCitiesError(error));
  }
}

export function* fetchCountries() {
  try {
    const countries = yield call(fetchCountriesRequest);
    yield put(staticDataCreator.staticDataFetchCountriesSuccess(countries));
  } catch (error) {
    yield put(staticDataCreator.staticDataFetchCountriesError(error));
  }
}

export function* fetchIndustries() {
  try {
    const data = yield call(fetchIndustriesRequest);
    yield put(staticDataCreator.staticDataFetchIndustriesSuccess(data));
  } catch (error) {
    yield put(staticDataCreator.staticDataFetchIndustriesError(error));
  }
}

export function* fetchSubIndustriesByIndustriesRequest({ industriesIds }) {
  try {
    if (industriesIds.length > 1) {
      const subIndustries = yield all(industriesIds.map(industryId => call(fetchSubIndustriesRequest, industryId)));
      yield put(staticDataCreator.staticDataFetchSubIndustriesSuccess(subIndustries));
    } else {
      const subIndustries = yield call(fetchSubIndustriesRequest, industriesIds);
      yield put(staticDataCreator.staticDataFetchSubIndustriesSuccess(subIndustries));
    }
  } catch (error) {
    yield put(staticDataCreator.staticDataFetchSubIndustriesError(error));
  }
}

export function* getClassStanding() {
  try {
    const classStanding = yield call(fetchClassStandingRequest);
    yield put(staticDataCreator.getClassStandingSuccess(classStanding));
  } catch (error) {
    yield put(staticDataCreator.getClassStandingError(error));
  }
}

export function* getZones() {
  try {
    const zones = yield call(getZonesRequest);
    yield put(staticDataCreator.getZonesSuccess(zones));
  } catch (error) {
    yield put(staticDataCreator.getZonesError(error));
  }
}

export function* fetchDiversities() {
  try {
    const [
      races,
      genders,
      sexualOrientations,
      nationalities,
      colleges,
      socioEconomics,
      veteran,
      religions,
      disabilities,
      others,
      ally,
      industries,
      jobFunctions,
      classStanding,
      categories,
    ] = yield all([
      call(fetchDiversitiesRequest, 1),
      call(fetchDiversitiesRequest, 2),
      call(fetchDiversitiesRequest, 3),
      call(fetchDiversitiesRequest, 4),
      call(fetchDiversitiesRequest, 5),
      call(fetchDiversitiesRequest, 6),
      call(fetchDiversitiesRequest, 7),
      call(fetchDiversitiesRequest, 8),
      call(fetchDiversitiesRequest, 9),
      call(fetchDiversitiesRequest, 10),
      call(fetchDiversitiesRequest, 34),
      call(fetchIndustriesRequest),
      call(fetchJobFunctionsRequest),
      call(fetchClassStandingRequest),
      call(fetchMajorCategoriesRequest),
    ]);
    yield put(
      staticDataCreator.getDiversitiesSuccess({
        races,
        genders,
        sexualOrientations,
        nationalities,
        colleges,
        socioEconomics,
        veteran,
        religions,
        disabilities,
        others,
        ally,
        industries,
        jobFunctions,
        classStanding,
        categories,
      })
    );
  } catch (error) {
    yield put(staticDataCreator.getDiversitiesError(error));
  }
}

export function* fetchMajorsMinorsByCategory({ categoryId, entity }) {
  try {
    const entities = yield call(fetchMajorsMinorsByCategoryRequest, categoryId);
    yield put(staticDataCreator.getMajorsMinorsByCategorySuccess(entity, entities));
  } catch (error) {
    yield put(staticDataCreator.getMajorsMinorsByCategoryError(entity, error));
  }
}

export function* fetchMajorsMinors() {
  try {
    const majorsMinors = yield call(fetchMajorsMinorsRequest);
    yield put(staticDataCreator.getMajorsMinorsSuccess(majorsMinors));
  } catch (error) {
    yield put(staticDataCreator.getMajorsMinorsError(error));
  }
}

export function* staticDataSaga() {
  yield takeLatest(staticDataTypes.STATIC_DATA_FETCH_REQUEST, fetch);
  yield takeLatest(staticDataTypes.STATIC_DATA_FETCH_STATES_REQUEST, fetchStates);
  yield takeLatest(staticDataTypes.STATIC_DATA_FETCH_CITIES_REQUEST, fetchCitiesByStatesRequest);
  yield takeLatest(staticDataTypes.STATIC_DATA_FETCH_COUNTRIES_REQUEST, fetchCountries);
  yield takeLatest(staticDataTypes.STATIC_DATA_FETCH_INDUSTRIES_REQUEST, fetchIndustries);
  yield takeLatest(staticDataTypes.STATIC_DATA_FETCH_SUB_INDUSTRIES_REQUEST, fetchSubIndustriesByIndustriesRequest);
  yield takeLatest(staticDataTypes.GET_CLASS_STANDING_REQUEST, getClassStanding);
  yield takeLatest(staticDataTypes.GET_ZONES_REQUEST, getZones);
  yield takeLatest(staticDataTypes.GET_DIVERSITIES_REQUEST, fetchDiversities);
  yield takeLatest(staticDataTypes.GET_MAJORS_MINORS_BY_CATEGORY_REQUEST, fetchMajorsMinorsByCategory);
  yield takeLatest(staticDataTypes.GET_MAJORS_MINORS_REQUEST, fetchMajorsMinors);
}
