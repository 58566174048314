import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

import { Cross } from './styled';

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    {children}
    {props.selectProps.inputValue && <Cross onClick={() => props.setValue('')} />}
  </components.ValueContainer>
);

ValueContainer.propTypes = {
  setValue: PropTypes.func.isRequired,
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string,
  }).isRequired,
};

export default ValueContainer;
