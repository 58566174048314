export const initialState = {
  isLoading: false,
  isLoadingStates: false,
  isLoadingCities: false,
  isLoadingCountries: false,
  isLoadingSubIndustries: false,
  isLoadingDiversities: false,
  subIndustries: [],
  industries: [],
  careerKeywords: [],
  companyTypes: [],
  minors: [],
  months: [],
  majors: [],
  jobFunctions: [],
  majorCategories: [],
  minorCategories: [],
  languages: [],
  degrees: [],
  states: [],
  cities: [],
  countries: [],
  tags: [],
  diversities: {
    races: [],
    genders: [],
    sexualOrientations: [],
    nationalities: [],
    colleges: [],
    socioEconomics: [],
    veteran: [],
    religions: [],
    disabilities: [],
    others: [],
    ally: [],
  },
  majorsMinors: [],
};
