import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';
import values from 'lodash/values';

const groupIndustriesByVertical = industries => {
  const dataGroupedByVertical = groupBy(industries, 'verticalId');
  const dataMapped = mapValues(dataGroupedByVertical, (value, key) => ({
    id: Number(key),
    subIndustries: uniq(value.map(currentValue => currentValue.industryId)),
  }));
  return values(dataMapped);
};

export default groupIndustriesByVertical;
