import { CloseButton } from '@hivediversity/common-lib/buttons';
import PropTypes from 'prop-types';
import React, { cloneElement, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Container, Content, Overlay, Title } from './styled';

const Modal = ({
  show,
  children,
  title,
  fullPage,
  background,
  onClose,
  withoutOverflow,
  $bsStyle,
  containerStyles,
  closeButtonStyle,
  ...props
}) => {
  const [scrollYPosition, setScrollYPosition] = useState(0);

  useEffect(() => {
    if (!withoutOverflow) {
      if (show) {
        setScrollYPosition(window.scrollY);
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'initial';
      }
    }
    // eslint-disable-next-line
    return () => (document.body.style.overflow = 'initial');
  }, [show]);

  useEffect(() => {
    window.onscroll = () => {
      window.scrollTo(0, show ? scrollYPosition : window.scrollY);
    };
  });

  return (
    show &&
    ReactDOM.createPortal(
      <>
        <Overlay />
        <Container aria-modal aria-hidden tabIndex={-1} role="dialog" fullPage={fullPage} $bsStyle={containerStyles}>
          <Content $bsStyle={$bsStyle} background={background} fullPage={fullPage}>
            {title && <Title>{title}</Title>}
            {onClose && <CloseButton onClick={onClose} $bsStyle={closeButtonStyle} />}
            {cloneElement(children, { ...props })}
          </Content>
        </Container>
      </>,
      document.body
    )
  );
};

Modal.propTypes = {
  onDismiss: PropTypes.func,
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  show: PropTypes.bool,
  withoutOverflow: PropTypes.bool,
  $bsStyle: PropTypes.shape({}),
  containerStyles: PropTypes.shape({}),
  closeButtonStyle: PropTypes.shape({}),
};

Modal.defaultProps = {
  title: null,
  show: false,
  onDismiss: undefined,
  withoutOverflow: false,
  bsStyle: undefined,
  containerStyles: undefined,
  closeButtonStyle: undefined,
};

export default Modal;
