import { Column, Hr, Row } from '@hivediversity/common-lib/base';
import { CustomButton } from '@hivediversity/common-lib/buttons';
import { OpportunityPreview, OpportunityBanner, LoadingPage } from '@hivediversity/common-lib/components';
import { colors, status } from '@hivediversity/common-lib/constant';
import { useQuery } from '@hivediversity/common-lib/hooks';
import { push } from 'connected-react-router';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import parseInt from 'lodash/parseInt';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { ErrorMessage, Tabs, Tab, ConfirmModal, OpportunitySwitch } from 'components';
import { defaultOpportunity, routes } from 'constant';
import { CANT_DELETE, WANT_DELETE, SURE_RESTORE_OR_ARCHIVE, SURE_COPY_OPPORTUNITY } from 'constant/messages';
import applicantDataCreator from 'store/applicant/actions';
import { getCompany } from 'store/company/selectors';
import opportunityCreator from 'store/opportunity/actions';
import {
  getError,
  getIsLoading,
  getIsSaving,
  getOpportunity,
  getOpportunityId,
  getUpdateOpportunityStatusError,
} from 'store/opportunity/selectors';
import staticDataCreator from 'store/static-data/actions';
import { getStaticData } from 'store/static-data/selectors';
import { getTitleWithNumber } from 'utils';

import Applicants from './applicants';
import Editor from './editor';
import { Wrapper, Created, Title, WrapperButton, WrapperEditor, TrashButton, Container, AnchorButton } from './styled';
import { Error } from '../profile/styled';

const { REACT_APP_URL_BASE } = process.env;

const getOpportunityStatus = (completed, isOpportunityArchived) => {
  if (!isOpportunityArchived) {
    return status.ARCHIVED;
  }
  if (!completed) {
    return status.INACTIVE;
  }
  return status.ACTIVE;
};

const Opportunity = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const opportunity = useSelector(getOpportunity);
  const company = useSelector(getCompany);
  const isSaving = useSelector(getIsSaving);
  const error = useSelector(getError);
  const isLoading = useSelector(getIsLoading);
  const updateStatusError = useSelector(getUpdateOpportunityStatusError);
  const { classStanding: classStandingsList } = useSelector(getStaticData);
  const currentOpportunityId = useSelector(getOpportunityId);
  const [showRemoveOpportunityModal, setShowRemoveOpportunityModal] = useState(false);
  const [showArchiveOrRestoreOpportunityModal, setShowArchiveOrArchiveOpportunityModal] = useState(false);
  const [showConfirmCopyOpportunityModal, setShowConfirmCopyOpportunityModal] = useState(false);
  const queryTab = useQuery('tab', location);
  const [selectedTab, setSelectedTab] = useState(queryTab);
  const { opportunityId } = useParams();
  const isOpportunityArchived = opportunity && opportunity.status === status.ARCHIVED;

  const classStanding = useMemo(
    () =>
      (classStandingsList || []).reduce(
        (acc, classStanding) => [
          ...acc,
          ...(classStanding?.types || []).map(({ id, name }) => ({ description: name, id })),
        ],
        []
      ),
    [classStandingsList]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (opportunityId && !error && !isLoading && opportunity) {
      dispatch(staticDataCreator.getClassStandingRequest());
      dispatch(opportunityCreator.getTotalApplicantsRequest(parseInt(opportunityId)));
    }
  }, [opportunityId, error, isLoading, opportunity]);

  useEffect(() => {
    if (opportunityId) {
      dispatch(opportunityCreator.opportunityFindRequest(parseInt(opportunityId)));
    }
  }, [opportunityId]);

  useEffect(() => {
    if (queryTab) {
      setSelectedTab(queryTab);
    }
  }, [queryTab]);

  useEffect(() => {
    if (currentOpportunityId !== Number(opportunityId)) {
      dispatch(opportunityCreator.cleanOpportunity());
    }
  }, []);

  const backgroundColor = () =>
    selectedTab === 'Review'
      ? 'linear-gradient(160deg, rgba(116,44,167,1) 0%, rgba(0,0,0,1) 100%),rgb(116,44,167)'
      : 'linear-gradient(to bottom right, #fd4cf4, #000)';

  const handleAcceptChangeOpportunityStatus = () => {
    setShowArchiveOrArchiveOpportunityModal(false);
    dispatch(
      opportunityCreator.updateOpportunityStatusRequest(
        opportunity.id,
        getOpportunityStatus(opportunity.completed, isOpportunityArchived),
        isOpportunityArchived,
        null,
        opportunity.completed
      )
    );
  };

  const handleAcceptDeleteOpportunity = () => {
    setShowRemoveOpportunityModal(false);
    dispatch(opportunityCreator.opportunityDeleteRequest(opportunityId));
  };

  const handleCopyOpportunity = () => {
    const newOpportunity = omit(opportunity, ['id', 'applicants', 'createdBy']);
    setShowConfirmCopyOpportunityModal(false);
    dispatch(
      opportunityCreator.opportunitySaveRequest({
        ...newOpportunity,
        title: `Copy of ${newOpportunity.title}`,
        isCopy: true,
        status: status.INACTIVE,
      })
    );
  };

  return (
    <>
      {isObject(error) ? (
        <Column $bsStyle={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Error>{error.message}</Error>
          <CustomButton
            onClick={() => dispatch(push(error.previousUrl))}
            color={colors.LIGHT_BLUE}
            $bsStyle={{ marginTop: '10px', textTransform: 'none', fontSize: '18px' }}
          >
            Go to Dashboard
          </CustomButton>
        </Column>
      ) : (
        <Container background={backgroundColor}>
          <ConfirmModal
            show={showRemoveOpportunityModal}
            title={opportunity?.totalApplicants > 0 ? CANT_DELETE : WANT_DELETE}
            onDismiss={() => setShowRemoveOpportunityModal(false)}
            onAccept={handleAcceptDeleteOpportunity}
            showAcceptButton={opportunity?.totalApplicants === 0}
            cancelLabel={opportunity?.totalApplicants > 0 ? 'Accept' : 'No'}
          />
          <ConfirmModal
            show={showArchiveOrRestoreOpportunityModal}
            title={SURE_RESTORE_OR_ARCHIVE(isOpportunityArchived)}
            onDismiss={() => setShowArchiveOrArchiveOpportunityModal(false)}
            onAccept={handleAcceptChangeOpportunityStatus}
          />
          <ConfirmModal
            show={showConfirmCopyOpportunityModal}
            title={SURE_COPY_OPPORTUNITY}
            onDismiss={() => setShowConfirmCopyOpportunityModal(false)}
            onAccept={handleCopyOpportunity}
          />
          <Wrapper>
            <WrapperButton onClick={() => dispatch(push(routes.DASHBOARD))}>
              <CustomButton color={colors.VIOLET}>Back</CustomButton>
            </WrapperButton>
            <Hr margin="20px 10px 56px 0" />
            {opportunityId && opportunity && (
              <>
                <Row $bsStyle={{ flexWrap: 'wrap' }}>
                  <Column flex>
                    <OpportunitySwitch
                      name="status"
                      status={opportunity.status}
                      id={opportunity.id}
                      isCompleted={opportunity.completed}
                    />
                  </Column>
                  <Column flex>
                    <Row right $bsStyle={{ flexWrap: 'wrap' }}>
                      {opportunity.totalApplicants > 0 && (
                        <CustomButton
                          color={colors.ORANGE}
                          onClick={() =>
                            dispatch(
                              applicantDataCreator.downloadApplicantsCSVRequest(opportunityId, opportunity.title)
                            )
                          }
                          $bsStyle={{
                            maxWidth: '160px',
                            lineHeight: 'initial',
                            marginBottom: '10px',
                          }}
                        >
                          Download csv
                        </CustomButton>
                      )}
                      {opportunity.totalApplicants > 0 && (
                        <AnchorButton
                          color={colors.ORANGE}
                          $bsStyle={{ maxWidth: '160px', marginBottom: '10px', marginLeft: '10px' }}
                          href={`${REACT_APP_URL_BASE}employer/applicantsResumes/${opportunityId}?download=true`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Export All Candidates
                        </AnchorButton>
                      )}
                      <CustomButton
                        color={colors.ORANGE}
                        onClick={() => setShowArchiveOrArchiveOpportunityModal(true)}
                        $bsStyle={{
                          maxWidth: '160px',
                          lineHeight: 'initial',
                          marginBottom: '10px',
                          marginLeft: '10px',
                        }}
                      >
                        {isOpportunityArchived ? 'Restore' : 'Archive this Position'}
                      </CustomButton>
                      <CustomButton
                        color={colors.ORANGE}
                        onClick={() => setShowConfirmCopyOpportunityModal(true)}
                        $bsStyle={{
                          maxWidth: '160px',
                          lineHeight: 'initial',
                          marginBottom: '10px',
                          marginLeft: '10px',
                        }}
                      >
                        Copy this position
                      </CustomButton>
                      <TrashButton onClick={() => setShowRemoveOpportunityModal(true)} />
                    </Row>
                  </Column>
                </Row>
                <Row>
                  <Column flex>
                    <Title $bsStyle={{ fontSize: '30px' }}>{opportunity ? `${opportunity.title}` : ''}</Title>
                    <Created $bsStyle={{ marginBottom: '20px' }}>
                      {opportunity ? `Created: ${opportunity.createdAt}` : ''}
                    </Created>
                    <Created>{opportunity ? `Created by: ${opportunity.createdBy.username}` : ''}</Created>
                  </Column>
                </Row>
                {updateStatusError && currentOpportunityId === opportunity.id && (
                  <ErrorMessage messageError={updateStatusError} />
                )}
                <Hr margin="16px 10px 56px 0" />
                <Tabs id="opportunity" active={selectedTab} onClick={setSelectedTab}>
                  <Tab
                    id="applicants"
                    title={`${getTitleWithNumber('APPLICANTS', opportunity?.totalApplicants, '(+', ')')}`}
                  >
                    <Applicants opportunity={opportunity} />
                  </Tab>
                  <Tab id="Review" title="PREVIEW">
                    <OpportunityBanner
                      opportunity={{ ...opportunity, industries: opportunity.originalIndustries }}
                      company={company}
                      durationsBand
                    />
                    <Hr $bsStyle={{ margin: '40px 0 0 0' }} />
                    <OpportunityPreview
                      opportunity={opportunity}
                      companyName={company?.name}
                      options={{ classStanding }}
                    />
                  </Tab>
                  <Tab id="Edit" title="EDIT">
                    <WrapperEditor>
                      <Editor opportunity={opportunity} />
                    </WrapperEditor>
                  </Tab>
                </Tabs>
              </>
            )}
          </Wrapper>
          <Wrapper>
            {!opportunityId && (
              <WrapperEditor>
                <Editor
                  opportunity={
                    opportunity?.id
                      ? opportunity
                      : {
                          ...defaultOpportunity,
                          logoUrl: company.logoUrl,
                          photoUrl: company.photoUrl,
                        }
                  }
                />
              </WrapperEditor>
            )}
            {!error && (isSaving || (opportunityId && !opportunity)) && <LoadingPage />}
          </Wrapper>
        </Container>
      )}
    </>
  );
};

export default Opportunity;
