import { all } from 'redux-saga/effects';

import { applicantSaga } from 'store/applicant/saga';
import { authorizationSaga } from 'store/authorization/saga';
import { companySaga } from 'store/company/saga';
import { employerSaga } from 'store/employer/saga';
import { opportunitySaga } from 'store/opportunity/saga';
import { recoverPassSaga } from 'store/recover-pass/saga';
import { statSaga } from 'store/stat/saga';
import { staticDataSaga } from 'store/static-data/saga';

export default function* rootSaga() {
  yield all([
    authorizationSaga(),
    opportunitySaga(),
    companySaga(),
    employerSaga(),
    applicantSaga(),
    recoverPassSaga(),
    staticDataSaga(),
    statSaga(),
  ]);
}
