import * as yup from 'yup';

import { emailRegex, schemaMessages, urlRegex } from 'constant';

function unique(message, mapper = a => a) {
  return this.test('unique', message, (list = []) => list.length === new Set(list.map(mapper)).size);
}

yup.addMethod(yup.array, 'unique', unique);

function uniqueProperty(propertyName, message) {
  // eslint-disable-next-line no-restricted-syntax,func-names
  return this.test('uniqueProperty', message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    if (this.parent.filter(parent => parent !== value).some(parent => parent[propertyName] === value[propertyName])) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      });
    }

    return true;
  });
}

yup.addMethod(yup.object, 'uniqueProperty', uniqueProperty);

const validationSchema = yup.object().shape({
  description: yup.string().required(schemaMessages.REQUIRED),
  type: yup.number().required(schemaMessages.REQUIRED),
  headquarterLocation: yup.object().shape({
    international: yup.bool().required(schemaMessages.REQUIRED),
    stateCode: yup
      .string()
      .when('international', {
        is: value => !value,
        then: yup.string().nullable().required(schemaMessages.REQUIRED),
      })
      .nullable(),
    cityId: yup
      .number()
      .when('stateCode', {
        is: value => value,
        then: yup.number().nullable().required(schemaMessages.REQUIRED),
      })
      .nullable(),
    countryId: yup
      .number()
      .when('international', {
        is: value => value,
        then: yup.number().nullable().required(schemaMessages.REQUIRED),
      })
      .nullable(),
    cityName: yup
      .string()
      .when('countryId', {
        is: value => value && value !== 214,
        then: yup.string().nullable().required(schemaMessages.REQUIRED),
      })
      .nullable(),
    countryName: yup
      .string()
      .when('countryId', {
        is: value => value && value === 214,
        then: yup.string().nullable().required(schemaMessages.REQUIRED),
      })
      .nullable(),
  }),
  urlAddress: yup.string().matches(urlRegex, schemaMessages.VALID_URL).required(schemaMessages.REQUIRED),
  numberOfEmployees: yup.number().min(1, schemaMessages.REQUIRED),
  industries: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          id: yup.number().nullable().required(schemaMessages.REQUIRED),
          subIndustries: yup
            .array()
            .of(yup.number())
            .compact()
            .min(1, schemaMessages.REQUIRED)
            .unique(schemaMessages.UNIQUE_SUB_INDUSTRIES),
        })
        .uniqueProperty('id', schemaMessages.INDUSTRY_REPEATED)
    )
    .required(schemaMessages.REQUIRED),
  jobFunctions: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number(),
        name: yup.string(),
      })
    )
    .min(1, schemaMessages.REQUIRED)
    .compact(value => value.id === 0)
    .required(schemaMessages.REQUIRED),
  additionalLocations: yup.array().of(
    yup.object().shape({
      international: yup.bool(),
      stateCode: yup
        .string()
        .when(['international'], {
          is: international => !international,
          then: yup.string().nullable(),
        })
        .nullable(),
      cityId: yup
        .number()
        .when(['stateCode'], {
          is: stateCode => stateCode,
          then: yup.number().nullable(),
        })
        .nullable(),
      countryId: yup
        .number()
        .when(['international'], {
          is: international => international,
          then: yup.number().nullable(),
        })
        .nullable(),
      cityName: yup
        .string()
        .when(['countryId'], {
          is: countryId => countryId && countryId !== 214,
          then: yup.string().nullable(),
        })
        .nullable(),
      countryName: yup
        .string()
        .when(['countryId'], {
          is: countryId => countryId && countryId === 214,
          then: yup.string().nullable(),
        })
        .nullable(),
    })
  ),
  overallShortStatement: yup.string().required(schemaMessages.REQUIRED),
  pointOfContact: yup.object().shape({
    international: yup.bool().required(schemaMessages.REQUIRED),
    stateCode: yup
      .string()
      .when('international', {
        is: international => !international,
        then: yup.string().nullable().required(schemaMessages.REQUIRED),
      })
      .nullable(),
    cityId: yup
      .number()
      .when('stateCode', {
        is: stateCode => stateCode,
        then: yup.number().nullable().required(schemaMessages.REQUIRED),
      })
      .nullable(),
    countryId: yup
      .number()
      .when('international', {
        is: international => international,
        then: yup.number().nullable().required(schemaMessages.REQUIRED),
      })
      .nullable(),
    cityName: yup
      .string()
      .when('countryId', {
        is: countryId => countryId && countryId !== 214,
        then: yup.string().nullable().required(schemaMessages.REQUIRED),
      })
      .nullable(),
    countryName: yup
      .string()
      .when('countryId', {
        is: countryId => countryId && countryId === 214,
        then: yup.string().nullable().required(schemaMessages.REQUIRED),
      })
      .nullable(),
    postalCode: yup.string().nullable().required(schemaMessages.REQUIRED),
    phone: yup.string().required(schemaMessages.REQUIRED),
    firstName: yup.string().required(schemaMessages.REQUIRED),
    address: yup.string().required(schemaMessages.REQUIRED),
    lastName: yup.string().required(schemaMessages.REQUIRED),
    companyEmailDomain: yup.string().required(schemaMessages.REQUIRED),
    email: yup.string().matches(emailRegex, schemaMessages.VALID_EMAIL).required(schemaMessages.REQUIRED),
  }),
  aggregateDataHive: yup.bool().required(schemaMessages.REQUIRED),
  diversityMean: yup.string().required(schemaMessages.REQUIRED),
  hiveCulturalKeywords: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number(),
        name: yup.string(),
      })
    )
    .min(5, 'You must select 5 cultural / career keywords minimum'),
  logoUrl: yup.string().required(),
  photoUrl: yup.string().required(),
  socialMedias: yup.array().of(
    yup.object().shape({
      url: yup.string().matches(urlRegex, schemaMessages.VALID_URL).nullable(),
    })
  ),
  influencers: yup.array().of(
    yup.object().shape({
      url: yup.string().matches(urlRegex, schemaMessages.VALID_URL).nullable(),
    })
  ),
  recentPress: yup.array().of(
    yup.object().shape({
      url: yup.string().matches(urlRegex, schemaMessages.VALID_URL).nullable(),
    })
  ),
  internalDivisions: yup.array().of(
    yup.object().shape({
      url: yup.string().matches(urlRegex, schemaMessages.VALID_URL).nullable(),
    })
  ),
  subsidiaries: yup.array().of(
    yup.object().shape({
      url: yup.string().matches(urlRegex, schemaMessages.VALID_URL).nullable(),
    })
  ),
  missionLink: yup.string().matches(urlRegex, schemaMessages.VALID_URL).nullable(),
  diversityMeanLink: yup.string().matches(urlRegex, schemaMessages.VALID_URL).nullable(),
  socialImpactLink: yup.string().matches(urlRegex, schemaMessages.VALID_URL).nullable(),
  talentGuideLink: yup.string().matches(urlRegex, schemaMessages.VALID_URL).nullable(),
  undergraduateLink: yup.string().matches(urlRegex, schemaMessages.VALID_URL).nullable(),
});

export default validationSchema;
