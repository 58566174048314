import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  authLoginRequest: ['email', 'password'],
  authLoginSuccess: ['user', 'token'],
  authLoginError: ['error'],

  authGetTokenRequest: [null],
  authGetTokenSuccess: ['token'],
  authGetTokenError: ['error'],

  authImpersonateRequest: ['code', 'opportunityId'],
  authImpersonateSuccess: ['token'],
  authImpersonateError: ['error'],
});

export const authTypes = Types;

export default Creators;
