import { Form } from 'formik';
import styled from 'styled-components';

import ResumeWhyCompanyImg from 'assets/png/resume-why-company.png';
import checkIcon from 'assets/svg/check.svg';
import { Check } from 'components';

export const FormFormik = styled(Form)`
  width: 100%;
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 40px;
`;

export const Span = styled.span`
  transition: all 0.3s ease-in-out;
  padding: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  align-items: center;
  display: flex;
  background: ${({ valid }) => (valid ? '#73db62' : '#E82EDF')};
  justify-content: center;
`;

export const Title = styled.h4`
  font-family: Brandon Grotesque;
  font-weight: 500;
  line-height: 20px;
  color: white;
  margin: 0 0 16px 0;
  font-size: 21px;
  ${({ $bsStyle }) => $bsStyle || ''}

  .colon {
    font-weight: normal;
  }
`;

export const StyledTitle = styled(Title)`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
`;

export const WrapperDates = styled.div`
  min-width: 300px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
`;

export const QuestionContainer = styled.div`
  margin-bottom: ${({ marginBottom }) => marginBottom || '50px'};
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const ExternalApplicationContainer = styled.div`
  margin-top: 20px;
  margin-left: 90px;
`;

export const SectionLabel = styled.h2`
  color: #fff;
  font-size: 20px;
  margin-bottom: 40px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const SubtitleLabel = styled.p`
  font-family: Brandon Grotesque;
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  color: white;
  margin: 24px 0 16px 0;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const Anchor = styled.a`
  font-family: Brandon Grotesque;
  color: #fff;
  text-decoration: underline;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const DollarSymbol = styled.span`
  margin-left: 5px;
  margin-top: 13px;
  position: absolute;
  color: #fff;
  font-size: 14px;
  &:before {
    content: '$';
  }
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const TooltipContainer = styled.div`
  margin-top: 10px;
`;

export const ResumeImage = styled.img.attrs(props => ({
  src: props.Img || ResumeWhyCompanyImg,
}))`
  width: 100%;
  min-width: 200px;
  max-width: 800px;
  height: 100%;
  max-height: 800px;
  min-height: 400px;
  @media only screen and (max-width: 600px) {
    min-width: 350px;
    max-width: 400px;
  }
  @media only screen and (max-height: 800px) {
    max-height: 750px;
  }
  @media only screen and (max-height: 758px) {
    max-height: 700px;
  }
  @media only screen and (max-height: 710px) {
    max-height: 650px;
  }
`;

export const ClassStandingLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;

  .description {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 4px;
    font-weight: 400;
  }
`;

export const StyledCheck = styled(Check)`
  & .circle {
    width: 30px;
    height: 30px;
    border-color: #fd4cf4;
    margin-right: 21px !important;
  }

  .checkbox {
    &:checked ~ .circle {
      background-color: #00d154;
      background-image: url(${checkIcon});
    }
  }

  .label {
    font-family: Brandon Grotesque;
    font-weight: 500;
    line-height: 20px;
    color: white;
    margin: 0;
    font-size: 21px;
  }
`;
