import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  applicantFindStudentRequest: ['opportunityId', 'applicantId'],
  applicantFindStudentSuccess: ['student'],
  applicantFindStudentError: ['error'],

  applicantSendLinkRequest: ['applicantId'],
  applicantSendLinkSuccess: [],
  applicantSendLinkError: ['error'],

  getApplicantEmailRequest: ['applicantId', 'tagId'],
  getApplicantEmailSuccess: ['email'],
  getApplicantEmailError: ['error'],

  downloadApplicantsCSVRequest: ['opportunityId', 'opportunityName'],
  downloadApplicantsCSVSuccess: [],
  downloadApplicantsCSVError: ['error'],

  cleanApplicantEmail: [],
});

export const applicantTypes = Types;

export default Creators;
