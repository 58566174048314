import { Hr, Row } from '@hivediversity/common-lib/base';
import { colors } from '@hivediversity/common-lib/constant';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tooltip } from 'components';
import { routes, tooltipsTexts } from 'constant';
import employerDataCreator from 'store/employer/actions';
import { getEmployer, getIsLoading } from 'store/employer/selectors';
import { Container, HeaderContainer, Title } from 'styled';

import { ButtonsContainer, Link, StyledCustomButton, SubTitle, TooltipContainer } from './styled';

const getAccountLabel = employer => {
  if (!employer) {
    return '';
  }
  if (employer.companyAdmin) {
    return 'Admin';
  }
  if (employer.editCompanyProfile) {
    return 'Company';
  }
  return 'Posting';
};

const Supports = () => {
  const dispatch = useDispatch();
  const employer = useSelector(getEmployer);
  const isLoading = useSelector(getIsLoading);

  useEffect(() => {
    dispatch(employerDataCreator.employerFetchRequest());
  }, []);

  return (
    <Container $bsStyle={{ overflow: 'hidden' }}>
      <HeaderContainer>
        <Title>Support</Title>
        <div style={{ minHeight: '35px', height: '35px' }} />
        <Hr margin="20px 0 10px 0" />
      </HeaderContainer>
      {!isLoading && (
        <Row $bsStyle={{ minWidth: '800px' }}>
          <SubTitle>{`Account: ${getAccountLabel(employer)}`}</SubTitle>
          <TooltipContainer>
            <Tooltip
              text={
                employer?.editCompanyProfile || employer?.companyAdmin
                  ? tooltipsTexts.IS_COMPANY_ADMIN
                  : tooltipsTexts.IS_NOT_COMPANY_ADMIN
              }
            />
          </TooltipContainer>
        </Row>
      )}
      <ButtonsContainer>
        <Link href="mailto:b@hellohive.com" color={colors.VIOLET}>
          Contact Support
        </Link>
        <StyledCustomButton color={colors.VIOLET} onClick={() => dispatch(push(routes.CHANGE_PASSWORD))}>
          Update Password
        </StyledCustomButton>
        <Link href="mailto:b@hellohive.com" color={colors.VIOLET}>
          Update Email
        </Link>
      </ButtonsContainer>
    </Container>
  );
};

export default Supports;
