import { CustomButton } from '@hivediversity/common-lib/buttons';
import { colors } from '@hivediversity/common-lib/constant';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonsContainer } from './styled';

const ConfirmCancelButtons = ({
  showAcceptButton,
  onAccept,
  isAcceptButtonDisabled,
  acceptLabel,
  onDismiss,
  cancelLabel,
  acceptButtonStyle,
  cancelButtonStyle,
}) => (
  <ButtonsContainer>
    {showAcceptButton && (
      <CustomButton
        $bsStyle={{ maxWidth: '200px', marginBottom: '0.5rem', ...acceptButtonStyle }}
        color={colors.LIGHT_BLUE}
        onClick={onAccept}
        disabled={isAcceptButtonDisabled}
      >
        {acceptLabel}
      </CustomButton>
    )}
    <CustomButton
      $bsStyle={{ maxWidth: '200px', marginBottom: '0.5rem', ...cancelButtonStyle }}
      color={colors.ORANGE}
      onClick={onDismiss}
    >
      {cancelLabel}
    </CustomButton>
  </ButtonsContainer>
);

ConfirmCancelButtons.propTypes = {
  onAccept: PropTypes.func,
  onDismiss: PropTypes.func.isRequired,
  showAcceptButton: PropTypes.bool,
  isAcceptButtonDisabled: PropTypes.bool,
  acceptLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  acceptButtonStyle: PropTypes.shape({}),
  cancelButtonStyle: PropTypes.shape({}),
};

ConfirmCancelButtons.defaultProps = {
  onAccept: undefined,
  isAcceptButtonDisabled: false,
  showAcceptButton: true,
  acceptLabel: 'Yes',
  cancelLabel: 'No',
  acceptButtonStyle: {},
  cancelButtonStyle: {},
};

export default ConfirmCancelButtons;
