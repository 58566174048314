import PropTypes from 'prop-types';
import React from 'react';

import ConfirmCancelButtons from '../confirm-cancel-buttons';
import Modal from '../modal';

const ConfirmModal = ({
  onAccept,
  onDismiss,
  show,
  title,
  acceptLabel,
  cancelLabel,
  showAcceptButton,
  children,
  isAcceptButtonDisabled,
  acceptButtonStyle,
  cancelButtonStyle,
  ...props
}) => (
  <Modal
    show={show}
    title={title}
    onDismiss={onDismiss}
    background="linear-gradient(135deg, rgba(29,37,38,1) 0%, rgba(29,37,38,1) 15%, rgba(0,0,0,1) 100%), #1D2526"
    {...props}
  >
    <>
      {children}
      <ConfirmCancelButtons
        onDismiss={onDismiss}
        cancelLabel={cancelLabel}
        acceptLabel={acceptLabel}
        onAccept={onAccept}
        showAcceptButton={showAcceptButton}
        isAcceptButtonDisabled={isAcceptButtonDisabled}
        acceptButtonStyle={acceptButtonStyle}
        cancelButtonStyle={cancelButtonStyle}
      />
    </>
  </Modal>
);

ConfirmModal.propTypes = {
  onAccept: PropTypes.func,
  onDismiss: PropTypes.func.isRequired,
  show: PropTypes.bool,
  showAcceptButton: PropTypes.bool,
  isAcceptButtonDisabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  acceptLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  acceptButtonStyle: PropTypes.shape({}),
  cancelButtonStyle: PropTypes.shape({}),
};

ConfirmModal.defaultProps = {
  onAccept: undefined,
  show: false,
  isAcceptButtonDisabled: false,
  showAcceptButton: true,
  acceptLabel: 'Yes',
  cancelLabel: 'No',
  children: undefined,
  acceptButtonStyle: {},
  cancelButtonStyle: {},
};

export default ConfirmModal;
