import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  employerFetchRequest: [null],
  employerFetchSuccess: ['employer'],
  employerFetchError: ['error'],

  changePasswordRequest: ['oldPassword', 'newPassword'],
  changePasswordSuccess: [],
  changePasswordError: ['error'],
});

export const employerTypes = Types;

export default Creators;
