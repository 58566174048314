import styled from 'styled-components';

export const Input = styled.input`
  font: inherit;
  min-width: 2px;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  color: inherit;
  background: 0;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  width: 100%;
  letter-spacing: -0.5px;
  grid-area: 1 / 2;
  font-weight: 400;
  font-size: 19px;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
`;
