import PropTypes from 'prop-types';
import React from 'react';

import { ArrowContainer, ArrowDown, Cross, Placeholder, StyledButton } from './styled';

const Target = ({ isOpen, placeholder, onAction, isClearable, disabled }) => (
  <StyledButton onClick={onAction} disabled={disabled}>
    <Placeholder>{placeholder}</Placeholder>
    <ArrowContainer>
      {isClearable && <Cross onClick={onAction} id="clean" />}
      <ArrowDown isOpen={isOpen} />
    </ArrowContainer>
  </StyledButton>
);

Target.propTypes = {
  onAction: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
};

Target.defaultProps = {
  isClearable: false,
  disabled: false,
};

export default Target;
