import { Row } from '@hivediversity/common-lib/base';
import { amAndPm } from '@hivediversity/common-lib/constant';
import { opportunityProps } from '@hivediversity/common-lib/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { getStaticData } from 'store/static-data/selectors';
import { DropdownContainer, Text } from 'styled';
import { getDays, getHours, getYears } from 'utils';
import { getMinutes } from 'utils/get-minutes';

import Dropdown from '../dropdown';

const DateTimeSelectors = ({
  title,
  setFieldTouched,
  setFieldValue,
  values,
  month,
  day,
  year,
  anteMeridiem,
  hour,
  minute,
  hasHour,
  hasAnteMeridiem,
  hasMinute,
}) => {
  const { months, isLoading } = useSelector(getStaticData);
  const isShowPrevDate =
    title === 'Start Date (all times in Eastern Time)' || 'Deadline Date (all times in Eastern Time)';

  return (
    <Row $bsStyle={{ flexWrap: 'wrap' }}>
      {title && <Text $bsStyle={{ alignSelf: 'center', marginRight: '20px' }}>{title}</Text>}
      <DropdownContainer $bsStyle={{ marginRight: '10px' }}>
        <Dropdown
          name={month}
          onChange={setFieldValue}
          value={values[month]}
          placeholder="Month"
          options={months}
          isLoading={isLoading}
          $bsStyle={{
            padding: 0,
            marginRight: '10px',
            minWidth: '90px',
            textAlign: 'center',
          }}
          setFieldTouched={setFieldTouched}
          showIndicatorSeparator
        />
      </DropdownContainer>
      <DropdownContainer $bsStyle={{ marginRight: '10px' }}>
        <Dropdown
          name={day}
          onChange={setFieldValue}
          value={values[day]}
          placeholder="Day"
          options={getDays(values[month] || 0, values[year] || 0)}
          $bsStyle={{
            padding: 0,
            minWidth: '90px',
            textAlign: 'center',
          }}
          setFieldTouched={setFieldTouched}
          showIndicatorSeparator
        />
      </DropdownContainer>
      <DropdownContainer $bsStyle={{ marginRight: '10px' }}>
        <Dropdown
          name={year}
          onChange={setFieldValue}
          value={values[year]}
          placeholder="Year"
          options={isShowPrevDate ? getYears(10, values[year]) : getYears(10)}
          $bsStyle={{
            padding: 0,
            minWidth: '90px',
            textAlign: 'center',
          }}
          setFieldTouched={setFieldTouched}
          noFormik
          showIndicatorSeparator
        />
      </DropdownContainer>
      {hasHour && (
        <DropdownContainer $bsStyle={{ marginRight: '10px' }}>
          <Dropdown
            name={hour}
            onChange={setFieldValue}
            value={values[hour]}
            placeholder="Time"
            options={getHours()}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
            }}
            setFieldTouched={setFieldTouched}
            showIndicatorSeparator
          />
        </DropdownContainer>
      )}
      {hasMinute && (
        <DropdownContainer $bsStyle={{ marginRight: '10px' }}>
          <Dropdown
            name={minute}
            onChange={setFieldValue}
            value={values[minute]}
            placeholder="Minute"
            options={getMinutes(5)}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
            }}
            setFieldTouched={setFieldTouched}
            showIndicatorSeparator
          />
        </DropdownContainer>
      )}
      {hasAnteMeridiem && (
        <DropdownContainer>
          <Dropdown
            name={anteMeridiem}
            onChange={setFieldValue}
            value={values[anteMeridiem]}
            placeholder="AM/PM (ET)"
            options={amAndPm}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
              maxWidth: '250px',
              margin: '0 10px 0 0',
            }}
            setFieldTouched={setFieldTouched}
            showIndicatorSeparator
          />
        </DropdownContainer>
      )}
    </Row>
  );
};

DateTimeSelectors.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  title: PropTypes.string,
  month: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  hour: PropTypes.string,
  minute: PropTypes.string,
  anteMeridiem: PropTypes.string,
  values: opportunityProps.isRequired,
  hasHour: PropTypes.bool,
  hasMinute: PropTypes.bool,
  hasAnteMeridiem: PropTypes.bool,
};

DateTimeSelectors.defaultProps = {
  hour: '',
  minute: '',
  anteMeridiem: undefined,
  hasHour: true,
  hasMinute: true,
  hasAnteMeridiem: true,
  title: undefined,
};

export default DateTimeSelectors;
