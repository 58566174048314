import { CustomButton } from '@hivediversity/common-lib/buttons';
import styled from 'styled-components';

export const SubTitle = styled.h2`
  color: #fff;
  font-size: 22px;
  font-weight: 800;
`;

export const Text = styled.p`
  color: #fff;
  font-size: 23px;
  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

export const StyledCustomButton = styled(CustomButton)`
  max-width: 200px;
  margin-bottom: 50px;
`;

export const Link = styled.a`
  max-width: 200px;
  margin-bottom: 50px;
  background: ${({ color }) => color};
  border-radius: 20px;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-size: 13px;
  line-height: 221.4%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 100%;
  height: 39px;
  padding: 0 31px;
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
`;

export const TooltipContainer = styled.div`
  margin-top: 5px;
`;
