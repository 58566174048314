import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';

import { apiRoutes, defaultIndustry } from 'constant';
import { groupIndustriesByVertical } from 'utils';

import { del, getOrCached, put, upload } from './http';

export const getIndustries = (industries = []) => {
  if (industries.length === 0) {
    return [defaultIndustry];
  }
  return groupIndustriesByVertical(
    industries.map(industry => ({
      verticalId: industry?.vertical?.id,
      industryId: industry?.industry?.id,
    }))
  );
};

export const buildCompanyData = company => ({
  ...company,
  originalIndustries: company.industries,
  photoUrl: company.photoUrl,
  logoUrl: company.logoUrl,
  alias: !isEmpty(company.alias) ? company.alias : '',
  description: !isEmpty(company.description) ? company.description : '',
  type: company.type ? Number(company.type) : '',
  additionalLocations: !isEmpty(company.additionalLocations) ? company.additionalLocations : [],
  headquarterLocation: isEmpty(company.headquarterLocation)
    ? {
        stateCode: '',
        cityId: '',
        countryId: undefined,
        international: false,
        cityName: '',
        countryName: '',
      }
    : company.headquarterLocation,
  urlAddress: !isEmpty(company.urlAddress) ? company.urlAddress : '',
  industries: company.industries,
  overallShortStatement: !isEmpty(company.overallShortStatement) ? company.overallShortStatement : '',
  emojis: !isEmpty(company.emojis) ? company.emojis : '',
  contactPoint: !isEmpty(company.contactPoint) ? company.contactPoint : '',
  phone: !isEmpty(company.phone) ? company.phone : '',
  address: !isEmpty(company.address) ? company.address : '',
  domain: !isEmpty(company.domain) ? company.domain : '',
  firstName: !isEmpty(company.firstName) ? company.firstName : '',
  lastName: !isEmpty(company.lastName) ? company.lastName : '',
  email: !isEmpty(company.email) ? company.email : '',
  aggregateDataHive: isBoolean(company.aggregateDataHive) ? company.aggregateDataHive : true,
  aggregateDataFallowers: !isEmpty(company.aggregateDataFallowers) ? company.aggregateDataFallowers : '',
  culture: !isEmpty(company.culture) ? company.culture : '',
  cultureCaption: !isEmpty(company.cultureCaption) ? company.cultureCaption : '',
  cultureImageUrl: !isEmpty(company.cultureImageUrl) ? company.cultureImageUrl : '',
  mission: !isEmpty(company.mission) ? company.mission : '',
  missionLink: !isEmpty(company.missionLink) ? company.missionLink : '',
  diversityMean: !isEmpty(company.diversityMean) ? company.diversityMean : '',
  diversityMeanLink: !isEmpty(company.diversityMeanLink) ? company.diversityMeanLink : '',
  hiveCulturalKeywords: !isEmpty(company.hiveCulturalKeywords) ? company.hiveCulturalKeywords : [{ id: '', name: '' }],
  hiveCulturalKeywordsUrl: !isEmpty(company.hiveCulturalKeywordsUrl) ? company.hiveCulturalKeywordsUrl : '',
  talent: !isEmpty(company.talent) ? company.talent : '',
  talentLink: !isEmpty(company.talentLink) ? company.talentLink : '',
  socialImpact: !isEmpty(company.socialImpact) ? company.socialImpact : '',
  socialImpactLink: !isEmpty(company.socialImpactLink) ? company.socialImpactLink : '',
  socialImpactImageUrl: !isEmpty(company.socialImpactImageUrl) ? company.socialImpactImageUrl : '',
  diversity: !isEmpty(company.diversity) ? company.diversity : '',
  diversityLink: !isEmpty(company.diversityLink) ? company.diversityLink : '',
  internalGroups: !isEmpty(company.internalGroups) ? company.internalGroups : '',
  internalGroupsLink: !isEmpty(company.internalGroupsLink) ? company.internalGroupsLink : '',
  welcome: !isEmpty(company.welcome) ? company.welcome : '',
  welcomeImageUrl: !isEmpty(company.welcomeImageUrl) ? company.welcomeImageUrl : '',
  videos: !isEmpty(company.videos) ? company.videos : [{ title: '', url: '' }],
  recentPress: !isEmpty(company.recentPress) ? company.recentPress : [{ title: '', url: '' }],
  recentCommitments: !isEmpty(company.recentCommitments) ? company.recentCommitments : [{ title: '', url: '' }],

  undergraduate: !isEmpty(company.undergraduate) ? company.undergraduate : '',
  undergraduateLink: !isEmpty(company.undergraduateLink) ? company.undergraduateLink : '',
  recruit: !isEmpty(company.recruit) ? company.recruit : '',
  careerRunways: !isEmpty(company.careerRunways) ? company.careerRunways : '',
  interviewStyles: !isEmpty(company.interviewStyles) ? company.interviewStyles : '',
  internships: !isEmpty(company.internships) ? company.internships : '',
  pointOfContact: isEmpty(company.pointOfContact)
    ? {
        phone: '',
        international: false,
        firstName: '',
        lastName: '',
        email: '',
        companyEmailDomain: '',
        address: '',
        stateCode: null,
        cityId: null,
        postalCode: null,
      }
    : company.pointOfContact,
  socialMedias: isEmpty(company.socialMedias) ? [''] : company.socialMedias,
});

export const fetchRequest = async () => {
  const { company } = await getOrCached(apiRoutes.COMPANY);
  return buildCompanyData(company);
};

export const updateRequest = company => {
  return put(apiRoutes.COMPANY, {
    ...company,
    profileComplete: company.profileComplete || company.validations,
    industries: company.industries.flatMap(industry =>
      industry.subIndustries.map(subIndustry => ({
        industry: { id: subIndustry },
        vertical: { id: industry.id },
      }))
    ),
  });
};

export const activateRequest = async active => {
  const { company } = await put(apiRoutes.ACTIVATE, { active });
  return buildCompanyData(company);
};

export const uploadCompanyVideos = (videos = []) => {
  if (videos.every(video => video.toDelete)) {
    return null;
  }
  const formData = new window.FormData();
  videos.forEach((video, index) => {
    if (!video.toDelete) {
      if (!video.id || (video.id && !video.url)) {
        formData.append(`videos[${index}][video]`, video.file, video.fileName);
      }
      if (video.id) {
        formData.append(`videos[${index}][id]`, video.id);
      }
      formData.append(`videos[${index}][title]`, video.title || '');
      formData.append(`videos[${index}][caption]`, video.caption || '');
    }
  });
  return upload(apiRoutes.VIDEOS, formData);
};

export const uploadFormImagesRequest = (typeImage, file) => {
  const formData = new window.FormData();
  formData.append('image', file);
  if (typeImage === 'video') {
    return uploadCompanyVideos(file);
  }
  if (typeImage === 'cultureImage') {
    return upload(apiRoutes.CULTURE_IMAGE, formData);
  }
  if (typeImage === 'socialImpactImage') {
    return upload(apiRoutes.SOCIAL_IMPACT_IMAGE, formData);
  }
  if (typeImage === 'photo') {
    return upload(apiRoutes.COMPANY_PHOTO, formData, 'blob.png');
  }
  if (typeImage === 'logo') {
    return upload(apiRoutes.COMPANY_LOGO, formData, 'blob.png');
  }
  return upload(apiRoutes.WELCOME_IMAGE, formData, file.name);
};

export const removeCompanyVideos = videosIds => del(apiRoutes.VIDEOS, { videoIds: videosIds });

export const removeFormImagesRequest = file => {
  if (file.typeImage === 'video') {
    return removeCompanyVideos(file.files.filter(video => video.toDelete).map(video => video.id));
  }
  if (file.typeImage === 'cultureImage') {
    return del(apiRoutes.CULTURE_IMAGE);
  }
  if (file.typeImage === 'socialImpactImage') {
    return del(apiRoutes.SOCIAL_IMPACT_IMAGE);
  }
  return del(apiRoutes.WELCOME_IMAGE);
};

export const progressRequest = () => getOrCached(apiRoutes.PROGRESS);

export const getUsersByCompanyRequest = () => getOrCached(apiRoutes.USERS_LIST);
