export default {
  filters: {
    academics: {
      classStandings: [],
      minorCategory: null,
      majorCategory: null,
      honors: null,
      research: null,
      scholarships: null,
      gpa: undefined,
      majors: [],
      minors: [],
    },
    diversities: {
      gender: [],
      race: [],
      religion: [],
      ally: null,
      disability: null,
      firstGeneration: null,
      sexual: null,
      nationality: null,
      socioEconomic: null,
      veteran: null,
      other: null,
      diversity: null,
    },
    interests: {
      industriesOfInterest: [],
      jobFunctions: [],
      technicalSkills: null,
      personalInterests: null,
      certificationsAndLicences: null,
    },
  },
};
