import styled from 'styled-components';

import { Title } from 'styled';

export const StyledTitle = styled(Title)`
  font-size: ${({ fontSize }) => fontSize};
  word-break: break-all;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-top: 10px;
    font-size: ${({ fontSize }) => fontSize};
  }
`;
