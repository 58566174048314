import { CustomButton } from '@hivediversity/common-lib/buttons';
import styled from 'styled-components';

import crossImage from 'assets/png/cross.png';
import arrowDown from 'assets/png/dropdown-arrow-down.png';

export const ArrowDown = styled.img.attrs({ src: arrowDown, alt: 'arrow-down' })`
  height: 10px;
  transform: ${({ isOpen }) => (!isOpen ? 'translateY(0)' : 'rotate(0.5turn)')};
`;

export const Placeholder = styled.label`
  padding-left: 24px;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Cross = styled.img.attrs({ alt: 'search', src: crossImage })`
  height: 10px;
  width: 10px;
  cursor: pointer;
  margin-right: 10px;
`;

export const StyledButton = styled(CustomButton)`
  background: #8768e6;
  border-radius: 15px;
  font-weight: 300;
  text-transform: none;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  padding: 0 10px;
  height: 51px;
  font-size: 21px;
  line-height: initial;
  max-width: initial;
  min-width: initial;
  &:disabled {
    opacity: 1;
    background: #8768e6;
  }
`;

export const ArrowContainer = styled.div`
  margin-left: auto;
  display: flex;
`;
