import styled from 'styled-components';

import crossImage from 'assets/png/cross.png';

export const Cross = styled.img.attrs({ alt: 'search', src: crossImage })`
  height: 10px;
  width: 10px;
  position: absolute;
  right: 10px;
  cursor: pointer;
`;
