import { status } from '@hivediversity/common-lib/constant';

import defaultIndustry from './default-industry';

export default {
  id: null,
  status: status.ACTIVE,
  title: '',
  jobFunctions: [],
  type: '',
  teamMedia: '',
  caption: '',
  summary: '',
  detailed: '',
  logoUrl: '',
  photoUrl: '',
  computer: '',
  otherQualifications: '',
  compensationType: 1,
  compensationAmount: null,
  laborActAgreement: false,
  endDay: undefined,
  endMonth: undefined,
  endYear: undefined,
  endHour: null,
  endMinute: null,
  endHourAnteMeridiem: null,
  eventDay: null,
  eventMonth: null,
  eventYear: null,
  eventHour: null,
  eventAnteMeridiem: null,
  startDay: new Date().getDate(),
  startMonth: new Date().getMonth() + 1,
  startYear: new Date().getFullYear(),
  startHour: null,
  startMinute: null,
  anteMeridiem: null,
  expectedDateDay: null,
  expectedDateMonth: new Date().getMonth() + 1,
  expectedDateYear: new Date().getFullYear(),
  numberOfPotentialHires: null,
  tips: '',
  minGpa: 0,
  languages: [],
  majorCategories: [],
  activation: '',
  applicationUrl: '',
  duration: null,
  profilePictures: true,
  diversities: true,
  whyOurCompany: true,
  prioritizedBullets: true,
  coworkersVideoUrl: undefined,
  expectedDateHire: 0,
  atsSystemId: null,
  applicationsReceived: undefined,
  partTimeDuration: undefined,
  hasExternalApplicationLink: null,
  from: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  location: {
    type: undefined,
    stateCode: '',
    cityId: null,
    cityName: '',
    countryId: null,
    countryName: '',
    zipCode: '',
    international: false,
    postalCode: null,
  },
  originalIndustries: [{ industryId: null, subIndustryId: null }],
  name: undefined,
  description: undefined,
  requireUsWorkAuthorization: null,
  sponsorWorkVisa: null,
  skipWorkEligibilityQuestions: false,
  hiringTemporarilyAuthorizedCandidates: null,
  salary: null,
  eventRegistrationUrl: null,
  applicantsFilter: null,
  completed: false,
  earlyMonth: null,
  earlyYear: null,
  lateMonth: null,
  lateYear: null,
  hasPortfolio: false,
  hasTranscript: false,
  visibleForCandidates: null,
  compensationVisibleForStudents: null,
  preferredContactEmail: null,
  classStanding: undefined,
  annualSalaryFrom: null,
  annualSalaryTo: null,
  resumeRequired: null,
  opportunityFor: null,
  opportunityForName: null,
  opportunityForDescription: null,
  reviewApplications: null,
  locations: [
    {
      type: undefined,
      stateCode: '',
      cityId: null,
      cityName: '',
      countryId: null,
      countryName: '',
      zipCode: '',
      international: false,
    },
  ],
  industries: [defaultIndustry],
  adminUsers: [''],
};
