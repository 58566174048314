import { FieldArray } from 'formik';
import castArray from 'lodash/castArray';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React from 'react';

import { Container, Label, WrapperCheckbox, Check } from './styled';
import ErrorMessage from '../error-message';

const Checkboxes = ({ options, name, values, isHorizontal, $bsStyle, optionLabel }) => {
  const value = castArray(values[name] || []);
  const findValue = option => value.find(v => v === option.id);
  const filterValue = option => value.filter(v => v !== option.id);
  const exclusiveValues = options.filter(currentOption => currentOption.exclusive);
  const notExclusiveValues = options.filter(currentOption => !currentOption.exclusive);
  const exclusiveOptionSelected = exclusiveValues.some(exclusiveOption => value.includes(exclusiveOption?.id));
  const notExclusiveOptionSelected = notExclusiveValues.some(notExclusiveOption =>
    value.includes(notExclusiveOption?.id)
  );

  const isOptionDisabled = option =>
    (!option.exclusive && exclusiveOptionSelected) || (option.exclusive && notExclusiveOptionSelected);

  const handleChange = (form, option) => {
    if (isOptionDisabled(option)) {
      return;
    }
    if (findValue(option)) {
      const nextValue = filterValue(option);
      form.setFieldValue(name, nextValue);
    } else {
      const nextValue = value.concat(option.id);
      form.setFieldValue(name, nextValue);
    }
  };

  return (
    <>
      <FieldArray
        key={`${name}-container`}
        name={name}
        render={({ form }) => (
          <Container isHorizontal={isHorizontal} $bsStyle={$bsStyle}>
            {options.map(option => (
              <WrapperCheckbox key={`${option.id}-wrapper`} className="checkbox-wrapper">
                <Label htmlFor={`${option.id}-${option.name}`} disabled={isOptionDisabled(option)}>
                  <Check
                    id={`${option.id}-${option.name}`}
                    name={option.id}
                    type="checkbox"
                    value={option.id}
                    checked={findValue(option) || false}
                    onChange={() => handleChange(form, option)}
                    disabled={isOptionDisabled(option)}
                  />
                  <span />
                  {isFunction(option.render) ? option.render(option) : <p>{option[optionLabel]}</p>}
                </Label>
              </WrapperCheckbox>
            ))}
          </Container>
        )}
      />
      <ErrorMessage control={name} />
    </>
  );
};

Checkboxes.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
      name: PropTypes.string,
      description: PropTypes.string,
      exclusive: PropTypes.bool,
      render: PropTypes.func,
    })
  ),
  values: PropTypes.shape({}).isRequired,
  $bsStyle: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  optionLabel: PropTypes.string,
  isHorizontal: PropTypes.bool,
};

Checkboxes.defaultProps = {
  isHorizontal: false,
  $bsStyle: undefined,
  optionLabel: 'name',
  options: [],
};

export default Checkboxes;
