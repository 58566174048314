import styled from 'styled-components';

import progressbarBackground from 'assets/png/progressbar-background.png';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 900px;
  background-image: url(${progressbarBackground});
  background-size: contain;
  border-radius: 35px;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const Container = styled.div`
  height: ${({ height }) => height};
  background-color: ${({ baseBgColor }) => baseBgColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  width: 100%;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    height: 80px;
  }
  @media screen and (max-width: 767px) {
    height: 39px;
    margin-bottom: 1px;
  }
`;

export const Progress = styled.div`
  height: 100%;
  background: ${({ bgCompleted }) => `${bgCompleted}`};
  border-radius: ${({ borderRadius }) => `${borderRadius}` || '0'};
  width: ${({ completed }) => `${completed}%` || '0'};
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-weight: bold;
  color: ${({ labelColor }) => `${labelColor}`};
  font-size: ${({ labelSize }) => `${labelSize}`};
  position: absolute;
  top: 30px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: 26px;
  }
  @media screen and (max-width: 767px) {
    font-size: 20px;
    top: 5px;
  }
`;
