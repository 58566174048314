import styled from 'styled-components';

import trashImage from 'assets/svg/trash.svg';

export const Container = styled.div`
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  color: white;
  width: 100%;
  min-height: 100vh;
  padding-left: 80px;
  padding-right: 32px;
  background: ${({ background }) => background || '#000'};
  @media screen and (max-width: 767px) {
    padding: 0 30px;
  }
`;

export const Title = styled.label`
  font-size: 35px;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  margin: 6px 0 10px 0;
  color: white;
  text-transform: capitalize;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const Created = styled.label`
  font-size: 18px;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-weight: 600;
  color: white;
  margin-bottom: 64px;
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const WrapperButton = styled.div`
  margin-top: 10px;
  max-width: 100px;
  @media screen and (max-width: 767px) {
    margin-left: 50px;
  }
`;

export const Wrapper = styled.div`
  padding: 10px 0 0 0;
`;

export const WrapperEditor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TrashButton = styled.img.attrs({ src: trashImage, alt: 'trash' })`
  padding: 7px;
  border: none;
  width: 35px;
  height: 35px;
  margin: 0 0 0 10px;
  background: #323536;
  border-radius: 50%;
  cursor: pointer;
`;

export const AnchorButton = styled.a`
  border: none;
  cursor: pointer;
  min-height: 39px;
  max-width: 160px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 100%;
  height: 39px;
  padding: 0 31px;
  border: transparent;
  font-weight: 700;
  color: white;
  background: linear-gradient(90deg, rgba(191, 91, 149, 1) 0%, rgba(244, 174, 69, 1) 100%), rgb(191, 91, 149);
  border-radius: 20px;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  ${({ $bsStyle }) => $bsStyle || ''}
`;
