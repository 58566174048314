import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 30px;
  width: 100%;
`;

export const Divider = styled.div`
  margin-bottom: 25px;
  width: 100%;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const Label = styled.label`
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  color: rgb(253, 191, 51);
  font-weight: bold;
  font-size: 17px;
`;
