import styled from 'styled-components';

export const MaxFileSize = styled.p`
  color: #fff;
  padding-left: 10px;
  font-size: 16px;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const Text = styled.p`
  font-size: 15px;
  color: #fff;
  line-height: 18px;
  font-weight: 500;
  cursor: initial;
  text-decoration: initial;
  text-transform: initial;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const InputLabel = styled.h4`
  font-family: Brandon Grotesque;
  font-weight: 500;
  line-height: 20px;
  color: white;
  margin: 0 0 16px 0;
  font-size: 21px;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const DropdownContainer = styled.div`
  min-height: 50px;
  min-width: 250px;
  max-height: 150px;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const Container = styled.div`
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  color: white;
  width: 100%;
  min-height: 100vh;
  padding-left: 80px;
  padding-right: 32px;
  background: ${({ background }) => background || 'linear-gradient(to bottom right, #742ca7, #000)'};
  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const Title = styled.div`
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  color: white;
  font-weight: 700;
  text-align: left;
  font-size: 40px;
  margin: 5px 0 10px 0;
  display: flex;
  flex-direction: row;
  span {
    margin: 0 10px;
    text-transform: capitalize;
  }
  @media screen and (max-width: 767px) {
    font-size: 35px;
    margin-left: 50px;
    margin-top: 10px;
  }
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const HeaderContainer = styled.div`
  max-height: 300px;
  height: 100%;
`;
