const getDays = (month, year) => {
  const day = new Date(year, month, 0).getDate();

  return Array.from({ length: day }, (value, index) => {
    const dayNumber = index + 1;
    return { id: dayNumber, name: dayNumber.toString() };
  });
};

export default getDays;
