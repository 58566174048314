import { Row } from '@hivediversity/common-lib/base';
import { Collapsible } from '@hivediversity/common-lib/components';
import { companySection } from '@hivediversity/common-lib/constant';
import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip, WrapTextBox } from 'components';
import { placeholders, tooltipsTexts } from 'constant';
import { InputLabel } from 'styled';

import { WrapperInput, SubtitleLabel } from '../styled';

const FlightGuide = ({ onCollapse, isValid, open, showValidateIcon }) => (
  <Collapsible
    id={companySection.FLIGHT_GUIDE}
    onCollapse={() => onCollapse({ target: { id: companySection.FLIGHT_GUIDE } })}
    title="Flight guide to success"
    isValid={isValid}
    open={open}
    showValidateIcon={showValidateIcon}
  >
    <WrapperInput>
      <Row>
        <InputLabel>Next Generation of Talent Statement</InputLabel>
        <Tooltip text={tooltipsTexts.NEXT_GENERATION_OF_TALENT_STATEMENT} />
      </Row>
      <WrapTextBox
        name="talentGuide"
        placeholder="At HIVE Diversity, we KNOW that the only way to create systemic
        change specific to diversity, equity and inclusion in the workforce is by
        supporting meaningful engagement between the next generation of talent and
        companies who value diversity. "
        rows="10"
        maxLength={5000}
        component="textarea"
        $bsStyle={{ marginBottom: '16px', overflowY: 'scroll', height: '100%' }}
      />
      <Row $bsStyle={{ alignItems: 'center', flexWrap: 'wrap' }}>
        <SubtitleLabel $bsStyle={{ margin: '0 96px 0 0' }}>More info link</SubtitleLabel>
        <WrapTextBox
          name="talentGuideLink"
          placeholder={placeholders.URL_START_WITH_HTTP}
          maxLength={255}
          wrapperContainerStyle={{ maxWidth: '289px', width: '100%' }}
        />
      </Row>
    </WrapperInput>

    <WrapperInput>
      <InputLabel>Recruitment Efforts: Timelines, Programs & Structure</InputLabel>
      <WrapTextBox
        name="recruit"
        placeholder="Include information here about the structure of your recruitment (on-demand, structured),
        timing of your recruitment seasons, and any other info that can help candidates better understand when
        to most effectively engage with your organization."
        rows="10"
        maxLength={5000}
        component="textarea"
        $bsStyle={{ marginBottom: '16px', overflowY: 'scroll', height: '100%' }}
      />
      <Row $bsStyle={{ alignItems: 'center', flexWrap: 'wrap' }}>
        <SubtitleLabel $bsStyle={{ margin: '0 96px 0 0' }}>More info link</SubtitleLabel>
        <WrapTextBox
          name="undergraduateLink"
          placeholder={placeholders.URL_START_WITH_HTTP}
          maxLength={255}
          wrapperContainerStyle={{ maxWidth: '289px', width: '100%' }}
        />
      </Row>
    </WrapperInput>

    <WrapperInput>
      <InputLabel>How to Prep & Succeed</InputLabel>
      <WrapTextBox
        name="careerRunways"
        placeholder="Include information here related to academic fields of study,
          additional soft or hard skills that make candidates successful, and
          any specific information about your interview process and techniques.
          IE should candidates know technical or case interview prep? What else
          can you share to help them prepare and succeed?"
        rows="10"
        component="textarea"
        maxLength={5000}
        $bsStyle={{ overflowY: 'scroll', height: '100%' }}
      />
    </WrapperInput>
  </Collapsible>
);

FlightGuide.propTypes = {
  onCollapse: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  showValidateIcon: PropTypes.bool,
};

FlightGuide.defaultProps = {
  showValidateIcon: false,
};

export default FlightGuide;
