import { Hr, Row } from '@hivediversity/common-lib/base';
import { CustomButton, LoadingButton } from '@hivediversity/common-lib/buttons';
import { OpportunityBanner } from '@hivediversity/common-lib/components';
import { colors, model, opportunitySection, status } from '@hivediversity/common-lib/constant';
import { opportunityProps } from '@hivediversity/common-lib/utils';
import { push } from 'connected-react-router';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorMessage, ImageEditor } from 'components';
import { routes } from 'constant';
import { getCompany } from 'store/company/selectors';
import opportunityCreator from 'store/opportunity/actions';
import { getIsSaving, getError } from 'store/opportunity/selectors';
import staticDataCreator from 'store/static-data/actions';
import { getIsLoading } from 'store/static-data/selectors';
import { getIsValid } from 'utils';

import AdministrationSection from './sections/administration';
import DetailsSection from './sections/details';
import QualificationsSection from './sections/qualifications';
import { FormFormik } from './sections/styled';
import { ButtonContainer } from './styled';
import validationSchema from './validation-schema';

const defaultOpenConfig = {
  details: false,
  aggDemographics: false,
  administration: false,
  qualifications: false,
  qualificationsOthers: false,
};

const OpportunityEditor = ({ opportunity }) => {
  const dispatch = useDispatch();
  const isSaving = useSelector(getIsSaving);
  const isLoadingStaticData = useSelector(getIsLoading);
  const company = useSelector(getCompany);
  const error = useSelector(getError);
  const [open, setOpen] = useState(defaultOpenConfig);
  const [staticDataRequested, setStaticDataRequested] = useState(false);
  const [showBannerEditor, setShowBannerEditor] = useState(false);
  const [showLogoEditor, setShowLogoEditor] = useState(false);
  const handleCollapse = ({ target: { id } }) => setOpen({ ...open, [id]: !open[id] });
  const goToOpportunities = () => dispatch(push(routes.DASHBOARD));

  useEffect(() => {
    dispatch(staticDataCreator.staticDataFetchStatesRequest());
    dispatch(
      staticDataCreator.staticDataFetchRequest({
        months: true,
        jobFunctions: true,
        majorCategories: true,
        languages: true,
        degrees: true,
        atsSystem: true,
      })
    );
    dispatch(staticDataCreator.getClassStandingRequest());
    setStaticDataRequested(true);
    return () => dispatch(staticDataCreator.resetStaticDataStore());
  }, []);

  useEffect(() => {
    if (staticDataRequested && !isLoadingStaticData) {
      dispatch(staticDataCreator.staticDataFetchIndustriesRequest());
      dispatch(staticDataCreator.staticDataFetchCountriesRequest());
    }
  }, [staticDataRequested, isLoadingStaticData]);

  const handleSaveImage = (name, image, setFieldValue) => {
    setFieldValue(name, image);
    setShowLogoEditor(false);
    setShowBannerEditor(false);
    setFieldValue(name === 'originalLogoUrl' ? 'logoUrl' : 'photoUrl', URL.createObjectURL(image));
  };

  const handleSave = values => {
    if (values.descriptionHTML) {
      const { description, ...rest } = values;
      const updatedValue = { ...rest, description: values.descriptionHTML };
      dispatch(opportunityCreator.opportunitySaveRequest(updatedValue));
    } else dispatch(opportunityCreator.opportunitySaveRequest(values));
  };

  return (
    <Formik
      onSubmit={values =>
        handleSave({
          ...values,
          validations: true,
          status: opportunity.status === status.INACTIVE && !opportunity.completed ? status.ACTIVE : opportunity.status,
          completed: true,
          description: values.descriptionHTML ? values.descriptionHTML : values.description,
        })
      }
      initialValues={opportunity}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {({ values, setFieldValue, errors, setFieldTouched, submitCount, handleSubmit, dirty }) => (
        <FormFormik>
          <OpportunityBanner
            company={company}
            opportunity={{ ...values, industries: values.originalIndustries }}
            editable
            showCompanyName={false}
            onEditLogo={() => setShowLogoEditor(true)}
            onEditBanner={() => setShowBannerEditor(true)}
          />
          <ImageEditor
            onConfirm={image => handleSaveImage('originalPhotoUrl', image, setFieldValue)}
            image={values.photoUrl}
            show={showBannerEditor}
            onDismiss={() => setShowBannerEditor(false)}
            borderRadius={0}
            className="image-editor"
            isTheImageSaved={!values.originalPhotoUrl}
            height={200}
            width={900}
          />
          <ImageEditor
            onConfirm={image => handleSaveImage('originalLogoUrl', image, setFieldValue)}
            image={values.logoUrl}
            show={showLogoEditor}
            onDismiss={() => setShowLogoEditor(false)}
            borderRadius={100}
            style={{ height: '142px', width: '142px' }}
            isTheImageSaved={!values.originalLogoUrl}
          />
          <Hr $bsStyle={{ marginBottom: 0 }} />
          <DetailsSection
            onCollapse={handleCollapse}
            isValid={getIsValid(model.OPPORTUNITY, opportunitySection.DETAILS, errors)}
            open={open.details}
            values={values}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            showValidateIcon={!!submitCount > 0}
          />
          <AdministrationSection
            onCollapse={handleCollapse}
            isValid={getIsValid(model.OPPORTUNITY, opportunitySection.ADMINISTRATION, errors)}
            open={open.administration}
            values={values}
            setFieldValue={setFieldValue}
            showValidateIcon={!!submitCount > 0}
          />
          <QualificationsSection
            onCollapse={handleCollapse}
            isValid={getIsValid(model.OPPORTUNITY, opportunitySection.QUALIFICATIONS, errors)}
            open={open.qualifications}
            values={values}
            initialValues={opportunity}
            setFieldValue={setFieldValue}
            showValidateIcon={!!submitCount > 0}
          />
          <Row center $bsStyle={{ margin: '20px 0', flexWrap: 'wrap' }}>
            <ButtonContainer>
              <LoadingButton
                component={CustomButton}
                color={colors.ORANGE}
                onClick={() => {
                  setOpen(defaultOpenConfig);
                  handleSubmit();
                }}
                label="save"
                isLoading={isSaving}
              />
            </ButtonContainer>
            {!opportunity.completed && (
              <ButtonContainer>
                <LoadingButton
                  component={CustomButton}
                  color={colors.BLUE}
                  onClick={() => {
                    setOpen(defaultOpenConfig);
                    handleSave({ ...values, validations: false });
                  }}
                  label="save Draft"
                  isLoading={isSaving}
                  disabled={!dirty}
                  $bsStyle={{
                    lineHeight: 'initial',
                  }}
                  greyDisabled
                />
              </ButtonContainer>
            )}
            <ButtonContainer>
              <CustomButton color={colors.LIGHT_BLUE} onClick={goToOpportunities}>
                Cancel
              </CustomButton>
            </ButtonContainer>
          </Row>
          {error && <ErrorMessage messageError={error} />}
        </FormFormik>
      )}
    </Formik>
  );
};

OpportunityEditor.propTypes = {
  opportunity: opportunityProps.isRequired,
};

export default OpportunityEditor;
