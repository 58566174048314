import { Column } from '@hivediversity/common-lib/base';
import { CloseButton, CustomButton } from '@hivediversity/common-lib/buttons';
import { LoadingPage, Profile, Resume } from '@hivediversity/common-lib/components';
import { colors, opportunityTypes } from '@hivediversity/common-lib/constant';
import { push, getLocation } from 'connected-react-router';
import lowerCase from 'lodash/lowerCase';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Modal } from 'components';
import ConfirmCandidateEmail from 'components/applicant-card/confirm-candidate-modal';
import { contactTags as contactTagsEnums, routes } from 'constant';
import { useContactTag } from 'hooks';
import applicantCreator from 'store/applicant/actions';
import { getStudent, getIsLoading, getError, getApplicantEmail } from 'store/applicant/selectors';
import opportunityCreator from 'store/opportunity/actions';
import { getOpportunity, getApplicant } from 'store/opportunity/selectors';
import staticDataCreator from 'store/static-data/actions';
import { getStaticData } from 'store/static-data/selectors';
import { transformRoute } from 'utils';

import { Error, FixedContainer } from './styled';

const StudentModals = () => {
  const dispatch = useDispatch();
  const student = useSelector(getStudent);
  const applicant = useSelector(getApplicant);
  const opportunity = useSelector(getOpportunity);
  const [showResume, setShowResume] = useState(false);
  const isLoading = useSelector(getIsLoading);
  const { opportunityId, applicantId } = useParams();
  const location = useSelector(getLocation);
  const error = useSelector(getError);
  const email = useSelector(getApplicantEmail);
  const { contactTags } = useSelector(getStaticData);
  const [
    setContactTag,
    setShowContactTags,
    acceptModal,
    dismissModal,
    showContactTags,
    showConfirmContactTagModal,
    applicantToSetTag,
  ] = useContactTag(opportunity, applicant);

  useEffect(() => {
    if (opportunityId && applicantId) {
      dispatch(applicantCreator.applicantFindStudentRequest(opportunityId, applicantId));
      dispatch(opportunityCreator.applicantFindRequest(opportunityId, applicantId));
      dispatch(opportunityCreator.opportunityFindRequest(opportunityId));
      dispatch(staticDataCreator.staticDataFetchRequest({ contactTags: true }));
    }
  }, []);

  const showProfilePictureIfPermittedInOpportunity = useMemo(() => {
    if (opportunity && opportunity.profilePictures) {
      return true;
    }
    return false;
  }, [opportunity]);

  return (
    <>
      {isLoading && <LoadingPage background="black" />}
      {!isLoading && student && applicant && (
        <>
          <ConfirmCandidateEmail
            show={showConfirmContactTagModal && applicantToSetTag === applicant.id}
            email={email}
            onAccept={emailText => acceptModal(emailText)}
            onDismiss={dismissModal}
          />
          <FixedContainer />
          <CloseButton
            onClick={() =>
              window.history.state?.state
                ? window.history.back()
                : dispatch(push(transformRoute(routes.OPPORTUNITY_APPLICANTS_TAB, { opportunityId })))
            }
            $bsStyle={{ position: 'fixed' }}
          />
          <Profile
            location={location}
            student={student}
            applicant={applicant}
            isEdit={false}
            goToMyResume={() => dispatch(push(transformRoute(routes.RESUME, { opportunityId, applicantId })))}
            viewDiversities={
              student.includeDiversityInclusionsResume && (opportunity?.viewDiversities || opportunity?.diversities)
            }
            viewProfilePicture={showProfilePictureIfPermittedInOpportunity}
            viewWhyCompany
            roundedImage
            showPhone
            showProfile
            hasHighlight={opportunity?.prioritizedBullets}
            showContactTagsSelector
            onContactTag={setContactTag}
            contactTags={
              opportunity?.hasExternalApplicationLink &&
              [opportunityTypes.JOB, opportunityTypes.INTERNSHIPS].includes(lowerCase(opportunity?.type))
                ? contactTags
                : contactTags?.filter(contactTag => contactTag.id !== contactTagsEnums.EXTERNAL_APPLICATION)
            }
            showContactTags={showContactTags}
            onSetShowContactTags={setShowContactTags}
          />
          <Modal show={showResume} fullPage onClose={() => setShowResume(false)}>
            <Resume applicantResumeUrl={applicant.resumeUrl} />
          </Modal>
        </>
      )}
      {error && (
        <Column $bsStyle={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Error>{error.message}</Error>
          <CustomButton
            onClick={() => dispatch(push(error.previousUrl))}
            color={colors.LIGHT_BLUE}
            $bsStyle={{ marginTop: '10px', textTransform: 'none', fontSize: '18px' }}
          >
            Go to Dashboard
          </CustomButton>
        </Column>
      )}
    </>
  );
};

export default StudentModals;
