import { Row } from '@hivediversity/common-lib/base';
import { CustomButton as Button } from '@hivediversity/common-lib/buttons';
import { Collapsible, FilePreview } from '@hivediversity/common-lib/components';
import { colors, companySection } from '@hivediversity/common-lib/constant';
import { companyProps } from '@hivediversity/common-lib/utils';
import { FieldArray } from 'formik';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import React, { Fragment, useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AddAndRemoveButtons, MultiText, Tooltip, UploadFile, WrapTextBox } from 'components';
import { placeholders, tooltipsTexts } from 'constant';
import { FILE_MAX_SIZE } from 'constant/messages';
import { getStaticData } from 'store/static-data/selectors';
import { InputLabel, MaxFileSize } from 'styled';
import { replaceArray } from 'utils';

import { WrapperInput, WrapperMulti, SubtitleLabel } from '../styled';

const People = ({ onCollapse, isValid, open, setFieldValue, values, showValidateIcon, setValues, initialValues }) => {
  const [welcomePreviewImage, setWelcomePreviewImage] = useState();
  const { socialNetworks } = useSelector(getStaticData);
  const inputFileWelcome = useRef(null);
  const inputFileVideos = useRef([]);

  useEffect(() => {
    if (size(values.videos) === 0 && setFieldValue && 'videos') {
      setFieldValue('videos', ['']);
    }
  }, [values.videos]);

  const handleChangeWelcome = file => {
    setValues({
      ...values,
      newWelcomeImageUploaded: true,
      welcomeImageFileName: file.name,
      welcomeImageUrl: file,
      deleteWelcomeImage: false,
    });
    setWelcomePreviewImage(URL.createObjectURL(file));
  };

  const handleRemoveWelcomeImage = () => {
    setValues({
      ...values,
      newWelcomeImageUploaded: false,
      welcomeImageFileName: null,
      welcomeImageUrl: null,
      deleteWelcomeImage: !!initialValues.welcomeImageUrl,
    });
    setWelcomePreviewImage(undefined);
  };

  const handleRemoveVideos = (videoToRemove, videoIndex) => {
    const videos = replaceArray(values.videos, videoIndex, {
      id: videoToRemove.id,
      toDelete: true,
    });
    if (videos.every(video => video.toDelete)) {
      videos.push({ title: '', url: '' });
    }
    setFieldValue('videos', videos);
  };

  return (
    <Collapsible
      id={companySection.PEOPLE}
      onCollapse={onCollapse}
      title="People"
      isValid={isValid}
      open={open}
      showValidateIcon={showValidateIcon}
    >
      <WrapperInput>
        <Row>
          <InputLabel>Primary Welcome from Leadership</InputLabel>
          <Tooltip text={tooltipsTexts.PRIMARY_WELCOME_FROM_LEADERSHIP} />
        </Row>
        <WrapperInput $bsStyle={{ marginBottom: 0 }}>
          <Row $bsStyle={{ padding: '0 0 10px 0' }}>
            <Button
              color={colors.VIOLET}
              onClick={() => inputFileWelcome.current.click()}
              $bsStyle={{ maxWidth: '184px', lineHeight: 'initial' }}
            >
              UPLOAD Picture/Video
            </Button>
            <MaxFileSize>{values.welcomeImageFileName ? `${values.welcomeImageFileName}` : FILE_MAX_SIZE}</MaxFileSize>
          </Row>
          <UploadFile
            types={['image', 'video']}
            accept="image/png, image/jpeg, video/mp4, video/mov, video/mpeg-4, video/quicktime"
            id="welcomeImageUrl"
            fileRef={inputFileWelcome}
            onChange={handleChangeWelcome}
          />
          {(welcomePreviewImage || values.welcomeImageUrl) && (
            <FilePreview
              fileName={values.welcomeImageFileName}
              url={welcomePreviewImage || values.welcomeImageUrl}
              imageHeight={200}
              imageWidth={200}
              onRemove={handleRemoveWelcomeImage}
            />
          )}
        </WrapperInput>
        <SubtitleLabel $bsStyle={{ fontStyle: 'italic' }}>Caption</SubtitleLabel>
        <WrapTextBox
          name="welcomeCaption"
          placeholder="Ex: Byron Slosar, HIVE Diversity CEO & Founder"
          wrapperContainerStyle={{ maxWidth: '496px', marginBottom: '20px' }}
        />
        <SubtitleLabel>Description</SubtitleLabel>
        <WrapTextBox
          name="welcome"
          placeholder="Ex: Even if you include a video above,
          please include a written welcome here as well."
          rows="10"
          component="textarea"
          $bsStyle={{ marginBottom: '16px', overflowY: 'scroll', height: '100%' }}
          maxLength={5000}
        />
      </WrapperInput>

      <WrapperInput marginBottom="30px">
        <Row>
          <InputLabel>Meet Our Team</InputLabel>
          <Tooltip text={tooltipsTexts.MEET_OUR_TEAM} />
        </Row>
        <FieldArray
          name="videos"
          render={arrayHelpers => (
            <div style={{ marginTop: '10px' }}>
              {values.videos.map((video, index) => (
                <Fragment key={index}>
                  {!video.toDelete && (
                    <Row key={index}>
                      <WrapperMulti style={{ marginBottom: 0, alignItems: 'flex-start' }}>
                        <Row $bsStyle={{ padding: '0  0 10px 0' }}>
                          <Button
                            color={colors.VIOLET}
                            onClick={() => inputFileVideos.current[index].click()}
                            $bsStyle={{ maxWidth: '184px', lineHeight: 'initial' }}
                          >
                            UPLOAD Picture/Video
                          </Button>
                          <MaxFileSize>{video.fileName ? video.fileName : FILE_MAX_SIZE}</MaxFileSize>
                        </Row>
                        <UploadFile
                          types={['image', 'video']}
                          accept="image/png, image/jpeg, video/mp4, video/mov, video/mpeg-4, video/quicktime"
                          onChange={file =>
                            setFieldValue(
                              'videos',
                              replaceArray(values.videos, index, {
                                title: video.title,
                                fileName: file.name,
                                file,
                                index,
                                previewUrl: URL.createObjectURL(file),
                                id: video.id,
                              })
                            )
                          }
                          id={`videos.${index}.id`}
                          /* eslint-disable-next-line no-return-assign */
                          fileRef={element => (inputFileVideos.current[index] = element)}
                        />
                        {(video.previewUrl || video.url) && (
                          <FilePreview
                            fileName={video.fileName}
                            url={video.previewUrl || video.url}
                            imageHeight={200}
                            imageWidth={200}
                            onRemove={index === 0 ? () => handleRemoveVideos(video, index) : undefined}
                          />
                        )}
                        <SubtitleLabel $bsStyle={{ fontStyle: 'italic' }}>Caption</SubtitleLabel>
                        <WrapTextBox
                          name={`videos.${index}.caption`}
                          placeholder="Ex: Our Media Team on Retreat in Denver!"
                          maxLength={255}
                          wrapperContainerStyle={{
                            maxWidth: '496px',
                            marginBottom: '20px',
                            width: '100%',
                          }}
                        />
                        <SubtitleLabel>Description</SubtitleLabel>
                        <WrapTextBox
                          name={`videos.${index}.title`}
                          placeholder="In addition to the video above, please also include a
                          written welcome/statement for those users who cannot access video content."
                          component="textarea"
                          rows={10}
                          $bsStyle={{
                            paddingRight: '35px',
                            marginBottom: '20px',
                            overflowY: 'scroll',
                          }}
                          wrapperContainerStyle={{
                            height: '100%',
                            flex: 1,
                            width: '100%',
                          }}
                          maxLength={5000}
                        />
                        <Row $bsStyle={{ flexWrap: 'wrap', marginBottom: '10px' }}>
                          <AddAndRemoveButtons
                            onAdd={() =>
                              arrayHelpers.push({
                                caption: null,
                                title: null,
                              })
                            }
                            onRemove={() =>
                              setFieldValue(
                                'videos',
                                replaceArray(values.videos, index, {
                                  id: video.id,
                                  toDelete: true,
                                })
                              )
                            }
                            showRemoveButton={values.videos.filter(currentVideo => !currentVideo.toDelete).length > 1}
                            showAddMore={values.videos.length - 1 === index}
                          />
                        </Row>
                      </WrapperMulti>
                    </Row>
                  )}
                </Fragment>
              ))}
            </div>
          )}
        />
      </WrapperInput>

      <WrapperInput marginBottom="35px">
        <Row>
          <InputLabel>Our Favorite Influencers</InputLabel>
          <Tooltip text={tooltipsTexts.OUR_FAVORITE_INFLUENCERS} />
        </Row>
        <MultiText
          name="influencers"
          value={values.influencers}
          setFieldValue={setFieldValue}
          inputs={[
            {
              id: 1,
              placeholder: 'Name',
              name: 'name',
            },
            {
              id: 2,
              placeholder: '@full_handle_here',
              name: 'handle',
            },
            {
              id: 3,
              placeholder: placeholders.URL_START_WITH_HTTP,
              name: 'url',
            },
          ]}
          dropdowns={[
            {
              id: 1,
              options: () => socialNetworks,
              name: 'socialNetwork',
              placeholder: 'Select Social Network',
              onChange: setFieldValue,
              isSearchable: false,
              style: { minWidth: '250px', maxWidth: '300px', marginRight: '24px' },
            },
          ]}
          onAdd={arrayHelpers =>
            arrayHelpers.push({
              name: '',
              handle: '',
              url: '',
              socialNetwork: null,
            })
          }
        />
      </WrapperInput>
    </Collapsible>
  );
};

People.propTypes = {
  onCollapse: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  showValidateIcon: PropTypes.bool,
  initialValues: companyProps.isRequired,
  values: companyProps.isRequired,
};

People.defaultProps = {
  showValidateIcon: false,
};

export default People;
