import { createReducer } from 'reduxsauce';

import { statTypes } from 'store/stat/actions';
import initialState from 'store/stat/initial-state';

const getStatsRequest = state => ({
  ...state,
  isLoading: true,
  error: null,
});

const getStatsSuccess = (state, { percentage }) => ({
  ...state,
  isLoading: false,
  error: null,
  percentage,
});

const getStatsError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

const reducer = createReducer(initialState, {
  [statTypes.GET_STATS_REQUEST]: getStatsRequest,
  [statTypes.GET_STATS_SUCCESS]: getStatsSuccess,
  [statTypes.GET_STATS_ERROR]: getStatsError,
});

export default reducer;
