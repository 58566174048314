export const aboutUsFields = [
  'description',
  'type',
  'urlAddress',
  'numberOfEmployees',
  'industries',
  'jobFunctions',
  'overallShortStatement',
  'headquarterLocation',
  'socialMedias',
  'internalDivisions',
  'subsidiaries',
];

export const administrationFields = ['pointOfContact', 'aggregateDataHive'];

export const cultureFields = [
  'diversityMean',
  'hiveCulturalKeywords',
  'missionLink',
  'diversityMeanLink',
  'socialImpactLink',
];

export const peopleFields = ['influencers', 'title'];

export const whatsTheBuzzFields = ['recentPress'];

export const flightGuideFields = ['talentGuideLink', 'undergraduateLink'];
