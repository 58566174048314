import { Row } from '@hivediversity/common-lib/base';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Dropdown, ErrorMessage, AddAndRemoveButtons } from 'components';
import { replaceArray } from 'utils';

const MultiSelect = ({
  values,
  name,
  placeholder,
  options,
  isLoading,
  setFieldValue,
  showIndicatorSeparator,
  noFormik,
  isRequired,
  getOptionLabel,
  getOptionValue,
  isArray,
}) => {
  const names = new Set(values.map(({ name }) => name));

  useEffect(() => {
    if (values.length === 0 && setFieldValue && name) {
      setFieldValue(name, isArray ? [''] : [{ id: 0, name: '' }]);
    }
  }, [values]);

  return (
    <>
      <FieldArray
        name={name}
        render={arrayHelpers =>
          values.map((entity, index) => {
            const uniqOptions = options.filter(({ name }) => entity.name === name || !names.has(name));

            return (
              <Row key={index} $bsStyle={{ flexWrap: 'wrap' }}>
                <Dropdown
                  name={`${name}.${index}.name`}
                  onChange={newValue =>
                    setFieldValue(
                      name,
                      replaceArray(values, index, isArray ? getOptionValue(newValue) : newValue || { id: 0, name: '' })
                    )
                  }
                  isClearable={index === 0 && values.length === 1 && !isRequired}
                  value={isArray ? values[index] : values[index].id}
                  placeholder={placeholder}
                  isLoading={isLoading}
                  isObject
                  options={uniqOptions}
                  $bsStyle={{
                    padding: 0,
                    width: '100%',
                    textAlign: 'center',
                    maxWidth: '400px',
                    marginRight: '24px',
                  }}
                  showIndicatorSeparator={showIndicatorSeparator}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue}
                />
                <AddAndRemoveButtons
                  onAdd={() => arrayHelpers.push(isArray ? '' : { id: 0, name: '' })}
                  onRemove={() => arrayHelpers.remove(index)}
                  showRemoveButton={values.length > 1}
                  showAddMore={values.length - 1 === index}
                />
              </Row>
            );
          })
        }
      />
      {!noFormik && <ErrorMessage control={name} />}
    </>
  );
};

MultiSelect.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })),
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  showIndicatorSeparator: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  $bsStyle: PropTypes.shape({}),
  noFormik: PropTypes.bool,
  isRequired: PropTypes.bool,
  isArray: PropTypes.bool,
};

MultiSelect.defaultProps = {
  placeholder: '',
  options: [],
  isLoading: false,
  showIndicatorSeparator: false,
  $bsStyle: null,
  noFormik: false,
  isArray: false,
  isRequired: true,
  getOptionLabel: option => option.name,
  getOptionValue: option => option.id,
  values: [],
};

export default MultiSelect;
