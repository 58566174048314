import camelCase from 'lodash/camelCase';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';

import { apiRoutes, defaultIndustry } from 'constant';
import {
  buildQueryString,
  formatDate,
  parseEmptyStringToUndefined,
  transformRoute,
  groupIndustriesByVertical,
} from 'utils';

import { del, getOrCached, post, put, upload } from './http';

const getIndustries = (industries = []) => {
  if (industries.length === 0) {
    return [defaultIndustry];
  }
  return groupIndustriesByVertical(
    industries.map(industry => ({
      verticalId: industry.industryId,
      industryId: industry.subIndustryId,
    }))
  );
};

export const requestOpportunities = async (filters, entity) => {
  const { data } = await getOrCached(`${apiRoutes.OPPORTUNITIES}${buildQueryString(filters)}`);
  return map(data, opportunity => ({
    ...parseEmptyStringToUndefined(opportunity),
    createdAt: formatDate(opportunity.createdAt).toLocaleDateString(),
    entity,
    createdBy: opportunity.createdBy?.username ? opportunity.createdBy : {},
    applicants: opportunity.applicants || [],
  }));
};

export const requestOpportunity = async (opportunityId, term, skip) => {
  const { opportunity, message } = await getOrCached(
    `${transformRoute(apiRoutes.OPPORTUNITY_WITH_ID, {
      id: opportunityId,
    })}${buildQueryString({ term, skip })}`
  );
  return {
    opportunity: {
      adminUsers: [],
      ...parseEmptyStringToUndefined(opportunity),
      originalIndustries: opportunity.industries,
      type: camelCase(opportunity.type),
      createdAt: formatDate(opportunity.createdAt).toLocaleDateString(),
      expectedDateMonth: opportunity.expectedDateMonth ? opportunity.expectedDateMonth : new Date().getMonth() + 1,
      expectedDateYear: opportunity.expectedDateYear ? opportunity.expectedDateYear : new Date().getFullYear(),
      startDay: opportunity.startDay ? opportunity.startDay : new Date().getDate(),
      startMonth: opportunity.startMonth ? opportunity.startMonth : new Date().getMonth() + 1,
      startYear: opportunity.startYear ? opportunity.startYear : new Date().getFullYear(),
      location: isEmpty(opportunity.location)
        ? {
            type: undefined,
            stateCode: '',
            cityId: null,
            cityName: '',
            countryId: null,
            countryName: '',
            zipCode: '',
            international: false,
          }
        : opportunity.location,
      locations: isEmpty(opportunity.locations)
        ? [
            {
              type: undefined,
              stateCode: '',
              cityId: null,
              cityName: '',
              countryId: null,
              countryName: '',
              zipCode: '',
              international: false,
            },
          ]
        : opportunity.locations,
      languages: isEmpty(opportunity.languages) ? [] : opportunity.languages,
      majorCategories: isEmpty(opportunity.majorCategories) ? [] : opportunity.majorCategories,
      industries: isEmpty(opportunity.industries) ? [defaultIndustry] : getIndustries(opportunity.industries),
      profilePictures: isBoolean(opportunity.profilePictures) ? opportunity.profilePictures : true,
      diversities: isBoolean(opportunity.diversities) ? opportunity.diversities : true,
      whyOurCompany: isBoolean(opportunity.whyOurCompany) ? opportunity.whyOurCompany : true,
      hasTranscript: isBoolean(opportunity.hasTranscript) ? opportunity.hasTranscript : true,
      hasPortfolio: isBoolean(opportunity.hasPortfolio) ? opportunity.hasPortfolio : true,
      prioritizedBullets: isBoolean(opportunity.prioritizedBullets) ? opportunity.prioritizedBullets : true,
      description:
        opportunity.description && !/<\/?[a-z][\s\S]*>/i.test(opportunity.description)
          ? `<p>${opportunity.description}</p>`
          : opportunity.description,
    },
    message,
  };
};

export const requestCreateNote = async (note, applicantId) => {
  const newNote = await post(transformRoute(apiRoutes.CREATE_APPLICANT_NOTE, { id: applicantId }), {
    note,
  });
  return newNote;
};

export const requestDeleteNote = async noteId => {
  const newNote = await del(transformRoute(apiRoutes.DELETE_APPLICANT_NOTE, { id: noteId }), null);
  return newNote;
};

export const requestSave = async opportunity => {
  const opportunityValues = {
    ...opportunity,
    completed: opportunity.completed,
    validations: opportunity.validations,
    isCopy: opportunity.isCopy && !opportunity.id,
  };
  if (!opportunity.isStatusUpdate) {
    opportunityValues.industries = opportunity.industries.flatMap(industry =>
      industry.subIndustries.map(subIndustry => ({
        subIndustryId: subIndustry,
        industryId: industry.id,
      }))
    );
  }
  const { opportunity: data, message } = opportunity.id
    ? await put(transformRoute(apiRoutes.OPPORTUNITY_WITH_ID, { id: opportunity.id }), opportunityValues)
    : await post(apiRoutes.OPPORTUNITY, opportunityValues);

  if (data) {
    return {
      opportunity: {
        ...data,
        type: camelCase(data.type),
        createdAt: formatDate(data.createdAt).toLocaleDateString(),
        originalIndustries: data.industries,
        industries: getIndustries(data.industries),
      },
    };
  }

  return {
    opportunity: data,
    message,
  };
};

export const requestDeleteOpportunity = opportunity =>
  del(transformRoute(apiRoutes.OPPORTUNITY_WITH_ID, { id: opportunity }));

export const uploadFileRequest = (opportunityId, file) => {
  const formData = new window.FormData();
  if (file.typeImage === 'photo') {
    formData.append('photoUrl', file.files, 'blob.png');
    return upload(transformRoute(apiRoutes.OPPORTUNITY_PHOTO, { id: opportunityId }), formData);
  }
  if (file.typeImage === 'teamMedia') {
    formData.append('teamMedia', file.files);
    return upload(transformRoute(apiRoutes.TEAM_MEDIA, { id: opportunityId }), formData);
  }
  formData.append('logoUrl', file.files, 'blob.png');
  return upload(transformRoute(apiRoutes.OPPORTUNITY_LOGO, { id: opportunityId }), formData);
};

export const removeFileRequest = (opportunityId, typeImage) => {
  if (typeImage === 'teamMedia') {
    return del(transformRoute(apiRoutes.TEAM_MEDIA, { id: opportunityId }));
  }
  return del(transformRoute(apiRoutes.COWORKERS_VIDEO, { id: opportunityId }));
};

export const getPotentialApplicants = opportunity => post(apiRoutes.POTENTIAL_APPLICANTS, opportunity);

export const getTotalApplicants = opportunityId =>
  getOrCached(transformRoute(apiRoutes.OPPORTUNITY_APPLICANTS, { id: opportunityId }));

export const requestSaveTag = (applicantId, tagId, isContactTag) => {
  if (isContactTag) {
    return put(transformRoute(apiRoutes.UPDATE_CONTACT_TAG, { id: applicantId }), {
      contactTag: tagId,
    });
  }
  return put(transformRoute(apiRoutes.APPLICANT, { id: applicantId }), { tag: tagId });
};

export const requestSendApplicantEmail = (applicantId, email) =>
  put(transformRoute(apiRoutes.CONFIRM_SEND_APPLICANT_EMAIL, { id: applicantId }), {
    message: email,
  });

export const requestOpportunityApplicants = (opportunityId, filters = {}) => {
  const searchParams = new URLSearchParams();
  const currentValues = JSON.parse(JSON.stringify(filters));
  mapValues(currentValues, (value, key) => {
    if (!value && !isBoolean(value)) {
      return;
    }
    if (isArray(value) && value.length > 1) {
      value.forEach(currentValue => {
        searchParams.append(`${key}[]`, currentValue);
      });
    } else if (isArray(value) && value.length === 1) {
      searchParams.set(`${key}`, value[0]);
    } else if (!isArray(value)) {
      searchParams.set(`${key}`, value);
    }
  });
  return getOrCached(
    `${transformRoute(apiRoutes.OPPORTUNITY_APPLICANTS_LIST, {
      id: opportunityId,
    })}?${decodeURIComponent(searchParams.toString())}`
  );
};

export const requestSaveOpportunitiesExpired = opportunities =>
  post(apiRoutes.EXPIRED_OPPORTUNITIES, { opportunities });

export const remindLaterOrDeclineOpportunitiesRequest = data => put(apiRoutes.REMIND_LATER_OR_DECLINE, data);

export const requestFindApplicant = (opportunityId, applicantId) =>
  getOrCached(transformRoute(apiRoutes.OPPORTUNITY_APPLICANT, { opportunityId, applicantId }));
