import { Row } from '@hivediversity/common-lib/base';
import { CustomButton } from '@hivediversity/common-lib/buttons';
import { colors } from '@hivediversity/common-lib/constant';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import staticDataCreator from 'store/static-data/actions';
import { getIsLoadingStates, getStaticData, getCities } from 'store/static-data/selectors';
import { DropdownContainer } from 'styled';

import Dropdown from '../dropdown';
import WrapTextBox from '../wrap-text-box';

const UsInternationalDropdowns = ({
  names,
  values,
  setFieldValue,
  isClearable,
  onMore,
  onClear,
  showAddAnotherCountry,
  showZipCode,
  disabled,
  showRemoveButton,
  showAddButton,
}) => {
  const dispatch = useDispatch();
  const { states, countries } = useSelector(getStaticData);
  const isLoadingStates = useSelector(getIsLoadingStates);
  const cities = useSelector(getCities);

  const getCitiesByState = state => cities?.flat()?.filter(city => city?.stateId === state) || [];

  const handleChangeState = state => {
    if (state) {
      const currentCities = getCitiesByState(state);
      if (currentCities.length === 0) {
        dispatch(staticDataCreator.staticDataFetchCitiesRequest([state]));
      }
    }
  };

  return (
    <>
      <Row $bsStyle={{ flexWrap: 'wrap', marginTop: '25px' }}>
        {!values.international ? (
          <>
            <DropdownContainer $bsStyle={{ marginRight: '10px' }}>
              <Dropdown
                name={names.state}
                onChange={(control, value) => {
                  setFieldValue(control, value);
                  handleChangeState(value);
                }}
                isLoading={isLoadingStates}
                value={values.stateId}
                showIndicatorSeparator
                placeholder="Select State"
                options={states}
                $bsStyle={{
                  padding: 0,
                  textAlign: 'center',
                  width: '100%',
                  maxWidth: '300px',
                }}
                isClearable={isClearable}
                disabled={disabled}
              />
            </DropdownContainer>
            <DropdownContainer $bsStyle={{ marginRight: '10px' }}>
              <Dropdown
                name={names.city}
                onChange={setFieldValue}
                value={values.cityId}
                placeholder="Select City for US"
                options={getCitiesByState(values.stateId)}
                showIndicatorSeparator
                $bsStyle={{
                  padding: 0,
                  minWidth: '90px',
                  textAlign: 'center',
                  maxWidth: '300px',
                }}
                isClearable={isClearable}
                disabled={disabled}
              />
            </DropdownContainer>
            {showZipCode && (
              <WrapTextBox
                name={names.zipCode}
                value={values.zipCode}
                placeholder="Zip Code"
                wrapperContainerStyle={{
                  width: '100%',
                  marginBottom: '10px',
                  minWidth: '250px',
                  maxWidth: '250px',
                  marginRight: '10px',
                }}
                onChange={e => setFieldValue(e.target.name, e.target.value)}
                maxLength={255}
                disabled={disabled}
              />
            )}
          </>
        ) : (
          <>
            <DropdownContainer $bsStyle={{ marginRight: '10px' }}>
              <Dropdown
                name={names.country}
                onChange={setFieldValue}
                value={values.countryId}
                placeholder="Select Country"
                options={countries}
                showIndicatorSeparator
                $bsStyle={{
                  padding: 0,
                  textAlign: 'center',
                  width: '100%',
                  maxWidth: '300px',
                }}
                isClearable={isClearable}
                disabled={disabled}
              />
            </DropdownContainer>
            <WrapTextBox
              disabled={disabled}
              name={showAddAnotherCountry ? names.anotherCountry : names.anotherCity}
              placeholder={showAddAnotherCountry ? 'Entry Country' : 'Enter City'}
              value={showAddAnotherCountry ? values.countryName : values.cityName}
              onChange={e => setFieldValue(e.target.name, e.target.value)}
              maxLength={255}
              wrapperContainerStyle={{
                margin: '0 10px 10px 0',
                maxHeight: '150px',
                minWidth: '250px',
              }}
              errorMessageStyle={{ marginBottom: '10px' }}
            />
          </>
        )}
        {showRemoveButton && (
          <CustomButton
            $bsStyle={{
              fontSize: '40px',
              maxWidth: '30px',
              paddingBottom: '5px',
              marginRight: '10px',
            }}
            onClick={onClear}
            color={colors.ORANGE}
          >
            -
          </CustomButton>
        )}
        {showAddButton && (
          <CustomButton
            $bsStyle={{ fontSize: '40px', maxWidth: '30px', paddingTop: '3px' }}
            color={colors.VIOLET}
            onClick={onMore}
          >
            +
          </CustomButton>
        )}
      </Row>
    </>
  );
};

UsInternationalDropdowns.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  onMore: PropTypes.func,
  onClear: PropTypes.func,
  names: PropTypes.shape({
    state: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    anotherCountry: PropTypes.string.isRequired,
    anotherCity: PropTypes.string.isRequired,
    zipCode: PropTypes.string,
  }).isRequired,
  values: PropTypes.shape({
    countryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stateId: PropTypes.string,
    cityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    international: PropTypes.bool,
    countryName: PropTypes.string,
    cityName: PropTypes.string,
    zipCode: PropTypes.string,
  }).isRequired,
  isClearable: PropTypes.bool,
  showAddAnotherCountry: PropTypes.bool,
  showZipCode: PropTypes.bool,
  disabled: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
  showAddButton: PropTypes.bool,
};

UsInternationalDropdowns.defaultProps = {
  isClearable: true,
  showAddAnotherCountry: false,
  showZipCode: false,
  disabled: false,
  onClear: undefined,
  onMore: undefined,
  showAddButton: true,
  showRemoveButton: false,
};

export default UsInternationalDropdowns;
