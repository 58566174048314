import FileSaver from 'file-saver';
import { takeLatest, call, put } from 'redux-saga/effects';

import {
  requestFindStudent,
  requestSendApplicantLink,
  requestGetApplicantEmail,
  requestDownloadApplicantsCSV,
} from 'services/applicant';
import applicantCreator, { applicantTypes } from 'store/applicant/actions';

export function* findStudent({ opportunityId, applicantId }) {
  try {
    const data = yield call(requestFindStudent, opportunityId, applicantId);
    yield put(applicantCreator.applicantFindStudentSuccess(data));
  } catch (error) {
    yield put(applicantCreator.applicantFindStudentError(error));
  }
}

export function* sendApplicantLink({ applicantId }) {
  try {
    yield call(requestSendApplicantLink, applicantId);
    yield put(applicantCreator.applicantSendLinkSuccess());
  } catch (error) {
    yield put(applicantCreator.applicantSendLinkError(error));
  }
}

export function* getApplicantEmail({ applicantId, tagId }) {
  try {
    const email = yield call(requestGetApplicantEmail, applicantId, tagId);
    yield put(applicantCreator.getApplicantEmailSuccess(email));
  } catch (error) {
    yield put(applicantCreator.getApplicantEmailError(error));
  }
}

export function* downloadCSV({ opportunityId, opportunityName }) {
  try {
    const csv = yield call(requestDownloadApplicantsCSV, opportunityId);
    yield call(FileSaver.saveAs, csv, `${opportunityName}.csv`);
    yield put(applicantCreator.downloadApplicantsCSVSuccess());
  } catch (error) {
    yield put(applicantCreator.downloadApplicantsCSVError(error));
  }
}

export function* applicantSaga() {
  yield takeLatest(applicantTypes.APPLICANT_FIND_STUDENT_REQUEST, findStudent);
  yield takeLatest(applicantTypes.APPLICANT_SEND_LINK_REQUEST, sendApplicantLink);
  yield takeLatest(applicantTypes.GET_APPLICANT_EMAIL_REQUEST, getApplicantEmail);
  yield takeLatest(applicantTypes.DOWNLOAD_APPLICANTS_CSV_REQUEST, downloadCSV);
}
