import PropTypes from 'prop-types';
import React, { useState } from 'react';

const UploadFile = ({ id, types, accept, fileRef, onChange, size }) => {
  const [exceedsSizeLimit, setExceedsSizeLimit] = useState(false);

  const handleChange = e => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.target.files && e.target.files[0];
    if (!file) {
      return;
    }
    const type = file.type.split('/')[0];
    e.target.value = null;
    let defaultSize = size;
    if (!defaultSize && type === 'video' && types.includes('video')) {
      defaultSize = 80;
    }
    if (!defaultSize && type === 'image' && types.includes('image')) {
      defaultSize = 10;
    }
    const maxAllowedSize = defaultSize * 1024 * 1024;
    if (file.size > maxAllowedSize) {
      setExceedsSizeLimit(true);
    }
    if (file && types.includes(type) && file.size <= maxAllowedSize) {
      onChange(file);
      setExceedsSizeLimit(false);
    }
  };

  return (
    <>
      <input id={id} type="file" accept={accept} ref={fileRef} onChange={handleChange} hidden />
      {exceedsSizeLimit && <p style={{ fontSize: '20px', color: 'red' }}>File exceeds size limit</p>}
    </>
  );
};

UploadFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  accept: PropTypes.string.isRequired,
  fileRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]).isRequired,
  size: PropTypes.number,
};

UploadFile.defaultProps = {
  size: undefined,
};

export default UploadFile;
