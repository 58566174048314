import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';

import { WrapperContainer, StyledTextBox } from './styled';
import ErrorMessage from '../error-message';

const WrapTextBox = ({
  name,
  showErrorMessage,
  errorMessageStyle,
  wrapperContainerStyle,
  debounce: shouldDebounce,
  onChange,
  ...props
}) => {
  const debounceSearch = debounce(e => onChange(e), 500);

  const handleChange = e => {
    if (shouldDebounce) {
      debounceSearch(e);
    } else {
      onChange(e);
    }
  };

  return (
    <WrapperContainer $bsStyle={wrapperContainerStyle}>
      {onChange ? (
        <StyledTextBox name={name} onChange={handleChange} {...props} />
      ) : (
        <StyledTextBox name={name} {...props} />
      )}
      {showErrorMessage && <ErrorMessage control={name} style={{ ...errorMessageStyle, marginTop: '10px' }} />}
    </WrapperContainer>
  );
};

WrapTextBox.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  showErrorMessage: PropTypes.bool,
  errorMessageStyle: PropTypes.shape({}),
  wrapperContainerStyle: PropTypes.shape({}),
  debounce: PropTypes.bool,
};

WrapTextBox.defaultProps = {
  showErrorMessage: true,
  errorMessageStyle: {},
  wrapperContainerStyle: undefined,
  onChange: undefined,
  debounce: false,
};

export default WrapTextBox;
