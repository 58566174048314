import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  flex-direction: column;
  color: white;
  width: 100%;
  padding-left: 80px;
  padding-right: 32px;
  background: ${({ background }) => background || '#000'};
  overflow-x: auto;
  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
`;
