import * as Cache from 'cache';
import jwtDecode from 'jwt-decode';

import { routes } from 'constant';

import { getToken, setToken } from './storage';

const API = process.env.REACT_APP_URL_BASE;
const cache = new Cache(5 * 60 * 1000); // Cache TTL - 5 minutes in milliseconds.

export const getValidToken = async () => {
  const currentToken = getToken();
  if (currentToken) {
    const { exp } = jwtDecode(currentToken);
    if (exp >= new Date().getTime() / 1000) {
      return currentToken;
    }
    const { token } = await fetch(`${API}auth/employer/refresh`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${currentToken}`,
      },
    });
    setToken(token);
    return token;
  }
  return null;
};

export const downloadCSVFile = async url => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'text/csv',
      Accept: 'text/csv',
    },
  });
  return response.blob();
};

/**
 * Send a GET request to the given URL or fetch the result from cache.
 * @param {String} url a URL where the request is send.
 * @returns {Promise<any>} A promise with the response body when the request is completed.
 */
export const getOrCached = async (url, to = 'json') => {
  const key = url + '||' + to;
  const cached = await cache.get(key);
  if (cached) {
    return cached;
  }
  const result = await get(url, to);
  // Only cache if request succeeded.
  if (result.status === 200) {
    await cache.put(key, result);
  }
  return result;
};

/**
 * Send a GET request to the given URL.
 * @param {String} url a URL where the request is send.
 * @returns {Promise<any>} A promise with the response body when the request is completed.
 */
export const get = async (url, to = 'json') => {
  const response = await fetch(url, {
    headers: {
      authorization: `Bearer ${await getValidToken()}`,
    },
  });

  if (response.status === 404) {
    window.location.href = routes.ERROR_404;
  }

  return response[to]();
};

/**
 * Send a POST request to the given URL.
 * @param {string} url a URL where the request is send.
 * @param {any} body data to be included in the request body.
 * @returns {Promise<any>} A promise with the response body when the request is completed.
 */
export const post = async (url, body) => {
  const response = await fetch(url, {
    method: 'post',
    body: JSON.stringify(body),
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${await getValidToken()}`,
    },
  });
  return response.json();
};

/**
 * Send a PUT request to the given URL.
 * @param {string} url a URL where the request is send.
 * @param {any} body data to be included in the request body.
 * @returns {Promise<any>} A promise with the response body when the request is completed.
 */
export const put = async (url, body = {}) => {
  const response = await fetch(url, {
    method: 'put',
    body: JSON.stringify(body),
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${await getValidToken()}`,
    },
  });
  return response.json();
};

/**
 * Send a DELETE request to the given URL.
 * @param {string} url a URL where the request is send.
 * @param {any} body data to be included in the request body.
 * @returns {Promise<any>} A promise with the response body when the request is completed.
 */
export const del = async (url, body) => {
  const response = await fetch(url, {
    method: 'delete',
    body: JSON.stringify(body),
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${await getValidToken()}`,
    },
  });
  return response.json();
};

export const upload = async (url, formData) => {
  const response = await fetch(url, {
    method: 'post',
    body: formData,
    headers: {
      authorization: `Bearer ${await getValidToken()}`,
    },
  });
  return response.json();
};
