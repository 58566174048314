import createLinkPlugin from '@draft-js-plugins/anchor';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from '@draft-js-plugins/buttons';
import Editor from '@draft-js-plugins/editor';
import createToolbarPlugin, { Separator } from '@draft-js-plugins/static-toolbar';
import { EditorState, Entity, CompositeDecorator } from 'draft-js';
import { colorPickerPlugin } from 'draft-js-color-picker';
import { stateFromHTML } from 'draft-js-import-html';
import PropTypes from 'prop-types';
import React, { useState, useEffect, forwardRef } from 'react';

import './styles.css';

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;
const linkPlugin = createLinkPlugin();
const plugins = [staticToolbarPlugin, linkPlugin];

const Link = props => {
  // eslint-disable-next-line react/prop-types
  const { url } = Entity.get(props.entityKey).getData();
  return <a href={url}>{props.children}</a>;
};

const findLinkEntities = (contentBlock, callback) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && Entity.get(entityKey).getType() === 'LINK';
  }, callback);
};

export const options = {
  customInlineFn: (element, { Style }) => {
    if (element.style.color) {
      return Style(`CUSTOM_COLOR_${element.style.color}`);
    }
  },
};

const decorators = [{ strategy: findLinkEntities, component: Link }];

const DraftEditor = forwardRef(({ placeholder, value, showToolbar, name, onChange, baseEditorState }, ref) => {
  const [editorState, setEditorState] = useState(() => {
    if (baseEditorState != null) {
      return baseEditorState;
    }
    if (value != null && typeof value === 'string') {
      const state = stateFromHTML(value, options);

      return EditorState.createWithContent(state, new CompositeDecorator(decorators));
    }
    return EditorState.createEmpty();
  });
  const picker = colorPickerPlugin(setEditorState, () => editorState);

  useEffect(() => {
    if (value && /<\/?[a-z][\s\S]*>/i.test(value)) {
      const state = stateFromHTML(value, options);
      setEditorState(EditorState.createWithContent(state, new CompositeDecorator(decorators)));
    }
  }, [value]);

  const handleChange = e => {
    setEditorState(e);
    if (onChange && name) {
      onChange(name, e, picker);
    }
  };

  return (
    <>
      {showToolbar && (
        <Toolbar>
          {externalProps => (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <Separator {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
              <BlockquoteButton {...externalProps} />
              <linkPlugin.LinkButton {...externalProps} />
              {/* <div style={{ flex: '1 0 25%' }}>
                  <ColorPicker
                    toggleColor={color => {
                      console.log('----------------ran toggle --------------');
                      picker.addColor(color);
                      console.log('🚀 ~ file: index.js ~ line 112 ~ color', color);
                      onChange(name, editorState, picker);
                    }}
                    color={picker.currentColor(editorState)}
                  />
                  <button type="button" onClick={picker.removeColor}>
                    clear color
                  </button>
                </div> */}
            </div>
          )}
        </Toolbar>
      )}
      <Editor
        editorState={editorState}
        onChange={handleChange}
        placeholder={placeholder}
        ref={ref}
        decorators={decorators}
        plugins={plugins}
        customStyleFn={picker.customStyleFn}
      />
    </>
  );
});

DraftEditor.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  showToolbar: PropTypes.bool,

  // eslint-disable-next-line
  baseEditorState: PropTypes.object.isRequired,
};

DraftEditor.defaultProps = {
  onChange: undefined,
  name: undefined,
  placeholder: '',
  value: undefined,
  showToolbar: false,
};

DraftEditor.displayName = 'DraftEditor';

export default DraftEditor;
