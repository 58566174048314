import styled from 'styled-components';

export const TooltipContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  white-space: pre-wrap;
  z-index: ${({ $bsStyle }) => $bsStyle.zIndex || 6};
  &:hover:before {
    content: attr(data-text);
    position: absolute;
    top: -5px;
    left: 20px;
    margin-left: 15px;
    padding: 10px;
    border-radius: 10px;
    background: #000;
    color: #fff;
    display: flex;
    height: auto;
    @media screen and (max-width: 540px) {
      margin-right: ${({ position }) => (position === 'left' ? '-45px' : '15px')};
      top: ${({ position }) => (position === 'left' ? '25px' : '-5px')};
      left: ${({ position }) => (position === 'left' ? 'auto' : '20px')};
      right: ${({ position }) => (position === 'left' ? '105%' : '0')};
    }
    ${({ $bsStyle }) => $bsStyle || ''}
  }
`;

export const Image = styled.img.attrs({ alt: 'info' })`
  width: 20px;
  height: auto;
  border-radius: 10px;
  border: 1px solid #f5c252;
  margin-left: 10px;
  ${({ imageStyle }) => imageStyle || ''}
`;
