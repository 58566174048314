import styled from 'styled-components';

export const Layout = styled.div`
  background-color: #000;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 0 auto;
  background-size: 60px;
  background-repeat: no-repeat;
  background-position: right 20px top 20px;
  min-height: 100vh;
  scroll-behavior: smooth;
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`;
