import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  flex-wrap: wrap;
  min-width: 200px;
  max-width: 800px;
  width: 100%;
`;
