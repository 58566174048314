import { createReducer } from 'reduxsauce';

import { authTypes } from 'store/authorization/actions';
import { initialState } from 'store/authorization/initial-state';

const loginRequest = state => ({ ...state, isLoading: true, error: null, token: null, user: null });

const loginSuccess = (state, { user, token }) => ({
  ...state,
  user,
  token,
  isLoading: false,
  error: null,
});

const loginError = (state, { error }) => ({
  ...state,
  error,
  token: null,
  isLoading: false,
  data: null,
});

const getTokenRequest = state => ({
  ...state,
  isLoadingToken: true,
  error: null,
  token: null,
});

const getTokenSuccess = (state, { token }) => ({
  ...state,
  token,
  isLoadingToken: false,
  error: null,
});

const getTokenError = (state, { error }) => ({
  ...state,
  error,
  token: null,
  isLoadingToken: false,
});

const impersonateRequest = state => ({
  ...state,
  isLoadingToken: true,
  error: null,
  token: null,
});

const impersonateSuccess = (state, { token }) => ({
  ...state,
  token,
  isLoadingToken: false,
  error: null,
});

const impersonateError = (state, { error }) => ({
  ...state,
  error,
  token: null,
  isLoadingToken: false,
});

const reducer = createReducer(initialState, {
  [authTypes.AUTH_LOGIN_REQUEST]: loginRequest,
  [authTypes.AUTH_LOGIN_SUCCESS]: loginSuccess,
  [authTypes.AUTH_LOGIN_ERROR]: loginError,

  [authTypes.AUTH_GET_TOKEN_REQUEST]: getTokenRequest,
  [authTypes.AUTH_GET_TOKEN_SUCCESS]: getTokenSuccess,
  [authTypes.AUTH_GET_TOKEN_ERROR]: getTokenError,

  [authTypes.AUTH_IMPERSONATE_REQUEST]: impersonateRequest,
  [authTypes.AUTH_IMPERSONATE_SUCCESS]: impersonateSuccess,
  [authTypes.AUTH_IMPERSONATE_ERROR]: impersonateError,
});

export default reducer;
