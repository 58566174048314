import { Row } from '@hivediversity/common-lib/base';
import { Collapsible } from '@hivediversity/common-lib/components';
import { companySection } from '@hivediversity/common-lib/constant';
import { companyProps } from '@hivediversity/common-lib/utils';
import { FieldArray } from 'formik';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { AddAndRemoveButtons, WrapTextBox } from 'components';
import { placeholders } from 'constant';
import { InputLabel } from 'styled';

import { WrapperInput, WrapperMulti } from '../styled';

const WhatsTheBuzz = ({ onCollapse, isValid, open, values, setFieldValue, showValidateIcon }) => {
  useEffect(() => {
    if (size(values.recentPress) === 0 && setFieldValue && 'recentPress') {
      setFieldValue('recentPress', ['']);
    }
  }, []);

  return (
    <Collapsible
      id={companySection.WHATS_THE_BUZZ}
      onCollapse={onCollapse}
      title="What's the Buzz"
      isValid={isValid}
      open={open}
      showValidateIcon={showValidateIcon}
    >
      <WrapperInput marginBottom="30px">
        <InputLabel>Recent Press</InputLabel>
        <FieldArray
          name="recentPress"
          render={arrayHelpers => (
            <div style={{ marginTop: '10px' }}>
              {values.recentPress.map((entity, index) => (
                <Row key={index}>
                  <WrapperMulti style={{ marginBottom: '10px', alignItems: 'baseline' }}>
                    <WrapTextBox
                      name={`recentPress.${index}.url`}
                      placeholder={placeholders.URL_START_WITH_HTTP}
                      maxLength={255}
                      wrapperContainerStyle={{ maxWidth: '391px', width: '100%' }}
                    />
                    <Row $bsStyle={{ padding: '0  0 10px 0', marginTop: '16px' }}>
                      <WrapTextBox
                        name={`recentPress.${index}.title`}
                        placeholder="Check out all of the exciting news about our company!
                        One of the things were are most excited about is the recent article in
                        Forbes that highlights how we are empowering the next generation of talent
                        with an easier way to connect with companies who value diversity."
                        rows="10"
                        component="textarea"
                        wrapperContainerStyle={{ flex: 1, paddingRight: '35px' }}
                        $bsStyle={{ overflowY: 'scroll', height: '100%' }}
                        maxLength={5000}
                      />
                    </Row>
                    <Row $bsStyle={{ flexWrap: 'wrap' }}>
                      <AddAndRemoveButtons
                        onAdd={() =>
                          arrayHelpers.push({
                            url: '',
                            title: '',
                          })
                        }
                        onRemove={() => arrayHelpers.remove(index)}
                        showRemoveButton={values.recentPress.length > 1}
                        showAddMore={values.recentPress.length - 1 === index}
                      />
                    </Row>
                  </WrapperMulti>
                </Row>
              ))}
            </div>
          )}
        />
      </WrapperInput>
    </Collapsible>
  );
};

WhatsTheBuzz.propTypes = {
  onCollapse: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  showValidateIcon: PropTypes.bool,
  values: companyProps.isRequired,
};

WhatsTheBuzz.defaultProps = {
  showValidateIcon: false,
};

export default WhatsTheBuzz;
