import { CustomButton } from '@hivediversity/common-lib/buttons';
import { colors } from '@hivediversity/common-lib/constant';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ProgressBar } from 'components';
import { getProgressbarPercentage } from 'utils';

import {
  TitleLabel,
  Container,
  TitleWrapper,
  CtaWrapper,
  Title,
  ProfileViews,
  OpportunityViews,
  ViewsContainer,
} from './styled';

const OpportunityProgressBar = ({ progress, title, showTotal, showDetailsButton }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      {title && <TitleLabel>{title}</TitleLabel>}
      <Container>
        <TitleWrapper>
          {showTotal && <Title>{progress.applicants ? progress.applicants : progress.total}</Title>}
        </TitleWrapper>
        <ProgressBar completed={getProgressbarPercentage(progress.percentage)} height="100px" labelSize="30px" />
        <CtaWrapper>
          {!isEmpty(progress.diversities) && showDetailsButton && (
            <CustomButton onClick={() => setShowDetails(!showDetails)} color={colors.LIGHT_BLUE}>
              {showDetails ? 'Less' : 'Details'}
            </CustomButton>
          )}
        </CtaWrapper>
      </Container>
      <div style={{ width: '100%', maxWidth: '900px', alignSelf: 'center' }}>
        {showDetails &&
          progress.diversities.map((diversity, i) => (
            <Container key={i}>
              <TitleWrapper>
                <Title fontSize="22px" subItem>
                  {diversity.name}
                </Title>
              </TitleWrapper>
              <ViewsContainer>
                {progress.applicants ? (
                  <ProfileViews>{diversity.applicants}</ProfileViews>
                ) : (
                  <ProfileViews>{diversity.potential}</ProfileViews>
                )}
                <OpportunityViews>{diversity.total}</OpportunityViews>
              </ViewsContainer>
              <ProgressBar completed={diversity.percentage} />
            </Container>
          ))}
      </div>
    </>
  );
};

OpportunityProgressBar.propTypes = {
  title: PropTypes.string,
  progress: PropTypes.shape({
    total: PropTypes.number.isRequired,
    potential: PropTypes.number,
    applicants: PropTypes.number,
    percentage: PropTypes.number.isRequired,
    diversities: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        potential: PropTypes.number,
        applicants: PropTypes.number,
        total: PropTypes.number.isRequired,
        percentage: PropTypes.number.isRequired,
      })
    ),
  }),
  showTotal: PropTypes.bool,
  showDetailsButton: PropTypes.bool,
};

OpportunityProgressBar.defaultProps = {
  title: undefined,
  showTotal: true,
  showDetailsButton: true,
  progress: {},
};

export default OpportunityProgressBar;
