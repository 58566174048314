import ResumeDiversitiesImg from 'assets/png/resume-diversities.png';
import ResumeProfilePictureImg from 'assets/png/resume-profile-picture.png';
import ResumeWhyCompanyImg from 'assets/png/resume-why-company.png';
import ResumeImg from 'assets/png/resume.png';

import opportunityAdministrationModalsEntities from './opportunity-administration-modals-entities';

export default [
  {
    key: opportunityAdministrationModalsEntities.PROFILE_PICTURES,
    image: ResumeProfilePictureImg,
  },
  {
    key: opportunityAdministrationModalsEntities.DIVERSITIES,
    image: ResumeDiversitiesImg,
  },
  {
    key: opportunityAdministrationModalsEntities.WHY_COMPANY,
    image: ResumeWhyCompanyImg,
  },
  {
    key: opportunityAdministrationModalsEntities.PRIORITIZED_BULLETS,
    image: ResumeImg,
  },
];
