import { companySection, model, opportunitySection } from '@hivediversity/common-lib/constant';

import {
  companyAdministrationFields,
  aboutUsFields,
  cultureFields,
  flightGuideFields,
  peopleFields,
  whatsTheBuzzFields,
  administrationFields,
  detailsFields,
  qualificationsFields,
} from 'constant';

const getIsValid = (modelIn, section, errors) => {
  if (modelIn === model.OPPORTUNITY) {
    switch (section) {
      case opportunitySection.ADMINISTRATION:
        return administrationFields.every(field => !errors[field]);
      case opportunitySection.DETAILS:
        return detailsFields.every(field => !errors[field]);
      case opportunitySection.QUALIFICATIONS:
        return qualificationsFields.every(field => !errors[field]);
      default:
        return false;
    }
  }
  if (modelIn === model.COMPANY) {
    switch (section) {
      case companySection.ABOUT_US:
        return aboutUsFields.every(field => !errors[field]);
      case companySection.ADMINISTRATION:
        return companyAdministrationFields.every(field => !errors[field]);
      case companySection.CULTURE:
        return cultureFields.every(field => !errors[field]);
      case companySection.PEOPLE:
        return peopleFields.every(field => !errors[field]);
      case companySection.WHATS_THE_BUZZ:
        return whatsTheBuzzFields.every(field => !errors[field]);
      case companySection.FLIGHT_GUIDE:
        return flightGuideFields.every(field => !errors[field]);
      default:
        return false;
    }
  }
  return false;
};

export default getIsValid;
