import styled from 'styled-components';

import checkIcon from 'assets/svg/check.svg';

export const Check = styled.input`
  &:not(:checked),
  &:checked {
    position: absolute;
    left: -9999px;
  }
  &:checked ~ span {
    background-color: ${({ disabled }) => (disabled ? 'gray' : '#00d154')};
    background-image: url(${checkIcon});
    border: none;
  }
  &:checked ~ p {
    opacity: 1;
  }
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`;

export const Label = styled.label`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  padding: 7px 0;
  color: white;
  @media screen and (max-height: 800px) {
    padding: 1.5vh 1vh;
  }

  p {
    margin-top: 5px;
    font-weight: 700;
    @media screen and (max-height: 800px) {
      font-size: 2vh;
    }
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }

  span {
    content: '';
    height: 32px;
    width: 32px;
    display: block;
    flex-shrink: 0;
    margin-right: 20px;
    border-radius: 100%;
    border: 1px solid #fff;
    background-size: 50%;
    background: transparent no-repeat center center;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    @media screen and (max-height: 800px) {
      height: 4vh;
      width: 4vh;
      margin-right: 2vh;
    }
  }
`;

export const Container = styled.div`
  ${({ centered }) => (centered ? `display: flex; justify-content: center;` : '')};
  ${({ isHorizontal }) => (isHorizontal ? 'display: flex; flex-direction: row; flex-wrap: wrap;' : '')}
  margin-bottom: 10px;
  ${({ $bsStyle }) => $bsStyle || ''}

  & .checkbox-wrapper {
    ${({ isHorizontal }) => (!isHorizontal ? 'max-width: 100%;' : '')}
  }
`;

export const WrapperCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 180px;
  width: 100%;
`;
