import { Row } from '@hivediversity/common-lib/base';
import { MessageError } from '@hivediversity/common-lib/components';
import PropTypes from 'prop-types';
import React from 'react';

const ErrorMessage = ({ control, style, messageError }) => (
  <Row center>
    <MessageError
      messageError={messageError}
      control={control}
      $bsStyle={{
        backgroundColor: '#E99623',
        color: '#fff',
        fontWeight: 'bold',
        minWidth: '200px',
        height: 'auto',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: '16px',
        ...style,
      }}
    />
  </Row>
);

ErrorMessage.propTypes = {
  control: PropTypes.string,
  messageError: PropTypes.string,
  style: PropTypes.shape({}),
};

ErrorMessage.defaultProps = {
  style: {},
  control: undefined,
  messageError: undefined,
};

export default ErrorMessage;
