import PropTypes from 'prop-types';
import React from 'react';

import { Input as StyledInput } from './styled';

const Input = ({ ...props }) => <StyledInput type={props.type} onChange={props.onChange} value={props.value} />;

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
  value: undefined,
};

export default Input;
