import { status as statusEnum } from '@hivediversity/common-lib/constant';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { opportunitiesEntities } from 'constant';
import opportunityCreator from 'store/opportunity/actions';

import Switch from '../switch';

const OpportunitySwitch = ({ id, status, name, isCompleted, entity }) => {
  const dispatch = useDispatch();

  return (
    <Switch
      name={name}
      value={status === statusEnum.ACTIVE}
      label={status === statusEnum.ACTIVE ? 'LIVE' : 'NOT LIVE'}
      disabled={!isCompleted || status === statusEnum.ARCHIVED}
      onChange={() =>
        dispatch(
          opportunityCreator.updateOpportunityStatusRequest(
            id,
            status === statusEnum.ACTIVE ? statusEnum.INACTIVE : statusEnum.ACTIVE,
            true,
            entity,
            isCompleted
          )
        )
      }
      noFormik
      labelStyle={{ fontSize: '20px' }}
    />
  );
};

OpportunitySwitch.propTypes = {
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool,
  entity: PropTypes.oneOf(Object.values(opportunitiesEntities)),
};

OpportunitySwitch.defaultProps = {
  isCompleted: false,
  entity: undefined,
};

export default OpportunitySwitch;
