import React from 'react';

const IndicatorSeparator = () => (
  <span
    style={{
      alignSelf: 'stretch',
      backgroundColor: '#fff',
      marginRight: '20px',
      width: 1,
    }}
  />
);

export default IndicatorSeparator;
