import { Column, Row } from '@hivediversity/common-lib/base';
import { Collapsible } from '@hivediversity/common-lib/components';
import {
  compensationType,
  opportunityFor,
  opportunityTypeOptions,
  durationOptions,
  locationTypes,
  compensationTypes,
  expectedDatesOfHire,
  compensationPeriod,
  yesNo,
  paymentPeriods,
  opportunityLocationType,
  opportunityTypes,
  expectedDateHire,
  opportunitySection,
  opportunityDuration,
  opportunityForEnums,
} from '@hivediversity/common-lib/constant';
import { opportunityProps, formatMoney } from '@hivediversity/common-lib/utils';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { FieldArray } from 'formik';
import compact from 'lodash/compact';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import replace from 'lodash/replace';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DateTimeSelectors,
  Dropdown,
  ConfirmModal,
  Editor,
  ErrorMessage,
  MultiSelect,
  RadioButtons,
  Tooltip,
  UsOrInternational,
  WrapTextBox,
  MultiIndustries,
} from 'components';
import { options } from 'components/editor';
import { tooltipsTexts } from 'constant';
import { locationTypesForEvent } from 'constant/options';
import staticDataCreator from 'store/static-data/actions';
import { getStaticData } from 'store/static-data/selectors';
import { InputLabel, Text } from 'styled';
import { unformatMoney } from 'utils';

import Duration from './duration';
import FairLaborStandardsModal from './fair-labor-standards-modal';
import { QuestionContainer, Title, DollarSymbol, TooltipContainer } from '../styled';

const Details = ({ values, open, onCollapse, isValid, setFieldValue, setFieldTouched, showValidateIcon }) => {
  const dispatch = useDispatch();
  const [baseEditorState, setBaseEditorState] = useState(null);
  const { jobFunctions, isLoading } = useSelector(getStaticData);
  const [showFairLaborStandardsModal, setShowFairLaborStandardsModal] = useState(false);
  const [showConfirmRemoveOpportunityLocation, setShowConfirmRemoveOpportunityLocation] = useState({
    show: false,
    confirm: () => {
      // eslint-disable-line @typescript-eslint/no-empty-function
    },
  });
  const draftRef = useRef(null);

  useEffect(() => {
    const statesCodes = [];
    values.locations.forEach(location => {
      if (!location.international && location.stateCode) {
        statesCodes.push(location.stateCode);
      }
    });
    if (statesCodes.length > 0) {
      dispatch(staticDataCreator.staticDataFetchCitiesRequest(compact(uniq(statesCodes))));
    }
    if (values?.industries) {
      dispatch(
        staticDataCreator.staticDataFetchSubIndustriesRequest(
          compact(uniq(values.industries.map(industry => industry.id)))
        )
      );
    }
  }, []);

  const handleAcceptModal = () => {
    setFieldValue('laborActAgreement', true);
    setShowFairLaborStandardsModal(false);
  };

  const handleAmount = ({ target: { name, value } }) => {
    const unformattedMoney = unformatMoney(value);
    setFieldValue(name, replace(formatMoney(unformattedMoney), '$', ''));
  };

  const handleChangeDraft = (name, e, picker) => {
    const html = stateToHTML(e.getCurrentContent(), { inlineStyles: picker.exporter(e) });

    const state = stateFromHTML(html, options);
    setBaseEditorState(EditorState.createWithContent(state));
    setFieldValue(name, e);
    setFieldValue(`${name}HTML`, html);
  };

  const handleRemoveLocation = (arrayHelpers, location, index) => {
    if (size(values.locations) > 1) {
      if (location.totalApplicants > 0) {
        setShowConfirmRemoveOpportunityLocation({
          show: true,
          confirm: () => arrayHelpers.remove(index),
        });
      } else {
        arrayHelpers.remove(index);
      }
    }
  };

  const handleAcceptRemoveLocation = () => {
    showConfirmRemoveOpportunityLocation.confirm();
    setShowConfirmRemoveOpportunityLocation({ show: false });
  };

  return (
    <Collapsible
      id={opportunitySection.DETAILS}
      title="Details"
      onCollapse={onCollapse}
      open={open}
      isValid={isValid}
      showValidateIcon={showValidateIcon}
    >
      <FairLaborStandardsModal
        show={showFairLaborStandardsModal}
        onDismiss={() => setShowFairLaborStandardsModal(false)}
        laborActAgreement={values.laborActAgreement}
        onAccept={handleAcceptModal}
      />
      <ConfirmModal
        show={showConfirmRemoveOpportunityLocation.show}
        title="A student has already applied to this location. Are you sure you want to delete this location?"
        onDismiss={() => setShowConfirmRemoveOpportunityLocation({ show: false })}
        onAccept={handleAcceptRemoveLocation}
      />
      <QuestionContainer>
        <Title>Who is this Position for? *</Title>
        <Dropdown
          name="opportunityFor"
          onChange={setFieldValue}
          value={values.opportunityFor}
          placeholder="Select Option"
          options={opportunityFor}
          showIndicatorSeparator
          $bsStyle={{
            padding: 0,
            width: '100%',
            textAlign: 'center',
            maxWidth: '400px',
          }}
        />
        {[opportunityForEnums.INTERNAL_DIVISIONS, opportunityForEnums.SUBSIDIARY].includes(values.opportunityFor) && (
          <>
            <Row $bsStyle={{ flexWrap: 'wrap' }}>
              <WrapTextBox
                name="opportunityForName"
                placeholder="Name of Division or Subsidiary"
                maxLength={255}
                wrapperContainerStyle={{ maxWidth: '300px', marginRight: '10px', width: '100%' }}
              />
              <Tooltip text={tooltipsTexts.OPPORTUNITY_FOR} imageStyle={{ marginTop: '5px' }} />
            </Row>
            <WrapTextBox
              name="opportunityForDescription"
              placeholder="Division or Subsidiary Short Description"
              maxLength={255}
              rows={2}
              component="textarea"
              $bsStyle={{
                marginBottom: '10px',
                flex: 1,
                overflowY: 'scroll',
                height: '100%',
                marginTop: '10px',
              }}
            />
          </>
        )}
      </QuestionContainer>
      <QuestionContainer marginBottom="35px">
        <Title>Industry Verticals *</Title>
        <MultiIndustries onChange={setFieldValue} industries={values.industries} />
      </QuestionContainer>
      <QuestionContainer marginBottom="15px">
        <Row>
          <Title>Position Type *</Title>
          <Tooltip text={tooltipsTexts.OPPORTUNITY_TYPE} />
        </Row>
        <Dropdown
          name="type"
          onChange={(name, id) => setFieldValue(name, id)}
          value={values.type}
          placeholder="Select Type"
          options={opportunityTypeOptions}
          $bsStyle={{
            padding: 0,
            minWidth: '90px',
            textAlign: 'center',
            maxWidth: '400px',
          }}
          showIndicatorSeparator
        />
      </QuestionContainer>
      <QuestionContainer>
        <div style={{ marginLeft: '100px' }}>
          {[opportunityTypes.JOB, opportunityTypes.INTERNSHIPS].includes(values.type) && (
            <RadioButtons
              name="duration"
              options={durationOptions}
              isHorizontal
              setFieldValue={setFieldValue}
              value={values.duration}
            />
          )}
          {values.type === opportunityTypes.JOB && (
            <>
              {values.duration === opportunityDuration.PART_TIME && (
                <div style={{ marginTop: '20px' }}>
                  <Column $bsStyle={{ marginRight: '10px' }}>
                    <Title>Compensation</Title>
                    <DollarSymbol $bsStyle={{ marginTop: '48px' }} />
                    <WrapTextBox
                      name="compensation"
                      maxLength={255}
                      onChange={handleAmount}
                      $bsStyle={{ maxWidth: '160px' }}
                    />
                    {!isEmpty(values.compensation) && (
                      <RadioButtons
                        name="compensationPeriod"
                        value={values.compensationPeriod}
                        options={compensationPeriod}
                        setFieldValue={setFieldValue}
                        isHorizontal
                        isOptional={values.compensationPeriod > 0}
                      />
                    )}
                  </Column>
                </div>
              )}
              {values.duration === opportunityDuration.FULL_TIME && (
                <Row $bsStyle={{ flexWrap: 'wrap', marginTop: '20px' }}>
                  <Column $bsStyle={{ maxWidth: '200px', marginRight: '10px' }}>
                    <Text>From</Text>
                    <DollarSymbol $bsStyle={{ marginTop: '45px' }} />
                    <WrapTextBox name="annualSalaryFrom" placeholder="From" onChange={handleAmount} />
                  </Column>
                  <Column $bsStyle={{ maxWidth: '200px' }}>
                    <Row $bsStyle={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <Text>To</Text>
                      <TooltipContainer>
                        <Tooltip text={tooltipsTexts.COMPENSATION} />
                      </TooltipContainer>
                    </Row>
                    <DollarSymbol $bsStyle={{ marginTop: '47px' }} />
                    <WrapTextBox name="annualSalaryTo" placeholder="To" onChange={handleAmount} />
                  </Column>
                </Row>
              )}
              {((values.duration === opportunityDuration.FULL_TIME &&
                !isEmpty(values.annualSalaryFrom) &&
                !isEmpty(values.annualSalaryTo)) ||
                (values.duration === opportunityDuration.PART_TIME && !isEmpty(values.compensation))) && (
                <>
                  <Title $bsStyle={{ marginTop: '35px' }}>Visible for candidates</Title>
                  <RadioButtons
                    name="visibleForCandidates"
                    value={values.visibleForCandidates}
                    options={yesNo}
                    setFieldValue={setFieldValue}
                    isHorizontal
                  />
                </>
              )}
            </>
          )}
        </div>
      </QuestionContainer>
      <QuestionContainer marginBottom="35px">
        <Title>Functions {values.type === opportunityTypes.EVENT ? '' : '*'}</Title>
        <MultiSelect
          name="jobFunctions"
          placeholder="Select Function"
          options={jobFunctions}
          values={values.jobFunctions}
          setFieldValue={setFieldValue}
          isLoading={isLoading}
          showIndicatorSeparator
        />
      </QuestionContainer>
      <QuestionContainer>
        <Row $bsStyle={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <Column>
            <Title>{`${values.type === opportunityTypes.EVENT ? 'Event' : 'Position'}`} Description (Preview) *</Title>
          </Column>
          <Column>
            <InputLabel $bsStyle={{ fontSize: '17px' }}>max 280 characters</InputLabel>
          </Column>
        </Row>
        <WrapTextBox
          name="summary"
          placeholder="Please include a brief summary of the posted positions here.
           This will show up on the preview of the position card that faces potential candidates.
           Less is more here, we have plenty of room for you in the below fields to include any
           and all details about requirements and a deeper description of the position or event."
          rows={2}
          component="textarea"
          maxLength={280}
          $bsStyle={{ marginBottom: '10px', flex: 1, overflowY: 'scroll', height: '100%' }}
        />
      </QuestionContainer>
      <QuestionContainer marginBottom="35px" $bsStyle={{ maxWidth: '900px' }}>
        <Title>Location of {`${values.type === opportunityTypes.EVENT ? 'Event' : 'Position'}`} *</Title>
        <FieldArray
          name="locations"
          render={arrayHelpers =>
            values.locations.map((location, index) => (
              <Fragment key={index}>
                <RadioButtons
                  name={`locations.${index}.type`}
                  takeDefaultLabel
                  options={values.type === opportunityTypes.EVENT ? locationTypesForEvent : locationTypes}
                  isHorizontal
                  setFieldValue={setFieldValue}
                  value={location.type}
                />
                <UsOrInternational
                  disabled={location.type === opportunityLocationType.REMOTE}
                  setFieldValue={setFieldValue}
                  names={{
                    international: `locations.${index}.international`,
                    state: `locations.${index}.stateCode`,
                    city: `locations.${index}.cityId`,
                    country: `locations.${index}.countryId`,
                    anotherCountry: `locations.${index}.countryName`,
                    anotherCity: `locations.${index}.cityName`,
                    zipCode: `locations.${index}.zipCode`,
                  }}
                  values={{
                    countryId: location.countryId,
                    stateId: location.stateCode,
                    cityId: location.cityId,
                    international: values.type === opportunityTypes.EVENT ? false : location.international,
                    countryName: location.countryName,
                    cityName: location.cityName,
                    zipCode: location.zipCode,
                  }}
                  showAddAnotherCountry={location.countryId === 214}
                  onMore={() =>
                    arrayHelpers.push({
                      stateCode: '',
                      cityId: '',
                      countryId: '',
                      international: false,
                      cityName: '',
                      countryName: '',
                    })
                  }
                  onClear={() => handleRemoveLocation(arrayHelpers, location, index)}
                  showZipCode={!location.international}
                  showRemoveButton={values.locations.length > 1}
                  showAddButton={values.locations.length - 1 === index}
                  showSwitch={values.type !== opportunityTypes.EVENT}
                />
              </Fragment>
            ))
          }
        />
      </QuestionContainer>
      {values.type === opportunityTypes.JOB && values.duration === opportunityDuration.PART_TIME && (
        <QuestionContainer>
          <Duration />
        </QuestionContainer>
      )}
      {values.type === opportunityTypes.INTERNSHIPS && (
        <QuestionContainer $bsStyle={{ maxWidth: '900px' }}>
          <Title>Compensation</Title>
          <RadioButtons
            name="compensationType"
            value={values.compensationType}
            options={compensationTypes}
            setFieldValue={(name, value) => {
              if (value === compensationType.UNPAID) {
                setShowFairLaborStandardsModal(true);
              }
              setFieldValue(name, value);
            }}
            isHorizontal
          />
          <Duration />
          {values.compensationType === compensationType.PAID && (
            <>
              <Title $bsStyle={{ marginTop: '8px', marginRight: '20px' }}>Compensation Amount</Title>
              <Row $bsStyle={{ flexWrap: 'wrap', alignItems: 'center', marginTop: '20px' }}>
                <DollarSymbol $bsStyle={{ marginTop: '-20px' }} />
                <WrapTextBox
                  name="salary"
                  type="text"
                  placeholder="Amount"
                  $bsStyle={{
                    minWidth: '60px',
                    maxWidth: '250px',
                    marginBottom: '20px',
                  }}
                  onChange={handleAmount}
                  showErrorMessage={false}
                />
              </Row>
              <Row>
                <ErrorMessage control="salary" />
              </Row>
              <RadioButtons
                name="paymentPeriod"
                value={values.paymentPeriod}
                options={paymentPeriods}
                setFieldValue={setFieldValue}
                isHorizontal
              />
              {!isEmpty(values.salary) && (
                <>
                  <Title $bsStyle={{ marginTop: '35px' }}>Visible for students</Title>
                  <RadioButtons
                    name="compensationVisibleForStudents"
                    value={values.compensationVisibleForStudents}
                    options={yesNo}
                    setFieldValue={setFieldValue}
                    isHorizontal
                  />
                </>
              )}
            </>
          )}
          {values.compensationType === compensationType.UNPAID && (
            <>
              <Text
                $bsStyle={{
                  lineHeight: 'initial',
                  background: 'transparent',
                  textDecoration: 'underline',
                  maxWidth: '900px',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                }}
                onClick={() => setShowFairLaborStandardsModal(true)}
              >
                Fair Labor Standards Act Agreement (Unpaid Interns Only)
              </Text>
              <ErrorMessage control="laborActAgreement" />
            </>
          )}
        </QuestionContainer>
      )}
      {values.type === opportunityTypes.EVENT && (
        <QuestionContainer>
          <Title>
            We require all registrants to submit resumes as part of the application process. Please keep in mind that
            resumes will continue to be enhanced and updated as you post additional opportunites.
          </Title>
        </QuestionContainer>
      )}
      <Title>{values.type === opportunityTypes.EVENT ? 'Registration Start Date *' : 'Application Start Date *'}</Title>
      <DateTimeSelectors
        title="Start Date (all times in Eastern Time)"
        values={values}
        anteMeridiem="anteMeridiem"
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        day="startDay"
        hour="startHour"
        minute="startMinute"
        month="startMonth"
        year="startYear"
      />
      <Row center $bsStyle={{ marginTop: '15px' }}>
        <ErrorMessage control="startYear" />
      </Row>
      <Row>
        <Title>{values.type === opportunityTypes.EVENT ? 'Registration Deadline *' : 'Application Deadline *'}</Title>
        <Tooltip text={tooltipsTexts.DEADLINE} />
      </Row>
      <DateTimeSelectors
        title="Deadline (all times in Eastern Time)"
        values={values}
        anteMeridiem="endHourAnteMeridiem"
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        day="endDay"
        hour="endHour"
        minute="endMinute"
        month="endMonth"
        year="endYear"
      />
      <Row center $bsStyle={{ marginTop: '15px', marginBottom: '15px' }}>
        <ErrorMessage control="endYear" />
      </Row>
      {[opportunityTypes.JOB, opportunityTypes.INTERNSHIPS].includes(values.type) && (
        <>
          <Text $bsStyle={{ fontWeight: 'bold' }}>Will you be reviewing applications on a rolling basis?</Text>
          <RadioButtons
            name="reviewApplications"
            options={yesNo}
            value={values.reviewApplications}
            setFieldValue={setFieldValue}
            isHorizontal
            $bsStyle={{ marginBottom: '20px' }}
            isOptional={isBoolean(values.reviewApplications)}
          />
        </>
      )}
      {values.type === opportunityTypes.EVENT && (
        <>
          <DateTimeSelectors
            title="Date of Event (date and time in ET)"
            values={values}
            anteMeridiem="eventAnteMeridiem"
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            day="eventDay"
            hour="eventHour"
            minute="eventMinute"
            month="eventMonth"
            year="eventYear"
          />
          <Row center $bsStyle={{ marginTop: '15px', marginBottom: '15px' }}>
            <ErrorMessage control="eventYear" />
          </Row>
        </>
      )}
      {values.type !== opportunityTypes.EVENT && (
        <QuestionContainer marginBottom="15px" $bsStyle={{ maxWidth: '300px' }}>
          <Title># of Potential/Intended Hires</Title>
          <WrapTextBox
            type="number"
            min={0}
            name="numberOfPotentialHires"
            wrapperContainerStyle={{ maxWidth: '80px' }}
            $bsStyle={{ marginBottom: '20px' }}
            showErrorMessage={false}
          />
          <ErrorMessage control="numberOfPotentialHires" />
        </QuestionContainer>
      )}
      {values.type !== opportunityTypes.EVENT && (
        <QuestionContainer>
          <Title>Intended Start Date</Title>
          <RadioButtons
            name="expectedDateHire"
            options={expectedDatesOfHire}
            isHorizontal
            setFieldValue={setFieldValue}
            value={values.expectedDateHire}
            isOptional={values.expectedDateHire > 0}
          />
          {values.expectedDateHire === expectedDateHire.DATE && (
            <div style={{ marginTop: '10px' }}>
              <DateTimeSelectors
                values={values}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                day="expectedDateDay"
                month="expectedDateMonth"
                year="expectedDateYear"
                hasMinute={false}
                hasHour={false}
                hasAnteMeridiem={false}
              />
              <Row center $bsStyle={{ marginTop: '15px', marginBottom: '15px' }}>
                <ErrorMessage control="expectedDateYear" />
              </Row>
            </div>
          )}
        </QuestionContainer>
      )}
      <QuestionContainer>
        <Title>Tips & Resources to Prepare for the {values.type === opportunityTypes.EVENT ? 'Event' : 'Role'}</Title>
        <WrapTextBox
          name="tips"
          placeholder="Include information here that provides potential candidates resources
           and suggestions on how best to prepare for the position. What kind of interview
           should they prepare for? What's not as obvious in the position requirements that
           you would like for candidates to consider before applying and interviewing?"
          rows="10"
          component="textarea"
          maxLength={5000}
          $bsStyle={{ marginBottom: '10px', flex: 1, overflowY: 'scroll', height: '100%' }}
        />
      </QuestionContainer>
      <QuestionContainer>
        <Title>{`${
          values.type === opportunityTypes.EVENT ? 'Event' : 'Position'
        } Description & Responsibilities`}</Title>
        <Editor
          value={values.description}
          placeholder={`${
            values.type !== opportunityTypes.EVENT
              ? 'Include information here that has not been included in any of the previous inputs. Explain the role and what the day to day responsibilities are - further describe the actual position. ' +
                'This section is not for additional requirements or recommended qualifications.'
              : 'Include information here that has not been included in any of the previous inputs.'
          }`}
          showToolbar
          ref={draftRef}
          onChange={handleChangeDraft}
          name="description"
          baseEditorState={baseEditorState}
        />
      </QuestionContainer>
    </Collapsible>
  );
};

Details.propTypes = {
  values: opportunityProps.isRequired,
  open: PropTypes.bool.isRequired,
  onCollapse: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  showValidateIcon: PropTypes.bool,
};

Details.defaultProps = {
  showValidateIcon: false,
};

export default Details;
