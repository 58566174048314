import React from 'react';

const Menu = props => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        background: '#8768E6',
        padding: '8px',
        borderRadius: 11,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        zIndex: 5,
        marginTop: '8px',
        width: '100%',
      }}
      {...props}
    />
  );
};

export default Menu;
