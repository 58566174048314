import { CustomButton } from '@hivediversity/common-lib/buttons';
import { locationsOptions, getColor } from '@hivediversity/common-lib/utils';
import locationPropTypes from '@hivediversity/common-lib/utils/prop-types/location';
import isEmpty from 'lodash/isEmpty';
import slice from 'lodash/slice';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown, WrapTextBox } from 'components';
import { applicantsSort } from 'constant/options';
import opportunityDataCreator from 'store/opportunity/actions';
import {
  getMySelectedTags,
  getOpportunityApplicantsFilters,
  getSelectedContactTags,
} from 'store/opportunity/selectors';
import { getStaticData } from 'store/static-data/selectors';

import { BottomWrapper, Title, TopWrapper, WrapperButton, WrapperCheckbox } from './styled';
import Filters from '../filters';

const TopFilters = ({ opportunityId, locations }) => {
  const dispatch = useDispatch();
  const filters = useSelector(getOpportunityApplicantsFilters);
  const { contactTags, tags } = useSelector(getStaticData);
  const mySelectedTags = useSelector(getMySelectedTags);
  const selectedContactTags = useSelector(getSelectedContactTags);

  useEffect(() => {
    if (!isEmpty(tags)) {
      dispatch(opportunityDataCreator.setTags(slice(tags, 0, tags.length - 1), 'tags'));
    }
    if (!isEmpty(contactTags)) {
      dispatch(opportunityDataCreator.setTags(contactTags, 'contactTag'));
    }
  }, [tags, contactTags]);

  useEffect(() => {
    dispatch(opportunityDataCreator.getOpportunityApplicantsRequest(opportunityId, { ...filters, page: 1 }));
  }, [filters.tags, filters.location, filters.name, filters.sort, filters.sortBy, filters.contactTag]);

  const handleFilterTag = (index, checked, name) => {
    const newCheckboxes = name === 'contactTag' ? [...selectedContactTags] : [...mySelectedTags];
    newCheckboxes[index].checked = checked;
    const tagsToFilter = newCheckboxes.filter(checkbox => checkbox.checked).map(checkbox => checkbox.id);
    dispatch(
      opportunityDataCreator.setOpportunityApplicantsFilters({
        ...filters,
        [name]: tagsToFilter,
      })
    );
    dispatch(opportunityDataCreator.setTags(newCheckboxes, name));
  };

  const handleChangeFilters = (name, value) => {
    let currentValue = value;
    if (!value || value === 'all') {
      currentValue = null;
    }
    dispatch(opportunityDataCreator.setOpportunityApplicantsFilters({ ...filters, [name]: currentValue }));
  };

  const handleFilter = ({ target: { name, value } }) => handleChangeFilters(name, value);

  const handleToggleSort = (control, sort) => {
    dispatch(
      opportunityDataCreator.setOpportunityApplicantsFilters({
        ...filters,
        sort,
        sortBy: filters.sortBy === 'asc' ? 'desc' : 'asc',
      })
    );
  };

  const handleRemoveFilter = ({ target: { id } }) => {
    let newFilters = filters;
    if (['honors', 'scholarships'].includes(id)) {
      newFilters = { ...newFilters, [id]: false };
    } else {
      newFilters = { ...newFilters, [id]: undefined };
    }
    dispatch(opportunityDataCreator.setOpportunityApplicantsFilters(newFilters));
    dispatch(opportunityDataCreator.getOpportunityApplicantsRequest(opportunityId, newFilters));
  };

  return (
    <div id="filters">
      <TopWrapper>
        <Title>My Tags</Title>
        {mySelectedTags.map((tag, i) => (
          <WrapperButton key={tag.id}>
            <WrapperCheckbox htmlFor={tag.id} checkedTag={tag.checked}>
              <input
                type="checkbox"
                value={tag.id}
                id={tag.id}
                checked={tag.checked}
                onChange={e => handleFilterTag(i, e.target.checked, 'tags')}
              />
              <CustomButton
                color={getColor(tag.color)}
                $bsStyle={{
                  width: '100%',
                  maxWidth: '180px',
                  height: '55px',
                  pointerEvents: 'none',
                  lineHeight: 'initial',
                  fontSize: '12px',
                }}
              >
                {tag.name}
              </CustomButton>
            </WrapperCheckbox>
          </WrapperButton>
        ))}
      </TopWrapper>
      <TopWrapper>
        <Title>Contact Tags</Title>
        {contactTags?.map((tag, i) => (
          <WrapperButton key={tag.id}>
            <WrapperCheckbox htmlFor={tag.id} checkedTag={tag.checked}>
              <input
                type="checkbox"
                value={tag.id}
                id={tag.id}
                checked={tag.checked}
                onChange={e => handleFilterTag(i, e.target.checked, 'contactTag')}
              />
              <CustomButton
                color={tag.color}
                $bsStyle={{
                  width: '100%',
                  maxWidth: '180px',
                  height: '55px',
                  pointerEvents: 'none',
                  lineHeight: 'initial',
                  fontSize: '12px',
                }}
              >
                {tag.name}
              </CustomButton>
            </WrapperCheckbox>
          </WrapperButton>
        ))}
      </TopWrapper>

      <BottomWrapper>
        {locations.length > 1 && (
          <Dropdown
            noFormik
            name="location"
            onChange={handleChangeFilters}
            placeholder="Location"
            options={locationsOptions(locations, true)}
            value={filters.location}
            $bsStyle={{
              padding: 0,
              margin: '0',
              maxWidth: '392px',
              minWidth: '90px',
              textAlign: 'center',
            }}
            dropdownStyles={{
              placeholder: {
                color: '#fff',
                fontSize: '14px',
                padding: '0 6px',
                fontWeight: 300,
              },
              container: {
                borderRadius: '8px',
                marginRight: '10px',
              },
            }}
          />
        )}
        <WrapTextBox
          name="name"
          noFormik
          placeholder="Search by name"
          onChange={handleFilter}
          $bsStyle={{
            borderRadius: '8px',
            marginTop: '5px',
            marginBottom: '5px',
          }}
          wrapperContainerStyle={{
            marginRight: '10px',
            width: '100%',
            maxWidth: '392px',
            minWidth: '200px',
          }}
          showErrorMessage={false}
          debounce
        />

        <Dropdown
          noFormik
          name="sort"
          onChange={handleToggleSort}
          placeholder="Sort"
          options={applicantsSort}
          value={filters.sort}
          $bsStyle={{
            padding: 0,
            margin: '0',
            maxWidth: '392px',
            minWidth: '90px',
            textAlign: 'center',
          }}
          dropdownStyles={{
            placeholder: {
              color: '#fff',
              fontSize: '14px',
              padding: '0 6px',
              fontWeight: 300,
            },
            container: {
              borderRadius: '8px',
              marginRight: '10px',
            },
          }}
        />

        <Filters
          opportunityId={opportunityId}
          onRemove={handleRemoveFilter}
          onChangeFilter={handleChangeFilters}
          filters={filters}
        />
      </BottomWrapper>
    </div>
  );
};

TopFilters.propTypes = {
  opportunityId: PropTypes.number.isRequired,
  locations: PropTypes.arrayOf(locationPropTypes),
};

TopFilters.defaultProps = {
  locations: [],
};

export default TopFilters;
