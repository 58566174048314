import { EmptyPage } from '@hivediversity/common-lib/base';
import { Error404 } from '@hivediversity/common-lib/components/errors';
import React, { lazy, Suspense, useCallback, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import { routes } from 'constant';
import { useLogin } from 'hooks';

const Login = lazy(() => import('pages/login'));
const Splash = lazy(() => import('pages/splash'));
const Recovery = lazy(() => import('pages/recovery-password/mail'));
const ResetPassword = lazy(() => import('pages/recovery-password/password'));

const WithoutSessionRoutes = () => {
  const { push, location } = useHistory();
  const [token] = useLogin();

  const Page404 = useCallback(
    () => <Error404 goBackButton={{ label: 'Back to Login Page', onClick: () => push(routes.LOGIN) }} />,
    []
  );

  useEffect(() => {
    if (!location.pathname.includes('/recovery') && !token) {
      push(routes.LOGIN);
    }
  }, []);

  return (
    <Suspense fallback={<EmptyPage />}>
      <Switch>
        <Route exact path="/" component={Splash} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/recovery" component={Recovery} />
        <Route exact path="/recovery/:token" component={ResetPassword} />
        <Route exact path="/404" component={Page404} />
        <Redirect from="/profile" to={routes.LOGIN} />
        <Redirect from="*" to="/404" />
      </Switch>
    </Suspense>
  );
};

export default WithoutSessionRoutes;
