import { Column, Row } from '@hivediversity/common-lib/base';
import { CustomButton } from '@hivediversity/common-lib/buttons';
import { Collapsible } from '@hivediversity/common-lib/components';
import { colors, companySection } from '@hivediversity/common-lib/constant';
import { companyProps } from '@hivediversity/common-lib/utils';
import { Picker } from 'emoji-mart';
import { FieldArray } from 'formik';
import size from 'lodash/size';
import toArray from 'lodash/toArray';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AddAndRemoveButtons,
  Dropdown,
  MultiIndustries,
  MultiSelect,
  MultiText,
  Tooltip,
  UsOrInternational,
  WrapTextBox,
} from 'components';
import { companySocial, placeholders, tooltipsTexts } from 'constant';
import staticDataCreator from 'store/static-data/actions';
import { getStaticData, getIsLoading, getSubIndustries } from 'store/static-data/selectors';
import { InputLabel } from 'styled';

import { WrapperInput, EmojiLabel, EditIcon, InputLabelWithAddButton } from '../styled';

const AboutUs = ({ onCollapse, isValid, open, setFieldValue, values, showValidateIcon }) => {
  const dispatch = useDispatch();
  const [staticDataRequested, setStaticDataRequested] = useState(false);
  const [showEmojiInput, setShowEmojiInput] = useState(false);
  const arrayHelperRef = useRef(null);
  const isLoadingStaticData = useSelector(getIsLoading);
  const { industries, jobFunctions, companyTypes, employees } = useSelector(getStaticData);
  const subIndustries = useSelector(getSubIndustries);

  useEffect(() => {
    setStaticDataRequested(true);
    dispatch(staticDataCreator.staticDataFetchStatesRequest());
  }, []);

  useEffect(() => {
    if (staticDataRequested && !isLoadingStaticData) {
      dispatch(staticDataCreator.staticDataFetchIndustriesRequest());
    }
  }, [staticDataRequested, isLoadingStaticData]);

  const getSubIndustriesByIndustry = industryId =>
    subIndustries?.flat()?.filter(subIndustry => Number(subIndustry?.industryId) === industryId) || [];

  const handleChangeIndustry = industryId => {
    const currentSubIndustries = getSubIndustriesByIndustry(industryId);
    if (currentSubIndustries.length === 0) {
      dispatch(staticDataCreator.staticDataFetchSubIndustriesRequest([industryId]));
    }
  };

  const addMoreAdditionalLocation = () => {
    arrayHelperRef.current.push({
      stateCode: '',
      cityId: '',
      countryId: '',
      international: false,
      cityName: '',
      countryName: '',
    });
  };

  return (
    <Collapsible
      id={companySection.ABOUT_US}
      onCollapse={onCollapse}
      title="About Us"
      isValid={isValid}
      open={open}
      showValidateIcon={showValidateIcon}
    >
      <WrapperInput>
        <InputLabel>Employer Alias</InputLabel>
        <WrapTextBox
          name="alias"
          placeholder="i.e. FBI, Federal Bureau of Investigation"
          maxLength={255}
          $bsStyle={{ minWidth: '60px', maxWidth: '300px' }}
        />
      </WrapperInput>

      <WrapperInput>
        <Row>
          <InputLabel $bsStyle={{ minWidth: '300px' }}>Company Description (Preview) *</InputLabel>
          <Tooltip text={tooltipsTexts.COMPANY_DESCRIPTION_PREVIEW} imageStyle={{ marginLeft: '-10px' }} />
          <Column $bsStyle={{ flex: 1, alignItems: 'flex-end' }}>
            <InputLabel $bsStyle={{ fontSize: '17px' }}>max 280 characters</InputLabel>
          </Column>
        </Row>
        <WrapTextBox
          name="overallShortStatement"
          component="textarea"
          rows={2}
          placeholder="Built for equity and powered by inclusion, HIVE Diversity virtual
          recruiting platform connects the next generation of talent to companies
          who value diversity."
          maxLength={280}
          $bsStyle={{ height: '100%' }}
        />
      </WrapperInput>

      <WrapperInput>
        <Row $bsStyle={{ flexWrap: 'wrap' }}>
          <InputLabel>Company Description *</InputLabel>
          <Tooltip text={tooltipsTexts.COMPANY_DESCRIPTION} />
        </Row>
        <WrapTextBox
          name="description"
          placeholder="HIVE DIVERSITY is a first of its kind virtual recruiting platform that
          creates meaningful engagement between companies and the next generation of talent -
          one community of students and recent graduates that represents an ever-evolving range
          of diverse backgrounds, experiences and interests. Students uniquely demonstrate their
          commitment to potential employers by completing our proprietary HIVE5 Process focused
          on career and DEI preparation. In the process, they also create a perfectly formatted
          resume and digital profile that highlights and showcases their individual identities,
          interests and achievements to potential employers in an unprecedented and innovative way.
          HIVE’s employer experience enhances companies’ visibility to—and interest from—the next
          generation of talent. With the assistance of Helper B, companies in the HIVE learn how
          to access even more candidates and are able to understand a great deal more about all
          potential and ever-evolving priorities of representation.
          Companies in the HIVE all share one thing in common—they are excited about proactively
          engaging the next generation of talent through HIVE DIVERSITY. Just like a simple
          definition of a hive, this is THE place where individuals can come together and work
          collectively toward a common goal—producing something pretty sweet. HIVE DIVERSITY is
          headquartered in New York City with employees based in Denver, New Orleans, New York and Boston."
          rows="10"
          component="textarea"
          maxLength={5000}
          $bsStyle={{ overflowY: 'scroll', height: '100%' }}
        />
      </WrapperInput>

      <WrapperInput $bsStyle={{ maxWidth: '396px' }}>
        <InputLabel>Company Type *</InputLabel>
        <Dropdown
          name="type"
          onChange={setFieldValue}
          value={values.type}
          placeholder="Choose Type"
          isSearchable={false}
          showIndicatorSeparator
          options={companyTypes}
          $bsStyle={{
            padding: 0,
            textAlign: 'center',
            maxWidth: '300px',
          }}
        />
      </WrapperInput>

      <WrapperInput marginBottom="35px">
        <InputLabel>Headquarters Location *</InputLabel>
        <UsOrInternational
          names={{
            international: 'headquarterLocation.international',
            state: 'headquarterLocation.stateCode',
            country: 'headquarterLocation.countryId',
            city: 'headquarterLocation.cityId',
            anotherCountry: 'headquarterLocation.countryName',
            anotherCity: 'headquarterLocation.cityName',
          }}
          values={{
            countryId: values.headquarterLocation.countryId,
            stateId: values.headquarterLocation.stateCode,
            cityId: values.headquarterLocation.cityId,
            international: values.headquarterLocation.international,
            cityName: values.headquarterLocation.cityName,
            countryName: values.headquarterLocation.countryName,
          }}
          setFieldValue={(field, value) => {
            const splittedField = field.split('.')[1];
            setFieldValue('headquarterLocation', {
              ...values.headquarterLocation,
              [splittedField]: value,
            });
          }}
          showAddAnotherCountry={values.headquarterLocation.country === 214}
          showAddButton={false}
        />
      </WrapperInput>

      <WrapperInput marginBottom="35px">
        <InputLabelWithAddButton>
          <InputLabel>Additional Locations</InputLabel>
          <CustomButton
            $bsStyle={{ fontSize: '40px', maxWidth: '30px', paddingTop: '3px' }}
            color={colors.VIOLET}
            onClick={addMoreAdditionalLocation}
          >
            +
          </CustomButton>
        </InputLabelWithAddButton>
        <FieldArray
          name="additionalLocations"
          render={arrayHelpers => {
            arrayHelperRef.current = arrayHelpers;
            return values.additionalLocations.map((location, index) => (
              <Fragment key={index}>
                <UsOrInternational
                  setFieldValue={setFieldValue}
                  names={{
                    international: `additionalLocations.${index}.international`,
                    state: `additionalLocations.${index}.stateCode`,
                    city: `additionalLocations.${index}.cityId`,
                    country: `additionalLocations.${index}.countryId`,
                    anotherCountry: `additionalLocations.${index}.countryName`,
                    anotherCity: `additionalLocations.${index}.cityName`,
                  }}
                  values={{
                    countryId: location.countryId,
                    stateId: location.stateCode,
                    cityId: location.cityId,
                    international: location.international,
                    countryName: location.countryName,
                    cityName: location.cityName,
                  }}
                  showAddAnotherCountry={location.countryId === 214}
                  onMore={() =>
                    arrayHelpers.push({
                      stateCode: '',
                      cityId: '',
                      countryId: '',
                      international: false,
                      cityName: '',
                      countryName: '',
                    })
                  }
                  showAddButton={false}
                  onClear={() => arrayHelpers.remove(index)}
                  showRemoveButton={size(values.additionalLocations) >= 1}
                />
              </Fragment>
            ));
          }}
        />
      </WrapperInput>

      <WrapperInput>
        <InputLabel>Company Website *</InputLabel>
        <WrapTextBox
          name="urlAddress"
          placeholder="start with http(s)"
          maxLength={255}
          wrapperContainerStyle={{ maxWidth: '300px' }}
        />
      </WrapperInput>

      <WrapperInput>
        <InputLabel>Number of Employees *</InputLabel>
        <Dropdown
          name="numberOfEmployees"
          onChange={setFieldValue}
          isClearable={false}
          value={values.numberOfEmployees}
          placeholder="1-20"
          isSearchable={false}
          showIndicatorSeparator
          options={employees}
          $bsStyle={{
            padding: 0,
            width: '100%',
            textAlign: 'center',
            maxWidth: '300px',
          }}
        />
      </WrapperInput>

      <WrapperInput marginBottom="35px">
        <Row>
          <InputLabel>Industry Verticals *</InputLabel>
          <Tooltip text={tooltipsTexts.INDUSTRY_VERTICALS} />
        </Row>
        <MultiIndustries onChange={setFieldValue} industries={values.industries} />
      </WrapperInput>

      <WrapperInput marginBottom="35px">
        <Row>
          <InputLabel>Internship and Job Functions *</InputLabel>
          <Tooltip text={tooltipsTexts.INTERNSHIPS_AND_JOB_FUNCTIONS} />
        </Row>
        <MultiSelect
          name="jobFunctions"
          placeholder="Choose Job Function"
          color={colors.VIOLET}
          options={jobFunctions}
          values={values.jobFunctions}
          setFieldValue={setFieldValue}
          showIndicatorSeparator
        />
      </WrapperInput>

      <WrapperInput marginBottom="-20px">
        <Row>
          <InputLabel>Internal Divisions</InputLabel>
          <Tooltip text={tooltipsTexts.INTERNAL_DIVISION} />
        </Row>
        <MultiText
          name="internalDivisions"
          value={values.internalDivisions}
          setFieldValue={setFieldValue}
          $bsStyle={{ marginBottom: '0' }}
          inputs={[
            {
              id: 1,
              placeholder: 'i.e. BigBankCo - Private Wealth Management OR HIVE DIVERSITY Northeast Division',
              name: 'title',
              style: {
                padding: 0,
                width: '100%',
                textAlign: 'center',
                maxWidth: '610px',
              },
            },
            {
              id: 2,
              placeholder: placeholders.URL_START_WITH_HTTP,
              name: 'url',
              style: {
                padding: 0,
                width: '100%',
                textAlign: 'center',
                maxWidth: '300px',
              },
            },
          ]}
          dropdowns={[
            {
              id: 1,
              options: () => industries,
              name: 'verticalId',
              label: 'Industries',
              placeholder: placeholders.SELECT_VERTICAL,
              onChange: (field, value) => {
                setFieldValue(field, value);
                handleChangeIndustry(value);
              },
              isSearchable: true,
              style: {
                padding: 0,
                width: '100%',
                textAlign: 'center',
                maxWidth: '300px',
                marginRight: '24px',
              },
            },
            {
              id: 2,
              options: entity => getSubIndustriesByIndustry(entity.verticalId),
              name: 'industryId',
              label: 'Select Industry',
              placeholder: placeholders.SELECT_INDUSTRY,
              onChange: setFieldValue,
              isSearchable: true,
              style: {
                padding: 0,
                width: '100%',
                textAlign: 'center',
                maxWidth: '300px',
                marginRight: '24px',
              },
            },
          ]}
          onAdd={arrayHelpers =>
            arrayHelpers.push({
              title: '',
              url: '',
              verticalId: null,
              industryId: null,
            })
          }
        />
      </WrapperInput>

      <WrapperInput marginBottom="-20px">
        <Row>
          <InputLabel>Subsidiaries</InputLabel>
          <Tooltip text={tooltipsTexts.SUBSIDIARIES} />
        </Row>
        <MultiText
          name="subsidiaries"
          value={values.subsidiaries}
          double
          setFieldValue={setFieldValue}
          inputs={[
            {
              id: 1,
              placeholder: 'Name of Subsidiary',
              name: 'title',
              style: {
                padding: 0,
                width: '100%',
                textAlign: 'center',
                maxWidth: '300px',
              },
            },
            {
              id: 2,
              placeholder: placeholders.URL_START_WITH_HTTP,
              name: 'url',
              style: {
                padding: 0,
                width: '100%',
                textAlign: 'center',
                maxWidth: '300px',
              },
            },
          ]}
          dropdowns={[
            {
              id: 1,
              options: () => industries,
              name: 'industryId',
              label: 'Industries',
              placeholder: placeholders.SELECT_VERTICAL,
              isSearchable: true,
              onChange: (field, value) => {
                setFieldValue(field, value);
                handleChangeIndustry(value);
              },
              style: {
                padding: 0,
                width: '100%',
                textAlign: 'center',
                maxWidth: '300px',
                marginRight: '24px',
              },
            },
            {
              id: 2,
              options: entity => getSubIndustriesByIndustry(entity.industryId),
              name: 'verticalIndustryId',
              label: 'Select Industry',
              placeholder: placeholders.SELECT_INDUSTRY,
              isSearchable: true,
              onChange: setFieldValue,
              style: {
                padding: 0,
                width: '100%',
                textAlign: 'center',
                maxWidth: '300px',
                marginRight: '24px',
              },
            },
          ]}
          onAdd={arrayHelpers =>
            arrayHelpers.push({
              title: '',
              url: '',
              industryId: null,
              verticalIndustryId: null,
            })
          }
        />
      </WrapperInput>

      <WrapperInput marginBottom="30px">
        <Row $bsStyle={{ flexWrap: 'wrap' }}>
          <InputLabel>Company in 5 Emojis</InputLabel>
          <Tooltip text={tooltipsTexts.COMPANY_IN_FIVE_EMOJIS} />
        </Row>
        <Row>
          {showEmojiInput && (
            <WrapTextBox
              name="emojis"
              placeholder="Company in 5 Emojis"
              onChange={e => setFieldValue('emojis', e.target.value)}
              value={values.emojis}
            />
          )}
          <EmojiLabel>
            {!showEmojiInput ? `${values.emojis}` : ''}
            {showEmojiInput ? (
              <CustomButton
                $bsStyle={{
                  fontSize: '20px',
                  maxWidth: '10px',
                  marginLeft: '5px',
                }}
                color={colors.ORANGE}
                onClick={() => setShowEmojiInput(false)}
              >
                X
              </CustomButton>
            ) : (
              <EditIcon
                $bsStyle={{ marginLeft: '16px' }}
                onClick={e => {
                  e.preventDefault();
                  setShowEmojiInput(!showEmojiInput);
                }}
              />
            )}
          </EmojiLabel>
        </Row>
        {showEmojiInput && (
          <Picker
            title="Pick 5 emojis..."
            emoji="point_up"
            onSelect={newEmoji => {
              const emojis = values.emojis.concat(newEmoji.native);
              if (toArray(emojis).length <= 5) {
                setFieldValue('emojis', emojis);
              }
              if (toArray(emojis).length >= 5) {
                setShowEmojiInput(false);
              }
            }}
          />
        )}
      </WrapperInput>

      <WrapperInput marginBottom="35px">
        <InputLabel>Social Media</InputLabel>
        <FieldArray
          name="socialMedias"
          render={arrayHelpers => (
            <>
              {values.socialMedias.map((media, index) => (
                <Row key={index} $bsStyle={{ flexWrap: 'wrap' }}>
                  <Column
                    $bsStyle={{
                      padding: 0,
                      width: '100%',
                      textAlign: 'center',
                      maxWidth: '280px',
                      marginRight: '24px',
                    }}
                  >
                    <Dropdown
                      name={`socialMedias.${index}.social`}
                      onChange={setFieldValue}
                      maxMenuHeight={120}
                      showIndicatorSeparator
                      value={values.socialMedias[index].social}
                      placeholder="Select Platform"
                      options={companySocial}
                    />
                  </Column>
                  <Column $bsStyle={{ minWidth: '280px', marginRight: '20px', marginBottom: '15px' }}>
                    <WrapTextBox
                      name={`socialMedias.${index}.url`}
                      placeholder={placeholders.URL_START_WITH_HTTP}
                      value={values.socialMedias[index].url}
                      maxLength={255}
                    />
                  </Column>
                  <AddAndRemoveButtons
                    onAdd={() => arrayHelpers.push({ social: 0, url: '' })}
                    onRemove={() => arrayHelpers.remove(index)}
                    showRemoveButton={values.socialMedias.length > 1}
                    showAddMore={values.socialMedias.length - 1 === index}
                  />
                </Row>
              ))}
            </>
          )}
        />
      </WrapperInput>
    </Collapsible>
  );
};

AboutUs.propTypes = {
  onCollapse: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  showValidateIcon: PropTypes.bool,
  values: companyProps.isRequired,
};

AboutUs.defaultProps = {
  showValidateIcon: false,
};

export default AboutUs;
