import React from 'react';

import { Container, ErrorMessage, ErrorMessageContainer, Exclamation } from './styled';

const ErrorFallback = () => (
  <Container>
    <ErrorMessageContainer>
      <Exclamation />
      <ErrorMessage>Something went wrong</ErrorMessage>
    </ErrorMessageContainer>
  </Container>
);

export default ErrorFallback;
