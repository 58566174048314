import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  staticDataFetchRequest: ['params'],
  staticDataFetchSuccess: ['data'],
  staticDataFetchError: ['error'],

  staticDataFetchStatesRequest: ['term'],
  staticDataFetchStatesSuccess: ['states'],
  staticDataFetchStatesError: ['error'],

  staticDataFetchCitiesRequest: ['statesIds'],
  staticDataFetchCitiesSuccess: ['cities'],
  staticDataFetchCitiesError: ['error'],

  staticDataFetchCountriesRequest: [null],
  staticDataFetchCountriesSuccess: ['countries'],
  staticDataFetchCountriesError: ['error'],

  staticDataFetchIndustriesRequest: [null],
  staticDataFetchIndustriesSuccess: ['industries'],
  staticDataFetchIndustriesError: ['error'],

  staticDataFetchSubIndustriesRequest: ['industriesIds'],
  staticDataFetchSubIndustriesSuccess: ['subIndustries'],
  staticDataFetchSubIndustriesError: ['error'],

  getClassStandingRequest: [],
  getClassStandingSuccess: ['classStanding'],
  getClassStandingError: ['error'],

  getZonesRequest: [],
  getZonesSuccess: ['zones'],
  getZonesError: ['error'],

  resetStaticDataStore: [],

  getDiversitiesRequest: [null],
  getDiversitiesSuccess: ['diversities'],
  getDiversitiesError: ['error'],

  getMajorsMinorsByCategoryRequest: ['entity', 'categoryId'],
  getMajorsMinorsByCategorySuccess: ['entity', 'entities'],
  getMajorsMinorsByCategoryError: ['entity', 'error'],

  getMajorsMinorsRequest: [],
  getMajorsMinorsSuccess: ['majorsMinors'],
  getMajorsMinorsError: ['error'],
});

export const staticDataTypes = Types;

export default Creators;
