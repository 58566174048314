import { Column, Row } from '@hivediversity/common-lib/base';
import { CustomButton } from '@hivediversity/common-lib/buttons';
import { colors } from '@hivediversity/common-lib/constant';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmCancelButtons, Dropdown, Modal } from 'components';
import opportunityDataCreator from 'store/opportunity/actions';
import { getOpportunitiesExpired } from 'store/opportunity/selectors';
import { getStudentFirstAndLastName } from 'utils';

import { StyledTitle } from './styled';

const HireSomeoneQuestionModal = () => {
  const dispatch = useDispatch();
  const opportunities = useSelector(getOpportunitiesExpired);
  const handleAnswer = (opportunityId, field, value) =>
    dispatch(opportunityDataCreator.setOpportunityHireSomeoneFromHive(opportunityId, field, value));

  return (
    <Modal
      show={opportunities.length > 0}
      background="linear-gradient(to bottom right, #FD4CF4, #000)"
      containerStyles={{
        minWidth: '200px',
        maxWidth: '800px',
        width: '100%',
      }}
      $bsStyle={{ borderRadius: '30px' }}
    >
      <>
        <Row center>
          <StyledTitle fontSize="22px">Did you hire someone from HIVE?</StyledTitle>
        </Row>
        <Column
          $bsStyle={{
            overflowY: 'auto',
            maxHeight: '380px',
            minHeight: '200px',
            paddingRight: '10px',
          }}
        >
          {opportunities.map(opportunity => (
            <Fragment key={opportunity.id}>
              <StyledTitle fontSize="18px">{`Position: ${opportunity.title}`}</StyledTitle>
              <ConfirmCancelButtons
                acceptLabel="Yes"
                cancelLabel="No"
                onDismiss={() => handleAnswer(opportunity.id, 'hireSomeoneFromHive', false)}
                onAccept={() => handleAnswer(opportunity.id, 'hireSomeoneFromHive', true)}
                acceptButtonStyle={{
                  opacity: isBoolean(opportunity.hireSomeoneFromHive) && opportunity.hireSomeoneFromHive ? 1 : 0.5,
                  minWidth: '100px',
                  maxWidth: '150px',
                  width: '100%',
                  marginBottom: '10px',
                }}
                cancelButtonStyle={{
                  opacity: isBoolean(opportunity.hireSomeoneFromHive) && !opportunity.hireSomeoneFromHive ? 1 : 0.5,
                  minWidth: '100px',
                  maxWidth: '150px',
                  width: '100%',
                  marginBottom: '10px',
                }}
              />
              {opportunity.hireSomeoneFromHive && !isEmpty(opportunity.applicants) && (
                <Row center>
                  <Dropdown
                    onChange={(name, values) => handleAnswer(opportunity.id, name, values)}
                    name="applicantsHired"
                    options={opportunity.applicants}
                    getOptionLabel={option => getStudentFirstAndLastName(option)}
                    noFormik
                    menuPortalTarget={undefined}
                    value={opportunity.applicantsHired}
                    isMulti
                    menuPlacement="top"
                    maxMenuHeight={100}
                    placeholder="Choose the hired applicant(s)"
                  />
                </Row>
              )}
            </Fragment>
          ))}
        </Column>
        <Column center>
          <CustomButton
            color={colors.GREEN}
            $bsStyle={{ marginTop: '30px' }}
            disabled={opportunities.some(opportunity => !isBoolean(opportunity.hireSomeoneFromHive))}
            onClick={() => dispatch(opportunityDataCreator.saveOpportunitiesExpiredRequest(opportunities))}
          >
            Confirm
          </CustomButton>
          <CustomButton
            color={colors.GREEN}
            $bsStyle={{
              marginTop: '30px',
              lineHeight: 'initial',
              marginLeft: '10px',
              background: 'transparent',
              textDecoration: 'underline',
            }}
            onClick={() =>
              dispatch(
                opportunityDataCreator.remindLaterOrDeclineOpportunitiesExpiredRequest({
                  opportunityIds: opportunities.map(opportunity => opportunity.id),
                  remindMeLater: 1,
                })
              )
            }
          >
            Remind me later
          </CustomButton>
          <CustomButton
            color={colors.GREEN}
            $bsStyle={{
              marginTop: '30px',
              lineHeight: 'initial',
              marginLeft: '10px',
              background: 'transparent',
              textDecoration: 'underline',
            }}
            onClick={() =>
              dispatch(
                opportunityDataCreator.remindLaterOrDeclineOpportunitiesExpiredRequest({
                  opportunityIds: opportunities.map(opportunity => opportunity.id),
                  declineToAnswer: 1,
                })
              )
            }
          >
            Decline to answer
          </CustomButton>
        </Column>
      </>
    </Modal>
  );
};

export default HireSomeoneQuestionModal;
