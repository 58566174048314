import styled from 'styled-components';

export const Button = styled.button.attrs({ type: 'button' })`
  border: none;
  padding: 16px 0px;
  width: 100%;
  margin: 0 20px;
  background: ${({ active }) => (active ? '#A39DF7' : 'rgba(0,0,0,0.5)')};
  color: #fff;
  font-size: 19px;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  border-radius: 11px 11px 0 0;
  max-width: 100%;
  cursor: pointer;
  opacity: 1;
  font-weight: 700;
  min-width: 200px;
  &:active,
  :focus {
    outline: 0;
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
  &:disabled {
    cursor: default;
  }
  ${({ $bsStyle }) => $bsStyle || ''}
  @media screen and (max-width: 1024px) {
    min-width: 146px;
    font-size: 15px;
    margin: 0 10px;
  }
  @media screen and (max-width: 767px) {
    min-width: 120px;
    font-size: 12px;
    margin: 0 5px;
    padding: 12px 0px;
  }
  @media screen and (max-width: 420px) {
    min-width: 100px;
    margin: 0 5px;
    padding: 8px 0px;
  }
`;

export const WrapperButton = styled.div`
  justify-content: flex-start;
  flex-direction: row;
  display: flex;
  border-bottom: 1px white solid;
  margin-bottom: 56px;
  width: 100%;
  overflow-x: auto;
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 549px) {
    flex-direction: column;
    & button {
      margin-bottom: 5px;
      margin-left: 0;
    }
  }
`;
