import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getStatsRequest: ['params'],
  getStatsSuccess: ['percentage'],
  getStatsError: ['error'],
});

export const statTypes = Types;

export default Creators;
