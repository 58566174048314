import { Form } from 'formik';
import styled from 'styled-components';

import ChartImage from 'assets/png/chart.png';
import editImage from 'assets/svg/edit.svg';
import { InputLabel } from 'styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const FormFormik = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const WrapperMulti = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  margin-bottom: 20px;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100vw;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const WrapperInput = styled.div`
  margin-bottom: ${({ marginBottom }) => marginBottom || '50px'};
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const SubtitleLabel = styled.p`
  font-family: Brandon Grotesque;
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  color: white;
  margin: 24px 0 16px 0;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const EmojiLabel = styled.h2`
  font-family: 'Gentleman', Arial, sans-serif;
  font-size: 24px;
  font-weight: 300;
  margin-top: 0;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const EditIcon = styled.img.attrs({ src: editImage, alt: 'edit', role: 'button' })`
  cursor: pointer;
  width: 25px;
  height: 25px;
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ isInitial }) => (isInitial ? '46px' : 0)};
  flex-direction: column;
  color: white;
  width: 100%;
`;

export const ChartImg = styled.img.attrs({ src: ChartImage })`
  min-width: 100%;
  max-width: 100%;
  @media only screen and (max-width: 600px) {
    max-width: 300px;
  }
`;

export const InputLabelWithAddButton = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  & ${InputLabel} {
    margin: 0;
  }
`;
