import { MessageError } from '@hivediversity/common-lib/components';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useSelector } from 'react-redux';

import Editor from 'pages/company/editor';
import { getIndustries } from 'services/company';
import { getCompany, getError } from 'store/company/selectors';
import { parseNullToEmptyString } from 'utils';

import { Container } from './styled';

const Company = () => {
  const company = useSelector(getCompany);
  const error = useSelector(getError);

  return (
    <Container background="linear-gradient(to bottom right, #FD4CF4, #000)">
      {!isEmpty(company) && (
        <Editor
          company={parseNullToEmptyString({
            ...company,
            industries: getIndustries(company?.industries),
          })}
          isInitial={!company.profileComplete}
        />
      )}
      <MessageError messageError={error} />
    </Container>
  );
};

export default Company;
