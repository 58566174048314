import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  companyFetchRequest: [null],
  companyFetchSuccess: ['company'],
  companyFetchError: ['error'],

  companyUpdateRequest: ['values'],
  companyUpdateSuccess: ['company'],
  companyUpdateError: ['error'],

  companyToggleActivateRequest: ['active'],
  companyToggleActivateSuccess: ['company'],
  companyToggleActivateError: ['error'],

  companyFetchProgressRequest: [null],
  companyFetchProgressSuccess: ['progress'],
  companyFetchProgressError: ['error'],

  companyFetchUsersRequest: [],
  companyFetchUsersSuccess: ['users'],
  companyFetchUsersError: ['error'],

  updateProfileComplete: ['value'],
});

export const companyTypes = Types;

export default Creators;
