import { applicantPropTypes, opportunityProps } from '@hivediversity/common-lib/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { contactTagEntities, contactTags as contactTagsEnums, resumeOrigin } from 'constant';
import { useContactTag } from 'hooks';
import { getApplicantEmail } from 'store/applicant/selectors';
import { getStaticData } from 'store/static-data/selectors';

import Card from './card';
import ConfirmCandidateEmail from './confirm-candidate-modal';
import ContactTags from './contact-tags';

const ApplicantCard = ({ applicant, opportunity, entity, origin }) => {
  const { contactTags } = useSelector(getStaticData);
  const email = useSelector(getApplicantEmail);
  const [
    setContactTag,
    setShowContactTags,
    acceptModal,
    dismissModal,
    showContactTags,
    showConfirmContactTagModal,
    applicantToSetTag,
  ] = useContactTag(opportunity, applicant, entity);

  return (
    <>
      <ConfirmCandidateEmail
        show={showConfirmContactTagModal && applicantToSetTag === applicant.id}
        email={email}
        onAccept={emailText => acceptModal(emailText)}
        onDismiss={dismissModal}
      />
      <Card
        origin={origin}
        entity={entity}
        applicant={applicant}
        opportunity={opportunity}
        onShowContactTags={showContactTag => setShowContactTags(showContactTag)}
        showContactTags={showContactTags}
      />
      <ContactTags
        show={showContactTags}
        contactTags={
          opportunity.hasExternalApplicationLink
            ? contactTags
            : contactTags?.filter(contactTag => contactTag.id !== contactTagsEnums.EXTERNAL_APPLICATION)
        }
        applicantContactTag={applicant.contactTag}
        onSelect={setContactTag}
      />
    </>
  );
};

ApplicantCard.propTypes = {
  applicant: applicantPropTypes.isRequired,
  opportunity: opportunityProps.isRequired,
  entity: PropTypes.oneOf([
    contactTagEntities.OPPORTUNITIES,
    contactTagEntities.OPPORTUNITY,
    contactTagEntities.OPPORTUNITIES_CREATED_BY_ME,
  ]).isRequired,
  origin: PropTypes.oneOf([resumeOrigin.OPPORTUNITIES, resumeOrigin.OPPORTUNITY]).isRequired,
};

export default ApplicantCard;
