import { apiRoutes } from 'constant';
import { transformRoute } from 'utils';

import { downloadCSVFile, getOrCached, post } from './http';

export const requestFindStudent = (opportunityId, applicantId) =>
  getOrCached(transformRoute(apiRoutes.STUDENT, { opportunityId, applicantId }));

export const requestSendApplicantLink = id => post(transformRoute(apiRoutes.SEND_APPLICANT_LINK, { id }));

export const requestGetApplicantEmail = (applicantId, tagId) =>
  getOrCached(transformRoute(apiRoutes.APPLICANT_EMAIL, { applicantId, tagId }), 'text');

export const requestDownloadApplicantsCSV = id =>
  downloadCSVFile(transformRoute(apiRoutes.DOWNLOAD_APPLICANTS_CSV, { id }));
