import React from 'react';
import { components } from 'react-select';

import { Triangle } from './styled';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Triangle />
  </components.DropdownIndicator>
);

export default DropdownIndicator;
