import { CompanyPreview, CompanyBanner } from '@hivediversity/common-lib/components';
import { companyProps } from '@hivediversity/common-lib/utils';
import size from 'lodash/size';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { companySocial } from 'constant';
import staticDataCreator from 'store/static-data/actions';
import { getStaticData } from 'store/static-data/selectors';

const Preview = ({ company }) => {
  const dispatch = useDispatch();
  const amountOpportunities = size(company.opportunities);
  const { companyTypes, employees, socialNetworks } = useSelector(getStaticData);

  useEffect(() => {
    dispatch(
      staticDataCreator.staticDataFetchRequest({
        companyTypes: true,
        employees: true,
        socialNetworks: true,
      })
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CompanyBanner
        company={{ ...company, industries: company.originalIndustries }}
        companyTypes={companyTypes}
        setEmoji
        separatorBand
      />

      <CompanyPreview
        company={company}
        amountOpportunities={amountOpportunities}
        companyTypes={companyTypes}
        socialNetworks={socialNetworks}
        companySocial={companySocial}
        numberOfEmployeesOptions={employees}
      />
    </>
  );
};

Preview.propTypes = {
  company: companyProps.isRequired,
};

export default Preview;
