import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { contactTagEntities } from 'constant';
import opportunityCreator from 'store/opportunity/actions';
import { getStaticData } from 'store/static-data/selectors';
import { getInitials } from 'utils';

import { Round, TagRound, TagsContainer, TagsSelector } from './styled';

const getTags = (tags = [], tagId) => tags.filter(tag => tag.id !== tagId);

const Tags = ({ applicantId, currentTag, opportunityId, entity }) => {
  const dispatch = useDispatch();
  const { tags } = useSelector(getStaticData);
  const [tagsMenu, setTagsMenu] = useState(false);
  const selectedTag = tags.find(tag => tag.id === currentTag);

  const handleClick = tagId => {
    setTagsMenu(false);
    dispatch(opportunityCreator.saveApplicantTagRequest(opportunityId, applicantId, tagId, entity, false));
  };

  return (
    <TagsContainer>
      <Round color={selectedTag?.color} onClick={() => setTagsMenu(!tagsMenu)} title={selectedTag?.name}>
        {getInitials(selectedTag?.id !== '' ? selectedTag?.name : '')}
      </Round>
      <TagsSelector $visibility={+tagsMenu}>
        {getTags(tags, selectedTag?.id).map((tag, index) => (
          <TagRound
            $visibility={+tagsMenu}
            delay={index + 1}
            key={tag.id}
            color={tag.color}
            title={tag.name}
            onClick={() => handleClick(tag.id, applicantId)}
          >
            {getInitials(tag.name)}
          </TagRound>
        ))}
      </TagsSelector>
    </TagsContainer>
  );
};

Tags.propTypes = {
  opportunityId: PropTypes.number.isRequired,
  applicantId: PropTypes.number.isRequired,
  currentTag: PropTypes.string,
  entity: PropTypes.oneOf([
    contactTagEntities.OPPORTUNITIES,
    contactTagEntities.OPPORTUNITY,
    contactTagEntities.OPPORTUNITIES_CREATED_BY_ME,
  ]).isRequired,
};

Tags.defaultProps = {
  currentTag: undefined,
};

export default Tags;
