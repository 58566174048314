import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { contactTags } from 'constant';
import applicantDataCreator from 'store/applicant/actions';
import { getCompany } from 'store/company/selectors';
import opportunityDataCreator from 'store/opportunity/actions';

const useContactTag = (opportunity, applicant, entity) => {
  const dispatch = useDispatch();
  const company = useSelector(getCompany);
  const [showContactTags, setShowContactTags] = useState(false);
  const [showConfirmContactTagModal, setShowConfirmModalContactTag] = useState(false);
  const [tagId, setTagId] = useState();
  const [applicantToSetTag, setApplicantToSetTag] = useState();

  const saveApplicantTag = (tag, email) =>
    dispatch(opportunityDataCreator.saveApplicantTagRequest(opportunity.id, applicant.id, tag, entity, true, email));

  const setContactTag = tag => {
    setShowContactTags(false);
    if ([contactTags.CONTACT_HIVE, contactTags.HIRED].includes(tag)) {
      saveApplicantTag(tag);
      setApplicantToSetTag(undefined);
    }
    if (contactTags.CONTACT_HIVE === tag) {
      window.location.href = `mailto:b@hellohive.com?subject=HIVE Employer Inquiry: ${company.name}`;
    }
    if (![contactTags.CONTACT_HIVE, contactTags.HIRED].includes(tag)) {
      setShowConfirmModalContactTag(true);
      setTagId(tag);
      setApplicantToSetTag(applicant.id);
      dispatch(applicantDataCreator.getApplicantEmailRequest(applicant.id, tag));
    }
  };

  const acceptModal = email => {
    setShowConfirmModalContactTag(false);
    saveApplicantTag(tagId, email);
    setApplicantToSetTag(undefined);
    dispatch(applicantDataCreator.cleanApplicantEmail());
  };

  const dismissModal = () => {
    setShowConfirmModalContactTag(false);
    setApplicantToSetTag(undefined);
    dispatch(applicantDataCreator.cleanApplicantEmail());
  };

  return [
    setContactTag,
    setShowContactTags,
    acceptModal,
    dismissModal,
    showContactTags,
    showConfirmContactTagModal,
    applicantToSetTag,
  ];
};

export default useContactTag;
