import { getLocation, push } from 'connected-react-router';
import { takeLatest, call, put, select, all, takeEvery } from 'redux-saga/effects';

import { contactTags as contactTagsEnums, routes } from 'constant';
import { AN_ERROR_OCCURRED } from 'constant/messages';
import {
  getPotentialApplicants,
  getTotalApplicants,
  requestOpportunities,
  requestOpportunity,
  requestSave,
  requestDeleteOpportunity,
  uploadFileRequest,
  requestSaveTag,
  removeFileRequest,
  requestSendApplicantEmail,
  requestOpportunityApplicants,
  requestSaveOpportunitiesExpired,
  remindLaterOrDeclineOpportunitiesRequest,
  requestFindApplicant,
  requestCreateNote,
  requestDeleteNote,
} from 'services/opportunity';
import opportunityCreator, { opportunityTypes } from 'store/opportunity/actions';
import { transformRoute } from 'utils';

export function* fetchOpportunities({ entity, filters }) {
  try {
    const opportunities = yield call(requestOpportunities, filters, entity);
    yield put(opportunityCreator.opportunityFetchSuccess(entity, opportunities));
  } catch (error) {
    yield put(opportunityCreator.opportunityFetchError(error));
  }
}

export function* findOpportunity({ opportunityId, term, skip }) {
  try {
    const { opportunity, message } = yield call(requestOpportunity, opportunityId, term, skip);
    if (message) {
      yield put(opportunityCreator.opportunityFindError({ message, previousUrl: routes.DASHBOARD }));
    } else {
      yield put(opportunityCreator.opportunityFindSuccess(opportunity));
    }
  } catch (error) {
    yield put(
      opportunityCreator.opportunityFindError({
        message: AN_ERROR_OCCURRED,
        previousUrl: routes.DASHBOARD,
      })
    );
  }
}

export function* createNote({ noteText, applicantId }) {
  try {
    const { data } = yield call(requestCreateNote, noteText, applicantId);
    if (data) yield put(opportunityCreator.applicantAddNote(data, applicantId));
  } catch (error) {
    yield put(
      opportunityCreator.opportunityFindError({
        message: AN_ERROR_OCCURRED,
        previousUrl: routes.DASHBOARD,
      })
    );
  }
}

export function* deleteNote({ noteId, applicantId }) {
  try {
    const { success } = yield call(requestDeleteNote, noteId);
    if (success) yield put(opportunityCreator.applicantRemoveNote(noteId, applicantId));
  } catch (error) {
    yield put(
      opportunityCreator.opportunityFindError({
        message: AN_ERROR_OCCURRED,
        previousUrl: routes.DASHBOARD,
      })
    );
  }
}

export function* uploadFormImage({ opportunityId, file }) {
  try {
    yield call(uploadFileRequest, opportunityId, file);
  } catch (error) {
    yield put(opportunityCreator.opportunitySaveError(error));
  }
}

export function* uploadFiles({ files, opportunityId }) {
  yield all(files.map(file => call(uploadFormImage, { opportunityId, file })));
}

export function* removeFormImage({ opportunityId, typeImage }) {
  try {
    yield call(removeFileRequest, opportunityId, typeImage);
  } catch (error) {
    yield put(opportunityCreator.opportunitySaveError(error));
  }
}

export function* removeFiles({ files, opportunityId }) {
  yield all(files.map(file => call(removeFormImage, { opportunityId, typeImage: file.typeImage })));
}

export function* saveOpportunity({ values }) {
  try {
    const response = yield call(requestSave, values);
    if (response.message) {
      yield put(opportunityCreator.opportunitySaveError(response.message));
    } else {
      const files = [
        {
          typeImage: 'teamMedia',
          files: values.teamMedia,
          toUpload: values.hasTeamMediaImage,
          toRemove: values.deleteTeamMediaImage,
        },
        {
          typeImage: 'logo',
          files: values.originalLogoUrl,
          toUpload: !!values.originalLogoUrl,
          toRemove: false,
        },
        {
          typeImage: 'photo',
          files: values.originalPhotoUrl,
          toUpload: !!values.originalPhotoUrl,
          toRemove: false,
        },
      ];
      let { opportunity } = response;
      const filesToUpload = files.filter(file => file.toUpload && !file.toRemove);
      const filesToRemove = files.filter(file => file.toRemove && !file.toUpload);
      if (filesToUpload.length > 0) {
        yield* uploadFiles({ files: filesToUpload, opportunityId: opportunity.id });
      }
      if (filesToRemove.length > 0) {
        yield* removeFiles({ files: filesToRemove, opportunityId: opportunity.id });
      }
      if (filesToRemove.length > 0 || filesToUpload.length > 0) {
        const data = yield call(requestOpportunity, opportunity.id);
        if (data.message) {
          yield put(
            opportunityCreator.opportunitySaveError({
              message: data.message,
              previousUrl: routes.DASHBOARD,
            })
          );
          return;
        }
        opportunity = data.opportunity;
      }
      yield put(opportunityCreator.opportunitySaveSuccess(opportunity));
      if (values.validations) {
        yield put(
          push(
            transformRoute(routes.REVIEW_OPPORTUNITY_TAB, {
              opportunityId: opportunity.id,
            })
          )
        );
      }
      const location = yield select(getLocation);
      if (
        !values.validations &&
        location.pathname !== transformRoute(routes.EDIT_OPPORTUNITY_TAB, { opportunityId: opportunity.id })
      ) {
        yield put(push(transformRoute(routes.EDIT_OPPORTUNITY_TAB, { opportunityId: opportunity.id })));
      }
    }
  } catch (error) {
    yield put(
      opportunityCreator.opportunitySaveError({
        message: AN_ERROR_OCCURRED,
        previousUrl: routes.DASHBOARD,
      })
    );
  }
}

export function* deleteOpportunity({ opportunityId }) {
  try {
    const { message } = yield call(requestDeleteOpportunity, opportunityId);
    if (message) {
      yield put(opportunityCreator.opportunityDeleteError(message));
    } else {
      yield put(opportunityCreator.opportunityDeleteSuccess());
      yield put(push(routes.DASHBOARD));
    }
  } catch (error) {
    yield put(
      opportunityCreator.opportunityFindError({
        message: AN_ERROR_OCCURRED,
        previousUrl: routes.DASHBOARD,
      })
    );
  }
}

export function* getPotentialApplicantsRequest({ opportunity }) {
  try {
    const { data } = yield call(getPotentialApplicants, opportunity);
    yield put(opportunityCreator.getPotentialApplicantsSuccess(data));
  } catch (error) {
    yield put(
      opportunityCreator.opportunityFindError({
        message: AN_ERROR_OCCURRED,
        previousUrl: routes.DASHBOARD,
      })
    );
  }
}

export function* getTotalApplicantsRequest({ opportunityId }) {
  try {
    const { data, message } = yield call(getTotalApplicants, opportunityId);
    if (message) {
      yield put(opportunityCreator.getTotalApplicantsError({ message, previousUrl: routes.DASHBOARD }));
    } else {
      yield put(opportunityCreator.getTotalApplicantsSuccess(data));
    }
  } catch (error) {
    yield put(opportunityCreator.getTotalApplicantsError(error));
  }
}

export function* updateOpportunityStatus({ id, status, validations, completed }) {
  try {
    const { opportunity, message } = yield call(requestSave, {
      id,
      status,
      validations,
      isStatusUpdate: true,
      completed,
    });
    if (message) {
      yield put(opportunityCreator.updateOpportunityStatusError(message));
    } else {
      yield put(opportunityCreator.updateOpportunityStatusSuccess(opportunity));
    }
  } catch (error) {
    yield put(opportunityCreator.updateOpportunityStatusError(AN_ERROR_OCCURRED));
  }
}

export function* confirmSendApplicantEmail({ applicantId, email }) {
  try {
    yield call(requestSendApplicantEmail, applicantId, email);
    yield put(opportunityCreator.confirmSendApplicantEmailSuccess());
  } catch (error) {
    yield put(opportunityCreator.confirmSendApplicantEmailError(error));
  }
}

export function* saveTag({ applicantId, tagId, isContactTag, email }) {
  try {
    const { applicant } = yield call(requestSaveTag, applicantId, tagId, isContactTag);
    yield put(opportunityCreator.saveApplicantTagSuccess(applicant));
    if (isContactTag && ![contactTagsEnums.CONTACT_HIVE, contactTagsEnums.HIRED].includes(tagId)) {
      yield call(confirmSendApplicantEmail, { applicantId, email });
    }
  } catch (error) {
    yield put(
      opportunityCreator.opportunityFindError({
        message: AN_ERROR_OCCURRED,
        previousUrl: routes.DASHBOARD,
      })
    );
  }
}

export function* getOpportunityApplicants({ opportunityId, filters }) {
  try {
    const { data, meta, message } = yield call(requestOpportunityApplicants, opportunityId, filters);
    if (message) {
      yield put(opportunityCreator.getOpportunityApplicantsError(message));
    } else {
      yield put(opportunityCreator.getOpportunityApplicantsSuccess(data, meta));
    }
  } catch (error) {
    yield put(
      opportunityCreator.opportunityFindError({
        message: AN_ERROR_OCCURRED,
        previousUrl: routes.DASHBOARD,
      })
    );
  }
}

export function* saveOpportunitiesExpired({ opportunities }) {
  try {
    yield call(requestSaveOpportunitiesExpired, opportunities);
    yield put(opportunityCreator.saveOpportunitiesExpiredSuccess());
  } catch (error) {
    yield put(opportunityCreator.saveOpportunitiesExpiredError(AN_ERROR_OCCURRED));
  }
}

export function* remindLaterOrDeclineOpportunities({ data }) {
  try {
    yield call(remindLaterOrDeclineOpportunitiesRequest, data);
    yield put(opportunityCreator.remindLaterOrDeclineOpportunitiesExpiredSuccess());
  } catch (error) {
    yield put(opportunityCreator.remindLaterOrDeclineOpportunitiesExpiredError(AN_ERROR_OCCURRED));
  }
}

export function* findApplicant({ opportunityId, applicantId }) {
  try {
    const { applicant, message } = yield call(requestFindApplicant, opportunityId, applicantId);
    if (message) {
      yield put(
        opportunityCreator.applicantFindError({
          message,
          previousUrl: transformRoute(routes.OPPORTUNITY_APPLICANTS_TAB, { opportunityId }),
        })
      );
    } else {
      yield put(opportunityCreator.applicantFindSuccess(applicant));
    }
  } catch (error) {
    yield put(opportunityCreator.applicantFindError(error));
  }
}

export function* opportunitySaga() {
  yield takeEvery(opportunityTypes.APPLICANT_DELETE_NOTE, deleteNote);
  yield takeEvery(opportunityTypes.APPLICANT_CREATE_NOTE, createNote);
  yield takeEvery(opportunityTypes.OPPORTUNITY_FETCH_REQUEST, fetchOpportunities);
  yield takeLatest(opportunityTypes.OPPORTUNITY_FIND_REQUEST, findOpportunity);
  yield takeLatest(opportunityTypes.OPPORTUNITY_SAVE_REQUEST, saveOpportunity);
  yield takeLatest(opportunityTypes.OPPORTUNITY_DELETE_REQUEST, deleteOpportunity);
  yield takeLatest(opportunityTypes.GET_POTENTIAL_APPLICANTS_REQUEST, getPotentialApplicantsRequest);
  yield takeLatest(opportunityTypes.GET_TOTAL_APPLICANTS_REQUEST, getTotalApplicantsRequest);
  yield takeLatest(opportunityTypes.UPDATE_OPPORTUNITY_STATUS_REQUEST, updateOpportunityStatus);
  yield takeLatest(opportunityTypes.SAVE_APPLICANT_TAG_REQUEST, saveTag);
  yield takeLatest(opportunityTypes.CONFIRM_SEND_APPLICANT_EMAIL_REQUEST, confirmSendApplicantEmail);
  yield takeLatest(opportunityTypes.GET_OPPORTUNITY_APPLICANTS_REQUEST, getOpportunityApplicants);
  yield takeLatest(opportunityTypes.SAVE_OPPORTUNITIES_EXPIRED_REQUEST, saveOpportunitiesExpired);
  yield takeLatest(
    opportunityTypes.REMIND_LATER_OR_DECLINE_OPPORTUNITIES_EXPIRED_REQUEST,
    remindLaterOrDeclineOpportunities
  );
  yield takeLatest(opportunityTypes.APPLICANT_FIND_REQUEST, findApplicant);
}
