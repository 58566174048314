import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';

const buildQueryString = searchParams => {
  const params = new URLSearchParams();
  mapValues(searchParams, (value, key) => {
    if (!isNil(value)) {
      params.set(key, value);
    }
  });
  return `?${params.toString()}`;
};

export default buildQueryString;
