import PropTypes from 'prop-types';
import React from 'react';

import { CheckField, Label, Container, WrapperCheck } from './styled';
import ErrorMessage from '../error-message';

const Check = ({
  name,
  className,
  centered,
  label,
  backgroundCheck,
  value,
  onChange,
  $bsStyle,
  labelStyle,
  link,
  noFormik,
  readOnly,
}) => (
  <Container className={className} centered={centered} $bsStyle={$bsStyle}>
    <WrapperCheck key={name}>
      <Label htmlFor={name} name={name} onClick={() => onChange(name, !value)}>
        <CheckField
          className="checkbox"
          type="checkbox"
          checked={value}
          {...{ name, backgroundCheck }}
          readOnly={readOnly}
        />
        <span className="circle" />
        {!link && (
          <p className="label" style={labelStyle}>
            {label}
          </p>
        )}
      </Label>
      {link && (
        <Label>
          <a style={labelStyle} rel="noreferrer" target="_blank" href={link}>
            {label}
          </a>
        </Label>
      )}
    </WrapperCheck>
    {!noFormik && <ErrorMessage control={name} />}
  </Container>
);

Check.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  backgroundCheck: PropTypes.string,
  link: PropTypes.string,
  $bsStyle: PropTypes.shape({}),
  labelStyle: PropTypes.shape({}),
  centered: PropTypes.bool,
  noFormik: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
};

Check.defaultProps = {
  centered: false,
  backgroundCheck: null,
  $bsStyle: {},
  labelStyle: {},
  link: undefined,
  value: false,
  noFormik: false,
  readOnly: false,
  className: '',
  onChange: () => {
    // eslint-disable-line @typescript-eslint/no-empty-function
  },
};

export default Check;
