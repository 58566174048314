import PropTypes from 'prop-types';
import React from 'react';

import { ContactTagSelector, TagsSelector, TagsSelectorContainer } from './styled';

const getContactTagsFiltered = (contactTags = [], tag) => contactTags.filter(contactTag => contactTag.id !== tag);

const ContactTags = ({ show, contactTags, onSelect, applicantContactTag }) => (
  <TagsSelectorContainer showContactTags={show}>
    {show && (
      <TagsSelector $visibility={show}>
        {getContactTagsFiltered(contactTags, applicantContactTag).map((tag, index) => (
          <ContactTagSelector $visibility={show} delay={index + 1} key={tag.id} onClick={() => onSelect(tag.id)}>
            {tag.name}
          </ContactTagSelector>
        ))}
      </TagsSelector>
    )}
  </TagsSelectorContainer>
);

ContactTags.propTypes = {
  onSelect: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  contactTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  applicantContactTag: PropTypes.number,
};

ContactTags.defaultProps = {
  contactTags: [],
  applicantContactTag: undefined,
};

export default ContactTags;
