import { Hr } from '@hivediversity/common-lib/base';
import { CustomButton, LoadingButton } from '@hivediversity/common-lib/buttons';
import { MessageError } from '@hivediversity/common-lib/components';
import { colors } from '@hivediversity/common-lib/constant';
import { push } from 'connected-react-router';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { routes } from 'constant';
import employerCreator from 'store/employer/actions';
import { getError } from 'store/employer/selectors';

import { ErrorMessage, FormContainer, Title, StyledForm, StyledTextBox, Container, WrapperButton } from './styled';
import validationSchema from './validation-schema';

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const ChangePassword = () => {
  const dispatch = useDispatch();
  const error = useSelector(getError);
  return (
    <Container background="linear-gradient(to bottom right, #742ca7, #000)">
      <WrapperButton onClick={() => dispatch(push(routes.SUPPORT))}>
        <CustomButton color={colors.LIGHT_BLUE}>Back</CustomButton>
      </WrapperButton>
      <Hr margin="20px 10px 0 0" />
      <FormContainer>
        <Title>Change password</Title>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={values =>
            dispatch(employerCreator.changePasswordRequest(values.currentPassword, values.passwordConfirmation))
          }
        >
          {({ isSubmitting }) => (
            <StyledForm>
              <div>
                <StyledTextBox name="currentPassword" type="password" placeholder="Current Password" />
                <MessageError control="currentPassword" />
              </div>
              <div>
                <StyledTextBox name="newPassword" type="password" placeholder="New Password" />
                <MessageError control="newPassword" />
              </div>
              <div>
                <StyledTextBox name="passwordConfirmation" type="password" placeholder="Confirm Password" />
                <MessageError control="passwordConfirmation" />
              </div>
              <LoadingButton
                component={CustomButton}
                color={colors.VIOLET}
                type="submit"
                label="Change password"
                isLoading={isSubmitting}
                $bsStyle={{ maxWidth: '200px', alignSelf: 'center' }}
              />
            </StyledForm>
          )}
        </Formik>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FormContainer>
    </Container>
  );
};

export default ChangePassword;
