import { push } from 'connected-react-router';
import { takeLatest, call, put } from 'redux-saga/effects';

import { defaultErrorMessage, routes } from 'constant';
import { requestLogin, requestImpersonate } from 'services/authorization';
import { getToken, setToken } from 'services/storage';
import authCreator, { authTypes } from 'store/authorization/actions';
import { transformRoute } from 'utils';

export function* login({ email, password }) {
  try {
    const { user, token, message } = yield call(requestLogin, email, password);
    if (token) {
      setToken(token);
      yield put(authCreator.authLoginSuccess(user, token));
      yield put(push(routes.ROOT));
    } else {
      yield put(authCreator.authLoginError(message));
    }
  } catch (error) {
    yield put(authCreator.authLoginError(defaultErrorMessage));
  }
}

export function* tokenRequest() {
  try {
    const token = yield call(getToken);
    yield put(authCreator.authGetTokenSuccess(token));
  } catch (error) {
    yield put(authCreator.authGetTokenError(error));
  }
}

export function* impersonate({ code, opportunityId }) {
  try {
    const { token, message } = yield call(requestImpersonate, code);
    if (token) {
      setToken(token);
      yield put(authCreator.authImpersonateSuccess(token));
    } else {
      yield put(authCreator.authImpersonateError(message));
    }
    yield put(push(opportunityId ? transformRoute(routes.OPPORTUNITY_APPLICANTS_TAB, { opportunityId }) : '/'));
  } catch (error) {
    yield put(authCreator.authImpersonateError(error));
  }
}
export function* authorizationSaga() {
  yield takeLatest(authTypes.AUTH_LOGIN_REQUEST, login);
  yield takeLatest(authTypes.AUTH_GET_TOKEN_REQUEST, tokenRequest);
  yield takeLatest(authTypes.AUTH_IMPERSONATE_REQUEST, impersonate);
}
