import styled from 'styled-components';

import exclamation from 'assets/png/exclamation.png';

export const Container = styled.div`
  background: linear-gradient(to bottom right, #fd4cf4, #000);
  height: 100vh;
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const ErrorMessage = styled.p`
  font-size: 30px;
  color: #fff;
`;

export const Exclamation = styled.img.attrs({ src: exclamation })`
  height: 80px;
  width: 80px;
`;
