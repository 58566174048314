import { TextBox } from '@hivediversity/common-lib/components/inputs/text-box/styled';
import styled from 'styled-components';

export const WrapperContainer = styled.div`
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const StyledTextBox = styled(TextBox)`
  &:hover {
    border: ${({ disabled }) => `1px solid ${disabled ? '#fff' : '#fdc93b'}`};
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
