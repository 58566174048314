export const administrationFields = [
  'hasExternalApplicationLink',
  'applicationUrl',
  'applicantsFilter',
  'applicationsReceived',
  'preferredContactEmail',
];

export const detailsFields = [
  'locations',
  'jobFunctions',
  'startDay',
  'startMonth',
  'startYear',
  'startHour',
  'startMinute',
  'anteMeridiem',
  'endDay',
  'endMonth',
  'endYear',
  'endHour',
  'endMinute',
  'endHourAnteMeridiem',
  'numberOfPotentialHires',
  'type',
  'division',
  'summary',
  'industries',
  'expectedDateDay',
  'expectedDateMonth',
  'expectedDateYear',
  'duration',
  'visibleForCandidates',
  'compensationVisibleForStudents',
  'annualSalaryFrom',
  'annualSalaryTo',
  'laborActAgreement',
  'opportunityFor',
  'opportunityForName',
  'opportunityForDescription',
  'eventAnteMeridiem',
  'eventMinute',
  'eventDay',
  'eventHour',
  'eventMonth',
  'eventYear',
  'salary',
  'paymentPeriod',
  'adminUsers',
];

export const qualificationsFields = [
  'requireUsWorkAuthorization',
  'sponsorWorkVisa',
  'hiringTemporarilyAuthorizedCandidates',
  'classStanding',
];
