import styled from 'styled-components';

export const Hr = styled.hr`
  width: 100%;
  margin: 10px 20px 10px 10px;
`;

export const Title = styled.label`
  font-size: 14px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  margin-bottom: 15px;
`;

export const Text = styled.label`
  font-size: 12px;
  color: white;
  line-height: 18px;
`;

export const ButtonContainer = styled.div`
  min-width: 120px;
  max-width: 220px;
  margin-bottom: 10px;
  width: 100%;
  @media screen and (min-width: 480px) {
    margin-right: 10px;
  }
`;
