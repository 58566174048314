import { push } from 'connected-react-router';
import { find, first } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { WrapperButton, Button } from './styled';

const Tabs = ({ id, active, onClick, children }) => {
  const dispatch = useDispatch();
  const selectedTab = find(children, child => child.props.id === active);
  const firstTab = first(children);
  const location = useLocation();
  const handleRedirect = useCallback(childId => dispatch(push(`${location.pathname}?tab=${childId}`)), []);

  return (
    <Fragment key={id}>
      <WrapperButton>
        {children.map(child => (
          <Button
            key={child.props.id}
            active={selectedTab ? child.props.id === active : child.props.id === firstTab.props.id}
            type="button"
            disabled={child.props.disabled}
            onClick={() => {
              handleRedirect(child.props.id);
              onClick(child.props.id);
            }}
            $bsStyle={{ ...child.props.$bsStyle, fontSize: '16px' }}
          >
            {child.props.title}
          </Button>
        ))}
      </WrapperButton>
      <Fragment key={id}>{selectedTab || firstTab}</Fragment>
    </Fragment>
  );
};

Tabs.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  active: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
};

Tabs.defaultProps = {
  id: 'tab',
  active: null,
  children: [],
};

export default Tabs;
