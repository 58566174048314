import { CustomButton } from '@hivediversity/common-lib/buttons';
import { colors } from '@hivediversity/common-lib/constant';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import React from 'react';

import { HiddenRadio, WrapperOption, RadioOption, Container } from './styled';
import ErrorMessage from '../error-message';

const Radio = ({
  options,
  name,
  value,
  onChange,
  isHorizontal,
  $bsStyle,
  setFieldValue,
  takeDefaultLabel,
  disabled,
  noFormik,
  isOptional,
}) => {
  const getDefaultLabel = option => {
    if (takeDefaultLabel) {
      return option.name ? option.name : option;
    }
    return capitalize(option.name ? option.name : option);
  };

  return (
    <>
      <Container key={`${name}-container`} $bsStyle={$bsStyle} isHorizontal={isHorizontal}>
        {options.map(option => (
          <WrapperOption
            key={option.name ? `${option.id}-wrapper` : `${option}-wrapper`}
            className={`${isHorizontal ? 'isHorizontal' : ''}`}
          >
            <HiddenRadio
              key={option.name ? `${name}-${option.id}` : option}
              id={option.name ? `${name}-${option.id}` : option}
              name={name}
              value={option.name ? option.id : option}
              onChange={e => {
                if (setFieldValue) {
                  setFieldValue(name, JSON.parse(e.target.value));
                } else {
                  onChange(e);
                }
              }}
              disabled={disabled}
              checked={option.name ? option.id === value : option === value}
            />
            <RadioOption
              key={option.id ? `${option.id}-button` : `${option}-button`}
              id={option.name ? `${name}-${option.id}` : option}
              htmlFor={option.name ? `${name}-${option.id}` : option}
              isHorizontal={isHorizontal}
            >
              <span className="correct-check" />
              <span>{getDefaultLabel(option)}</span>
            </RadioOption>
          </WrapperOption>
        ))}
        {isOptional && (
          <CustomButton
            color={colors.ORANGE}
            $bsStyle={{ maxWidth: '10px', padding: '20px', alignSelf: 'center', marginTop: '10px' }}
            onClick={() => {
              if (setFieldValue) {
                setFieldValue(name, null);
              } else {
                onChange({ target: { value: null, name } });
              }
            }}
          >
            X
          </CustomButton>
        )}
        {!noFormik && <ErrorMessage control={name} style={{ marginTop: '20px' }} />}
      </Container>
    </>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
      }),
    ])
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  isHorizontal: PropTypes.bool,
  $bsStyle: PropTypes.shape({}),
  takeDefaultLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  noFormik: PropTypes.bool,
  isOptional: PropTypes.bool,
};

Radio.defaultProps = {
  $bsStyle: {},
  isHorizontal: false,
  onChange: undefined,
  setFieldValue: undefined,
  takeDefaultLabel: false,
  disabled: false,
  noFormik: false,
  isOptional: false,
  value: undefined,
};

export default Radio;
