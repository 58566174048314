/* eslint no-useless-escape: 0 */
export const urlRegex =
  /^(https?:\/\/[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/[a-zA-Z0-9]+\.[^\s]{2,}|Https?:\/\/[a-zA-Z0-9]+\.[^\s]{2,})$/;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\\[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_NUMBER = /^(?=.*[0-9])/;

export const PASSWORD_LOWERCASE_LETTER = /^(?=.*[a-z])/;

export const PASSWORD_UPPERCASE_LETTER = /^(?=.*[A-Z])/;

export const PASSWORD_SPECIAL_CHARACTER = /^(?=.*[!@#\$%\^&\-\*])/;
