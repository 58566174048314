import { applicantsDefaultFilters } from 'constant';

export const initialState = {
  opportunitiesCreatedByMe: [],
  opportunitiesCreatedByOthers: [],
  archivedOpportunities: [],
  opportunities: [],
  opportunity: null,
  error: null,
  operationRequestNote: {
    msg: '',
    color: '',
  },
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  isSavingSection: false,
  applicants: [],
  opportunityId: null,
  filters: applicantsDefaultFilters,
  tags: [],
  contactTag: [],
  metadata: {},
  expiredOpportunities: [],
  tag: {},
};
