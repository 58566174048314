import { Column } from '@hivediversity/common-lib/base';
import { CustomButton } from '@hivediversity/common-lib/buttons';
import { colors } from '@hivediversity/common-lib/constant';
import PropTypes from 'prop-types';
import React from 'react';

const AddAndRemoveButtons = ({ onAdd, onRemove, showAddMore, showRemoveButton, addButtonStyle, removeButtonStyle }) => (
  <>
    {showAddMore && (
      <Column $bsStyle={addButtonStyle}>
        <CustomButton
          $bsStyle={{
            fontSize: '40px',
            maxWidth: '30px',
            marginBottom: '10px',
            marginRight: '20px',
            paddingTop: '3px',
          }}
          color={colors.VIOLET}
          onClick={onAdd}
        >
          +
        </CustomButton>
      </Column>
    )}
    {showRemoveButton && (
      <Column $bsStyle={removeButtonStyle}>
        <CustomButton
          $bsStyle={{
            fontSize: '40px',
            maxWidth: '30px',
            marginBottom: '10px',
            paddingBottom: '5px',
          }}
          color={colors.ORANGE}
          onClick={onRemove}
        >
          -
        </CustomButton>
      </Column>
    )}
  </>
);

AddAndRemoveButtons.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  addButtonStyle: PropTypes.shape({}),
  removeButtonStyle: PropTypes.shape({}),
  showAddMore: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
};

AddAndRemoveButtons.defaultProps = {
  addButtonStyle: {},
  removeButtonStyle: {},
  showAddMore: true,
  showRemoveButton: true,
};

export default AddAndRemoveButtons;
