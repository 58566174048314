import { Field } from 'formik';
import styled from 'styled-components';

import checkIcon from 'assets/svg/check.svg';

export const HiddenRadio = styled(Field).attrs({ type: 'radio' })`
  padding: 6px 0;
  display: ${({ hidden }) => (hidden ? 'hidden' : '')};
  &:checked,
  &:not(:checked) {
    left: -9999px;
    position: absolute;
  }
  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    padding-left: 50px;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    margin-bottom: 0;
    line-height: 20px;
    display: inline-flex;
    justify-content: flex-start;
  }
  &:checked + label:before,
  &:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 30px;
    transform: translateY(-50%);
    height: 30px;
    border: 1px solid #fd4cf4;
    border-radius: 100%;
    background: transparent;
    background-image: none;
  }
  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  &:checked + label:after,
  &:not(:checked) + label:after {
    content: '';
    width: 31px;
    height: 31px;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  &:checked + label:before {
    border: none;
  }
  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-50%);
    transform: scale(1) translateY(-50%);
    background-size: 46%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${({ disabled }) => (disabled ? 'gray' : '#00d154')};
    background-image: url(${checkIcon});
  }
`;

export const WrapperOption = styled.div`
  flex-shrink: 0;
  display: flex;
  transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
  min-height: 32px;
  margin-top: 10px;
  min-width: 200px;
  max-width: fit-content;
  &.isHorizontal {
    margin-right: 30px;
  }
  span {
    line-height: 1.8;
    display: block;
  }
  @media screen and (max-height: 860px) {
    .isHorizontal:not {
      min-height: 7.5vh;
    }
  }
  @media screen and (max-height: 812px) {
    .isHorizontal:not {
      padding: 6px 0;
      label {
        font-size: 1.6vh !important;
        @media screen and (max-height: 712px) {
          font-size: 1.8vh !important;
        }
        &:before {
          height: 5vh !important;
          width: 5vh !important;
        }
        &:after {
          height: 4vh !important;
          width: 4vh !important;
          left: 0.55vh !important;
        }
      }
    }
  }
`;

export const Container = styled.div`
  ${({ isHorizontal }) => (isHorizontal ? 'display: flex; flex-direction: row; flex-wrap: wrap;' : '')}
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const RadioOption = styled.label`
  display: ${({ hidden }) => (hidden ? 'hidden' : '')};
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  ${({ isHorizontal }) => (isHorizontal ? '' : 'width: 100%')}
`;
