import styled from 'styled-components';

export const Title = styled.h3`
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
`;

export const OpportunityTitle = styled.h3`
  color: #ffc033;
  text-transform: capitalize;
`;

export const CompanyName = styled.h4`
  color: #8f8f8f;
  text-transform: capitalize;
`;

export const Card = styled.div`
  cursor: pointer;
  border-radius: 33px;
  background: linear-gradient(135deg, rgba(29, 37, 38, 1) 0%, rgba(29, 37, 38, 1) 15%, rgba(0, 0, 0, 1) 100%), #1d2526;
  padding: 24px;
  margin: 20px 20px 20px 0;
  letter-spacing: 0.01px;
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 1;
  }
`;

export const Description = styled.p`
  font-family: 'Gentleman', Arial, sans-serif;
  color: #fff;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  overflow-wrap: break-word;
  overflow: hidden;
  max-height: 150px;
`;

export const WrapperImage = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  object-fit: cover;
  height: 80px;
  width: 80px;
  border-radius: 50%;
`;

export const Wrapper = styled.div`
  flex-direction: row;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  grid-gap: 1rem;
  @media screen and (min-width: 1028px) {
    max-width: 1028px;
    align-self: center;
  }
`;

export const EmptyOpportunities = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: 600;
`;
